import React, { useEffect, useState } from "react";
import { Users } from "react-feather";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import swal from "sweetalert2";
// import { addUsersLogin, getRolesUserData, updateUser, userActive, userInactive } from "../Apicommon";
import { BootstrapTooltip, socket } from "../../Common/common";
import { activeandinactive, addUsersLogin, getAllRoles, SocketBaseValidUserCheck, updateUser, updateUserPassword, UserPhotouploads } from "../../Common/Utils";
import { ContryCodeData } from "../Login/Contrycode";
import { Modal } from "antd";
import { Formik,Field,Form,ErrorMessage } from "formik";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import * as Yup from "yup";
window.Swal = swal;
export default function AddUser() {
	const [conCode, setConCode] = useState("+91");
	const [user, setUser] = useState({
		userID: new Date().valueOf(),
		LastName: "",
		FirstName: "",
		email: "",
		password: "",
		profile_user: "",
		mobileNo: null,
		roles: "",
		create_by: "admin",
	});
	const [flag, setFlag] = useState(false);
	const [userId, setuserId] = useState(null);
	const [loginErrors, setValidation] = useState({});
	const [disable, setDisable] = useState(true);
	const [role, setrole] = useState([]);

	const location = useLocation();
	const [pass, setPass] = useState(false);
	const [ismodel,setismodel]=useState(false)
	const history = useNavigate();
	useEffect(() => {
		if (location?.state) {
			setuserId(location?.state?.unique_id);
			setDisable(false);
			setUser({
				LastName: location?.state?.last_name,
				FirstName: location?.state?.first_name,
				email: location?.state?.email,
				password: "",
				profile_user: "",
				mobileNo: location?.state?.mobile,
				roles: location?.state?.role,
				STATUS: location?.state?.status,
				create_by: "admin",
			});
		}
	}, [location]);

	useEffect(() => {
		getRoles();
	}, []);

	const getRoles = async () => {
		const data = await getAllRoles();
		if (data.success === true) {
			setrole(data.data.data);
		} else {
			console.log("somthing want wrong");
		}
	};

	function isImage(icon) {
		const ext = [".jpg", ".jpeg", ".bmp", ".gif", ".png", ".svg"];
		return ext.some((el) => icon.endsWith(el));
	}

	const onHandaleFile = (e) => {
		console.log(e.target.files);
		var totalSizeMB = e.target.files[0].size / Math.pow(1024, 2);
		if (totalSizeMB >= 2 || !isImage(e.target.files[0].name)) {
			swal.fire("", "File size should be under 2 MB and File Extension Should only be PNG , JPG , JPEG , JIF . Please Try Again", "error");
		} else {
			// var reader = new FileReader();
			// reader.onloadend = function () {
			// 	var base64data = reader.result;
			setUser({ ...user, profile_user: e.target.files[0] });
			// };
			// reader.readAsDataURL(e.target.files[0]);
		}
	};

	const onHandalchange = (e) => {
		setUser({ ...user, [e.target.name]: e.target.value });
	};

	const validation = (name, value) => {
		switch (name) {
			case "email":
				const emailRegex = new RegExp(/^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-]+@[A-Za-z0-9.-]+$/, "gm");
			const checks=emailRegex.test(value)
				if (!value) {
					return "Please input email!";
				}else if(!checks){
					return "Please input Valid email!";

				}else if(checks==true){
					return '';

				} else {
					return "";
				}
				
			case "password":
				const emailRegex1 = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{6,}$/, "gm");
			const checks1=emailRegex1.test(value)
				if (!value) {
					return "Please input password!";
				}else if (!checks1) {
					return "Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.";
				} else {
					return "";
				}
			case "LastName":
				if (!value) {
					return "Please input Last Name!";
				} else {
					return "";
				}
			case "FirstName":
				if (!value) {
					return "Please input First Name!";
				} else {
					return "";
				}
			case "mobileNo":
				if (!value) {
					return "Please input Mobile No!";
				}else if(!value?.split(' ')[1]){
					return "Please input Mobile No!";

				} else {
					return "";
				}
			case "roles":
				if (!value) {
					return "Please input roles!";
				} else {
					return "";
				}
			default: {
				return null;
			}
		}
	};
	const generateClientId = () => {
        const length = 32;
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * characters.length));
        }

        return result;
    };
	const onSubmitAddUser = async (e) => {
		e.preventDefault();
		setFlag(true);
		let allErrors = {};
		Object.keys(user).forEach((key) => {
			const error = validation(key, user[key]);
			if (error && error.length) {
				allErrors[key] = error;
			}
		});
		console.log(allErrors);
		if (userId !== null && allErrors.password) {
			delete allErrors['password']
		}

		if (Object.keys(allErrors).length) {
			setFlag(false);
			return setValidation(allErrors);
		} else {
			setValidation({});
			if (userId === null) {
				const client_id = generateClientId();
        const client_secret = generateClientId();
				if (user.profile_user !== "") {
					const formData = new FormData();
					formData.append("Images", user.profile_user);
					const uploadImges = await UserPhotouploads(formData);

					if (uploadImges.success === true) {
						const data = await addUsersLogin({
							...user,
							mobileNo: user.mobileNo,
							profile_user: uploadImges.data.originalname,
							client_id:client_id,
							client_secret:client_secret
							
						});
						if (data.success === true) {
							toast.success("Add User Success");
							setUser({
								userID: null,
								LastName: "",
								FirstName: "",
								email: "",
								password: "",
								profile_user: "",
								mobileNo: null,
								roles: "",
							});
							history("/user");
							setFlag(false);
						} else if (data?.err?.response?.status === 400) {
							toast.error("User Email Already Exist");
							setFlag(false);
						}else if (data?.err?.response?.status === 503) {
							toast.error("User Mobile No. Already Exist");
							setFlag(false);
						}else{
							toast.error("Server Error");
						setFlag(false);
						}
					} else {
						toast.error("User Photo Not Valid");
						setFlag(false);
					}
				} else {
					const data = await addUsersLogin({ ...user, mobileNo: user.mobileNo,client_id:client_id,
					client_secret:client_secret });
					if (data.success === true) {
						toast.success("Add User Success");
						setUser({ userID: null, LastName: "", FirstName: "", email: "", password: "", profile_user: "", mobileNo: null, roles: "" });
						history("/user");
						setFlag(false);
					} else if (data?.err?.response?.status === 400) {
						toast.error("User Email Already Exist");
						setFlag(false);
					}else if (data?.err?.response?.status === 503) {
						toast.error("User Mobile No. Already Exist");
						setFlag(false);
					}else{
						toast.error("Server Error");

						setFlag(false);
					}
				}
			} else {
				if (user.profile_user !== "" && typeof user.profile_user !== "string") {
					const formData = new FormData();
					formData.append("Images", user.profile_user);
					const uploadImges = await UserPhotouploads(formData);

					if (uploadImges.success === true) {
						const obj = {
							LastName: user.LastName,
							FirstName: user.FirstName,
							email: user.email,
							profile_user: uploadImges.data.originalname,
							mobileNo: user.mobileNo,
							roles: user?.roles,
							create_by: user?.create_by,
							password:user?.password
						};
						const data = await updateUser(userId, obj);
						if (data.success === true) {
							toast.success("Update User Success");
							setUser({
								userID: "",
								LastName: "",
								FirstName: "",
								email: "",
								password: "",
								profile_user: "",
								mobileNo: null,
								roles: "",
							});
							history("/user");
							setFlag(false);
						}  else if (data?.err?.response?.status === 400) {
							toast.error("User Email Already Exist");
							setFlag(false);
						}else if (data?.err?.response?.status === 503) {
							toast.error("User Mobile No. Already Exist");
							setFlag(false);
						} else {
							toast.error("Server Error");

							setFlag(false);
						}
					} else {
						toast.error("User Photo Not Valid");
						setFlag(false);
					}
				} else {
					const obj = {
						LastName: user.LastName,
						FirstName: user.FirstName,
						email: user.email,
						profile_user: user?.profile_user,
						mobileNo: user.mobileNo,
						roles: user?.roles,
						create_by: user?.create_by,
						password:user?.password
					};
					const data = await updateUser(userId, obj);
					if (data.success === true) {
						toast.success("Update User Success");
						setUser({ userID: "", LastName: "", FirstName: "", email: "", password: "", profile_user: "", mobileNo: null, roles: "" });
						history("/user");
						setFlag(false);
					}  else if (data?.err?.response?.status === 400) {
						toast.error("User Email Already Exist");
						setFlag(false);
					} else if (data?.err?.response?.status === 503) {
						toast.error("User Mobile No. Already Exist");
						setFlag(false);
					} else {
						toast.error("Server Error");

						setFlag(false);
					}
				}
			}
		}
	};

	const oninactive = async () => {
		swal.fire({
			title: "Are you sure Inactive user?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data = await activeandinactive({ tableName: "user_index_dev", colName: "STATUS", value: "0", matchId: "unique_id" }, userId);
				if (data.success === true) {
					toast.success("Success Inactive user");
					history("/user");
				} else {
					toast.error("something want wrong");
				}
			}
		});
	};
	const onactive = async () => {
		swal.fire({
			title: "Are You Sure User Active?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
			if (willDelete.isConfirmed) {
				const data = await activeandinactive({ tableName: "user_index_dev", colName: "STATUS", value: "1", matchId: "unique_id" }, userId);
				if (data.success === true) {
					toast.success("Success Active user");
					history("/user");
				} else {
					toast.error("something want wrong");
				}
			}
		});
	};

	const AllUserLogout=async()=>{
		// const data=await SocketBaseValidUserCheck({UNIQUE_ID:userId})
		// if(data?.success==true){
		// toast.success("All User Logout Success");
		// }

	}

	const handleCancel=()=>{
		setismodel(false)
	}
	const passwordSchema = Yup.object({
		password: Yup
		  .string()
		  .required('Please enter your password.')
		  .matches(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]).{6,}$/,
			'Password must contain at least one lowercase letter, one uppercase letter, one digit, and one special character.'
		  )
		  .min(6, 'Password must be at least 6 characters long.'),
		retypePassword: Yup
		  .string()
		  .required('Please retype your password.')
		  .oneOf([Yup.ref('password')], 'Your passwords do not match.')
	  });

	  const onSubmitBtnClick=async(resetInfo)=>{
let resetObj = { empEmail: user?.email, password: resetInfo?.retypePassword, comform_password: resetInfo?.retypePassword }
const data=await updateUserPassword(userId,resetObj)
    if(data.success===true){
      swal.fire('', 'Update Password Successfuly', 'success');
	  setismodel(false)

    }else if (data?.err?.response?.status === 400) {
		setismodel(false)
		swal.fire('', 'Password Not Match', 'error');
	}else{
		setismodel(false)
		swal.fire('', 'Server Issue', 'error');

    }
	  }
	return (
		<>
		<Modal title="Reset Password" visible={ismodel} footer='' onCancel={handleCancel} width={500}>
		<Formik initialValues={{
                                        Email: user?.email,
                                        password: '',
                                        retypePassword: '',

                                    }} validationSchema={passwordSchema}
                                        enableReinitialize={true}
                                        onSubmit={(values, { resetForm }) => {
                                            console.log(JSON.stringify(values))
                                            onSubmitBtnClick(values)
                                            resetForm()
                                        }}>
                                        {({ values, errors, touched, isValid, dirty }) => (
                                            <Form>
                                                <div className='tw-pb-1 '>
                                                    <div className='tw-my-4'>
                                                        <label htmlFor='Email' className='tw-mb-2 tw-px-2 tw-font-semibold tw-text-gray-900 '>Email</label>

                                                        <Field disabled value={values.Email} className='tw-bg-gray-50 tw-border tw-border-solid tw-border-gray-200 tw-text-gray-900 sm:tw-text-sm tw-rounded-lg focus:!tw-border-indigo-500 tw-w-full tw-p-2.5 ' name='Email' id='Email' autoComplete='false' type="email" ></Field>

                                                    </div>
                                                    <div className='tw-my-4'>
                                                        <label htmlFor='password' className='tw-mb-2 tw-px-2 tw-font-semibold tw-text-gray-900 '>Password</label>

                                                        <Field className='tw-bg-gray-50 tw-border tw-border-solid tw-border-indigo-200 tw-text-gray-900 sm:tw-text-sm tw-rounded-lg focus:!tw-border-indigo-500 tw-w-full tw-p-2.5 ' name='password' id='password' type='password' autoComplete='current-password'  ></Field>
                                                        <div className='tw-text-red-500 tw-text-xs' >
                                                            <ErrorMessage name="password" />
                                                        </div>

                                                    </div>
                                                    <div className='tw-my-4'>
                                                        <label htmlFor='retypePassword' className='tw-mb-2 tw-px-2 tw-font-semibold tw-text-gray-900 '>Confirm Password</label>

                                                        <Field className='tw-bg-gray-50 tw-border tw-border-solid tw-border-indigo-200 tw-text-gray-900 sm:tw-text-sm tw-rounded-lg focus:!tw-border-indigo-500 tw-w-full tw-p-2.5' name='retypePassword' id='retypePassword' type='password' autoComplete='current-password'  ></Field>
                                                        <div className='tw-text-red-500 tw-text-xs' >
                                                            <ErrorMessage name="retypePassword" />
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className='tw-flex tw-gap-2 tw-my-4'>
                                                    <button type="submit" disabled={!isValid} className={`${!isValid ? 'tw-opacity-60 tw-bg-indigo-500' : 'tw-bg-indigo-500 hover:tw-bg-indigo-600'} tw-shadow-md tw-shadow-indigo-500/50 tw-px-3 tw-text-white tw-py-2 tw-rounded-md `}>Save</button>
                                                    <Link onClick={()=>{setismodel(false)}}>
                                                        <button type="button" className={'tw-px-3 tw-text-black tw-py-2 tw-rounded-md tw-bg-zinc-200  hover:tw-bg-zinc-300 tw-shadow-md tw-shadow-zinc-500/50'}>Cancel</button>
                                                    </Link>
                                                </div>
                                            </Form>
                                        )}

                                    </Formik>
		</Modal>
			<div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/group-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/roles-users">
								<Users style={{ height: "16px" }} /> Roles & Users
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to="/users">Users</Link>
						</li>
						<li className="breadcrumb-item">
							<Link>{userId === null ? "Add User" : "Update User"}</Link>
						</li>
					</ol>
				</nav>
				<section className="section">
					<div className="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4>{userId === null ? "Add User" : "Update User"}</h4>
										<div class="card-header-action">
										{userId != null ?<div  class="btn btn-primary mr-2" onClick={()=>(setismodel(true))}>
												Set Password
											</div>:''}
											{userId !== null ? (
												<div class="btn btn-info text-white btnedit mr-2">
													<BootstrapTooltip title="edit">
														<span
															data-toggle="tooltip"
															data-placement="bottom"
															title=""
															data-original-title="Edit"
															onClick={() => setDisable(true)}
														>
															<i class="fas fa-edit  text-white"></i>
														</span>
													</BootstrapTooltip>
												</div>
											) : (
												""
											)}
											{userId !== null && user?.STATUS === 1 ? (
												<div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={oninactive}>
													<BootstrapTooltip title="Inactive">
														<span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive">
															<i class="fas fa-ban  text-white"></i>
														</span>
													</BootstrapTooltip>
												</div>
											) : (
												""
											)}
											{userId !== null && user?.STATUS === 0 ? (
												<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}>
													<BootstrapTooltip title="Active">
														<span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive">
															<i class="fas fa-check  text-white"></i>
														</span>
													</BootstrapTooltip>
												</div>
											) : (
												""
											)}
											<Link to="/user" class="btn btn-info text-white">
												Back
											</Link>
										</div>
									</div>
									<div class="card-body">
										<form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data">
											<div class="form-row">
												<div class="form-group col-md-3">
													<label for="inputEmail4">Role:<span class="text-danger">*</span></label>
													<select
														id="inputState"
														class="form-control form-select"
														name="roles"
														onChange={onHandalchange}
														value={user.roles}
														disabled={!disable}
													>
														<option value="">Select Role</option>
														{role && role.map((item) => <option value={item.roles_unique_id}>{item.roles_name}</option>)}
													</select>
													<p className="text-danger">{loginErrors?.roles || ""}</p>
												</div>
											</div>
											<div class="form-row">
												<div class="form-group col-md-3">
													<label for="inputEmail4">First Name:<span class="text-danger">*</span></label>
													<input
														type="text"
														class="form-control MapLat"
														name="FirstName"
														placeholder="Enter First Name"
														onChange={onHandalchange}
														value={user.FirstName}
														disabled={!disable}
													/>
													<p className="text-danger">{loginErrors?.FirstName || ""}</p>
												</div>
												<div class="form-group col-md-3">
													<label for="inputPassword4">Last Name:<span class="text-danger">*</span></label>
													<input
														type="text"
														class="form-control MapLat"
														name="LastName"
														placeholder="Enter Last Name"
														onChange={onHandalchange}
														value={user.LastName}
														disabled={!disable}
													/>
													<p className="text-danger">{loginErrors?.LastName || ""}</p>
												</div>
												<div class="form-group col-md-3">
													<label for="inputPassword4">Email:<span class="text-danger">*</span></label>
													<input
														type="email"
														class="form-control MapLat"
														name="email"
														placeholder="Enter Email"
														onChange={onHandalchange}
														value={user.email}
														disabled={!disable}
														autocomplete="off"
													/>
													<p className="text-danger">{loginErrors?.email || ""}</p>
												</div>
												
												<div class="form-group col-md-3">
													<label for="inputState"> Mobile Number:<span class="text-danger">*</span></label>
													<PhoneInput
  country={'in'}
  value={user?.mobileNo?.split('+')[1]?.replaceAll("\\s", "")}
  onChange={(value, data, event, formattedValue) => {
	setUser({ ...user, mobileNo: `${formattedValue?.split('-')?.join('')||''}` })}}
  disabled={!disable}
/>
													{/* <div className="d-flex">
														<select
															class="form-select form-control"
															aria-label="Default select example"
															style={{ width: "36%" }}
															onChange={(e) => setConCode(e.target.value)}
															value={conCode}
															disabled={!disable}
															autocomplete="off"
														>
															{ContryCodeData &&
																ContryCodeData.map((item) => (
																	<option value={item.dial_code}>{item.dial_code}</option>
																))}
														</select>
														<input
															type="text"
															class="form-control MapLat"
															name="mobileNo"
															maxlength="10"
															placeholder="Enter Mobile Number"
															onChange={onHandalchange}
															disabled={!disable}
															value={user.mobileNo}
														/>
													</div> */}
													<p className="text-danger">{loginErrors?.mobileNo || ""}</p>
													<input type="hidden" class="form-control MapLat" id="btntypeforsubmit" name="submityper" />
												</div>
											</div>
											<div class="form-row">
												<div class="form-group col-md-3">
													<label for="inputState">Profile Photo</label>
													<input
														class="form-control"
														type="file"
														id="user_photo"
														name="user_profile_photo"
														onChange={onHandaleFile}
														disabled={!disable}
													/>
													<span id="user_phpt_span" style={{ display: "none" }}>
														<img id="us_pftp" height="100" width="100" />
													</span>
												</div>
												
													{userId==null?<div class="form-group col-md-3">
														<label for="inputState">Password</label>
														<div class="input-group">
															<input
																class="form-control"
																type={`${pass ? "text" : "password"}`}
																name="password"
																id="user_password"
																placeholder="Enter Password"
																onChange={onHandalchange}
																value={user.password}
																disabled={!disable}
																autocomplete="off"
															/>

															<div class="input-group-append" onClick={() => setPass(!pass)}>
																<div class="input-group-text">
																	{pass ? (
																		<i
																			class={"far fa-eye-slash"}
																			id="togglePassword"
																			style={{ cursor: "pointer" }}
																		></i>
																	) : (
																		<i class={"far fa-eye"} id="togglePassword" style={{ cursor: "pointer" }}></i>
																	)}
																</div>
															</div>
														</div>
														<p className="text-danger">{loginErrors?.password || ""}</p>
													</div>:''}
												
											</div>
											<div class="form-row d-flex justify-content-between"></div>
										</form>
									</div>
									<div class="form-group" style={{ marginLeft: "20px" }}>
										<div class="text-left">
											<input
												type="submit"
												name="submit"
												class={`btn btn-danger ${flag ? "btn-progress" : ""}`}
												value={userId === null ? `Submit` : `Update`}
												id="submitvn"
												onClick={onSubmitAddUser}
												disabled={!disable}
											/>
											<Link class="btn btn-light text-dark ml-3" to="/user">
												Cancel
											</Link>
											{userId !== null?<div class="btn btn-primary ml-3" onClick={AllUserLogout}>
												All User Logout
											</div>:''}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</>
	);
}

import React, { useEffect, useState } from 'react'
import { Users } from 'react-feather'
import { Link, useNavigate, useParams } from 'react-router-dom'
// import GeoLocation from '../../../Common/GecodeLocation'
import { activeandinactive, getAccount, getAllMeter, getAllLoraModem, getAllModem, getArea, getAreaById, getCircle, getCircleById, getCountUseToConsumerInEUI, getDivision, getDivisionById, getGa, getGaById, getGSMDataById, GetLoraModemTypeWiseIMEI, getMeterByID, getModemByID, getRolesById, getServiceProfile, getSubdevision, getSubdevisionById, InsertGsmData, UpdateInGSMmodem, UpdateInLorawanmodem, UpdateInSlave, UpdateConsumerStatusHistory, getslave_all_register_profile, getSlaveGroups } from '../../../Common/Utils';
import { Modal, Space, DatePicker } from 'antd'
import moment from 'moment'
// import MapPicker from 'react-google-map-picker';
import { ContryCodeData } from '../../Login/Contrycode';
import $ from 'jquery'
import {toast} from 'react-toastify'
import { BootstrapTooltip } from '../../../Common/common';
import Select from 'react-select';
import swal from "sweetalert2";
window.Swal = swal;
const DefaultLocation = { lat: null, lng: null };
const DefaultZoom = 10;

export default function Consumerinfo(props) {
    const {GSMdata,ConsumerDashDetails}=props
   const [country, setCountry] = useState("");
   const [state, setState] = useState("");
   const [city, setCity] = useState("");
   const [serviceData,setServiceData]=useState([])

   const [SubdevisionData, setSubdevisionData] = useState([])
   const [DivisionData, setDivisionData] = useState([])
   const [CircleData, setCircleData] = useState([])
   const [GaData, setGaData] = useState([])
   const [AccounData, setAccountData] = useState([])
   const [AreaData, setAreaData] = useState([])
   const [ModemData, setModemData] = useState([])
   const [MeterData, setMeterData] = useState([])
   const [Profile, setProfile] = useState({ account: '', ga: '', circle: '', division: '', subdivision: '', area: '' })
   const [MeterInputs, setMeterInputs] = useState({})
   const [ModemInputs, setModemInputs] = useState({})
   const [remark, setRemark] = useState('')
   const [Consumer, setConsumer] = useState({ cunsumerId: '', Installation: '', BPN: '' })
   const [ConsumerType, setConsumerType] = useState({ MeterId: GSMdata?.slave_type, ModemId: GSMdata?.modem_type, IMEI: GSMdata?.mmd_id_eui, MSRNO: GSMdata?.slave_serial_no, PSNO: '',MachanicalNo:'',FPORT:'2',slave_version:'' })
   const [serviceInput,setServiceInput]=useState('')
   const [AccountInput, serAccountInput] = useState({ "address_compony_name": '', "address_building_no": "", "address_street_area": "", "address_landmark_colony": "", "address_longitude": "", "address_lattitude": "", "address_pin_code": "", "address_city_id": "", "address_state_id": "", "address_country_id": "" })
   const [contactDetails, setcontactDetails] = useState([])
   const [installDetails, setinstallDetails] = useState({ "Installation_Person_First_Name": "", "Installation_Person_Last_Name": "", "Installation_Person_Email": "", "Installation_Person_Work_Number": "", "Installation_Person_Mobile_Number": "", "Installation_Person_Position": null, "Installation_Date_And_Time": "" })
   const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [location, setLocation] = useState(defaultLocation);
   const [zoom, setZoom] = useState(DefaultZoom);
   const [disable,setDisable]=useState(true)
   const history=useNavigate()
   const params=useParams()
   const [permision,setpermision]=useState({})
	const [VersionList,setVersionList]=useState([])
   const [pole_Details,setpole_Details]=useState({})
	const userInfo=JSON.parse(window.localStorage.getItem('user_info'))
   const [BtnFlag,setBtnFlag]=useState(false)
   const [GroupDetails,setGroupDetails]=useState([])

   const onHandaleRoles=async()=>{
      const data=await getRolesById(userInfo?.role)
      if(data.success===true){
         const obj=data.data.data[0]
         setpermision({roles_permission_category0: JSON.parse(obj?.roles_permission_category0||'{}'), roles_permission_category5: JSON.parse(obj?.roles_permission_category5||'{}'), roles_permission_category2: JSON.parse(obj?.roles_permission_category2||'{}'), roles_permission_category3: JSON.parse(obj?.roles_permission_category3||'{}'), roles_permission_category1: JSON.parse(obj?.roles_permission_category1||'{}'), roles_permission_category4: JSON.parse(obj?.roles_permission_category4||'{}'),roles_other_permission:JSON.parse(obj?.roles_other_permission||'{}')})
      }else{
      console.log('something want wrong')
      }
           }

const onHandaleMeter=async()=>{
   const data=await getMeterByID(ConsumerType.MeterId)
   if(data.success===true){
      data.data.data.length>0?setMeterInputs(data.data.data[0]):setMeterInputs({})
      
   }else{
      console.log('something want wrong')
   }
}



const ongetGaById=async(id)=>{
   const data1=await getGaById(id)
   if(data1.success===true){
       setGaData(data1.data.data)
   }
}

const ongetCircle=async(id)=>{
   const data1=await getCircleById(id)
   if(data1.success===true){
      setCircleData(data1.data.data)
   }
}
const ongetSubdevisionById=async(id)=>{
   const data1=await getSubdevisionById(id)
   if(data1.success===true){
      setSubdevisionData(data1.data.data)
   }
}
const ongetDivisionById=async(id)=>{
   const data3=await getDivisionById(id)
    if(data3.success===true){
        setDivisionData(data3.data.data)
    }
}
const ongetArea=async(id)=>{
   const data3=await getAreaById(id)
    if(data3.success===true){
      setAreaData(data3.data.data)
    }
}
 useEffect(() => {
   if(Profile.account!==''){
      ongetGaById(Profile.account)
      setProfile({...Profile,ga:'',circle:'',division:'',subdivision:'',area:''})
      setCircleData([])
      setSubdevisionData([])
      setDivisionData([])
      setAreaData([])

   }else{
      setProfile({...Profile,ga:'',circle:'',division:'',subdivision:'',area:''})
      setGaData([])
      setCircleData([])
      setSubdevisionData([])
      setDivisionData([])
      setAreaData([])
   }
 },[Profile.account])

 useEffect(() => {
   if(Profile.ga!==''){
      ongetCircle(Profile.ga)
      setProfile({...Profile,circle:'',division:'',subdivision:'',area:''})
      setSubdevisionData([])
      setDivisionData([])
      setAreaData([])
   }else{
      setProfile({...Profile,circle:'',division:'',subdivision:'',area:''})
      setCircleData([])
      setDivisionData([])
      setSubdevisionData([])
      setAreaData([])
   }
 },[Profile.ga])

 useEffect(() => {
   if(Profile.circle!==''){
      ongetDivisionById(Profile.circle)
      setProfile({...Profile,division:'',subdivision:'',area:''})
      setAreaData([])
      setSubdevisionData([])
   }else{
      setProfile({...Profile,division:'',subdivision:'',area:''})
      setAreaData([])
      setDivisionData([])
      setSubdevisionData([])
   }
 },[Profile.circle])

 useEffect(() => {
   if(Profile.division!==''){
      ongetSubdevisionById(Profile.division)
      setAreaData([])
      setProfile({...Profile,subdivision:'',area:''})
   }else{
      setProfile({...Profile,subdivision:'',area:''})
      setSubdevisionData([])
      setAreaData([])

   }
 },[Profile.division])

 useEffect(() => {
   if(Profile.subdivision!==''){
      ongetArea(Profile.subdivision)
      setProfile({...Profile,area:''})
   }else{
      setProfile({...Profile,area:''})
      setAreaData([])
   }
 },[Profile.subdivision])


const handaleModemSelect=async()=>{
  
   const data7 = await getAllMeter(true);
		if (data7.success === true) {
			setMeterData(data7.data.data);
		}
}
const onHandaleSlaveGroup=async()=>{
   const data=await getSlaveGroups()
   if(data.success===true){
      setGroupDetails(data.data.data.map((item)=>({value:item?.unique_id,label:item?.group_name})))
   }else{
      console.log('Something Went Wrong')
   }
 }
   useEffect(() => {
      handaleModemSelect()
    setDisable(false)
    onHandaleRoles()
      
    setConsumerType({...ConsumerType, MSRNO: GSMdata?.slave_serial_no,MeterId:GSMdata?.slave_type,slave_version:GSMdata?.version_id})
    setpole_Details({pole_id:GSMdata?.slave_address,lattitude:GSMdata?.latitude,longitude:GSMdata?.longitude,feeder_name:GSMdata?.feeder_name,group_id:JSON.parse(GSMdata?.group_id||'[]')||[]})
    onHandaleSlaveGroup()
    getRegisterProfile()
   //  setLoraConsumerId(GSMdata?.unique_id)
   }, []);

   const getRegisterProfile=async()=>{
      const data=await getslave_all_register_profile({slave_type:GSMdata?.slave_type})
      if(data.success===true){
         setVersionList(data?.data?.data)
      }else{
         console.log('something went wrong')
      }
   }

   const handleCancel = () => {
      setIsModalVisible(false);
      setLocation({ ... DefaultLocation})

   };
   function handleChangeLocation(lat, lng) {
      setLocation({ lat: lat, lng: lng });
      serAccountInput({...AccountInput,address_lattitude:lat,address_longitude:lng})
   }

   function handleChangeZoom(newZoom) {
      setZoom(newZoom);
   }

   const handleChangeTask = async (e) => {
      const {name, value, id} = e.target;
      let updatedTask = [...contactDetails];
      if(name === 'Work_no' || name==='Mobile'){
          updatedTask[id][name] =value.target.value;
      }else {
         console.log(updatedTask,id,name);
                    updatedTask[id][name] = value.target.value;
      }
      setcontactDetails([...updatedTask])
  };
   const onAdd = () => {
      const initial = { "Sr_No": contactDetails.length, "salutation": " ", "First_Name": "", "Last_Name": "", "Email": "", "Mobile": "", "Work_no": "", "Designation": "", "UserType": " " }
      // if(contactDetails.length>0){
      const task = contactDetails[contactDetails.length - 1];
      contactDetails[contactDetails.length] = initial
      setcontactDetails([...contactDetails])
      // }

   };

   const onDelete=(id)=>{
      const test=[...contactDetails]
      test.splice(id, 1)
      setcontactDetails([...test])
   }

   const onHandaleUpdateGroup=async()=>{
		console.log(GSMdata,ConsumerDashDetails)
		if(ConsumerType?.MeterId&&pole_Details?.pole_id!=''){
			var ErrorStr=''
		// 	if(ConsumerType?.IMEI==""&&ConsumerType?.MSRNO==""){
		// 		setBtnFlag(false)
		// 		return toast.error('Please Input IMEI And Modem Serial No')
		// 	}else if(ConsumerType?.MSRNO==""){
		// setBtnFlag(false)
		// 		return toast.error('Please Input Modem Serial No')
		// 	}else if(ConsumerType?.IMEI==""){
		// setBtnFlag(false)
		// 		return toast.error('Please Input IMEI')
		// 	}
      swal.fire({
			title: "If You Update 'Slave Profile' or 'Slave Profile Version' slave data table move in old table and new table generate",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
		setBtnFlag(true)

		const  obj={
			IMEI:ConsumerType?.IMEI,
			MSRNO:ConsumerType?.MSRNO,
			ModemId:ConsumerType.ModemId,
			MODEM_DATA: JSON.stringify({
				modem_make: ModemInputs?.MODEM_MAKE_NAME,
				modem_sku: ModemInputs?.SKU_NAME,
				modem_model: ModemInputs?.MODEM_MODEL_NAME,
				modem_firmware: ModemInputs?.MODEM_FIRMWARE_NAME,
				modem_hardware: ModemInputs?.MODEM_HARDWARE_NAME,
				modem_profile_name: ModemInputs?.MODEM_PROFILE_NAME,
			}),
			pole_id:pole_Details?.pole_id,
			lattitude:pole_Details?.lattitude,
			longitude:pole_Details?.longitude,
			group_consumer_id:params.GSMID,
			userId:userInfo?.unique_id,
         MeterId:ConsumerType?.MeterId,
         scriptdecode_id:ConsumerDashDetails?.modem_type+'_'+ConsumerType?.MeterId,
         payload:{...GSMdata?.unique_table_name||{},...ConsumerDashDetails?.unique_table_name},
			consumer_unique_id:ConsumerDashDetails?.unique_id,
         slave_version:ConsumerType?.slave_version,
         feeder_name:pole_Details?.feeder_name,
			group_id:pole_Details?.group_id

		}
		console.log(obj)
		const data=await UpdateInSlave(obj,GSMdata?.unique_id)
		if(data.success==true){
			toast.success('Successfull Updated Slave Consumer')
		setBtnFlag(false)
      setDisable(false)
      props?.onConsumerUpdateDate()
		}else{
			toast.error('Server Issue')
		setBtnFlag(false)
		}
   }
})
	}else{
		toast.error('Select Slave Type & Inpurt Slave Address')
		setBtnFlag(false)
	}
	}

   const oninactive=async()=>{
      swal.fire({
			title: "Are You Sure InActive Slave Modem?",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'globle_slave_list',colName:'slave_status',value:'0',matchId:'unique_id'},GSMdata?.unique_id)
      if(data.success===true){
         toast.success('Success Inactive Slave Modem')
         props.onConsumerUpdateDate()
      }else{
         toast.error('something want wrong')
      }
   }
})
     }
     const onactive=async()=>{
      swal.fire({
			title: "Are You Sure Active Slave Modem?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'globle_slave_list',colName:'slave_status',value:'1',matchId:'unique_id'},GSMdata.unique_id)
      if(data.success===true){
         toast.success('Success Active Slave Modem')
         props.onConsumerUpdateDate()
      }else{
         toast.error('something want wrong')
      }
   }
})
     }

     const onUnderMaintenance = async () => {
      swal.fire({
         title: 'Notes',
         input: 'text',
         inputAttributes: {
           autocapitalize: 'off'
         },
         showCancelButton: true,
         confirmButtonText: 'Submit',
         showLoaderOnConfirm: true,
         preConfirm: (data) => {
            if(data){
           return data
            }else{
               swal.showValidationMessage(
                  `Please Enter Notes`
                )
            }
         },
         allowOutsideClick: () => !swal.isLoading()
       }).then(async(result) => {
          if (result.isConfirmed) {
            await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo?.unique_id,notes:result?.value,status_activity:`Update Status From ${GSMdata?.slave_status === 1 ? 'Active' : GSMdata?.slave_status === 0 ? 'InActive' : GSMdata?.slave_status === 2 ? 'Under Maintenance' : GSMdata?.slave_status === 3 ? 'Stand By' : 'Pending Configration'} To under maintenance`}},GSMdata.unique_id)

      swal.fire({
         title: "Consumer is under maintenance",
         icon: "success",

      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
           
            const data = await activeandinactive({ tableName: 'globle_slave_list', colName: 'slave_status', value: '2', matchId: 'unique_id' }, GSMdata.unique_id)
            if (data.success === true) {
               toast.success('Success Active Slave Modem')
               props.onConsumerUpdateDate()
            } else {
               toast.error('something want wrong')
            }
         }
      })
         }
      })
   }

   const onStandBy = async () => {
      swal.fire({
         title: 'Notes',
         input: 'text',
         inputAttributes: {
           autocapitalize: 'off'
         },
         showCancelButton: true,
         confirmButtonText: 'Submit',
         showLoaderOnConfirm: true,
         preConfirm: (data) => {
            if(data){
           return data
            }else{
               swal.showValidationMessage(
                  `Please Enter Notes`
                )
            }
         },
         allowOutsideClick: () => !swal.isLoading()
       }).then(async(result) => {
          console.log(result)
          if (result.isConfirmed) {
            await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo?.unique_id,notes:result?.value,status_activity:`Update Status From ${GSMdata?.slave_status === 1 ? 'Active' : GSMdata?.slave_status === 0 ? 'InActive' : GSMdata?.slave_status === 2 ? 'Under Maintenance' : GSMdata?.slave_status === 3 ? 'Stand By' : 'Pending Configration'} To Stand By`}},GSMdata.unique_id)

      swal.fire({
         title: "Comsumer is Stand By",
         icon: "success",

      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            
            const data = await activeandinactive({ tableName: 'globle_slave_list', colName: 'slave_status', value: '3', matchId: 'unique_id' }, GSMdata.unique_id)
            if (data.success === true) {
               toast.success('Success Active Slave Modem')
               props.onConsumerUpdateDate()
            } else {
               toast.error('something want wrong')
            }
         }
      })
         }
      })
   }

   const onPenddingConfguration = async () => {
      swal.fire({
         title: "Are You Sure Pending Configuration GSM Modem?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      swal.fire({
         title: 'Notes',
         input: 'text',
         inputAttributes: {
           autocapitalize: 'off'
         },
         showCancelButton: true,
         confirmButtonText: 'Submit',
         showLoaderOnConfirm: true,
         preConfirm: (data) => {
            if(data){
           return data
            }else{
               swal.showValidationMessage(
                  `Please Enter Notes`
                )
            }
         },
         allowOutsideClick: () => !swal.isLoading()
       }).then(async(result) => {
         console.log(result)
                if (result.isConfirmed) {
      swal.fire({
         title: "Comsumer is Pending Configuration",
         icon: "success",

      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
                  await UpdateConsumerStatusHistory({comment:{created_at:moment().format(),created_by:userInfo?.unique_id,notes:result?.value,status_activity:`Update Status From ${GSMdata?.slave_status === 1 ? 'Active' : GSMdata?.slave_status === 0 ? 'InActive' : GSMdata?.slave_status === 2 ? 'Under Maintenance' : GSMdata?.slave_status === 3 ? 'Stand By' : 'Pending Configuration'} To Pending Configuration`}},GSMdata.unique_id)
            const data = await activeandinactive({ tableName: 'globle_slave_list', colName: 'slave_status', value: '-1', matchId: 'unique_id' }, GSMdata.unique_id)
            if (data.success === true) {
               toast.success('Success Pending Configuration Slave Modem')
               props.onConsumerUpdateDate()
            } else {
               toast.error('Something Went Wrong')
            }
         }
      })
         }
      })
   }
})
   }
   return (
      <>
            <Modal title="Map" visible={isModalVisible} onOk={()=>(setIsModalVisible(false))} onCancel={handleCancel} width={800}>
               {/* <MapPicker defaultLocation={{ lat: parseFloat(location?.lat||'19.506765954448635'), lng: parseFloat(location?.lng||'76.46875000000001')}}
                  zoom={zoom}
                  mapTypeId="roadmap"
                  style={{ height: '500px' }}
                  onChangeLocation={handleChangeLocation}
                  onChangeZoom={handleChangeZoom}
                  apiKey='AIzaSyDG0jH5rr6z6KU0Zo_ilALl5ZXUP5NSPSs' /> */}
            </Modal>
            <section class="section">
               <div class="section-body">
                  <div class="row">
                     <div class="col-12">
                        <div class="card">
                           <div class="card-header">
                              <h4>Update Slave  Consumers</h4>
                              <div class="card-header-action">
                              <div  class="btn btn-info text-white btnedit mr-2"><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" onClick={()=>(setDisable(true))}><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>
                              {<div class="dropdown">
                                 <button class={`btn ${GSMdata?.slave_status == 1 ? 'btn-success' : GSMdata?.slave_status == 0 ? 'btn-danger' : GSMdata?.slave_status == 2 ? 'btn-info' : GSMdata?.slave_status == 3 ? 'btn-dark' : 'btn-warning'} dropdown-toggle`} type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true" >
                                    {GSMdata?.slave_status == 1 ? 'Active' : GSMdata?.slave_status == 0 ? 'InActive' : GSMdata?.slave_status == 2 ? 'Under Maintenance' : GSMdata?.slave_status == 3 ? 'Stand By' : 'Pending Configration'}
                                 </button>
                                 <div class="dropdown-menu !tw-left-[-50px]" aria-labelledby="dropdownMenuButton1">
                                    {GSMdata?.slave_status != 1 ? <a class="dropdown-item text-success" onClick={() => (onactive())} >Active</a> : ''}
                                    {GSMdata?.slave_status != 0 ? <a class="dropdown-item text-danger" onClick={() => (oninactive())} >InActive</a> : ''}
                                    {GSMdata?.slave_status != 2 ? <a class="dropdown-item text-info" onClick={() => (onUnderMaintenance())} >Under Maintenance</a> : ''}
                                    {GSMdata?.slave_status != 3 ? <a class="dropdown-item text-dark" onClick={() => (onStandBy())} >Stand By</a> : ''}
                                    {GSMdata?.slave_status != -1 ? <a class="dropdown-item !tw-text-[#f59e0b]" onClick={() => (onPenddingConfguration())} >Pending Configuration</a> : ''}
                                 </div>
                              </div>}
                              </div>
                           </div>
                           <div class="card-body">

                                 <fieldset class="customLegend">
												<legend>Slave Details</legend>
												<div
													class="form-row p-3"
													style={{
														marginLeft: "10px",
														borderColor: "#e3e6fc !important",
														position: "relative",
														marginTop: "5px",
													}}
												>
													<div class="form-group col-md-3">
														<label for="inputPassword4">
															Slave Profile 
														</label>
														<select
															class="form-control form-select second_meter_type skjghudfjgnv"
															onChange={(e) =>{ setConsumerType({ ...ConsumerType, MeterId: e.target.value })}}
															value={ConsumerType.MeterId}
															required=""
															name="meter_type"
                                             disabled={!disable}
														>
															<option value="">Select Slave Profile</option>
															{MeterData &&
																MeterData.map((item) => (
																	<option value={item.METER_UNIQUE_ID}>{item.METER_PROFILE_NAME}</option>
																))}
														</select>
													</div>
                                       <div class="form-group col-md-3">
														<label for="inputPassword4">
															Slave Profile Version
														</label>
														<select
															class="form-control form-select second_meter_type skjghudfjgnv"
															onChange={(e) =>{ setConsumerType({ ...ConsumerType, slave_version: e.target.value })}}
															value={ConsumerType.slave_version}
															required=""
															name="meter_type"
                                             disabled={!disable}
														>
															{VersionList[VersionList?.length-1]?.unique_id!=GSMdata?.version_id?<option value={GSMdata?.version_id}>{VersionList.filter((item)=>item.unique_id==GSMdata?.version_id)[0]?.version}</option>:''}
                                             <option value={VersionList[VersionList?.length-1]?.unique_id}>{VersionList[VersionList?.length-1]?.version}</option>
														</select>
													</div>
                                       <div class="form-group col-md-3">
													<label for="address_lattitude">Slave Serial No:</label>
                                        <input type="text" class="form-control" name="address_lattitude" id="loca_lat" placeholder="Slave Serial No" value={ConsumerType?.MSRNO||''} onChange={(e) => (setConsumerType({...ConsumerType,MSRNO:e.target.value}))} disabled={!disable}/>
													</div>
                                       <div class="form-group col-md-3">
													<label for="address_lattitude">Feeder Name:</label>
                                                   <input type="text" class="form-control" name="address_lattitude" id="loca_lat" placeholder="Feeder Name" value={pole_Details?.feeder_name||''} onChange={(e) => (setpole_Details({...pole_Details,feeder_name:e.target.value}))} disabled={!disable}/>
													</div>
                                       <div class="form-group col-md-3">
													<label for="address_lattitude">Slave Address:</label>
                                                   <input type="text" class="form-control" name="address_lattitude" id="loca_lat" placeholder="Slave Address" value={pole_Details?.pole_id||''} onChange={(e) => (setpole_Details({...pole_Details,pole_id:e.target.value}))} disabled={true}/>
													</div>
													<div class="col-lg-3">
                                                <div class="form-group">
                                                   <label for="address_lattitude">Latitude:</label>
                                                   <input type="text" class="form-control" name="address_lattitude" id="loca_lat" placeholder="Lattitude" value={pole_Details?.lattitude||''} onChange={(e) => (setpole_Details({...pole_Details,lattitude:e.target.value}))} disabled={!disable}/>
                                                </div>
                                             </div>
                                             <div class="col-lg-3">
                                                <div class="form-group">
                                                   <label for="address_longitude">Longitude:</label>
                                                   <input type="text" class="form-control" name="address_longitude" id="loca_long" placeholder="Longitude" value={pole_Details?.longitude||''} onChange={(e) => (setpole_Details({...pole_Details,longitude:e.target.value}))} disabled={!disable}/>
                                                </div>
                                             </div>
                                             <div class="col-lg-3">
                                                <div class="form-group">
                                                   <label for="address_longitude">Group:</label>
                                                   <Select placeholder='Select Group...' isMulti isClearable   options={GroupDetails} onChange={(e) => { 
													setpole_Details({...pole_Details,group_id:e.map((item) => (item?.value))}) }} style={{width:'100px'}} value={GroupDetails?.filter((item)=>(pole_Details?.group_id?.includes(item?.value)))} isDisabled={!disable}/>
                                                </div>
                                             </div>
													</div>
												</fieldset>
											
											<p style={{ marginLeft: "25px" }} class="text-danger font-10">Note : * Indicates Mandatory Fields</p>
                                 {disable&&<div class="text-left">
                                    <input type="submit" name="submit" class={`btn btn-danger mr-4 ${BtnFlag?'disabled btn-progress':''}`} value="Submit" id="submitvn" onClick={onHandaleUpdateGroup}/>

                                    <Link class="btn btn-light text-dark" to="/All-GSM">Cancel</Link>
                                 </div>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
      </>
   )
}


import { Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Pagination from '../../../../Common/Pagination';
import { CheckModemStatus, geModemIdWiseColumnName, getAccountWiseModemTableViewCoumnName, getHeardbeatData, getScriptCodeIdWiseColumnName } from '../../../../Common/Utils';
import swal from 'sweetalert2';
window.Swal = swal;
export default function Onlineoffline(props) {

    const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [loader, setLoader] = useState(false);
	const [heartData, setHeartData] = useState([])
    const [ColumnName,setColumnName]=useState({})

    const ongetHeardbeatData = async () => {
		setLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await getHeardbeatData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.unique_table_name?.online_offline,changeCol:true,colName:'u_sr_no' })
		if (data.success === true) {
			setHeartData(data.data.data)
			setnumberofPage(data.data.total)
			setLoader(false)
		} else {
			console.log('something want wrong')
			setLoader(false)

		}
	}
	useEffect(() => {
		ongetHeardbeatData();
	  }, [currentPage, PageSize]);
	  const ongetScriptCodeIdWiseColumnName=async()=>{
		const data1 = await getAccountWiseModemTableViewCoumnName({ tableName: `${props?.GSMdata?.cat0_id}_modem_table_view`, modem_id: props?.GSMdata?.modem_type })
		if(data1.success==true){
			if(data1.data.data.length>0){
				setColumnName(JSON.parse(data1.data.data[0].column_json||'{}'))
			}else{
		const data=await geModemIdWiseColumnName(props?.GSMdata?.modem_type)
        if(data.success===true){
            if(data?.data?.data?.length>0){
				var modem_table_json=data?.data?.data[0]?.COLUMN_NAME
				var column_name={}
            Object.keys(JSON.parse(modem_table_json||'{}')).map((key)=>{
                    column_name[key]= [...(JSON.parse(modem_table_json||'{}')[key]||[]).map((item)=>({...item,u_name:item?.Header}))]
            })
            setColumnName(column_name)
            }
        }else{
console.log('something want wrong');
        }
	}
	}else{
		console.log('something want wrong');
				}
    }
    useEffect(()=>{
        ongetHeardbeatData()
		ongetScriptCodeIdWiseColumnName()
    },[])

	const onCheckConsumerConnectionStatus = async () => {
		const data = await CheckModemStatus(props?.GSMdata?.imei)
		if (data.success) {
			swal.fire({
				title: "Get Status Success",
				icon: "success",
			})
		} else {
			swal.fire({
				title: "Server Error",
				icon: "erroe",
			})
		}
	}
  return (
    <>
        <section className="card-diff-section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4></h4>
									<div className="card-header-action">
										<div id="filtergrm">
										<button class="btn btn-info mr-1" onClick={() => onCheckConsumerConnectionStatus()}>
												Get Status
											</button>
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh"  onClick={ongetHeardbeatData}>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="bottom" title="Download">
												<i class="fa fa-download" aria-hidden="true"></i>
											</a>
										</div>
									</div>
								</div>
								<div className="card-body">
									{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
									{heartData.length === 0 && !loader && <h4 className="text-center">No Data Found</h4>}
									{heartData.length > 0 &&
										<div className="table-responsive text-center">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												
												<table id="table_id" className="table border table-borderless table-striped font-10">
													<thead>
														<tr className="tw-border-b">
														{ ColumnName?.online_offline?.length>0&&ColumnName?.online_offline?.map((col)=>(col?.Display==true?<th>{col?.Header}</th>:''))}
														</tr>
													</thead>
													<tbody >
														{heartData.length > 0 && heartData.map((item, id) => (
															
															<tr className="trheigth  ">
																{ ColumnName?.online_offline?.length>0&&ColumnName?.online_offline
															?.map((col)=>(col?.Display==true?col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD/MM/YYYY hh:mm:ss A"):''}</td>:col.Column=='MMD_STATUS_ONLINE_OFFLINE'?<td >
															<div style={{fontSize:'10px'}} className={`badge ${item[col.Column] === 'online' ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item[col.Column] === 'online' ? 'Online' : 'Offline'}</div>
														</td>:<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>:''))}
                                                            
															{/* <td><div style={{fontSize:'10px'}} className={`badge ${item?.DEVICE_STATUS === 'online' ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{heartData[id+1]?.ENTRY_TS?moment.duration(moment(heartData[id+1]?.ENTRY_TS).diff(moment(heartData?.ENTRY_TS),'minutes')).minutes()+' minutes ago':''}</div></td> */}
															
															</tr>
														))}
													</tbody>
												</table>
												<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
											</div>
										</div>}

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
  )
}

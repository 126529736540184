import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BootstrapTooltip } from "../../Common/common";
import { getRolesById, getalarm_template } from "../../Common/Utils";
import chroma from 'chroma-js';
import moment from 'moment'
import * as $ from "jquery";
import dt from "datatables.net-dt";
import Pagination from "../../Common/Pagination";
$.fn.DataTable = dt;
export default function EventTemplate() {

  const [tagsData,setTagsData]=useState([])
const [filterrolesData,setfilterRolesData]=useState([])
const [loader,setLoader]=useState(false)
const [permision,setpermision]=useState({})
const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
const [activeTab, setActiveTab] = useState("Notification");
const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
const [PageSize, setPageSize] = useState(50);
const [numberofPage, setnumberofPage] = useState(1);
const [currentPage, setCurrentPage] = useState(1);
const history=useNavigate()
const onHandaleRoles = async () => {
	const data = await getRolesById(userInfo?.role);
	if (data.success === true) {
		const obj = data.data.data[0];
		setpermision({...obj,roles_other_permissions:JSON.parse(obj.roles_other_permissions||'{}')});
	}else{
		console.log('Something Went Wrong')
	}
}

  const ongetTagsData=async()=>{
    setLoader(true);
	const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		$(".loader").fadeOut("slow");
const data=await getalarm_template({ start: firstPageIndex, end: PageSize })
if(data.success===true){
  setTagsData(data.data.data)
  setnumberofPage(data.data.total)

  setLoader(false)

}else{
  console.log('somthing Went wrong')
  setLoader(false)
}
  }

  useEffect(() => {
	onHandaleRoles()
    ongetTagsData()
  }, []);

	return (
		<div>
    {loader ? (
						<div className="main-content">
							<div class="loader"></div>
						</div>
					) : (
						""
					)}
            
				
				<section class="section">
                
					<div class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4><span className="mr-2">Event Template</span><BootstrapTooltip title='Table Name is alarm_template_index' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											
										</div>
									</div>
									<div class="card-body">
                                    <div className="table-responsive">
												
												<table id="table_id" className="table border table-borderless text-center table-striped font-12">
													<thead>
											
												<tr>
													<th className="text-center" style={{ maxWidth: "100px" }}>sr_no</th>
													<th className="text-center">notification_type_id</th>
													<th className="text-center" style={{ minWidth: "130px" }}>created_at</th>
													<th className="text-center" style={{ minWidth: "100px" }}>created_by</th>
													<th className="text-center" style={{ minWidth: "130px" }}>updated_at</th>
													<th className="text-center" style={{ minWidth: "100px" }}>updated_by</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>notification_title</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>lookup_table</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>whatsapp_template_name</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>sms_template_id</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>email_template</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>web_notification_template</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>ivr_notification_template</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>mobile_notification_template</th>
													<th className="text-center" style={{ maxWidth: "100px" }}>status</th>
													
												</tr>
											</thead>
											<tbody>
												{tagsData &&
													tagsData.map((item, id) => (
														<tr style={{ height: "4rem" }} className="trheigth" key={id} >
															<td className="text-center">{item.sr_no}</td>
															<td className="text-center">{item.notification_type_id}</td>
															<td className="text-center">{moment.utc(item.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
															<td className="text-center">{item.created_by}</td>
															<td className="text-center">{moment.utc(item.updated_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
															<td className="text-center">{item.updated_by}</td>
															<td className="text-center">{item.notification_title}</td>
															<td className="text-center">{item.lookup_table}</td>
															<td className="text-center">{item.whatsapp_template_name}</td>
															<td className="text-center">{item.sms_template_id}</td>
															<td className="text-center">{item.email_template}</td>
															<td className="text-center">{item.web_notification_template}</td>
															<td className="text-center">{item.ivr_notification_template}</td>
															<td className="text-center">{item.mobile_notification_template}</td>
															
															{item.status===1?<td className="text-center">
																			<div className="badge badge-success badge-shadow text-white">Active</div>
																		</td>:<td className="text-center">
																			<div className="badge badge-danger badge-shadow text-white">Inactive</div>
																		</td>}
														</tr>
													))}
											</tbody>
										</table>
										<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
									</div>
                  </div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		
	);
}

import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import useInterval from "use-interval";
import { useNavigate } from "react-router-dom";
import { ContryCodeData } from "./Contrycode";
import { Modal } from "react-bootstrap";
import axios from "axios";
import {LoginUser} from '../../Common/Utils'
import "./Login.css";
import moment from "moment";

const Login = (props) => {
	const [login, setLogin] = useState({ email: "", password: "" });
	const [input, setInput] = useState({ email_otp: null, added_otp: null });
	const [errorOtp, setErrorOtp] = useState({ email_otp: "", added_otp: "" });
	const [loginErrors, setValidation] = useState({});
	const [typeLogin, setTypeLogin] = useState("logpass");
	const [flag, setFlag] = useState(false);
	const [conCode, setConCode] = useState("+91");
	const [time, setTime] = useState(60);
	const [run, setRun] = useState(false);
	const [isOtp, setIsOtp] = useState(0);
	const [enableOtp, setEnableOtp] = useState(false);
	const [showError, setShowError] = useState(false);
const [model,setModel]=useState(false)
	const history = useNavigate();
	const onHandalChange = (e) => {
		setLogin({ ...login, [e.target.name]: e.target.value });
	};

	const inputHandler = (e) => {
		setInput({ ...input, [e.target.name]: e.target.value });
	};

	let token = window.localStorage.getItem("token");
	useEffect(() => {
		if(token&&token!==""){
		  history('/all-deshboard')
		}
	}, []);
	const validation = (name, value) => {
		switch (name) {
			case "email":
				if (!value) {
					return "Please input email!";
				} else {
					return "";
				}
			case "password":
				if (!value) {
					return "Please input password!";
				} else {
					return "";
				}
			default: {
				return null;
			}
		}
	};

	const onFormSubmit = async (e) => {
		e.preventDefault();
		setFlag(true);
		const userData = {
			email: login.email,
			password: login.password,
		};
		let allErrors = {};
		Object.keys(login).forEach((key) => {
			const error = validation(key, userData[key]);
			if (error && error.length) {
				allErrors[key] = error;
			}
		});

		if (Object.keys(allErrors).length) {
			setFlag(false);
			return setValidation(allErrors);
			
		} else {
			setValidation({});
			const resultData=await LoginUser(userData)
			console.log(resultData)
			if(resultData.success===true){
				toast.success("Login Success")
				window.localStorage.setItem('token',resultData?.data?.userData[0]?.token)
				window.localStorage.setItem('user_info',JSON.stringify(resultData?.data?.userData[0]))
				window.localStorage.setItem('login_time',moment().utcOffset("+05:30"))
				history('/all-deshboard')
				setFlag(false)
			}else if(resultData?.err?.response?.status===401) {
				toast.error("Invalid Password And User Email")
				setFlag(false)
			  }else if(resultData?.err?.response?.status===400) {
				toast.error("Invalid Password And User Email")
				setFlag(false)
			  }else{
				setFlag(false)
			  }
			// const resultData=await LoginUser(login)
			// if (resultData.success === true) {
			//   toast.success("Login Success")
			//   window.localStorage.setItem('token',resultData?.data?.userData[0]?.token)
			//   window.localStorage.setItem('user_info',JSON.stringify(resultData?.data?.userData[0]))
			//   props.history.push('/group-deshboard')
			//   setFlag(false)

			// }else if(resultData?.err?.response?.status===401) {
			//   toast.error("Invalid Password And User Email")
			//   setFlag(false)

			// }else if(resultData?.err?.response?.status===400) {
			//   toast.error("Invalid Password And User Email")
			//   setFlag(false)

			// }
		}
	};

	function zeroPad(num) {
		var str = String(num);
		if (str.length < 2) {
			return "0" + str;
		}

		return str;
	}

  const eventHandler = (e) => {
    if (input.added_otp == isOtp) {
      console.log('successfull Login')
    }else{
      setShowError(true);
    }
  }

	const send_otp = (e) => {
		const regx = /^[789][0-9]{9}$/;
		const otp = Math.floor(100000 + Math.random() * 900000);

		if (!regx.test(input.email_otp)) {
			return setErrorOtp({ ...errorOtp, email_otp: "Enter Valid Mobile No" });
		} else {
			setErrorOtp({ ...errorOtp, email_otp: "" });
			if (e.target.value === "Resend") {
				setTime(60);
			}

			const templateid = "1207165115205225348";
			const msg = `Hi%20there!%20%20Use%${otp}%20as%20your%20OTP%20to%20login%20to%20EUMS%20portal.%20The%20code%20is%20valid%20for%2015%20mins.-Team%20MEMIGHTY`;

			const service_url = `http://sms.instanceit.in/api/sms/SendSMS.aspx?usr=memighty&key=A5D53AC3-55DB-469A-B7B8-4291695F9A82&smstype=TextSMS&to=${input.email_otp}&msg=${msg}&rout=Transactional&from=MMIPIL&templateid=${templateid}`;

			axios.defaults.withCredentials = true;
			axios({
				method: "get",
				url: service_url,
				withCredentials: true,
				credentials: "include",
				headers: {
					// 	responseEncoding : "gzip, deflate",
					// 	// Cookie: "ASP.NET_SessionId=mzx4q3puzgwsispcwtahamnm",
					// 	// 'Access-Control-Max-Age': 86400,
					"Content-Type": "text/html; charset=utf-8",
				},
			})
				.then((response) => {
					if (response.data.status === "success") {
						setIsOtp(otp);
						setModel(true);
						setRun(true);
						setEnableOtp(true);
					}
				})
				.catch((error) => {
					console.log("otp ", otp);
					setIsOtp(otp);
					setModel(true);
					setRun(true);
					setEnableOtp(true);
				});
		}
	};

	useInterval(
		() => {
			if (time <= 1) {
				setRun(false);
			}
			setTime((preState) => preState - 1);
		},
		time && run ? 1000 : null
	);

	return (
		<>
			<section className="section">
				<div className="container mt-5">
					<div className="row">
						<div className="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
							<div className="card card-primary">
								<div className="card-header">
									<h4>Login</h4>
								</div>
								<div className="card-body">
									<div class="form-group d-flex ">
										<div>
											<input
												type="radio"
												name="login_type"
												id="logindefault_password"
												style={{ height: "20px", width: "20px", verticalAlign: "middle" }}
												onChange={() => setTypeLogin("logpass")}
												checked={typeLogin === "logpass" ? true : false}
											/>
											<label for="logindefault_password" style={{ marginLeft: "5px" }}>
												Login with Password
											</label>
										</div>
										<div className="ml-5">
											<input
												type="radio"
												name="login_type"
												id="logindefault_otp"
												style={{ height: "20px", width: "20px", verticalAlign: "middle" }}
												onChange={() => setTypeLogin("logotp")}
												checked={typeLogin === "logotp" ? true : false}
											/>
											<label for="logindefault_otp" style={{ marginLeft: "5px" }}>
												Login With OTP
											</label>
										</div>
									</div>
									{typeLogin === "logpass" ? (
										<form className="needs-validation" onSubmit={onFormSubmit}>
											<div className="form-group">
												<label for="email">Email</label>
												<input
													id="email"
													type="email"
													className="form-control"
													name="email"
													tabIndex="1"
													onChange={onHandalChange}
													value={login.email}
												/>
												<p className="text-danger">{loginErrors?.email || ""}</p>
											</div>
											<div className="form-group">
												<div className="d-block">
													<label for="password" className="control-label">
														Password
													</label>
													<div className="float-right">
														<a className="text-small">Forgot Password?</a>
													</div>
												</div>
												<input
													id="password"
													type="password"
													className="form-control"
													name="password"
													tabIndex="2"
													onChange={onHandalChange}
													value={login.password}
												/>
												<p className="text-danger">{loginErrors?.password || ""}</p>
											</div>
											<div className="form-group">
												<div className="custom-control custom-checkbox">
													<input
														type="checkbox"
														name="remember"
														className="custom-control-input"
														tabIndex="3"
														id="remember-me"
													/>
													<label className="custom-control-label" for="remember-me">
														Remember Me
													</label>
												</div>
											</div>
											<div className="form-group">
												{flag ? (
													<button
														type="submit"
														className="btn btn-primary btn-lg btn-progress disabled "
														tabIndex="4"
														style={{ width: "100%" }}
													>
														progress
													</button>
												) : (
													<button type="submit" className="btn btn-primary btn-lg btn-block" tabIndex="4">
														Login
													</button>
												)}
											</div>
										</form>
									) : (
										<div class="form-row">
										<div className="d-flex">
											<div class="form-group col-md-9">
												<label>Mobile Number</label>
												<div className="d-flex">
													<select
														class="form-select form-control"
														aria-label="Default select example"
														style={{ width: "36%" }}
														onChange={(e) => setConCode(e.target.value)}
														value={conCode}
													disabled={run ? true : false}

													>
														{ContryCodeData &&
															ContryCodeData.map((item) => <option value={item.dial_code}>{item.dial_code}</option>)}
													</select>
													<input
														type="text"
														class="form-control"
														name="email_otp"
														tabindex="1"
														id="inputEmailAddressData"
														placeholder="Mobile Number"
														onChange={inputHandler}
														value={input.email_otp}
													disabled={run ? true : false}
													/>
												</div>
												<span className="text-danger">{errorOtp.email_otp}</span>
											</div>
											<div class="form-group sendotpgrp">
												<label>&nbsp;</label>
												<input
													type="button"
													className="btn btn-primary btn-lg btn-block"
													id="send_otp"
													tabIndex="4"
													onClick={(e) => send_otp(e)}
													value={run ? `${zeroPad(time)}` : time ? "Send" : "Resend"}
													disabled={run ? true : false}
												/>
											</div>
											</div>
											{/* <div class="form-group" id="timeoutbar" style="display: none;">
                           <label>&nbsp;</label>
                           <label>Resend After</label>
                           <div id="timer" style="margin-left:20px;">0:60</div>
                        </div> */}
                        {!enableOtp ? (
													""
												) : (
													<div className="col-12">
														<div className="form-group hsgdfaehgdf">
															<input
																id="password"
																type="text"
																className="form-control"
																name="added_otp"
																tabIndex="2"
																placeholder="Enter OTP"
																onChange={(e) => inputHandler(e)}
                                value={input.added_otp}
															/>
															{/* <div className="invalid-feedback">
                                                                please fill in your password
                                                            </div> */}
														</div>
														<div className="form-group loginbutton">
															<input
																type="button"
																className="btn btn-primary btn-lg btn-block"
																tabIndex="4"
																name="submit"
																value="Log In"
																onClick={() => {
																	eventHandler();
																}}
															/>
														</div>
													</div>
												)}
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>

        <Modal show={showError} id="myModalconform" onHide={() => setShowError(false)}>
				<div className="modal-confirm">
					<Modal.Header className="justify-content-center bg-danger">
						<div className="modal-headercom bg-danger">
							<div className="icon-box">
								<i className="fas fa-check"></i>
							</div>
						</div>
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => setShowError(false)}>
							&times;
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className="modal-body text-center">
							<h4>Opps!</h4>
							<p>Enter Valid OTP</p>
							<button
								className="btn btn-denger"
								data-dismiss="modal"
								style={{ backgroundColor: "#fc544b" }}
								onClick={() => setShowError(false)}
							>
								<span>Okay</span> <i className="fas fa-arrow-right material-icons">&#xE5C8;</i>
							</button>
						</div>
					</Modal.Body>
				</div>
			</Modal>

			<Modal show={model} id="myModalconform" onHide={() => setModel(false)}>
				<div className="modal-confirm">
					<Modal.Header className="justify-content-center">
						<div className="modal-headercom">
							<div className="icon-box">
								<i className="fas fa-check material-icons">&#xf00c;</i>
							</div>
						</div>
						<button type="button" className="close" data-dismiss="modal" aria-hidden="true" onClick={() => setModel(false)}>
							&times;
						</button>
					</Modal.Header>
					<Modal.Body>
						<div className="modal-body text-center">
							<h4>Great!</h4>
							<p>
								Otp Has been sent to <span className="mobnumber">{input.email_otp}</span> mobile number
							</p>
							<button className="btn btn-success" data-dismiss="modal" onClick={() => setModel(false)}>
								<span>Okay</span> <i className="fas fa-arrow-right material-icons">&#xE5C8;</i>
							</button>
						</div>
					</Modal.Body>
				</div>
			</Modal>
			</section>
		</>
	);
};

export default Login;

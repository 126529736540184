import { DatePicker, Modal, Space, Popover } from 'antd'
import React, { useState } from 'react'
import { Users } from 'react-feather'
import { Link } from 'react-router-dom'
import { BootstrapTooltip, TaskUrl } from '../../Common/common'
import SlidingTabs from '../../Common/Full_Tabs/SlidingTabs'
import TabPane from '../../Common/Full_Tabs/TabPane'
import chroma from 'chroma-js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { usePopper } from 'react-popper';
import { toast, Slide } from "react-toastify";
import './tasks.css'
import moment from 'moment'
import { activeandinactive, getAccount, getAllTaskDetails, getAllTaskDetailsByUniqueId, getAreaById, getCircleById, getDivisionById, getFavoriteFilters, getFilteredTaskDetails, getGaById, getGSMConsumerList, getProfilNameByAccountId, getRolesById, getSubdevisionById, getTaskCommentAndHistoryById, getTaskTag, geturls3, getUsersList, getUsersListForTask, InsertsTaskDetails, saveFilterToDb, Taskuploads, UpdateConsumerStatusInMaintanace, UpdateLorawanConsumerStatusInMaintanace, UpdateTaskDetailesComentAndHistory, UpdateTaskDetails, uploadsDeleteFile, uploadsFiles,getUsersByGroups, TaskActivityLogMaintainOnFrontend, getActivityLogs, getTaskActivityLogs } from '../../Common/Utils'
import exports from '../../assets/img/export.png'
import { useEffect } from 'react'
import Pagination from '../../Common/Pagination'
import fileDownload from 'js-file-download'
import GoogleLoader from '../../Common/Loaders/GoogleLoader'
import classNames from 'classnames'
import Dropdown2 from '../../Common/Dropdown/Dropdown2/Dropdown2'
import { ReactComponent as NoTaskFoundSVG } from '../../assets/SVGs/No_tasks.svg'
import TaskFilter from './TaskFilter'
import ButtonLoader from '../../Common/Loaders/ButtonLoader'
import DownloadBtn from '../../Common/buttons/DownloadBtn'
import AdvancedDropdown from '../../Common/Dropdown/basic-dropdown/AdvancedDropdown'
import { CSVLink } from 'react-csv';
import * as XLSX from 'xlsx';
import AdvancedDownload from '../../Common/Download/AdvancedDownload'
import * as $ from "jquery";

export default function Tasks({ storedFilter, onChangeStoredFilter, storedShowFilter, onChangeShowFilter,setstoredFilterType ,storedFilterType,storedFilterS,setstoredFilterS,consumerId,ConsumerType,GSMdata}) {
    let userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    // let filters = [
    //     { value: 'all_tasks', label: 'All Tasks', filtervalue: {} },
    //     { value: 'todays_tasks', label: "Today's tasks", filtervalue: { todays: true } },
    //     { value: 'overdue_tasks', label: 'All Overdue Tasks', filtervalue: { overdue: true } },
    //     { value: 'my_tasks', label: 'My Tasks', filtervalue: { taskAssignToMe: userInfo.unique_id } },
    // ];
    const taskStatuses = [
        { value: -1, label: 'Open', color: '#78cc82' },
        { value: 0, label: 'Working On It', color: '#2eaee8' },
        { value: 2, label: 'In Review', color: "#8fbabb" },
        { value: 3, label: 'On Hold', color: "#f8c133" },
        { value: 1, label: 'Completed', color: "#f16965" },
        { value: 4, label: 'Canceled', color: "#5a8dc8" },
    ];

    const priorities = [
        { value: 'low', label: 'Low', color: '#78cc82' },
        { value: 'medium', label: 'Medium', color: '#f8c133' },
        { value: 'High', label: 'High', color: '#f16965' },
    ];

    const getTaskStatus = (status) => {
        const taskStatus = taskStatuses.find((t) => t.value === status);
        if (!taskStatus) {
            return { label: 'Unknown', color: '#ccc' };
        }
        return { label: taskStatus.label, color: taskStatus.color };
    };

    const TaskStatusBadge = ({ status, isTable }) => {
        const { label, color } = getTaskStatus(status);
        return (
            (isTable ? (
                <span className={`tw-text-xs tw-text-white tw-py-1 tw-px-2 tw-rounded-full tw-inline-block tw-whitespace-nowrap`} style={{ backgroundColor: color }}>
                    {label}
                </span>
            )
                :
                (<span
                    className={`tw-uppercase tw-text-xs tw-text-white tw-py-1 tw-px-2 tw-inline-block tw-whitespace-nowrap`}
                    style={{ backgroundColor: color }}>
                    {label}
                </span>)
            )
        )
    };

    const getPriority = (priority) => {
        const priorityObj = priorities.find((p) => p.value === priority);
        if (!priorityObj) {
            return { label: 'None', color: '#ccc' };
        }
        return { label: priorityObj.label, color: priorityObj.color };
    };

    const PriorityBadge = (priProp) => {
        const { priority,classAdd }=priProp
        const { color, label } = getPriority(priority);
        return (
            <div className={'tw-flex tw-items-center tw-flex-no-wrap '+classAdd} {...priProp} onChange={(e)=>priProp.onChange(e)}>
                <span
                    className="tw-inline-block tw-relative tw-mr-1.5 "
                    style={{
                        width: '11px',
                        height: '11px',
                        backgroundColor: color,
                        transform: 'rotate(45deg)',
                    }}
                >
                    <span
                        className="priority-badge-exclamation tw-absolute tw-top-1/2 tw-left-1/2 tw-font-semibold"
                        style={{
                            fontSize: '9px',
                            color: 'white',
                            transform: 'translate(-50%, -50%) rotate(-45deg)',
                        }}
                    >
                        !
                    </span>
                </span>
                {label}
            </div>


        );
    };

    

    const [filters, setFilters] = useState([])
    const [AllFilters, setAllFilters] = useState([])
    const [Myfilters, setMyfilters] = useState([])

    const [isLoading, setIsLoading] = useState(false)
    const [isFilterLoading, setIsFilterLoading] = useState(false)
    const [isTaskLoading, setIsTaskLoading] = useState(false)
    const [isAddTaskModalOpen, setIsAddTaskModalOpen] = useState(false)
    // const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
    const [isSideExapnded, setIsSideExpanded] = useState(false)
    const [referenceElement, setReferenceElement] = useState(null);
    const [popperElement, setPopperElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const [taskDetails, setTaskDetails] = useState({ Title: '', description: '', assignto: '', consumer: '', duedate: '', priority: '', consumer_type: '', task_type: '', Maintenance: false, tags: [],plan:'-1',SHARED_WITH:[] })
    const [flag, setFlag] = useState(false);
    const [Errors, setValidation] = useState({});
    const [UserList, setUserList] = useState([])//users list
    const [ConsumerList, setConsumerList] = useState([])
    const [TaskList, setTaskList] = useState([])
    const [TaskdownLoadList, setTaskdownLoadList] = useState([])
    const [PageSize, setPageSize] = useState(50)
    const [numberofPage, setnumberofPage] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const [TaskDetailsById, setTaskDetailsById] = useState({});
    const [UpdateId, setUpdateId] = useState('')
    const [Comment, setComment] = useState('')
    const [commentData, setcommentData] = useState([])
    const [ShowHide,setShowHide]=useState(false)
    const { styles, attributes } = usePopper(referenceElement, popperElement, {
        modifiers: [
            {
                name: "arrow",
                options: {
                    element: arrowElement,
                }
            },
            {
                name: "offset",
                options: {
                    offset: [60, 10]
                }
            },
            {
                name: 'flip',
                enabled: false,
            },
        ]
    });
    const [showPopper, setShowPopper] = useState(false);
    const [showAntdPopOver, setShowAntdPopover] = useState(false)
    const [files, setFiles] = useState([])
    const [sideFiles, setSideFiles] = useState([])
    const [permision, setpermision] = useState({})
    const [searchLoader, setsearchLoader] = useState(false)

    const [selectedDropdownFilter, setSelectedDropdownFilter] = useState()
    const [MyDropdownFilter, setMyDropdownFilter] = useState()
    const [AllDropdownFilter, setAllDropdownFilter] = useState()
    const [totalUserList, setTotalUserList] = useState([])
    const [selectedUser, SetSelectedUser] = useState()
    const [selectedPriority, SetSelectedPriority] = useState()
    const [taskTags, setTaskTags] = useState([])
    const [filteredData, setFilteredData] = useState({ taskAssignTo: userInfo.unique_id,taskStatus:['-1','0','3','2'] })
    const [selectedRow, setSelectedRow] = useState(null);
    const [isTaskUploading, setIsTaskUploading] = useState(false);
    const [OnLoaderTask,setOnLoaderTask]=useState(false)
	const [ismodelFilter, setismodelFilter] = useState(false);
    const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
    const [UpdateTrigger, setUpdateTrigger] = useState(false)

    const [AllTaskdata,setAllTaskdata]=useState({})
    const [userList, setuserList] = useState([]);
  
  const ongetUsersByGroups=async()=>{
    setIsLoading(true)

  const data=await getUsersByGroups(userInfo.unique_id)
  if(data.success==true){
    setuserList(data?.data?.users?.map(item=>item?.user_id))
    if(data?.data?.users?.length==0){
    setIsLoading(false)

    }
  }else{
  
  }
}
    const onChangeInfo=(data)=>{
        setAllTaskdata(data)
			setismodelFilter(true);

    }

    const handleRowClick = (item) => {
        setSelectedRow(item.TASK_UNIQUE_ID);
        onGetTaskByUniqueId(item.TASK_UNIQUE_ID);
    };


    const handleOpenChange = (newOpen) => {
        setShowAntdPopover(!showAntdPopOver);
    };
    const hide = () => {
        setShowAntdPopover(false);
    };

    const ongetUsersList = async () => {
        const data = await getUsersList()
        if (data.success === true) {
            console.log("Data = ", data.data.data)
            var userLists=[]
            data.data.data.map((item)=>userList.includes(item.value)||userInfo?.role === 'ROLE1650614500708'?(userLists.push(item)):[])
            setUserList(userLists)
        } else {
            console.log('something want wrong')
        }
    }

    const getAllUsers = async () => {
        const users = await getUsersListForTask()
        if (users.success === true) {
            var userLists=[]
            users.data.data.map((item) => userList.includes(item?.unique_id)||userInfo?.role === 'ROLE1650614500708'?(userLists.push({ value: item?.unique_id, label: item?.first_name + " " + item?.last_name, filtervalue: { taskAssignTo: item?.unique_id } })):'')
            setTotalUserList(userLists)
        }
        else {
            console.log('Error in getting users', users.err)
        }
    }

    //get tasktags
    const ongetTaskTags = async () => {
        const data = await getTaskTag(true)
        if (data.success === true) {
            setTaskTags(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME, color: item?.TAG_COLOR })))
        } else {
            console.log('Error in getting task tags', data.err)
        }
    }

    const getFavFilters = async () => {
        setFilters([])
        const FavFilterdata = await getFavoriteFilters(userInfo.unique_id);
        if (FavFilterdata.success === true) {
            console.log("Fav = ", FavFilterdata.data.data);

            if (FavFilterdata.data.data && Object.entries(FavFilterdata.data.data).length > 0) {
                const favoriteFilters = Object.entries(FavFilterdata.data.data).map(([key, filterValue]) => ({
                    value: key,
                    label: key,
                    filtervalue: filterValue,
                }));

                setFilters([...favoriteFilters]);
            }
        } else {
            console.log("Error in getting fav filters", FavFilterdata.err);
        }
    };


    const ongetGSMConsumerList = async (info) => {
        console.log("first")
        if (info?.type) {
            setsearchLoader(true)
            const data = await getGSMConsumerList({ ...info, CATEGORY0_view: permision.roles_permission_category0.CATEGORY0_view, CATEGORY1_view: permision.roles_permission_category1.CATEGORY1_view, CATEGORY2_view: permision.roles_permission_category2.CATEGORY2_view, CATEGORY3_view: permision.roles_permission_category3.CATEGORY3_view, CATEGORY4_view: permision.roles_permission_category4.CATEGORY4_view, CATEGORY5_view: permision.roles_permission_category5.CATEGORY5_view, CATEGORY0: Profile.account, CATEGORY1: Profile.ga, CATEGORY2: Profile.circle, CATEGORY3: Profile.division, CATEGORY4: Profile.subdivision, CATEGORY5: Profile.area, roles: userInfo?.role })
            if (data.success === true) {
                setsearchLoader(false)
                setConsumerList(data.data.data)
            } else {
                setsearchLoader(false)

                console.log('something went wrong', data.err)
            }
        } else {
            toast.error('Consumer Type is not selected, select consumer type to get consumer list', {
                position: toast.POSITION.TOP_CENTER,
                transition: Slide,
                theme: "colored",
                autoClose: false,
            });
        }
    }
    const handleFile = (e) => {

        let totalFiles = (Object.entries(e.target.files).length + files.length)
        if (totalFiles < 4) {
            console.log(e.target.files);
            Object.entries(e.target.files).map(([key, value]) => {
                let isExist = files.some(fileItem => fileItem.name === value.name && fileItem.lastModified == value.lastModified)
                if (!isExist) {
                    if (value.size < 2e+7) {
                        setFiles(files => [...files, value])
                    }
                    else {
                        toast.warn("Maximum file size allowed is 20 MB, please try with different file.", {
                            position: toast.POSITION.TOP_CENTER,
                            transition: Slide,
                            theme: "colored",
                            autoClose: false,
                        });
                    }
                }
                else {
                    toast.info('You have already selected this file. Please select different file', {
                        position: toast.POSITION.TOP_CENTER,
                        transition: Slide,
                        theme: "colored",
                        autoClose: false,
                    })
                }
            })
        }
        else {
            toast.warn(`You can upload only a maximum of 3 files`, {
                position: toast.POSITION.TOP_CENTER,
                transition: Slide,
                theme: "colored",
                autoClose: false,
            });
            // alert(`You can only select 3 files maximum... But You have selected ${totalFiles}.`)
        }
    }

    const handleSideFiles = (e) => {
        let totalFiles = (Object.entries(e.target.files).length + sideFiles.length)
        if (totalFiles < 4) {
            console.log(e.target.files)
            Object.entries(e.target.files).map(async ([key, value],id) => {
                let isExist = sideFiles.some(fileItem => fileItem.name === value.name)
                console.log("isExist === ", isExist,value)

                if (!isExist) {
                    if (value.size < 2e+7) {
                        // setSideFiles(sideFiles => [...sideFiles, value])
                        
                        var attechmentsobj = sideFiles
                        console.log("Images === ",value)
                        
                        var formData = new FormData()
                        formData.append('Images', e.target.files[id])
                        console.log("formData === ",formData)
                        const uploadImges = await Taskuploads(formData)
                        console.log(attechmentsobj);
                        if (uploadImges.success === true) {
                            attechmentsobj.push({ SRNO: attechmentsobj[attechmentsobj?.length - 1]?.SRNO || 0 + 1, file: uploadImges.data.originalname, size: value?.size })
                            await activeandinactive({ tableName: 'consumer_task_index', colName: 'attachment', value: `'${JSON.stringify(attechmentsobj)}'`, matchId: 'task_unique_id' }, TaskDetailsById?.TASK_UNIQUE_ID)
                            onGetTaskByUniqueId(TaskDetailsById?.TASK_UNIQUE_ID)

                        } else {

                        }
                    }
                    else {
                        toast.warn("Maximum file size allowed is 20 MB, please try with different file.", {
                            position: toast.POSITION.TOP_RIGHT,
                            transition: Slide,
                            theme: "colored",
                            autoClose: false,
                        });
                    }
                }
                else {
                    toast.info('You have already selected this file. Please select different file', {
                        position: toast.POSITION.TOP_RIGHT,
                        transition: Slide,
                        theme: "colored",
                        autoClose: false,
                    })
                }
            })
        }
        else {
            toast.warn(`You can upload only a maximum of 3 files`, {
                position: toast.POSITION.TOP_RIGHT,
                transition: Slide,
                theme: "colored",
                autoClose: false,
            });
        }

    }

    const deleteSelectedFile = (id) => {

        setFiles(files.filter(item => id != item))
    }
    function formatBytes(bytes) {
        var marker = 1024; // Change to 1000 if required
        var decimal = 2; // Change as required
        var kiloBytes = marker; // One Kilobyte is 1024 bytes
        var megaBytes = marker * marker; // One MB is 1024 KB
        var gigaBytes = marker * marker * marker; // One GB is 1024 MB

        // return bytes if less than a KB
        if (bytes < kiloBytes) return bytes + " Bytes";
        // return KB if less than a MB
        else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
        // return MB if less than a GB
        else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
    }

    const onDeleteFile = async (file, SRNO) => {
        const data = await uploadsDeleteFile({ path: `TASK_ATTACHMENTS/${file}` })
        if (data.success == true) {
            const filterData = sideFiles.filter((item) => (item.SRNO !== SRNO))
            const data = await activeandinactive({ tableName: 'consumer_task_index', colName: 'ATTACHMENT', value: `'${JSON.stringify(filterData)}'`, matchId: 'TASK_UNIQUE_ID' }, TaskDetailsById?.TASK_UNIQUE_ID)
            if (data.success === true) {
                onGetTaskByUniqueId(TaskDetailsById?.TASK_UNIQUE_ID)
            } else {
            }
        } else {
            toast.error('Server Issue File Not Delete')
        }
    }
    const onOpenAddTaskModal = () => {
        setIsAddTaskModalOpen(true)
        ongetUsersList()
        ongetTaskTags()
        onHandaleSelection()
        if(consumerId){
        ongetGSMConsumerList({ type: ConsumerType })
        setProfile({ account: GSMdata?.CAT0_ID || '', ga: GSMdata?.CAT1_ID || '', circle: GSMdata?.CAT2_ID || '', division: GSMdata?.CAT3_ID || '', subdivision: GSMdata?.CAT4_ID || '', area: GSMdata?.CAT5_ID || '' })
        }
    }

    const onModelCancel = () => {
        setIsAddTaskModalOpen(false)
        setFiles([])
        setTaskDetails({ Title: '', description: '', assignto: '', consumer: '', duedate: '', priority: '', consumer_type: '', task_type: '', Maintenance: false, tags: [],plan:'-1',SHARED_WITH:[] })
        setValidation({})
        setUpdateId('')
        setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" })

    }

    const onDownloadFiles = async (name) => {
        const data1 = await geturls3({ bucketName: 'iot.memighty.com', objectKey: `TASK_ATTACHMENTS/${name}`, expiration: 60 })
        console.log(data1);
        var link = document.createElement("a");
        link.href = data1.data.data;
        link.download = name;
        link.click();

    }
    // const onUpdateTaskDetails=async()=>{
    // }

    const consumerSelectStyle = {
        menu: provided => ({ ...provided, zIndex: 9999 }),
        control: (base, state) => ({
            ...base,
            border: '1px solid #e4e6fc',
            boxShadow: state.isFocused ? "0 0 0 1px #8179f0" : 0,
            '&:hover': {
                border: '1px solid #e4e6fc',
                cursor: 'text'
            }
        }),
        clearIndicator: (base, state) => ({
            ...base,
            cursor: 'pointer'
        })
    }

    const taskSelectStyle = {
        menu: provided => ({
            ...provided, width: "max-content",
            minWidth: "100%", zIndex: 9999
        }), control: (base, state) => ({
            ...base,
            border: '1px solid #e4e6fc',
            boxShadow: state.isFocused ? "0 0 0 1px #8179f0" : 0,
            '&:hover': {
                border: '1px solid #e4e6fc',

            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
                color: isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                    color: 'black'
                },
            };
        },
        singleValue: (provided, { data }) => {
            const color = chroma(data.color);
            return {
                ...provided,
                width: 'max-content',
                padding: '2px 4px',
                borderRadius: '4px',
                backgroundColor: color.alpha(0.2).css(),
                color: data.color,
            };
        },
        clearIndicator: (base, state) => ({
            ...base,
            cursor: 'pointer'
        })
    }

    const customStyles = {
        menu: provided => ({
            ...provided, width: "max-content",
            minWidth: "100%", zIndex: 9999
        }), control: (base, state) => ({
            ...base,
            border: '1px solid #e4e6fc',
            boxShadow: state.isFocused ? "0 0 0 1px #8179f0" : 0,
            '&:hover': {
                border: '1px solid #e4e6fc',

            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
                color: isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                    color: 'black'
                },
            };
        },
        multiValue: (provided, { data }) => {
            console.log("Colorr ====== ", data)
            const color = chroma(data.color);
            return {
                ...provided,
                width: 'min-content',
                backgroundColor: color.alpha(0.2).css(),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),


    }
    const taskStatus = [
        { value: '-1', label: 'Yet To Start', color: '#fc544b' },
        { value: '0', label: 'Working On It', color: '#ffa426' },
        { value: '1', label: 'Completed', color: '#54ca68' }
    ]
    const taskPriority = [
        { value: 'High', label: 'High', color: '#fc544b' },
        { value: 'medium', label: 'Medium', color: '#ffa426' },
        { value: 'low', label: 'Low', color: '#54ca68' }
    ]

    const taskPlan = [
         { value: -1, label: 'Yet To Decide', color: '#54ca68' },
        { value: 1, label: 'Today', color: '#fc544b' },
        { value: 0, label: 'This Week', color: '#ffa426' }
    ]

    const validation = (name, value) => {
        switch (name) {
            case "Title":
                if (!value) {
                    return "Please input Title!";
                } else {
                    return "";
                }
            case "assignto":
                if (!value) {
                    return "Please select assignto!";
                } else {
                    return "";
                }

            default: {
                return null;
            }
        }
    };

    const onFormSubmit = async (e) => {
        e.preventDefault();
        let allErrors = {};
        setIsTaskUploading(true);

        Object.keys(taskDetails).forEach((key) => {
            const error = validation(key, taskDetails[key]);
            if (error && error.length) {
                allErrors[key] = error;
            }
        });
        var objProfile = { CATEGORY0: Profile?.account, CATEGORY1: Profile?.ga, CATEGORY2: Profile?.circle, CATEGORY3: Profile?.division, CATEGORY4: Profile?.subdivision, CATEGORY5: Profile?.area }
        if (Object.keys(allErrors).length) {
            setFlag(false);
            setIsTaskUploading(false);
            return setValidation(allErrors);

        } else {
            if (taskDetails.Maintenance === true) {
                await activeandinactive({ tableName: 'gsm_consumer_device_index', colName: 'CUSTOMER_STATUS', value: `2`, matchId: 'UNIQUE_ID' }, taskDetails?.consumer)
            }
            if (UpdateId === '') {
                const attechmentsobj = []

                if (files.length > 0) {
                    await new Promise(async (resolve) => files.length > 0 && files.map(async (item, id) => {
                        if (item) {
                            const formData = new FormData()
                            formData.append('Images', item)
                            const uploadImges = await Taskuploads(formData)
                            if (uploadImges.success === true) {
                                attechmentsobj.push({ SRNO: id + 1, file: uploadImges.data.originalname, size: item?.size })
                            } else {
                                attechmentsobj.push({ SRNO: id + 1, files: '' })
                            }
                        } else {
                            attechmentsobj.push({ SRNO: id + 1, files: '' })
                        }
                        if (attechmentsobj.length === files.length) {
                            resolve()
                        }
                    })
                    )
                }
                if (attechmentsobj.length === files.length) {
                    const data = await InsertsTaskDetails({ ...taskDetails, tags: taskDetails.tags.map(item => item.value), CREATED_BY: userInfo?.unique_id, attechmentsobj: JSON.stringify(attechmentsobj), Filter_object: JSON.stringify(objProfile) })
                    if (data.success === true) {
                        ongetTaskList(storedFilter)
                        toast.success('Task Created', {
                            position: toast.POSITION.TOP_CENTER,
                            transition: Slide,
                            theme: "colored",
                        })
                        setFiles([])
                        setIsAddTaskModalOpen(false)
                        setTaskDetails({ Title: '', description: '', assignto: '', consumer: '', duedate: '', priority: '', consumer_type: '', task_type: '', Maintenance: false, tags: [],plan:'-1',SHARED_WITH:[] })
                        setFlag(false);
                        setValidation({})
                        setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" })
                        setIsTaskUploading(false);
                    } else {
                        toast.error('Server Issue', (JSON.stringify(data.err.response)), {
                            position: toast.POSITION.TOP_CENTER,
                            transition: Slide,
                            theme: "colored",
                        })
                        setFlag(false);
                        setValidation({})
                        setIsTaskUploading(false);
                    }
                }
            } else {
                const old_json={  assignto: TaskDetailsById?.TASK_ASSIGN_TO,plan:TaskDetailsById?.PLAN||-1, consumer: TaskDetailsById?.CONSUMER_ID, consumer_type: TaskDetailsById?.TASK_CONSUMER_TYPE, description: TaskDetailsById?.TASK_DESCRIPTION, duedate: TaskDetailsById?.DUE_DATE && TaskDetailsById?.DUE_DATE != '0000-00-00' ? moment(TaskDetailsById?.DUE_DATE).format('DD-MM-YYYY') : '', priority: TaskDetailsById?.TASK_PRIORITY, Title: TaskDetailsById?.TASK_TITLE, task_type: TaskDetailsById?.TASK_TYPE, tags: JSON.parse(TaskDetailsById?.tags_info||'[]')?.map(item => ({ value: item?.TASK_TAGS_ID, label: item?.TAG_NAME, color: item?.TAG_COLOR })) }
                var old_diffrent_json={}
                var diffrent_json={}
         Object.keys(old_json).map((item)=>{
            if(old_json[item]!=taskDetails[item]){
               diffrent_json[item]=taskDetails[item]
               old_diffrent_json[item]=old_json[item]
            }
         })
                const data = await UpdateTaskDetails({ ...taskDetails, UPDATED_BY: userInfo?.unique_id, Filter_object: JSON.stringify(objProfile), tags: taskDetails.tags.map(item => item?.value) }, UpdateId)
                if (data.success === true) {
                    ongetTaskList(storedFilter)
                    await TaskActivityLogMaintainOnFrontend({tableName:'task_activity_log',created_by:userInfo?.unique_id,old_json:JSON.stringify(old_diffrent_json),new_json:JSON.stringify(diffrent_json),task_unique_id:UpdateId})
                    toast.success('Task Updated', {
                        position: toast.POSITION.TOP_CENTER,
                        transition: Slide,
                        theme: "colored",
                    })
                    setIsAddTaskModalOpen(false)
                    setTaskDetails({ Title: '', description: '', assignto: '', consumer: '', duedate: '', priority: '', consumer_type: '', task_type: '', Maintenance: false, tags: [],plan:'-1',SHARED_WITH:[] })
                    setFlag(false);
                    setValidation({})
                    setUpdateId('')
                    setProfile({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" })

                    onCommentTask(TaskDetailsById?.TASK_UNIQUE_ID)
                    onGetTaskByUniqueId(TaskDetailsById?.TASK_UNIQUE_ID)
                    setIsTaskUploading(false);
                } else {
                    toast.error('Server Issue', {
                        position: toast.POSITION.TOP_CENTER,
                        transition: Slide,
                        theme: "colored",
                    })
                    setFlag(false);
                    setValidation({})
                    setIsTaskUploading(false);

                }
            }
        }
    }

    const ongetTaskList = async (newFilter) => {

        const firstPageIndex = (currentPage - 1) * PageSize;
        let data;
        if (newFilter) {
            data = await getFilteredTaskDetails({ filters: {...newFilter,userList:userList,taskid:newFilter?.taskid?.map((item)=>(item?.split('GUMS-T')[1]?item?.split('GUMS-T')[1]:item))}, start: firstPageIndex, end: PageSize,ROLE:userInfo?.role,consumerId:consumerId
            })
        }
        else {
            data = await getFilteredTaskDetails({ filters: {...filteredData,userList:userList,taskid:newFilter?.taskid?.map((item)=>(item?.split('GUMS-T')[1]?item?.split('GUMS-T')[1]:item))}, start: firstPageIndex, end: PageSize,ROLE:userInfo?.role,consumerId:consumerId })
        }

        if (data.success === true) {
            console.log("getAllTaskDetails = ", data.data.data)
            setTaskList(data.data.data)
            setnumberofPage(data.data.totalCount)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            console.log('something went wrong')
        }
    }

    const onGetTasksToDownload = async () => {
        const data = await getFilteredTaskDetails({ filters: filteredData, start: 0, end: 100000 })
        if (data.success === true) {
            setTaskdownLoadList(data.data.data)
            setIsLoading(false)
        } else {
            setIsLoading(false)
            console.log('something went wrong')
        }
    }



    const onHandaleRoles = async () => {
        const data = await getRolesById(userInfo?.role);
        if (data.success === true) {
            const obj = data.data.data[0];
            setpermision({ ...obj, roles_permission_category0: JSON.parse(obj?.roles_permission_category0||'{}'), roles_permission_category5: JSON.parse(obj?.roles_permission_category5||'{}'), roles_permission_category2: JSON.parse(obj?.roles_permission_category2||'{}'), roles_permission_category3: JSON.parse(obj?.roles_permission_category3||'{}'), roles_permission_category1: JSON.parse(obj?.roles_permission_category1||'{}'), roles_permission_category4: JSON.parse(obj?.roles_permission_category4||'{}'),roles_other_permission:JSON.parse(obj?.roles_other_permission||'{}') });
        } else {
            console.log('something want wrong')
        }
    }

    const getFilteredData = async (newFilter) => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        setIsFilterLoading(true)
        // const filteredData = await getFilteredTaskDetails({ filters: { ...selectedDropdownFilter?.filtervalue, ...selectedPriority?.filtervalue, ...selectedUser?.filtervalue }, start: firstPageIndex, end: PageSize })

        const filteredData = await getFilteredTaskDetails({ filters: {...newFilter,userList:userList,taskid:newFilter?.taskid?.map((item)=>(item?.split('GUMS-T')[1]?item?.split('GUMS-T')[1]:item))}, start: firstPageIndex, end: PageSize,ROLE:userInfo?.role,consumerId:consumerId })
        if (filteredData.success === true) {
            setTaskList(filteredData.data.data)
            setnumberofPage(filteredData.data.totalCount)
            setIsFilterLoading(false)
        } else {
            setIsFilterLoading(false)
            console.log('Error:', filteredData.err)
        }
    }

    // useEffect(() => {
    //     getFilteredData()
    // }, [selectedDropdownFilter, selectedUser, selectedPriority]);


    useEffect(() => {
        if(userList.length>0||userInfo?.role === 'ROLE1650614500708'){
        setIsLoading(true)
        console.log("Stored Filter = ", storedFilter)
        if (storedFilter) {
            ongetTaskList(storedFilter)
            if(storedFilterType=='All Filter'){
                setAllDropdownFilter(storedFilterS)
            }else if(storedFilterType=='My Filters'){
                setMyDropdownFilter(storedFilterS)
            }else if(storedFilterType=='Custom Filters'){
                setSelectedDropdownFilter(storedFilterS)
            }
        } else {
            ongetTaskList({ taskAssignToMe: userInfo.unique_id,taskStatus:[-1,0,3,2] })
            setMyDropdownFilter({value: 'My Pending',
                label: 'My Pending',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['-1','0','3','2'],
                    "taskAssignTo": [
                        userInfo.unique_id
                    ],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }})
        }
        onHandaleRoles()
        ongetTaskTags()
        getAllUsers()
        getFavFilters()

        const Static_My_filter=[{value: 'My Pending',
                label: 'My Pending',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['-1','0','3','2'],
                    "taskAssignTo": [
                        userInfo.unique_id
                    ],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'My Status Open',
                label: 'My Status Open',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['-1'],
                    "taskAssignTo": [
                        userInfo.unique_id
                    ],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'My Status In Review',
                label: 'My Status In Review',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['2'],
                    "taskAssignTo": [
                        userInfo.unique_id
                    ],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'My Status Working On It',
                label: 'My Status Working On It',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['0'],
                    "taskAssignTo": [
                        userInfo.unique_id
                    ],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'My Status On Hold',
                label: 'My Status On Hold',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['3'],
                    "taskAssignTo": [
                        userInfo.unique_id
                    ],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'My Status Complete',
                label: 'My Status Complete',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['1'],
                    "taskAssignTo": [
                        userInfo.unique_id
                    ],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'My Status Cancel',
                label: 'My Status Cancel',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['4'],
                    "taskAssignTo": [
                        userInfo.unique_id
                    ],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }}]

                const Static_ALL_filter=[{value: 'All Pending',
                label: 'All Pending',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['-1','0','3','2'],
                    "taskAssignTo": [],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'All Status Open',
                label: 'All Status Open',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['-1'],
                    "taskAssignTo": [],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'All Status In Review',
                label: 'All Status In Review',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['2'],
                    "taskAssignTo": [],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'All Status Working On It',
                label: 'All Status Working On It',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['0'],
                    "taskAssignTo": [],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'All Status On Hold',
                label: 'All Status On Hold',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['3'],
                    "taskAssignTo": [],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'All Status Complete',
                label: 'All Status Complete',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['1'],
                    "taskAssignTo": [],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }},{value: 'All Status Cancel',
                label: 'All Status Cancel',
                filtervalue: {
                    "todays": false,
                    "overdue": false,
                    "taskTags": [],
                    "taskType": [],
                    "taskTitle": "",
                    "taskStatus": ['4'],
                    "taskAssignTo": [],
                    "taskPriority": [],
                    "taskCreatedBy": [],
                    "taskConsumerType": []
                }}]

                
                setMyfilters([...Static_My_filter])
                setAllFilters([...Static_ALL_filter])
            }else{
                // setIsLoading(false)
            }
            
    }, [userList]);
    useEffect(()=>{
        ongetUsersByGroups()
        if(consumerId){
            setTaskDetails({ ...taskDetails, consumer_type: ConsumerType, consumer: GSMdata?.UNIQUE_ID })
        }
    },[])

    useEffect(() => {
        if(userList?.length>0||userInfo?.role === 'ROLE1650614500708'){
        if (storedFilter) {
            ongetTaskList(storedFilter)
        } else {
            ongetTaskList({ taskAssignToMe: userInfo.unique_id,taskStatus:[-1,0,3,2] })
        }
    }
    }, [currentPage, PageSize])


    const onGetTaskByUniqueId = async (UniqueId) => {
        setOnLoaderTask(true)
        const data = await getAllTaskDetailsByUniqueId(UniqueId)
        if (data.success == true) {
            setIsSideExpanded(true)
            setTaskDetailsById(data.data.data)
            const profileObj = JSON.parse(data.data.data?.FILTER_OBJECT || '{}')
            setProfile({ account: profileObj?.CATEGORY0 || '', ga: profileObj?.CATEGORY1 || '', circle: profileObj?.CATEGORY2 || '', division: profileObj?.CATEGORY3 || '', subdivision: profileObj?.CATEGORY4 || '', area: profileObj?.CATEGORY5 || '' })
            setSideFiles(JSON.parse(data?.data?.data?.ATTACHMENT || '[]'))
        setOnLoaderTask(false)
        } else {
            console.log('something want wrong')
        setOnLoaderTask(false)
        }
        onCommentTask(UniqueId)
    }



    const onActive = async (value, UID, type, isUnderMaintenance,TASK_STATUS) => {
        console.log("value = ", value)
        const data = await activeandinactive({ tableName: 'consumer_task_index', colName: 'TASK_STATUS', value: value, matchId: 'TASK_UNIQUE_ID' }, UID)
        if (data.success === true) {
            onGetTaskByUniqueId(TaskDetailsById?.TASK_UNIQUE_ID)
            // ongetTaskList()
            const Index_array=TaskList?.findIndex((item)=>(item?.TASK_UNIQUE_ID==TaskDetailsById?.TASK_UNIQUE_ID));
            var Array_Task=[...TaskList];
            Array_Task[Index_array].TASK_STATUS=value;
            setTaskList(Array_Task)
            await TaskActivityLogMaintainOnFrontend({tableName:'task_activity_log',created_by:userInfo?.unique_id,old_json:JSON.stringify({TASK_STATUS:TASK_STATUS}),new_json:JSON.stringify({TASK_STATUS:value}),task_unique_id:UID})
        } else {
        }
        if (value == '1' && type === "GSM" && isUnderMaintenance) {
            await UpdateConsumerStatusInMaintanace(TaskDetailsById?.CONSUMER_ID)
            // await activeandinactive({tableName:'gsm_consumer_device_index',colName:'CUSTOMER_STATUS',value:`1`,matchId:'UNIQUE_ID'},taskDetails?.consumer)
        } else if (value == '1' && type === "LORAWAN" && isUnderMaintenance) {
            await UpdateLorawanConsumerStatusInMaintanace(TaskDetailsById?.CONSUMER_ID)
        }
    }

    const onChangesStatus = async (value, UID, type, isUnderMaintenance,TASK_STATUS) => {
        console.log("value = ", value)
        const data = await activeandinactive({ tableName: 'consumer_task_index', colName: 'TASK_STATUS', value: value, matchId: 'TASK_UNIQUE_ID' }, UID)
        if (data.success === true) {
            if(TaskDetailsById?.TASK_UNIQUE_ID){
            onGetTaskByUniqueId(UID)
            }
            // ongetTaskList()
            const Index_array=TaskList?.findIndex((item)=>(item?.TASK_UNIQUE_ID==UID));
            var Array_Task=[...TaskList];
            Array_Task[Index_array].TASK_STATUS=value;
            setTaskList(Array_Task)
            if(TASK_STATUS!=value){
            await TaskActivityLogMaintainOnFrontend({tableName:'task_activity_log',created_by:userInfo?.unique_id,old_json:JSON.stringify({TASK_STATUS:TASK_STATUS}),new_json:JSON.stringify({TASK_STATUS:value}),task_unique_id:UID})
            }
        } else {
        }
    }

    const onChangesPriority = async (value, UID,TASK_PRIORITY) => {
        console.log("value = ", value)
        const data = await activeandinactive({ tableName: 'consumer_task_index', colName: 'TASK_PRIORITY', value: `'${value}'`, matchId: 'TASK_UNIQUE_ID' }, UID)
        if (data.success === true) {
            if(TaskDetailsById?.TASK_UNIQUE_ID){
            onGetTaskByUniqueId(UID)
            }
            // ongetTaskList()
            const Index_array=TaskList?.findIndex((item)=>(item?.TASK_UNIQUE_ID==UID));
            var Array_Task=[...TaskList];
            Array_Task[Index_array].TASK_PRIORITY=value;
            setTaskList(Array_Task)
            if(TASK_PRIORITY!=value){
            await TaskActivityLogMaintainOnFrontend({tableName:'task_activity_log',created_by:userInfo?.unique_id,old_json:JSON.stringify({TASK_PRIORITY:TASK_PRIORITY}),new_json:JSON.stringify({TASK_PRIORITY:value}),task_unique_id:UID})
            }
        } else {
        }
    }

    const onChangesPlan = async (value, UID,PLAN) => {
        console.log("value = ", value)
        const data = await activeandinactive({ tableName: 'consumer_task_index', colName: 'PLAN', value: `${value}`, matchId: 'TASK_UNIQUE_ID' }, UID)
        if (data.success === true) {
            if(TaskDetailsById?.TASK_UNIQUE_ID){
            onGetTaskByUniqueId(UID)
            }
            // ongetTaskList()
            const Index_array=TaskList?.findIndex((item)=>(item?.TASK_UNIQUE_ID==UID));
            var Array_Task=[...TaskList];
            Array_Task[Index_array].PLAN=value;
            setTaskList(Array_Task)
            if(PLAN!=value){
            await TaskActivityLogMaintainOnFrontend({tableName:'task_activity_log',created_by:userInfo?.unique_id,old_json:JSON.stringify({PLAN:PLAN}),new_json:JSON.stringify({PLAN:value}),task_unique_id:UID})
            }
        } else {
        }
    }

    const handleClick = (status, taskDetails) => {
        onActive(status?.value, taskDetails.TASK_UNIQUE_ID, taskDetails.TASK_CONSUMER_TYPE, taskDetails.IS_UNDER_MAINTENANCE,taskDetails?.TASK_STATUS);
    };

    const handleStatus = (status, taskDetails) => {
        onChangesStatus(status?.value, taskDetails.TASK_UNIQUE_ID, taskDetails.TASK_CONSUMER_TYPE, taskDetails.IS_UNDER_MAINTENANCE,taskDetails?.TASK_STATUS);
    };
    const onCommentTask = async (id) => {
        const data = await getTaskCommentAndHistoryById({ UNIQUE_ID: id })
        if (data.success == true) {
            setcommentData(data.data.data)
        } else {
            console.log('something want wrong')
            setcommentData([])
        }
    }
    const onEditTask = () => {
        setIsAddTaskModalOpen(true)
        setTaskDetails({ assignto: TaskDetailsById?.TASK_ASSIGN_TO,plan:TaskDetailsById?.PLAN||-1, consumer: TaskDetailsById?.CONSUMER_ID, consumer_type: TaskDetailsById?.TASK_CONSUMER_TYPE, description: TaskDetailsById?.TASK_DESCRIPTION, duedate: TaskDetailsById?.DUE_DATE && TaskDetailsById?.DUE_DATE != '0000-00-00' ? moment(TaskDetailsById?.DUE_DATE).format('DD-MM-YYYY') : '', priority: TaskDetailsById?.TASK_PRIORITY, Title: TaskDetailsById?.TASK_TITLE, task_type: TaskDetailsById?.TASK_TYPE,assignee_id:TaskDetailsById?.ASSIGNEE_ID,SHARED_WITH:JSON.parse(TaskDetailsById?.SHARED_WITH||'[]'), tags: JSON.parse(TaskDetailsById?.tags_info||'[]')[0]?.UNIQUE_ID&&JSON.parse(TaskDetailsById?.tags_info||'[]')?.map(item => ({ value: item?.UNIQUE_ID, label: item?.TAG_NAME, color: item?.TAG_COLOR }))||[] })
        setUpdateId(TaskDetailsById?.TASK_UNIQUE_ID)
        onHandaleSelection()
        ongetTaskTags()
        if (UserList.length === 0) {
            ongetUsersList()
        }
        const profileObj = JSON.parse(TaskDetailsById?.FILTER_OBJECT || '{}')
        setProfile({ account: profileObj?.CATEGORY0 || '', ga: profileObj?.CATEGORY1 || '', circle: profileObj?.CATEGORY2 || '', division: profileObj?.CATEGORY3 || '', subdivision: profileObj?.CATEGORY4 || '', area: profileObj?.CATEGORY5 || '' })
        if (profileObj?.CATEGORY0) {
            ongetGSMConsumerList({ type: TaskDetailsById?.TASK_CONSUMER_TYPE })
        }
    }

    const option_Type = [{ value: 'GSM', label: 'GSM' }, { value: 'LORAWAN', label: 'LORAWAN' }]
    const animatedComponents = makeAnimated();

    const addCommandTask = async () => {
        const data = await UpdateTaskDetailesComentAndHistory({ comment: { comment: Comment, created_by: userInfo?.unique_id, created_at: moment() } }, TaskDetailsById?.TASK_UNIQUE_ID)
        if (data.success === true) {
            setComment('')
            onCommentTask(TaskDetailsById?.TASK_UNIQUE_ID)
        } else {
            console.log('something want wrong')
        }
    }
    const [Profile, setProfile] = useState({ account: "", ga: "", circle: "", division: "", subdivision: "", area: "" });
    const [SubdevisionData, setSubdevisionData] = useState([]);
    const [DivisionData, setDivisionData] = useState([]);
    const [CircleData, setCircleData] = useState([]);
    const [GaData, setGaData] = useState([]);
    const [AccounData, setAccountData] = useState([]);
    const [AreaData, setAreaData] = useState([]);
    const buttonArray = ["All Tasks", "Task Dashboard"];
    const [btnText, setButtontext] = useState("All Tasks");
    const onHandalebtn = (text) => {
        setButtontext(text);
    };
    const [NameObj, setNameObj] = useState({})
    const ongetProfilNameByAccountId = async (id) => {
        const data = await getProfilNameByAccountId({ ID: id })
        if (data.success === true) {
            setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
        } else {
            console.log('something want wrong')
        }
    }
    const onHandaleSelection = async () => {
        const data = await getAccount();
        if (data.success === true) {
            setAccountData(data.data.data);
        } else {
            console.log("somthing want wrong");
        }
    };
    const ongetGaById = async (id) => {
        const data1 = await getGaById(id);
        if (data1.success === true) {
            setGaData(data1.data.data);
        }
    };

    const ongetCircle = async (id) => {
        const data1 = await getCircleById(id);
        if (data1.success === true) {
            setCircleData(data1.data.data);
        }
    };
    const ongetSubdevisionById = async (id) => {
        const data1 = await getSubdevisionById(id);
        if (data1.success === true) {
            setSubdevisionData(data1.data.data);
        }
    };
    const ongetDivisionById = async (id) => {
        const data3 = await getDivisionById(id);
        if (data3.success === true) {
            setDivisionData(data3.data.data);
        }
    };
    const ongetArea = async (id) => {
        const data3 = await getAreaById(id);
        if (data3.success === true) {
            setAreaData(data3.data.data);
        }
    };

    useEffect(() => {
        if (Profile.account !== "") {
            ongetGaById(Profile.account);
            setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([])
            ongetProfilNameByAccountId(Profile.account)

        } else {
            setProfile({ ...Profile, ga: "", circle: "", division: "", subdivision: "", area: "" });
            setGaData([]);
            setCircleData([]);
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);
            setNameObj({})
        }
    }, [Profile.account]);

    useEffect(() => {
        if (Profile.ga !== "") {
            ongetCircle(Profile.ga);
            setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
            setSubdevisionData([]);
            setDivisionData([]);
            setAreaData([]);

        } else {
            setProfile({ ...Profile, circle: "", division: "", subdivision: "", area: "" });
            setCircleData([]);
            setDivisionData([]);
            setSubdevisionData([]);
            setAreaData([]);

        }
    }, [Profile.ga]);

    useEffect(() => {
        if (Profile.circle !== "") {
            ongetDivisionById(Profile.circle);
            setProfile({ ...Profile, division: "", subdivision: "", area: "" });
            setAreaData([]);
            setSubdevisionData([]);

        } else {
            setProfile({ ...Profile, division: "", subdivision: "", area: "" });
            setAreaData([]);
            setDivisionData([]);
            setSubdevisionData([]);


        }
    }, [Profile.circle]);

    useEffect(() => {
        if (Profile.division !== "") {
            ongetSubdevisionById(Profile.division);
            setAreaData([]);
            setProfile({ ...Profile, subdivision: "", area: "" });

            setProfile({ ...Profile, subdivision: "", area: "" });
            setSubdevisionData([]);
            setAreaData([]);

        }
    }, [Profile.division]);

    useEffect(() => {
        if (Profile.subdivision !== "") {
            ongetArea(Profile.subdivision);
        } else {
            setProfile({ ...Profile, area: "" });
            setAreaData([]);
        }
    }, [Profile.subdivision]);

    const handleSelectedDropdownFilter = (newFilter) => {
        console.log("Filter = ", newFilter)
        setstoredFilterS(newFilter)
        setstoredFilterType('Custom Filters')
        setSelectedDropdownFilter(newFilter);
        if (newFilter) {
            getFilteredData(newFilter?.filtervalue);
            setFilteredData(newFilter?.filtervalue);
            onChangeStoredFilter(newFilter?.filtervalue);
            setAllDropdownFilter();
            setMyDropdownFilter();
        } else {
            getFilteredData({});
            setFilteredData({});
            onChangeStoredFilter({});
        }
    }

    const handleMyDropdownFilter = (newFilter) => {
        console.log("Filter = ", newFilter)
        setstoredFilterS(newFilter)
        setstoredFilterType('My Filters')
        setMyDropdownFilter(newFilter);
        if (newFilter) {
            getFilteredData(newFilter?.filtervalue);
            setFilteredData(newFilter?.filtervalue);
            onChangeStoredFilter(newFilter?.filtervalue);
            setSelectedDropdownFilter()
        setAllDropdownFilter();

        } else {
            getFilteredData({});
            setFilteredData({});
            onChangeStoredFilter({});
        }
    }

    const handleAllDropdownFilter = (newFilter) => {
        console.log("Filter = ", newFilter)
        setAllDropdownFilter(newFilter);
        setstoredFilterS(newFilter)
        setstoredFilterType('All Filter')
        if (newFilter) {
            getFilteredData(newFilter?.filtervalue);
            setFilteredData(newFilter?.filtervalue);
            onChangeStoredFilter(newFilter?.filtervalue);
            setSelectedDropdownFilter()
            setMyDropdownFilter();
        } else {
            getFilteredData({});
            setFilteredData({});
            onChangeStoredFilter({});
        }
    }

    const handleSelectedUserFilter = (newFilter) => {
        console.log("Filter = ", newFilter)
        SetSelectedUser(newFilter);
    }

    const handlePriorityFilter = (newFilter) => {
        console.log("Filter = ", newFilter)
        SetSelectedPriority(newFilter);
    }

    const handleFilter = (newFilter) => {
        console.log("Filter = ", newFilter)
        setFilteredData(newFilter);
        getFilteredData(newFilter);
        onChangeStoredFilter(newFilter);
    }

    const onAddFavoriteFilter = async (newFilter) => {
        console.log("Filter = ", newFilter)
    }

    const onDownLoadTasks = async (type) => {
        console.log('type', type)
        // onGetTasksToDownload()
    }
    $(document).ready(function () {
		var maxLength = 9;
		$(".show-read-more").each(function () {
			var myStr = $(this).text();
			if ($.trim(myStr).length > maxLength) {
				var newStr = myStr.substring(0, maxLength);
				$(this).empty().html(newStr);
				$(this).append('<span>...</span>');

			}
		});
		var maxLengths = 80;
		$(".show-read-more-name").each(function () {
			var myStr = $(this).text();
			if ($.trim(myStr).length > maxLengths) {
				var newStr = myStr.substring(0, maxLengths);
				$(this).empty().html(newStr);
				$(this).append('<span>...</span>');

			}
		});
	});
    
    const [tags_visible,settags_visible]=useState(false)

    
    return (
        <>
        <Modal title="All Task Detail" visible={ismodelFilter} footer="" onCancel={() => setismodelFilter(false)} width={800}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Fields</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
								<>
                                    <tr>
										<td style={{ width: "50%" }}>Task No</td>
										<td style={{ width: "50%" }}>GUMS-T{AllTaskdata?.SRNO}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>TASK_UNIQUE_ID</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.TASK_UNIQUE_ID}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Title</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.TASK_TITLE}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Description</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.TASK_DESCRIPTION}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Due Date</td>
                                        <td style={{ width: "50%" }}>{AllTaskdata?.DUE_DATE && AllTaskdata?.DUE_DATE !== '0000-00-00' ? moment(AllTaskdata?.DUE_DATE).format('DD-MM-YYYY') : '-'}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Assign To</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.assign_users}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Consumer</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.consumer_name}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Created Date</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.CREATED_AT?moment(AllTaskdata?.CREATED_AT).format('DD-MM-YYYY'):''}</td>
									</tr>
                                    <tr>
										<td style={{ width: "50%" }}>Created By</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.CREATED_BY_USER}</td>
									</tr>
                                    <tr>
										<td style={{ width: "50%" }}>Update Date</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.UPDATED_AT?moment(AllTaskdata?.UPDATED_AT).format('DD-MM-YYYY'):''}</td>
									</tr>
                                    <tr>
										<td style={{ width: "50%" }}>Update By</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.UPDATE_BY_USER}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Duration </td>
										<td style={{ width: "50%" }}>{AllTaskdata?.DUE_DATE && AllTaskdata?.DUE_DATE !== '0000-00-00' ? moment.utc(AllTaskdata?.DUE_DATE) > moment() ? <div className='badge badge-success'>{moment.utc(AllTaskdata?.DUE_DATE).diff(moment.utc(AllTaskdata?.CREATED_AT), 'days') + ' Day '}</div> : <div className='badge badge-danger'>{moment.utc(AllTaskdata?.DUE_DATE).diff(moment.utc(AllTaskdata?.CREATED_AT), 'days') + ' Day '}</div> : '-'}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Task Type</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.TASK_TYPE}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>Task Tags</td>
										<td style={{ width: "50%" }}> {AllTaskdata?.tags_info && JSON.parse(AllTaskdata?.tags_info)?.length > 0 ? (
                                                                    <div className='tw-flex tw-gap-2 tw-flex-wrap'>
                                                                        {JSON.parse(AllTaskdata?.tags_info)?.map(tag =>
                                                                            <div className={`btn btn-icon btn-sm icon-left ml-1 mb-1 !tw-py-0`} style={{
                                                                                color: "white",
                                                                                backgroundColor: tag?.TAG_COLOR,
                                                                                position: 'relative',
                                                                                display: 'inline-block',
                                                                                borderRadius: '1px',
                                                                            }}>
                                                                                <BootstrapTooltip title={tag?.TAG_NAME} placement="top">
                                                                                    <div className="tw-max-w-[100px] tw-truncate">
                                                                                        {tag?.TAG_NAME}
                                                                                    </div>
                                                                                </BootstrapTooltip>
                                                                                <span style={{
                                                                                    content: '',
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    right: '-10px',
                                                                                    transform: 'translateY(-50%)',
                                                                                    borderTop: '14px solid transparent',
                                                                                    borderBottom: '14px solid transparent',
                                                                                    borderLeft: `10px solid ${tag?.TAG_COLOR}`,
                                                                                    borderRadius: '5px',
                                                                                }}>
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : " - "}</td>
									</tr>
									<tr>
										<td style={{ width: "50%" }}>FILTER_OBJECT</td>
										<td style={{ width: "50%" }}>{AllTaskdata?.FILTER_OBJECT}</td>
									</tr>
									
									
								</>
						</tbody>
					</table>
				</div>
			</Modal>
            <Modal title={UpdateId == '' ? 'Create Task' : 'Update Task'} style={{ top: 0 }} visible={isAddTaskModalOpen} footer='' onCancel={onModelCancel} width={860}>
                <div>
                    <form className='tw-w-full'>
                        <div class="form-group row">
                            <div class="col-12">
                                <label for="title" class="col-form-label required"> Title <sup className='text-danger'>*</sup> :</label>
                                <div class="">
                                    <input type="text" class="form-control" id="title" placeholder="Enter title" onChange={(e) => { setTaskDetails({ ...taskDetails, Title: e.target.value }) }} value={taskDetails?.Title} />
                                    <span className='text-danger pb-0'>{Errors?.Title || ''}</span>
                                </div>
                            </div>

                            <div class="col-12">
                                <label class="col-form-label">Description :</label>
                                <div>

                                    <textarea rows={3} class="form-control" id="title" placeholder="Max. 1000 Character" onChange={(e) => { setTaskDetails({ ...taskDetails, description: e.target.value }) }} value={taskDetails?.description} />
                                    <span className='text-danger pb-0'>{Errors?.description || ''}</span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="assign-to" class="col-form-label required">Assign to <sup className='text-danger'>*</sup> : </label>
                                <div class="">
                                    <Select isClearable placeholder='Select User...' id='assign-to' styles={consumerSelectStyle} components={animatedComponents} name='account' options={UserList} onChange={(e) => { setTaskDetails({ ...taskDetails, assignto: e?.value || '',assignee_id:userInfo?.unique_id }) }} value={UserList?.filter((items) => (items.value == taskDetails?.assignto))[0] || null} />

                                    <span className='text-danger pb-0'>{Errors?.assignto || ''}</span>
                                </div>
                            </div>
                            <div class="col-lg-4">
                                <label for="task-priority" class="col-form-label tw-font-medium ">Priority:</label>
                                <div class="">
                                    <Select isClearable id='task-priority' styles={taskSelectStyle} components={animatedComponents} options={taskPriority} onChange={(e) => { setTaskDetails({ ...taskDetails, priority: e?.value || '' }) }} value={taskPriority?.filter((items) => (items.value == taskDetails?.priority))[0] || null} />

                                    <span className='text-danger pb-0'>{Errors?.priority || ''}</span>
                                </div>
                            </div>
                            <div
                                class="col-lg-4">
                                <label for="task-status" class="col-form-label tw-font-medium">Tags :</label>
                                <div class="">
                                    <Select placeholder='Select Tags...' isMulti isClearable id='task-tags' styles={customStyles} components={animatedComponents} options={taskTags} onChange={(e) => { setTaskDetails({ ...taskDetails, tags: e.map((item) => (item)) }) }} value={taskDetails?.tags} />
                                </div>
                            </div>
                            
                            {UpdateId!=''? <div class="col-lg-4">
                                <label class="col-form-label">Due Date</label>
                                <div>
                                    <Space style={{ width: "100%", display: "block" }} direction="horizontal">
                                        <DatePicker style={{ width: "100%", padding: "7px 15px", borderColor: "#e4e6fc" }} id='due-date' format='DD-MM-YYYY' name='start' onChange={(e, dateString) => {
                                            setTaskDetails({ ...taskDetails, duedate: dateString })
                                        }} value={taskDetails?.duedate ? moment.utc(taskDetails?.duedate, 'DD-MM-YYYY') : ''} />
                                        <span className='text-danger pb-0'>{Errors?.duedate || ''}</span>
                                    </Space>
                                </div>
                            </div> :''}
                        </div>
                        <div class="col-lg-4">
                                <label for="assign-to" class="col-form-label required">Shared With : </label>
                                <div class="">
                                    <Select isClearable placeholder='Select User...' id='assign-to' styles={consumerSelectStyle} components={animatedComponents} name='account' options={UserList} onChange={(e) => { setTaskDetails({ ...taskDetails, SHARED_WITH: e.map((item)=>(item?.value)) || '' }) }} value={UserList?.filter((items) => (taskDetails?.SHARED_WITH?.includes(items.value)))|| null} isMulti/>
                                </div>
                            </div>
                        {ShowHide==false?<div class='d-flex tw-justify-center mb-3'>
                            <div class=' tw-pr-7 tw-pl-5 tw-pt-3 tw-pb-3  tw-rounded-[100px] hover:tw-bg-[#dfdfdf] tw-font-bold' style={{boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',cursor:'pointer'}} onClick={()=>setShowHide(!ShowHide)}>Show More</div>
                        </div>:''}
                        
                        {ShowHide==true?<><div class="form-group row">
                        <div class="col-lg-4">
                                <label for="task-priority" class="col-form-label tw-font-medium ">Plan:</label>
                                <div class="">
                                    <Select isClearable id='task-priority' styles={taskSelectStyle} components={animatedComponents} options={taskPlan} onChange={(e) => { setTaskDetails({ ...taskDetails, plan: e?.value || '' }) }} value={taskPlan?.filter((items) => (taskDetails?.plan==items.value ))[0] || null} />

                                </div>
                            </div>
                        <div class="col-lg-4">
                                <label for="assign-to" class="col-form-label">Consumer Type :</label>
                                <div>
                                    <Select isClearable placeholder='Select Consumer...' id='consumer' styles={consumerSelectStyle} components={animatedComponents} name='account' options={option_Type} onChange={(e) => {
                                        setTaskDetails({ ...taskDetails, consumer_type: e?.value || '' })

                                    }} value={option_Type?.filter((items) => (items.value == taskDetails?.consumer_type))[0] || null} />

                                    <span className='text-danger pb-0'>{Errors?.consumer || ''}</span>

                                </div>
                            </div>
                                    </div>
                                    <div class="form-group row">
                                    {taskDetails?.consumer_type?<><div className='col-lg-4'>
                                <div className='tw-flex tw-items-center tw-gap-2'>

                                    <label for="consumer" class="col-form-label tw-font-medium">Account:</label>
                                    {/* {Profile.subdivision === "" && Profile.division === "" && Profile.circle === "" && Profile.ga === "" && Profile.account !== "" && Profile?.area === '' &&
                                        <div className='tw-cursor-pointer' onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search' ></i></div>} */}
                                </div>
                                <div class="">
                                    <select
                                        class="form-control form-select accountoga ga_calueacc"
                                        name="accountid"
                                        required=""
                                        id="accountid"
                                        onChange={(e) => setProfile({ ...Profile, account: e.target.value })}
                                        value={Profile?.account}
                                    >
                                        <option value="">Select Account</option>
                                        {AccounData &&
                                            AccounData.map((item, id) =>
                                                permision?.roles_permission_category0?.CATEGORY0_view?.filter(
                                                    (items) => items === item.UNIQUE_ID
                                                ).length > 0 || userInfo?.role === 'ROLE1650614500708' ? (
                                                    <option key={id} value={item.UNIQUE_ID}>{item.CATEGORY0}</option>
                                                ) : (
                                                    ""
                                                )
                                            )}
                                    </select>
                                </div>

                            </div></>:''}
                            {Profile.account !== "" && (<div class="col-lg-4">
                                <div className='tw-flex tw-items-center tw-gap-2'>
                                    <label for="consumer" class="col-form-label tw-font-medium">{NameObj?.CATEGORY1 || 'CATEGORY1'}:</label>
                                    {/* {Profile.subdivision === "" && Profile.division === "" && Profile.circle === "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area === '' ? <div className='tw-cursor-pointer' onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div> : ''} */}
                                </div>
                                <div class="">
                                    <select
                                        class="form-control form-select gatocircle ga_calue"
                                        name="address_location_d"
                                        id="address_location_d"
                                        onChange={(e) => setProfile({ ...Profile, ga: e.target.value })}
                                        value={Profile?.ga}
                                    >
                                        <option value="">Select {NameObj?.CATEGORY1 || 'CATEGORY1'}</option>
                                        {GaData &&
                                            GaData.map((item, id) =>
                                                permision?.roles_permission_category1?.CATEGORY1_view?.filter(
                                                    (items) => items === item.CATEGORY1_UNIQUE_ID
                                                ).length > 0 || userInfo?.role === 'ROLE1650614500708' ? (
                                                    <option key={id} value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>
                                                ) : (
                                                    ""
                                                )
                                            )}
                                    </select>
                                </div>
                            </div>)}

                            {Profile.ga !== "" && Profile.account !== "" && (
                                <div class="col-lg-4">
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <label for="consumer" class="col-form-label tw-font-medium">{NameObj?.CATEGORY2 || 'CATEGORY2'}:</label>

                                        {/* {Profile.subdivision === "" && Profile.division === "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area === '' && <div className="tw-cursor-pointer" onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div>} */}
                                    </div>
                                    <div class="">
                                        <select
                                            class="form-control form-select circletodivision ga_calue"
                                            name="circle_id"
                                            onChange={(e) => setProfile({ ...Profile, circle: e.target.value })}
                                            value={Profile?.circle}>
                                            <option value="">Select {NameObj?.CATEGORY2 || 'CATEGORY2'}</option>
                                            {CircleData &&
                                                CircleData.map((item, id) =>
                                                    permision?.roles_permission_category2?.CATEGORY2_view?.filter(
                                                        (items) => items === item.CATEGORY2_UNIQUE_ID
                                                    ).length > 0 || userInfo?.role === 'ROLE1650614500708' ? (
                                                        <option key={id} value={item.CATEGORY2_UNIQUE_ID}>{item.CATEGORY2_NAME}</option>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                        </select>

                                    </div>

                                </div>)}
                        </div>

                        {Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && <div class="form-group row">
                            {Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
                                <div class="col-lg-4">
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <label for="consumer" class="col-form-label tw-font-medium">{NameObj?.CATEGORY3 || 'CATEGORY3'}:</label>
                                        {/* {Profile.subdivision === "" && Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area === '' &&
                                            <div className="tw-cursor-pointer" onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div>} */}
                                    </div>
                                    <div className=''>
                                        <select
                                            class="form-control divisiontosubdivision form-select"
                                            name="division_id"
                                            id="address_location_d"
                                            onChange={(e) => setProfile({ ...Profile, division: e.target.value })}
                                            value={Profile?.division}
                                        >
                                            <option value="">Select {NameObj?.CATEGORY3 || 'CATEGORY3'}</option>
                                            {DivisionData &&
                                                DivisionData.map((item, id) =>
                                                    permision?.roles_permission_category3?.CATEGORY3_view?.filter(
                                                        (items) => items === item.CATEGORY3_UNIQUE_ID
                                                    ).length > 0 || userInfo?.role === 'ROLE1650614500708' ? (
                                                        <option key={id} value={item.CATEGORY3_UNIQUE_ID}>{item.CATEGORY3_NAME}</option>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                        </select>

                                    </div>
                                </div>)}
                            {Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
                                <div class="col-lg-4">
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <label for="consumer" class="col-form-label tw-font-medium">{NameObj?.CATEGORY4 || 'CATEGORY4'}:</label>
                                        {/* {Profile.subdivision !== "" && Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area === '' && <div className="tw-cursor-pointer" onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div>} */}
                                    </div>
                                    <div class="">
                                        <select
                                            class="form-control subdivisiontoarea form-select"
                                            name="sub_division_id"
                                            id="address_location_d"
                                            onChange={(e) => setProfile({ ...Profile, subdivision: e.target.value })}
                                            value={Profile?.subdivision}
                                        >
                                            <option value="">Select {NameObj?.CATEGORY4 || 'CATEGORY4'}</option>
                                            {SubdevisionData &&
                                                SubdevisionData.map((item, id) =>
                                                    permision?.roles_permission_category4?.CATEGORY4_view?.filter(
                                                        (items) => items === item.CATEGORY4_UNIQUE_ID
                                                    ).length > 0 || userInfo?.role === 'ROLE1650614500708' ? (
                                                        <option key={id} value={item.CATEGORY4_UNIQUE_ID}>
                                                            {item.CATEGORY4_NAME}
                                                        </option>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                        </select>

                                    </div>
                                </div>)}
                            {Profile.subdivision !== "" && Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && (
                                <div class="col-lg-4">
                                    <div className='tw-flex tw-items-center tw-gap-2'>
                                        <label for="consumer" class=" col-form-label tw-font-medium">{NameObj?.CATEGORY5 || 'CATEGORY5'}:</label>
                                        {/* {Profile.subdivision !== "" && Profile.division !== "" && Profile.circle !== "" && Profile.ga !== "" && Profile.account !== "" && Profile?.area !== '' && <div className="tw-cursor-pointer" onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}><i className='fas fa-search'></i></div>} */}
                                    </div>
                                    <div class="">
                                        <select
                                            class="form-control form-select lastarea cust_ara"
                                            name="area_id"
                                            id="area_id"
                                            onChange={(e) => setProfile({ ...Profile, area: e.target.value })}
                                            value={Profile?.area}
                                        >
                                            <option value="">Select {NameObj?.CATEGORY5 || 'CATEGORY5'}</option>
                                            {AreaData &&
                                                AreaData.map((item, id) =>
                                                    permision?.roles_permission_category5?.CATEGORY5_view?.filter(
                                                        (items) => items === item.UNIQUE_ID
                                                    ).length > 0 || userInfo?.role === 'ROLE1650614500708' ? (
                                                        <option key={id} value={item.UNIQUE_ID}>{item.CATEGORY5_NAME}</option>
                                                    ) : (
                                                        ""
                                                    )
                                                )}
                                        </select>

                                    </div>
                                </div>)}
                        </div>}


                        <div className='form-group row'>
                            <div className='col-lg-6'>
                                <div className='tw-flex tw-items-center tw-gap-2'>
                                    <label for="consumer" class="col-form-label tw-font-medium">Consumer:</label>
                                    
                                </div>
                                <div class="">
                                    <div class='d-flex'>
                                        <div style={{width:'100%'}}> 
                                    <Select isClearable placeholder='Select Consumer...' id='consumer' styles={{...consumerSelectStyle,width:'100%'}} components={animatedComponents} name='account' options={ConsumerList} onChange={(e) => { setTaskDetails({ ...taskDetails, consumer: e?.value || '' }) }} value={ConsumerList?.filter((items) => (items.value == taskDetails?.consumer))[0] || null} />
                                    </div>
                                    {Profile.account !== "" &&
                                    <span className='btn btn-secondary ml-2'>
                                        <ButtonLoader isLoading={searchLoader} onClick={() => ongetGSMConsumerList({ type: taskDetails?.consumer_type })}>
                                            <i className='fas fa-search'></i>
                                        </ButtonLoader>
                                        </span>
                                    }
                                    </div>
                                    <span className='text-danger pb-0'>{Errors?.consumer || ''}</span>
                                    {taskDetails?.consumer ? <div className='d-flex '>
                                        <input type="checkbox" id="checktext2" name="Business_Number" className='mr-2' onChange={(e) => setTaskDetails({ ...taskDetails, Maintenance: e.target.checked === true ? true : false })} checked={taskDetails?.Maintenance} disabled={UpdateId ? true : false} />
                                        <label className='mt-2' for="checktext2">change status in Under Maintenance</label>
                                    </div> : ''}

                                </div>
                            </div>
                            <div className='col-lg-6'>
                                <label htmlFor="taskType" className="tw-w-1/5 col-form-label tw-font-medium">Task Type:</label>
                                <div className="">
                                    <div className="form-check form-check-inline !tw-mb-0">
                                        <input className="form-check-input" type="radio" name="taskType" id="taskType1" value="Development" onChange={(e) => { setTaskDetails({ ...taskDetails, task_type: e.target.value }) }} checked={taskDetails?.task_type === "Development"} />
                                        <label className="form-check-label" htmlFor="taskType1">Development</label>
                                    </div>
                                    <div className="form-check form-check-inline !tw-mb-0">
                                        <input className="form-check-input" type="radio" name="taskType" id="taskType2" value="Action" onChange={(e) => { setTaskDetails({ ...taskDetails, task_type: e.target.value }) }} checked={taskDetails?.task_type === "Action"} />
                                        <label className="form-check-label" htmlFor="taskType2">Action</label>
                                    </div>
                                    <div className="form-check form-check-inline !tw-mb-0">
                                        <input className="form-check-input" type="radio" name="taskType" id="taskType3" value="Bug" onChange={(e) => { setTaskDetails({ ...taskDetails, task_type: e.target.value }) }} checked={taskDetails?.task_type === "Bug"} />
                                        <label className="form-check-label" htmlFor="taskType3">Bug</label>
                                    </div>
                                    <span className="text-danger pb-0">{Errors?.task_type || ""}</span>
                                </div>
                            </div>
                        </div>

                        <div class="form-group row border-top mb-0">
                            
                            {UpdateId === '' && <div class="col-lg-4">
                                <div id="ember836" class="form-group ember-view d-print-none">
                                    <p class="form-control-plaintext">
                                        <div class="font-xs">Attach File(s)</div>
                                        <div className='tw-flex tw-gap-3'>
                                            <label for="attachments" className={`${files.length > 2 ? 'hover:!tw-border-[#ddd] tw-text-stone-400' : 'hover:!tw-border-[#6777ef] tw-cursor-pointer'} tw-mb-0  tw-px-4 tw-py-2 tw-text-xs tw-font-semibold tw-border !tw-border-dashed tw-border-[#ddd] `}>
                                                <input disabled={files.length > 2 ? true : false} name="attachments" type="file" multiple id='attachments' className='tw-hidden' onChange={handleFile} onClick={e => (e.target.value = null)} />
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-4 tw-w-3" viewBox="0 0 512 512"> <path d="M262.4 512c-35.7 0-64.2-10.5-84.9-31.4-24.7-24.9-37.1-63.8-36.8-115.6.2-32.6-.3-248.7-1.4-268.1-.9-15.9 4.8-41.6 23.6-61.5 11.4-12 32-26.3 66-26.3 30.3 0 55.1 15.7 69.8 44.2 10.1 19.7 12.2 38.8 12.4 40.9l.1.9v.9l.5 236.9v.5c-1 19.2-15.9 47.6-53 47.6h-.7c-39.1-.4-53.7-30.4-56-46.2l-.2-1.3V179.6c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v150.5c.4 1.5 1.4 4.4 3.6 7.2s6.2 6.5 16 6.6c9.2.1 12.4-3.2 14.1-6 1.6-2.6 2.2-5.6 2.3-6.3l-.7-234.5c-.4-3-2.4-15.6-8.8-27.6-8.3-15.7-20.2-23.3-36.4-23.3-16.7 0-29.8 5-39.1 14.8-10.7 11.3-14 26.6-13.6 34 1.2 21.6 1.6 244.3 1.4 270.3-.2 41.6 8.5 71.7 26 89.3 13.5 13.6 33.2 20.4 58.7 20.4 17.2 0 31.8-5.9 43.5-17.7 18.9-18.9 30.1-53.4 30-92.2 0-19.6-.1-193.2-.1-250.9 0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5c0 57.6.1 231.2.1 250.8.1 49.1-14.8 92.3-40.8 118.4-18.6 18.7-42.7 28.6-69.6 28.6z" /> </svg>
                                                Upload File(s)
                                            </label>
                                            {files.length > 0 ? <span className='tw-flex tw-bg-indigo-500 tw-py-1 tw-px-3 tw-rounded' ref={setReferenceElement} onClick={() => setShowPopper(!showPopper)}>
                                                <div className='tw-flex tw-items-center tw-justify-center tw-text-white tw-cursor-pointer'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-4 tw-w-3" viewBox="0 0 512 512"> <path d="M262.4 512c-35.7 0-64.2-10.5-84.9-31.4-24.7-24.9-37.1-63.8-36.8-115.6.2-32.6-.3-248.7-1.4-268.1-.9-15.9 4.8-41.6 23.6-61.5 11.4-12 32-26.3 66-26.3 30.3 0 55.1 15.7 69.8 44.2 10.1 19.7 12.2 38.8 12.4 40.9l.1.9v.9l.5 236.9v.5c-1 19.2-15.9 47.6-53 47.6h-.7c-39.1-.4-53.7-30.4-56-46.2l-.2-1.3V179.6c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v150.5c.4 1.5 1.4 4.4 3.6 7.2s6.2 6.5 16 6.6c9.2.1 12.4-3.2 14.1-6 1.6-2.6 2.2-5.6 2.3-6.3l-.7-234.5c-.4-3-2.4-15.6-8.8-27.6-8.3-15.7-20.2-23.3-36.4-23.3-16.7 0-29.8 5-39.1 14.8-10.7 11.3-14 26.6-13.6 34 1.2 21.6 1.6 244.3 1.4 270.3-.2 41.6 8.5 71.7 26 89.3 13.5 13.6 33.2 20.4 58.7 20.4 17.2 0 31.8-5.9 43.5-17.7 18.9-18.9 30.1-53.4 30-92.2 0-19.6-.1-193.2-.1-250.9 0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5c0 57.6.1 231.2.1 250.8.1 49.1-14.8 92.3-40.8 118.4-18.6 18.7-42.7 28.6-69.6 28.6z" /> </svg>
                                                    {files.length}

                                                </div>
                                            </span> : ''}


                                            {files.length > 0 && showPopper ? <div className='tw-bg-white tw-w-96 tw-rounded-md' ref={setPopperElement} style={styles.popper} {...attributes.popper} id='tooltip'>
                                                <div className='' ref={setArrowElement} style={styles.arrow} id='arrow' />
                                                <div className='tw-border tw-border-solid tw-border-[#ddd] tw-px-2 tw-py-1 tw-rounded-md'>
                                                    <div class="tw-float-right tw-mr-2 tw-cursor-pointer" onClick={() => setShowPopper(!showPopper)}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-3 tw-w-2" viewBox="0 0 512 512">
                                                            <path d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                    <div className='tw-box-border'>
                                                        {files.map((item, id) => <div id={id} className='tw-px-4 tw-pt-4 tw-pb-2 border-bottom !tw-border-b-gray-300 last:!tw-border-b-0'>
                                                            <div className='container'>
                                                                <div className='tw-flex tw-gap-4'>
                                                                    <div className="tw-flex tw-items-center fa-2xl">
                                                                        <i class="fas fa-file-alt" aria-hidden="true"></i>
                                                                    </div>

                                                                    <div className='tw-flex'>
                                                                        <div className=' tw-block'>
                                                                            <div className='tw-w-full tw-text-indigo-400 ' style={{ wordWrap: 'anywhere' }}>

                                                                                {item.name}
                                                                                <span className='tw-text-xs tw-text-[#777] tw-block '>
                                                                                    File Size : {formatBytes(item.size)}
                                                                                </span>
                                                                            </div>
                                                                            <div className='tw-mt-1'>
                                                                                <span onClick={() => deleteSelectedFile(item)} class="tw-flex tw-items-center tw-gap-1 tw-cursor-pointer tw-text-indigo-400 tw-text-sm">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill='#6366f1' viewBox="0 0 512 512" width="14" height="16" >
                                                                                        <path d="M207 415c8.8 0 16-7.2 16-16V175c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16z"></path>
                                                                                        <path d="M467 63H351V11c0-6.6-5.4-12-12-12H170.9c-6.6 0-12 5.4-12 12l.1 52H43c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h20v404c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12V95h20c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12zM191 31h128v32H191V31zm224 436c0 6.6-5.4 12-12 12H107c-6.6 0-12-5.4-12-12V95h320v372z">
                                                                                        </path>
                                                                                        <path d="M303 415c8.8 0 16-7.2 16-16V175c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16z">
                                                                                        </path>
                                                                                    </svg> Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>

                                                </div>
                                            </div> : ''}
                                        </div>
                                        <div>
                                            <small className='tw-text-stone-400'>You can upload a maximum of 3 files, 20MB each</small>
                                        </div>
                                    </p>
                                </div>
                            </div>}
                        </div></>:''}
                        {ShowHide==true?<div class='d-flex tw-justify-center mb-3'>
                        <div class=' tw-pr-7 tw-pl-5 tw-pt-3 tw-pb-3  tw-rounded-[100px] hover:tw-bg-[#dfdfdf] tw-font-bold' style={{boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px',cursor:'pointer'}} onClick={()=>setShowHide(!ShowHide)}>Show Less</div>
                        </div>:''}
                        <div className='border-top !tw-border-b-gray-300 tw-flex'>
                            <div class="buttons tw-mt-5">
                                <button disabled={isTaskUploading} class="btn btn-danger" onClick={onFormSubmit}>
                                    {isTaskUploading ? (
                                        <div className="spinner-container">
                                            <i className="fa fa-spinner fa-spin"></i>
                                        </div>
                                    ) : (
                                        UpdateId === '' ? 'Create' : 'Update'
                                    )}</button>
                                <Link class="btn btn-light text-dark" onClick={onModelCancel}>Cancel</Link>
                            </div>
                        </div>

                    </form>
                </div >
            </Modal >

            {isLoading ? <div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                < GoogleLoader bodyClassName={'tw-h-[70%]'} />
            </div > :
                <>
                    <div className="border-bottom !tw-border-b-gray-300">
                        <div className='tw-mx-2 tw-mt-2 ml-4'>
                        {AllFilters &&<><b>All Filter : </b> <Dropdown2 options={AllFilters}
                                placeHolder="All Filters"
                                value={AllDropdownFilter}
                                valueClassname={`tw-font-semibold !tw-W-full ${AllDropdownFilter&&'tw-text-indigo-500'}`}
                                onChange={handleAllDropdownFilter}
                                searchable={true}
                                searchPlaceholder="search filters ..."
                                arrow={true}
                                containerClassName={`tw-min-w-[100px] border tw-rounded !tw-border-Cyan-500 ${AllDropdownFilter?'mr-1':'mr-4'}`}
                                style={{ width: '200px' }}
                                menuClassName='!tw-W-full'
                                disabled={false} />{AllDropdownFilter&&!isFilterLoading&&<span class="badge badge-primary mr-4">{numberofPage}</span>}&#12644;&#12644;&#12644;<span style={{fontSize:'20px',color:'#b3b3b3'}}>|</span>&#12644;&#12644;&#12644;</>}
                        {Myfilters &&<><b>My Filters : </b> <Dropdown2 options={Myfilters}
                                placeHolder="My Filters"
                                value={MyDropdownFilter}
                                valueClassname={`tw-font-semibold !tw-W-full ${MyDropdownFilter&&'tw-text-indigo-500'}`}
                                onChange={handleMyDropdownFilter}
                                searchable={true}
                                searchPlaceholder="search filters ..."
                                arrow={true}
                                containerClassName={`tw-min-w-[100px] border tw-rounded !tw-border-Cyan-500  ${MyDropdownFilter?'mr-1':'mr-4'}`}
                                style={{ width: '200px' }}
                                disabled={false} />{MyDropdownFilter&&!isFilterLoading&&<span class="badge badge-primary mr-4">{numberofPage}</span>}&#12644;&#12644;&#12644;<span style={{fontSize:'20px',color:'#b3b3b3'}}>|</span>&#12644;&#12644;&#12644;</>}
                            {filters &&<><b>Custom Filters : </b> <Dropdown2 options={filters}
                                placeHolder="Custom Filters"
                                value={selectedDropdownFilter}
                                valueClassname={`tw-font-semibold !tw-W-full ${selectedDropdownFilter&&'tw-text-indigo-500'}`}
                                onChange={handleSelectedDropdownFilter}
                                searchable={true}
                                searchPlaceholder="search filters ..."
                                arrow={true}
                                containerClassName={`tw-min-w-[100px] border tw-rounded !tw-border-Cyan-500  ${selectedDropdownFilter?'mr-1':'mr-4'}`}
                                style={{ width: '200px' }}
                                disabled={false} />{selectedDropdownFilter&&!isFilterLoading&&<span class="badge badge-primary mr-2">{numberofPage}</span>}{selectedDropdownFilter&&!isFilterLoading&&<span class="badge badge-info mr-2" style={{cursor:'pointer'}} onClick={()=>{setIsFilterModalOpen(true)
                                    setUpdateTrigger(true)}}> <i className='fas fa-edit'></i></span>}</>}
                        </div>
                                    <hr class='mt-2 mb-0'/>
                        <div className='tw-px-5 tw-py-3 tw-flex tw-justify-between tw-items-center '>
                            <div className='tw-flex tw-items-center'>
                                {totalUserList.length > 0 && <TaskFilter handleFilter={handleFilter} tagsTypsOptions={taskTags} taskAssignToOptions={totalUserList} currentUser={userInfo.unique_id} currentFilter={storedFilter} handleShowFilter={(showFilter) => onChangeShowFilter(showFilter)} currentShowFilter={storedShowFilter} onAddFavoriteFilter={onAddFavoriteFilter} getFavFilters={getFavFilters} setIsFilterModalOpen={setIsFilterModalOpen} isFilterModalOpen={isFilterModalOpen} storedFilterType={storedFilterType} storedFilterS={storedFilterS} setUpdateTrigger={setUpdateTrigger} UpdateTrigger={UpdateTrigger}/>}
                            </div>


                            <div className="d-flex">
                                {btnText === 'All Tasks' ? permision?.roles_other_permissions?.Task?.add === true || userInfo?.role === 'ROLE1650614500708' ? <button type="button" className="btn btn-primary mr-1" onClick={() => (onOpenAddTaskModal())}>
                                    <BootstrapTooltip title="Task">
                                        <div className='tw-flex tw-items-center tw-gap-1 '>
                                            <i class="fas fa-plus" aria-hidden="true"></i>
                                            <span>
                                                New
                                            </span>
                                        </div>
                                    </BootstrapTooltip>
                                    
                                </button> : '' : ''}
                                <div className='tw-mx-4'>
                                                   
                                                    <AdvancedDownload
                                                        data={TaskList}
                                                        filename="tasks"
                                                        selectedClassName={'tw-bg-indigo-500 tw-text-white'}
                                                        fileTypeOptions={[
                                                            { value: 'csv', label: 'CSV' },
                                                            { value: 'excel', label: 'Excel' }
                                                        ]}
                                                        onDownload={onDownLoadTasks}
                                                    />

                                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='tw-flex tw-relative tw-w-full tw-overflow-y-auto tw-grow'>
                        <div className={classNames('!tw-border-r-gray-300', isSideExapnded ? 'animate__fadeInRight_grow border-end tw-w-[50%]' : 'animate__fadeInLeft tw-flex-grow')}>

                            {isFilterLoading ? <><div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                <GoogleLoader msg={"Loading Filtered data"} bodyClassName={'tw-h-[70%]'} />
                            </div> </> : <div>
                                <div className="tw-flex">
                                    <div className="table-responsive tw-h-screen tw-max-h-screen">

                                        <div id="table-1_wrapper" className="dataTables_wrapper no-footer">

                                            <div className="dataTables_length mt-2 ml-2 tw-flex tw-justify-between tw-w-full" id="table_id_length">
                                            </div>
                                            <table class="tw-w-full tw-text-sm tw-border-collapse border-top">
                                                <thead className='tw-sticky tw-z-10 tw-top-0 tw-text-xs tw-uppercase tw-bg-gray-100  border-bottom  !tw-border-b-gray-300 tw-rounded-lg' >
                                                    <tr>
                                                        <th className='tw-py-3 tw-rounded-l-lg tw-px-1 tw-text-center' style={{ width: "9%", maxWidth: '10%' }}>Task no</th>
                                                        {/* <th style={{ width: '38%', maxWidth: '38%' }}>Subject</th> */}
                                                        <th className='tw-max-w-[20%] tw-w-[20%] tw-px-1 tw-text-center' >Title</th>
                                                        <th className='tw-px-1 tw-text-center'>Consumer</th>
                                                        <th className='tw-px-1 tw-text-center'>Assign To</th>
                                                        <th className='tw-px-1 tw-text-center'>Assign By</th>
                                                        <th className='tw-px-1 tw-text-center'> Created By</th>
                                                        <th className='tw-px-1 tw-text-center'> SHARED WITH</th>
                                                        <th className='tw-px-1 tw-text-center' >Status</th>
                                                        <th className='tw-px-1 tw-text-center'>Priority</th>
                                                        <th className='tw-px-1 tw-text-center'>Task Type</th>
                                                        <th className='tw-px-1 tw-text-center'>Tags</th>
                                                        <th className='tw-px-1 tw-text-center'>Plan</th>
                                                        <th className='tw-px-1 tw-text-center' style={{ width: '100px' }}>Due Date</th>
                                                        <th className='tw-px-1 tw-text-center' style={{ width: '100px' }}>Duration</th>
                                                        <th className='tw-py-3 tw-rounded-r-lg tw-px-1 tw-text-center' style={{ width: '2%', maxWidth: '2%' }}></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {TaskList?.length > 0 && TaskList.map((item, id) => (
                                                        <tr
                                                            key={item.TASK_UNIQUE_ID}
                                                            className={` border-bottom !tw-border-b-gray-300 ${selectedRow === item.TASK_UNIQUE_ID ? 'tw-bg-indigo-100' : 'hover:tw-bg-[#fafafa]'} ${item?.TASK_STATUS==1||item?.TASK_STATUS==4?'':'tw-font-semibold'} `}>
                                                            
                                                            <td class="tw-px-2 tw-py-3 tw-text-center tw-text-blue-600 tw-cursor-pointer tw-group" onClick={() => handleRowClick(item)}>
                                                            <div className='tw-flex tw-items-center  tw-gap-2 tw-mx-2'>
                                                                <span>IOTOPS-T{item?.SRNO}</span>
                                                                <div className="tw-opacity-0 tw-transition-all group-hover:tw-opacity-100" >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" id="right-arrow"><g data-name="Layer 2"><g data-name="arrowhead-right"><path fill='currentColor' d="m18.78 11.37-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"></path><path fill='currentColor' d="M7 5.37a1 1 0 0 0-1.61 1.26L9.71 12l-4.48 5.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 6 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 0-1.27z"></path></g></g></svg>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className={`tw-max-w-[20%] tw-w-[20%] tw-px-1 `}><BootstrapTooltip title={item?.TASK_TITLE} placement="top">{item?.TASK_STATUS==1||item?.TASK_STATUS==4?<del className='show-read-more-name'>{item?.TASK_TITLE}</del>:<div className='show-read-more-name'>{item?.TASK_TITLE}</div>}</BootstrapTooltip></td>
                                                            <td className='tw-px-1 tw-text-center'>  {item?.consumer_name||'-'}</td>
                                                            <td className='tw-px-1 tw-text-center'>{item?.assign_users||'-'}</td>
                                                            <td className='tw-px-1 tw-text-center'>{item?.assignee_name||'-'}</td>
                                                            <td className='tw-px-1 tw-text-center'>{item?.CREATED_BY_USER}</td>
                                                            <td className='tw-px-1 tw-text-center'>{item?.shared}</td>
                                                            <td className='tw-px-1 tw-text-center'>
                                                            <Dropdown2 value={taskStatuses.filter((items) => items.value == item?.TASK_STATUS)[0]}
                                            isClearable={false} isSearchable={true} options={taskStatuses} onChange={(value) => handleStatus(value, item)}
                                            placeHolder="Change Status" arrow={false} className="tw-min-w-[189px] tw-min-h-full" searchable={true} 
                                            searchPlaceholder="search Status ..."  costomStyle={'tw-rounded-[15px]'}/>
                                                            </td>
                                                            <td className='tw-px-1 tw-text-center'>
                                                                <div className='dropdown d-inline tw-text-center' id={item.TASK_UNIQUE_ID} onChange={(e)=>(console.log(e))}>
                      <PriorityBadge priority={item?.TASK_PRIORITY} classAdd={'dropdown-toggle tw-text-center'} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" />
                      <div class="dropdown-menu" >
                      {priorities?.map((prio)=>(
                         <div class="dropdown-item" value={prio?.value} onClick={(e)=>(onChangesPriority(prio?.value,item.TASK_UNIQUE_ID,item?.TASK_PRIORITY))}><PriorityBadge priority={prio?.value} /></div>
                      ))}
                       
                    </div> 
                    </div>
                    </td>
                                                            <td className='tw-px-1 tw-text-center'>{item?.TASK_TYPE ? item?.TASK_TYPE : ' - '}</td>
                                                            <Popover placement="bottom" title={ <span>Tags</span>} content={<SetTags TASK_TAGS={JSON.parse(item?.tags_info||'[]')[0]?.UNIQUE_ID&&JSON.parse(item?.tags_info||"[]")||[]} taskTags={taskTags} UID={item.TASK_UNIQUE_ID} TaskList={TaskList} setTaskList={setTaskList}/>} trigger="click" >
                                                            <td className='tw-min-w-[125px] tw-px-1 tw-text-center tw-cursor-pointer'>
                                                                {JSON.parse(item?.tags_info||"[]") && JSON.parse(item?.tags_info||"[]")?.length > 0&&JSON.parse(item?.tags_info||'[]')[0]?.UNIQUE_ID ? (
                                                                    <div className='tw-flex tw-gap-2 tw-flex-wrap'>
                                                                        {JSON.parse(item?.tags_info||"[]")?.map(tag =>
                                                                            <div className={`btn btn-icon btn-sm icon-left ml-1 mb-1 !tw-py-0`} style={{
                                                                                color: "white",
                                                                                backgroundColor: tag?.TAG_COLOR,
                                                                                position: 'relative',
                                                                                display: 'inline-block',
                                                                                borderRadius: '1px',
                                                                            }}>
                                                                                <BootstrapTooltip title={tag?.TAG_NAME} placement="top">
                                                                                    <div className="tw-max-w-[100px] tw-truncate">
                                                                                        {tag?.TAG_NAME}
                                                                                    </div>
                                                                                </BootstrapTooltip>
                                                                                <span style={{
                                                                                    content: '',
                                                                                    position: 'absolute',
                                                                                    top: '50%',
                                                                                    right: '-10px',
                                                                                    transform: 'translateY(-50%)',
                                                                                    borderTop: '14px solid transparent',
                                                                                    borderBottom: '14px solid transparent',
                                                                                    borderLeft: `10px solid ${tag?.TAG_COLOR}`,
                                                                                    borderRadius: '5px',
                                                                                }}>
                                                                                </span>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                ) : " - "}
                                                            </td>
                                                                 </Popover>
                    {/* <td className='tw-px-1 tw-text-center'>
                                                                <div className='dropdown d-inline tw-text-center' id={item.TASK_UNIQUE_ID} onChange={(e)=>(console.log(e))}>
                      <div  class={'dropdown-toggle tw-text-center'} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                      {taskPlan?.find((items) => (item?.PLAN==items.value ))?.label||'Plan For Pendding'}
                        </div>
                      <div class="dropdown-menu" >
                      {taskPlan?.map((prio)=>(
                         <div class="dropdown-item" value={prio?.value} onClick={(e)=>(onChangesPlan(prio?.value,item.TASK_UNIQUE_ID))}>{prio?.label}</div>
                      ))}
                    </div> 
                    </div>
                                                            </td> */}
                                                            <td className='tw-px-2 tw-text-center'>
                                                            <Dropdown2 value={taskPlan?.filter((items) => ((item?.PLAN==null?-1:item?.PLAN)==items.value ))[0]}
                                            isClearable={false} isSearchable={true} options={taskPlan} onChange={(value) => onChangesPlan(value?.value, item.TASK_UNIQUE_ID,item?.PLAN)}
                                            placeHolder="Change Status" arrow={false} className="tw-min-w-[189px] tw-min-h-full" searchable={true} 
                                            searchPlaceholder="search Status ..."  costomStyle={'tw-rounded-[15px]'}/>
                                            </td>
                                                            <td className='tw-px-1 tw-text-center' >{item?.DUE_DATE && item?.DUE_DATE !== '0000-00-00' ? moment(item?.DUE_DATE).format('DD-MM-YYYY') : '-'}</td>
                                                            <td className='tw-px-1 tw-text-center'>{item?.DUE_DATE && item?.DUE_DATE !== '0000-00-00' ? moment.utc(item?.DUE_DATE) > moment() ? <div className='badge badge-success'>{moment.utc(item?.DUE_DATE).diff(moment.utc(item?.CREATED_AT), 'days') + ' Day '}</div> : <div className='badge badge-danger'>{moment.utc(item?.DUE_DATE).diff(moment.utc(item?.CREATED_AT), 'days') + ' Day '}</div> : '-'}</td>
                                                            <td className='tw-py-4 tw-text-center tw-px-1'></td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            {!isLoading && TaskList?.length === 0 && <div className='d-flex tw-flex-col tw-justify-center tw-my-32 tw-items-center'><NoTaskFoundSVG /> <b className='tw-text-xl tw-text-indigo-500'>No Task Found !!</b></div>}
                                        </div>
                                    </div>
                                </div>
                                <><div className='d-flex justify-content-between pt-4' style={{borderTop:'1px solid'}}>
                                    <div>Total Number Of Row {numberofPage}</div>
                                    <label>
                                                    Show{" "}
                                                    <select name="table_id_length" aria-controls="table_id" className="" onChange={(e) => (setPageSize(e.target.value))} value={PageSize}>
                                                        <option value={10}>10</option>
                                                        <option value={25}>25</option>
                                                        <option value={50}>50</option>
                                                        <option value={100}>100</option>
                                                    </select>{" "}
                                                    entries
                                                </label>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={numberofPage || 1}
                                        pageSize={PageSize}
                                        onPageChange={(page) => setCurrentPage(page)}
                                    /></div></>

                            </div>}


                        </div>

                        {isSideExapnded && <div className={classNames(' tw-w-[50%] !tw-relative tw-overflow-y-auto tw-overflow-x-hidden tw-h-screen', isSideExapnded ? 'animate__fadeInRight' : 'animate__fadeOutRight')}>
                        {OnLoaderTask==true?<><div className='tw-h-screen tw-w-full tw-transition tw-ease-in-out tw-duration-300'>
                                <GoogleLoader msg={"Loading Task"} bodyClassName={'tw-h-[60%]'} />
                            </div> </>:
                            <div className='tw-flex tw-flex-col tw-w-full'>
                                <div className='tw-w-full tw-flex tw-items-center border-bottom !tw-border-b-gray-300 lg:tw-pl-5 tw-pr-2 tw-py-3 md:tw-px-0   '>
                                    <span className='tw-text-md '>Task No : <b>GUMS-T{TaskDetailsById?.SRNO}</b> <button class="tw-border tw-border-indigo-500 tw-rounded tw-px-1.5 tw-transition tw-ease-in-out tw-duration-300 hover:tw-bg-indigo-200" onClick={() =>
																					onChangeInfo(TaskDetailsById)
																				}><i class="fa-solid fa-info fa-sm"></i></button></span>
                                    <div class="ml-auto"></div>
                                    <div className="tw-w-max tw-border tw-font-medium tw-flex tw-border-solid tw-border-gray-300 tw-rounded tw-uppercase tw-text-xs tw-px-2 tw-py-1 tw-mr-2 tw-gap-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="tw-h-4 tw-w-3">
                                                {TaskDetailsById?.TASK_PRIORITY === "High" ? (
                                                    <path fill="#ff0018" d="M398.5 310.5l-108-156.6c-2.9-4.2-6.5-7.8-10.7-10.7-19-13.1-45.1-8.4-58.3 10.7l-108 156.6c-4.8 7-7.4 15.3-7.4 23.8 0 23.1 18.8 41.9 41.9 41.9h216.1c8.5 0 16.8-2.6 23.8-7.4 19-13.2 23.8-39.2 10.6-58.3z" />
                                                ) : TaskDetailsById?.TASK_PRIORITY === "medium" ? (
                                                    <path fill="#ffa426" d="M392.8 215L297 119.2c-22.7-22.7-59.4-22.7-82.1 0L119.2 215c-22.7 22.7-22.7 59.4 0 82.1l95.8 95.8c22.7 22.7 59.4 22.7 82.1 0l95.8-95.8c22.6-22.7 22.6-59.5-.1-82.1z" />
                                                ) : TaskDetailsById?.TASK_PRIORITY === "low" ? (
                                                    <path fill="#54ca68" d="M364.1 135.8H147.9c-8.5 0-16.8 2.6-23.8 7.4-19 13.1-23.8 39.2-10.7 58.3l108.1 156.6c2.9 4.2 6.5 7.8 10.7 10.7 19 13.1 45.1 8.4 58.3-10.7l108.1-156.6c4.8-7 7.4-15.3 7.4-23.8-.1-23.1-18.8-41.9-41.9-41.9z"></path>

                                                ) : (
                                                    <path fill="#9CA3AF" d="M371.7 243.2h-231c-28.8 0-52.2 23.4-52.2 52.2v0c0 28.8 23.4 52.2 52.2 52.2h231c28.8 0 52.2-23.4 52.2-52.2v0c0-28.8-23.4-52.2-52.2-52.2z" />
                                                )}
                                            </svg>

                                            {TaskDetailsById?.TASK_PRIORITY ? `${TaskDetailsById.TASK_PRIORITY} Priority` : "No Priority"}
                                        </div>
                                    {/* <div className='tw-align-middle tw-inline-flex dropdown '>
                                            <button className='btn btn-primary tw-py-2 dropdown-toggle' data-toggle='dropdown'>Change Status</button>
                                            <div className='dropdown-menu show'>
                                                <button className='dropdown-item' type='button'>Working On It</button>
                                                <button className='dropdown-item' type='button'>Completed</button>

                                            </div>
                                        </div> */}
                                    {permision?.roles_other_permissions?.Task?.active_inactive === true || userInfo?.role === 'ROLE1650614500708' ? <div class="dropdown tw-inline-flex">
                                        <Dropdown2 value={taskStatuses.filter((item) => item.value === TaskDetailsById?.TASK_STATUS)[0]}
                                            isClearable={false} isSearchable={true} options={taskStatuses} onChange={(value) => handleClick(value, TaskDetailsById)}
                                            placeHolder="Change Status" arrow={false} className="tw-min-w-[189px] tw-min-h-full" searchable={true}
                                            searchPlaceholder="search Status ..." />

                                    </div> : ''}
                                    <div className="tw-ml-1">
                                        {permision?.roles_other_permissions?.Task?.edit === true || userInfo?.role === 'ROLE1650614500708' ? <Popover
                                            arrowPointAtCenter={true}
                                            align={{ offset: [80, -5] }}
                                            overlayStyle={{ width: '24rem', borderRadius: '5px' }}
                                            overlayInnerStyle={{ borderRadius: '5px' }}
                                            content={<div className=''>
                                                <div className=''>
                                                    <div class="tw-float-right tw-mr-2 tw-cursor-pointer" onClick={hide}>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-3 tw-w-2" viewBox="0 0 512 512">
                                                            <path d="M455.2 9.2L256 208.4 56.8 9.2C44.5-3.1 24.6-3.1 12.2 9.2l-2.9 2.9C-3 24.4-3 44.4 9.3 56.7L208.4 256 9.2 455.2c-12.3 12.3-12.3 32.3 0 44.6l2.9 2.9c12.3 12.3 32.3 12.3 44.6 0L256 303.6l199.2 199.2c12.3 12.3 32.3 12.3 44.6 0l2.9-2.9c12.3-12.3 12.3-32.3 0-44.6L303.6 256 502.8 56.8c12.3-12.3 12.3-32.3 0-44.6l-2.9-2.9c-12.5-12.4-32.4-12.4-44.7-.1z">
                                                            </path>
                                                        </svg>
                                                    </div>
                                                    <div className='tw-box-border'>
                                                        {sideFiles.map((item) => <div className='tw-px-1 tw-pt-1 tw-pb-2 border-bottom !tw-border-b-gray-300 last:!tw-border-b-0'>
                                                            <div className='container'>
                                                                <div className='tw-flex tw-gap-4'>
                                                                    <div className="tw-flex tw-items-center fa-2xl">
                                                                        <i class="fas fa-file-alt" aria-hidden="true"></i>
                                                                    </div>

                                                                    <div className='tw-flex'>
                                                                        <div className=' tw-block'>
                                                                            <div className='tw-w-full tw-text-indigo-400 ' style={{ wordWrap: 'anywhere' }}>

                                                                                {item.file}
                                                                                <span className='tw-text-xs tw-text-[#777] tw-block '>
                                                                                    File Size : {formatBytes(item.size)}
                                                                                </span>
                                                                            </div>
                                                                            <div className='tw-mt-1 tw-box-border tw-flex'>
                                                                                <a className='!tw-text-indigo-400 tw-font-normal tw-px-2 border-right !tw-border-b-gray-300 ' onClick={() => (onDownloadFiles(item.file))}>Download</a>
                                                                                <span class="tw-flex tw-items-center tw-px-2 tw-gap-1 tw-cursor-pointer tw-text-indigo-400 tw-text-sm" onClick={() => onDeleteFile(item.file, item.SRNO)}>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill='#6366f1' viewBox="0 0 512 512" width="14" height="16" >
                                                                                        <path d="M207 415c8.8 0 16-7.2 16-16V175c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16z"></path>
                                                                                        <path d="M467 63H351V11c0-6.6-5.4-12-12-12H170.9c-6.6 0-12 5.4-12 12l.1 52H43c-6.6 0-12 5.4-12 12v8c0 6.6 5.4 12 12 12h20v404c0 6.6 5.4 12 12 12h360c6.6 0 12-5.4 12-12V95h20c6.6 0 12-5.4 12-12v-8c0-6.6-5.4-12-12-12zM191 31h128v32H191V31zm224 436c0 6.6-5.4 12-12 12H107c-6.6 0-12-5.4-12-12V95h320v372z">
                                                                                        </path>
                                                                                        <path d="M303 415c8.8 0 16-7.2 16-16V175c0-8.8-7.2-16-16-16s-16 7.2-16 16v224c0 8.8 7.2 16 16 16z">
                                                                                        </path>
                                                                                    </svg> Delete
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>)}
                                                    </div>

                                                </div>
                                                <div className='tw-pb-2 tw-pt-5'>
                                                    <div className='tw-text-center'>
                                                        <p className='tw-text-stone-400 tw-text-sm tw-mb-0'>You can upload a maximum of 3 files, 20MB each</p>
                                                    </div>
                                                    <div className='tw-text-center tw-mt-2'>
                                                        <label for="side-attachments" className={`${sideFiles.length > 2 ? 'hover:!tw-border-[#ddd] tw-text-stone-400' : 'hover:!tw-border-[#6777ef] tw-cursor-pointer'} tw-mb-0  tw-px-4 tw-py-2 tw-text-xs tw-font-semibold tw-rounded tw-border tw-border-solid tw-border-[#ddd] tw-bg-[#f5f5f5]`}>
                                                            <input disabled={sideFiles.length > 2 ? true : false} name="side-attachments" type="file" multiple id='side-attachments' className='tw-hidden' onChange={handleSideFiles} onClick={e => (e.target.value = null)} />
                                                            <i className='fas fa-upload'></i>

                                                            <div className='tw-text-indigo-400 tw-p-1'>
                                                                Add File
                                                            </div>
                                                        </label>

                                                    </div>
                                                </div>
                                            </div>}
                                            placement="bottomRight"
                                            trigger="click"
                                            visible={showAntdPopOver}>
                                            <button onClick={handleOpenChange} className="tw-border tw-border-solid tw-border-[#ddd] tw-rounded tw-py-1 tw-px-[0.4rem] ">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="tw-h-5 tw-w-4" viewBox="0 0 512 512"> <path d="M262.4 512c-35.7 0-64.2-10.5-84.9-31.4-24.7-24.9-37.1-63.8-36.8-115.6.2-32.6-.3-248.7-1.4-268.1-.9-15.9 4.8-41.6 23.6-61.5 11.4-12 32-26.3 66-26.3 30.3 0 55.1 15.7 69.8 44.2 10.1 19.7 12.2 38.8 12.4 40.9l.1.9v.9l.5 236.9v.5c-1 19.2-15.9 47.6-53 47.6h-.7c-39.1-.4-53.7-30.4-56-46.2l-.2-1.3V179.6c0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5v150.5c.4 1.5 1.4 4.4 3.6 7.2s6.2 6.5 16 6.6c9.2.1 12.4-3.2 14.1-6 1.6-2.6 2.2-5.6 2.3-6.3l-.7-234.5c-.4-3-2.4-15.6-8.8-27.6-8.3-15.7-20.2-23.3-36.4-23.3-16.7 0-29.8 5-39.1 14.8-10.7 11.3-14 26.6-13.6 34 1.2 21.6 1.6 244.3 1.4 270.3-.2 41.6 8.5 71.7 26 89.3 13.5 13.6 33.2 20.4 58.7 20.4 17.2 0 31.8-5.9 43.5-17.7 18.9-18.9 30.1-53.4 30-92.2 0-19.6-.1-193.2-.1-250.9 0-10.2 8.3-18.5 18.5-18.5s18.5 8.3 18.5 18.5c0 57.6.1 231.2.1 250.8.1 49.1-14.8 92.3-40.8 118.4-18.6 18.7-42.7 28.6-69.6 28.6z" /> </svg>{sideFiles?.length}
                                            </button>
                                        </Popover> : ''}
                                        {permision?.roles_other_permissions?.Task?.edit === true || userInfo?.role === 'ROLE1650614500708' ? <><button style={{ marginLeft: '5px' }} className="tw-border tw-py-1 tw-px-[0.4rem] tw-text-[#212529] tw-border-solid tw-border-[#ddd] tw-rounded " onClick={onEditTask} >
                                        <i className='fas fa-edit'></i>
                                        </button>
                                            </> : ''}
                                    </div>

                                    <span onClick={() => {
                                        setIsSideExpanded(false)
                                        setSelectedRow(null)
                                        setTaskDetailsById({})
                                    }}
                                        className='border-start !tw-border-l-gray-300 tw-float-right tw-text-[#777] tw-ml-2 tw-cursor-pointer hover:tw-text-[#212529]'>
                                        <svg className='tw-h-8 lg:tw-w-10 md:tw-w-7' xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16"> <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" /> </svg>
                                    </span>

                                </div>

                                <div className='tw-overflow-y-auto tw-pb-10'>
                                    <div className='tw-px-4 '>
                                        {/* <div className="tw-w-max tw-border tw-font-medium tw-flex tw-border-solid tw-border-gray-300 tw-rounded tw-uppercase tw-text-xs tw-px-3 tw-py-2 tw-mt-4 tw-gap-1">
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="tw-h-4 tw-w-3">
                                                {TaskDetailsById?.TASK_PRIORITY === "High" ? <path fill="#ff0018" d="M398.5 310.5l-108-156.6c-2.9-4.2-6.5-7.8-10.7-10.7-19-13.1-45.1-8.4-58.3 10.7l-108 156.6c-4.8 7-7.4 15.3-7.4 23.8 0 23.1 18.8 41.9 41.9 41.9h216.1c8.5 0 16.8-2.6 23.8-7.4 19-13.2 23.8-39.2 10.6-58.3z"></path> : TaskDetailsById?.TASK_PRIORITY === "medium" ? <path fill="#ffa426" d="M392.8 215L297 119.2c-22.7-22.7-59.4-22.7-82.1 0L119.2 215c-22.7 22.7-22.7 59.4 0 82.1l95.8 95.8c22.7 22.7 59.4 22.7 82.1 0l95.8-95.8c22.6-22.7 22.6-59.5-.1-82.1z"></path> : TaskDetailsById?.TASK_PRIORITY ? <path fill="#54ca68" d="M364.1 135.8H147.9c-8.5 0-16.8 2.6-23.8 7.4-19 13.1-23.8 39.2-10.7 58.3l108.1 156.6c2.9 4.2 6.5 7.8 10.7 10.7 19 13.1 45.1 8.4 58.3-10.7l108.1-156.6c4.8-7 7.4-15.3 7.4-23.8-.1-23.1-18.8-41.9-41.9-41.9z"></path> : null}
                                            </svg> */}
                                        
                                        <div className='tw-grid tw-grid-cols-5 tw-gap-4 tw-mt-4'>
                                        <div className=''>
                                        Title:- 
                                            {/* <TaskStatusBadge status={TaskDetailsById?.TASK_STATUS} /> */}

                                        </div>
                                        <div className='tw-col-span-4'>
                                        <b className='tw-text-base tw-mr-2'> {TaskDetailsById?.TASK_TITLE}</b>
                                        </div>
                                        <div className=''>
                                           Description:-
                                        </div>
                                        <div className='tw-col-span-4'>
                                        <b>{TaskDetailsById?.TASK_DESCRIPTION}</b>
                                        </div>

                                            <div>
                                                Due Date :
                                            </div>
                                            <div className='tw-col-span-4'>
                                            <b>  {TaskDetailsById?.DUE_DATE && TaskDetailsById?.DUE_DATE !== '0000-00-00' ? moment(TaskDetailsById?.DUE_DATE).format('DD-MM-YYYY') : '-'}</b>
                                            </div >

                                            {/* <div>
                                                Created By :
                                            </div>
                                            <div className='tw-col-span-2'>
                                                {TaskDetailsById?.CREATED_BY_USER}

                                            </div> */}
                                            <div >
                                                Assign To :
                                            </div>
                                            <div className='tw-col-span-4'>
                                            <b>  {TaskDetailsById?.assign_users} </b>

                                            </div>
                                            <div>
                                                Consumer :
                                            </div>
                                            <div className='tw-col-span-4 d-flex '>
                                                {TaskDetailsById?.consumer_name ? TaskDetailsById?.consumer_name : '-'}
                                                {TaskDetailsById?.consumer_name && <a className='ml-2' href={TaskDetailsById?.TASK_CONSUMER_TYPE == "GSM" ? `/view_existing_consumer_gsm/${TaskDetailsById?.CONSUMER_ID}` : `/view_existing_consumer_lorawan/${TaskDetailsById?.CONSUMER_ID}`} target='_blank'><i className='
fas fa-external-link-alt text-info'></i></a>}
                                            </div>

                                            <div>
                                                Created Date :
                                            </div>
                                            <div className='tw-col-span-4'>
                                            <b>  {moment.utc(TaskDetailsById?.CREATED_AT).format('DD-MM-YYYY hh:mm A')}</b>
                                            </div>
                                            <div>
                                                Duration :
                                            </div>
                                            <div className='tw-col-span-4'>{TaskDetailsById?.DUE_DATE && TaskDetailsById?.DUE_DATE !== '0000-00-00' ? moment.utc(TaskDetailsById?.DUE_DATE) > moment() ? <div className='badge badge-success'>{moment.utc(TaskDetailsById?.DUE_DATE).diff(moment.utc(TaskDetailsById?.CREATED_AT), 'days') + ' Day '}</div> : <div className='badge badge-danger'>{moment.utc(TaskDetailsById?.DUE_DATE).diff(moment.utc(TaskDetailsById?.CREATED_AT), 'days') + ' Day '}</div> : '-'}</div>

                                            <div>
                                                Task Type :
                                            </div>
                                            <div className='tw-col-span-4'>
                                            <b>{TaskDetailsById?.TASK_TYPE ? TaskDetailsById?.TASK_TYPE : '-'}</b>
                                            </div>
                                            <div>
                                            Assign By :
                                            </div>
                                            <div className='tw-col-span-4'>
                                            <b>{TaskDetailsById?.assignee_name ? TaskDetailsById?.assignee_name : '-'}</b>
                                            </div>
                                            <div>
                                            Assign At :
                                            </div>
                                            <div className='tw-col-span-4'>
                                            <b>{TaskDetailsById?.ASSIGNED_AT ? moment.utc(TaskDetailsById?.ASSIGNED_AT).format('DD-MM-YYYY hh:mm A') : '-'}</b>
                                            </div>
                                            <div>
                                            Shared with :
                                            </div>
                                            <div className='tw-col-span-4'>
                                            <b>{TaskDetailsById?.shared ? TaskDetailsById?.shared : '-'}</b>
                                            </div>
                                            <div>
                                                Task Tags :
                                            </div>
                                            <div className='tw-col-span-4 tw-flex tw-gap-2'>
                                            <Popover placement="bottom" title={ <span>Tags</span>} content={<SetTags TASK_TAGS={JSON.parse(TaskDetailsById?.tags_info||'[]')[0]?.UNIQUE_ID&&JSON.parse(TaskDetailsById?.tags_info||'[]')||[]} taskTags={taskTags} UID={TaskDetailsById?.TASK_UNIQUE_ID} TaskList={TaskList} setTaskList={setTaskList} onGetTaskByUniqueId={(ID)=>onGetTaskByUniqueId(ID)}/>} trigger="click" >  
                                                {JSON.parse(TaskDetailsById?.tags_info||'[]').length>0&&JSON.parse(TaskDetailsById?.tags_info||'[]')[0]?.UNIQUE_ID ? JSON.parse(TaskDetailsById?.tags_info||'[]')?.map(tag =>
                                                    <div className={`btn btn-icon btn-sm icon-left ml-1 mb-1 !tw-py-0`} style={{
                                                        color: "white", backgroundColor: tag?.TAG_COLOR, position: 'relative',
                                                        display: 'inline-block',
                                                        borderRadius: '1px',
                                                    }}><BootstrapTooltip title={tag?.TAG_NAME} placement="top"><span className='show-read-more'>{tag?.TAG_NAME}</span></BootstrapTooltip><span style={{
                                                        content: '',
                                                        position: 'absolute',
                                                        top: '50%',
                                                        right: '-10px',
                                                        transform: 'translateY(-50%)',
                                                        borderTop: '14px solid transparent',
                                                        borderBottom: '14px solid transparent',
                                                        borderLeft: `10px solid ${tag?.TAG_COLOR}`,
                                                        borderRadius: '5px',
                                                    }}>
                                                        </span></div>)

                                                    : <div className='tw-cursor-pointer'> - </div>}
                                                    </Popover>
                                            </div>
                                            {/* <div>
                                                Filter Object:
                                            </div>
                                            <div className='tw-col-span-2'>
                                                {TaskDetailsById?.FILTER_OBJECT && Object.entries(JSON.parse(TaskDetailsById?.FILTER_OBJECT)).map(([key, value]) => `${key}: ${(value)}`).join(', ')}
                                            </div> */}

                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                    <SlidingTabs onTabChange={(e)=>{console.log(e)}}>
                    <TabPane id="1" title="Comment">
                        <div className="side-panel-tabpane">
                        <div className='tw-p-5'>
                                        <div>
                                            <div className='tw-mb-4'>
                                                <textarea rows="2" id="comment" class=" form-control" data-test-title="user-comment-box" onChange={(e) => (setComment(e.target.value.replace(/[\/\\"`\t\n]/g, '')))} value={Comment}></textarea>
                                            </div>
                                            <div className='tw-mb-4'>
                                                <button className={`btn btn-sm btn-danger  ${Comment == '' ? 'disabled' : ''}`} onClick={addCommandTask}>
                                                    Add comment
                                                </button>
                                            </div>
                                            <div style={{ overflow: 'auto' }}>
                                                <div class="container" style={{ paddingBottom: '0px', paddingTop: '0px', width: '1000px' }}>
                                                    {commentData?.length > 0 && commentData.map((item) => (
                                                        <div class="timeline-block timeline-block-right">
                                                            <div class="mt-3 mr-3" style={{ width: "171px" }}>
                                                                <span className="mt-2">{moment(item?.created_at).format('DD-MM-YYYY hh:mm A')}</span>
                                                            </div>
                                                            <div class="marker active">
                                                                <i class="fas fa-clipboard-list active"></i>
                                                            </div>
                                                            <div class="timeline-content">
                                                                <span className="d-flex"><p style={{ marginTop: '14px', fontSize: '17px', fontWeight: 500 }}>{item?.comment} </p>&nbsp; <p className="" style={{ marginTop: '16px', color: '#787878', fontWeight: 500 }}> By {item?.created_by}</p></span>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        </div>
                    </TabPane>
                    <TabPane id="2" title="Activity">
                        <div className="side-panel-tabpane">
                        <ActivityLogs ID={TaskDetailsById?.TASK_UNIQUE_ID}/>
                        </div>
                    </TabPane>
                </SlidingTabs>
                               </div>    
                                    
                                </div>
                            </div>}
                        </div>}

                    </div>
                </>
            }

        </>
    )
}


export const SetTags=(props)=>{
    const [tagsListSelect,settagsListSelect]=useState(props?.TASK_TAGS?.map(item => ({ value: item?.UNIQUE_ID, label: item?.TAG_NAME, color: item?.TAG_COLOR }))||[])
    const [loader,setloader]=useState(false)
    let userInfo = JSON.parse(window.localStorage.getItem("user_info"));

    const animatedComponents = makeAnimated();

    const customStyles = {
        menu: provided => ({
            ...provided, width: "max-content",
            minWidth: "100%", zIndex: 9999
        }), control: (base, state) => ({
            ...base,
            border: '1px solid #e4e6fc',
            boxShadow: state.isFocused ? "0 0 0 1px #8179f0" : 0,
            '&:hover': {
                border: '1px solid #e4e6fc',

            }
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = chroma(data.color);
            return {
                ...styles,
                backgroundColor: isSelected
                    ? data.color
                    : isFocused
                        ? color.alpha(0.1).css()
                        : undefined,
                color: isSelected
                    ? chroma.contrast(color, 'white') > 2
                        ? 'white'
                        : 'black'
                    : data.color,

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !isDisabled
                        ? isSelected
                            ? data.color
                            : color.alpha(0.3).css()
                        : undefined,
                    color: 'black'
                },
            };
        },
        multiValue: (provided, { data }) => {
            console.log("Colorr ====== ", data)
            const color = chroma(data.color);
            return {
                ...provided,
                width: 'min-content',
                backgroundColor: color.alpha(0.2).css(),
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: data.color,
        }),
        multiValueRemove: (styles, { data }) => ({
            ...styles,
            color: data.color,
            ':hover': {
                backgroundColor: data.color,
                color: 'white',
            },
        }),


    }
        
    const onChangesTags = async () => {
        setloader(true)
        console.log(props)
        const data = await activeandinactive({ tableName: 'consumer_task_index', colName: 'TASK_TAGS', value: `'${JSON.stringify(tagsListSelect?.map(item=>item?.value))}'`, matchId: 'TASK_UNIQUE_ID' }, props?.UID)
        if (data.success === true) {
            // ongetTaskList()
            const Index_array=props?.TaskList?.findIndex((item)=>(item?.TASK_UNIQUE_ID==props?.UID));
            var Array_Task=[...props?.TaskList];
            Array_Task[Index_array].tags_info=JSON.stringify(tagsListSelect.map((item)=>({TAG_COLOR:item?.color,TAG_NAME:item?.label,UNIQUE_ID:item?.value})))
            props?.setTaskList(Array_Task)
        setloader(false)
        await TaskActivityLogMaintainOnFrontend({tableName:'task_activity_log',created_by:userInfo?.unique_id,old_json:JSON.stringify({TASK_TAGS:props?.tags_info||[]}),new_json:JSON.stringify({TASK_TAGS:tagsListSelect}),task_unique_id:props?.UID})
        if(props?.onGetTaskByUniqueId){
            props?.onGetTaskByUniqueId(props?.UID)
          }
        } else {
        setloader(false)
            toast.error('server Issue')
        }
    }
        return (
            <div>
                <Select placeholder='Select Tags...' isMulti isClearable id='task-tags' styles={customStyles} components={animatedComponents} options={props?.taskTags} onChange={(e) => { settagsListSelect(e.map((item) => (item))) }} style={{width:'100px'}} value={tagsListSelect} />
                <div className={`btn btn-danger ${loader?'btn-progress disabled':''} mt-2 ml-2`} onClick={onChangesTags}>Submit</div>
            </div>
        )
    }

export function ActivityLogs(props) {

        const [PageSize, setPageSize] = useState(50)
          const [numberofPage, setnumberofPage] = useState(1)
          const [currentPage, setCurrentPage] = useState(1);
          const [BufferData,setBufferData]=useState([])
          const [loader, setLoader] = useState(true)
          const [IsNewJsonData,setIsNewJsonData]=useState(false)
          const [NewJsonInfo,setNewJsonInfo]=useState({})
          const [IsOldJsonData,setIsOldJsonData]=useState(false)
          const [OldJsonInfo,setOldJsonInfo]=useState({})
          const [loading,setloading]=useState(false)
      
      const ongetGSMBufferData=async()=>{
          setLoader(true)
          const firstPageIndex = (currentPage - 1) * PageSize;
              const lastPageIndex = firstPageIndex + PageSize;
          const data=await getTaskActivityLogs({ start: firstPageIndex, end: PageSize,task_unique_id:props?.ID })
          if(data.success===true){
              const groupByDate = data.data.data?.reduce((acc, log) => {
                  const date =moment.utc(log.created_at).format('DD-MM-YYYY');
                  if (!acc[date]) {
                      acc[date] = [];
                  }
                  acc[date].push(log);
                  return acc;
              }, {});
              const groupedLogs = Object.keys(groupByDate).map(date => {
                  return {
                      date,
                      logs: groupByDate[date]
                  }
              });
              setBufferData(groupedLogs)
              setnumberofPage(parseInt(data.data.totalCount))
             
          setLoader(false)
          }else{
      console.log('something want wrong')
              setLoader(false)
          }
      }
      
      useEffect(() => {
          ongetGSMBufferData()
      }, []);
      
      useEffect(() => {
          ongetGSMBufferData()
      }, [currentPage, PageSize])
      
      const onHandalNewJson=(data,info)=>{
          setIsNewJsonData(true)
          setNewJsonInfo(data)
          setOldJsonInfo(info)
      
      }
      
      const onHandalOldJson=(data)=>{
          setIsOldJsonData(true)
          setOldJsonInfo(data)
      }
        return (
          <>
          <Modal title="Activity Json" maskClosable={false} visible={IsNewJsonData} footer='' onCancel={() => setIsNewJsonData(false)} width={1500}>
          <table id="table_id" className="table border text-center table-borderless table-striped font-14">
                                                          <thead>
                                                              <tr >
                                                                  <th className="text-center" >Old Json</th>
                                                                  <th className="text-center">New Changed json</th>
                                                              </tr>
                                                          </thead>
                                                          <tbody>
                                                          <tr>
                                                                  <th className="text-center" ><pre> {JSON.stringify(OldJsonInfo, null, 2)}</pre></th>
                                                                  <th className="text-center"><pre> {JSON.stringify(NewJsonInfo, null, 2)}</pre></th>
                                                              </tr>
                                                          </tbody>
                                                          </table>
                  </Modal>
                  <Modal title="Old Json" maskClosable={false} visible={IsOldJsonData} footer='' onCancel={() => setIsOldJsonData(false)} width={1500}>
                    <pre> {JSON.stringify(OldJsonInfo, null, 2)}</pre>
                  </Modal>
            
                              <div className="row">
                                  
      
      <div className="timeline-card">
                          {loading ? (
                              <p className="loading-message">Loading...</p>
                          ) : (
                              <>
                                  {BufferData.length > 0 && BufferData.map((logP, index) => (
                                      <div className="timeline">
                                          <div className="date-section" key={index}>
                                              <div className="date">{logP.date}</div>
                                          </div>
                                          {logP?.logs.map((log, index) => (
                                              <div className="timeline-item" key={index}>
                                                  <div className="timeline-icon">{logP?.logs.length - index}</div>
                                                  <div className="timeline-data">
                                                      <div className="activity-log-avatar">
                                                          <span>{log?.created_by[0]}</span>
                                                      </div>
                                                      <div className="timeline-content">
                                                          <div className="activity-log-activity">
                                                              <p className="activity-log-activity-creator">{log?.created_by} has </p>
                                                              <p className="activity-log-activity-type">{log?.activity_type}</p>
                                                              <p className="activity-log-activity-static">Updated <b>({Object.keys(JSON.parse(log?.new_changed_json||'{}')).join(', ')})</b></p>
                                                              <p className="activity-log-activity-column">{log?.updated_columns && log?.updated_columns?.length > 0 ? log?.updated_columns?.join(", ") : ""}</p>
                                                          </div>
                                                          <div className="activity-log-changes">
                                                          <div key={index}>
                                                                              <pre style={{width:'300px'}}>{JSON.stringify(JSON.parse(log?.old_json||'{}'), null, 2)}</pre>
                                                                              <span className="arrow">→</span>
                                                                              <pre style={{width:'300px'}}>{JSON.stringify(JSON.parse(log?.new_changed_json||'{}'), null, 2)}</pre>
                                                           </div>
                                                          </div>
      
                                                          <p className="activity-log-date">at {moment.utc(log.created_at).format('hh:mm:ss A')}</p>
                                                      </div>
                                                  </div>
                                              </div>
                                          ))}
                                      </div>
                                  ))}
                              </>
                          )}
                          </div>
                                              
                                      
                              </div>
                          
          </>
        )
      }
      
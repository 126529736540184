import React, { useState, Suspense, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import chroma from 'chroma-js';
function DefaultTabButton({ name, color, onClick, isActive, className }) {
    const lightColor = chroma(color).alpha(0.4).css();

    return (
        <button
            style={{
                backgroundColor: isActive ? lightColor : '',
                color: isActive ? chroma(color).darken(1.5).css() : color,
                borderBottom: `1px solid ${color}`,
                // border: `1px solid ${color}`,
                boxShadow: isActive ? `0 2px 8px -3px ${color} ` : '',
            }}
            className={classnames(`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none `,
                className, isActive ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'
            )}
            onClick={onClick}>
            {name}
        </button>
    );
}

DefaultTabButton.propTypes = {
    name: PropTypes.string.isRequired,
    color: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    isActive: PropTypes.bool,
    className: PropTypes.string
};

DefaultTabButton.defaultProps = {
    color: '#333',
    isActive: false,
    className: ''
};

function ButtonTabs({ children,
    color,
    transitionClass,
    className,
    TabButton: CustomTabButton,
    defaultTab,IsDefault,setIsDefault }) {
        const [activeTab, setActiveTab] = useState(
            defaultTab 
            );

            useEffect(()=>{
                setActiveTab(defaultTab)
                
            },[defaultTab])
                console.log('defaultTab',defaultTab,activeTab,IsDefault)

            useEffect(()=>{
                setActiveTab(activeTab)

            },[activeTab,IsDefault])
    const TabButton = CustomTabButton || DefaultTabButton;
        console.log(children);
    return (
        <div className={classnames('tw-flex tw-flex-col')}>
            <div className='tw-py-2 tw-border-b'>

                <div className="-tw-mb-px tw-text-xs md:tw-text-sm md:tw-flex tw-mx-4 tw-gap-2">
                    {children.map(child => ( child &&
                        <TabButton
                            className={classnames(className, transitionClass)}
                            key={child?.props?.value}
                            name={child?.props?.name}
                            color={child?.props?.color}
                            onClick={() => {setActiveTab(child?.props?.value)
                                setIsDefault(false)}}
                            isActive={IsDefault?(defaultTab === child?.props?.value):(activeTab === child?.props?.value)}
                        />

                    ))}
                </div>
            </div>
            <Suspense fallback={<div>Loading...</div>}>
                {children.map(child => {
                    if (child?.props?.value === (IsDefault?defaultTab:activeTab)) {
                        return (
                            <div key={child?.props?.value} className={classnames(transitionClass)}>
                                {child}
                            </div>
                        );
                    }
                    return null;
                })}
            </Suspense>
        </div>
    );
}

ButtonTabs.propTypes = {
    children: PropTypes.node.isRequired,
    color: PropTypes.string,
    transitionClass: PropTypes.string,
    className: PropTypes.string
};

ButtonTabs.defaultProps = {
    color: '#333',
    transitionClass: 'tw-transition tw-duration-500 tw-ease-in-out',
    className: ''
};

export default ButtonTabs;

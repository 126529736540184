import React from 'react'
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from '../../Common/ui/dialog'
import { cn } from "../../lib/utils.js"

export default function IotModel({
    isModalOpen,
    onOpenChange,
    modalTitle,
    children,
    AddEditModelClassName
}) {
    return (
        <Dialog open={isModalOpen} onOpenChange={onOpenChange} className='!tw-gap-0' >
            <DialogTrigger asChild>
                {/* <Button variant="outline">Edit Profile</Button> */}
            </DialogTrigger>
            <DialogContent className={cn("tw-overflow-y-auto  tw-max-h-[90dvh] dvgdfgdfgh tw-min-w-[85%] tw-max-w-[95%] !tw-p-0 ", AddEditModelClassName)}>
                <DialogHeader className={'tw-py-4 md:tw-px-6 tw-px-4 tw-text-start tw-border-b-[1px] tw-border-slate-200'} >
                    <DialogTitle className='tw-leading-6' >{modalTitle || "Add Model Title"}</DialogTitle>
                </DialogHeader>
                <DialogDescription className={'tw-py-4 md:tw-px-6 tw-px-4'} >
                    {children}
                </DialogDescription>
            </DialogContent>
        </Dialog>
    )
}

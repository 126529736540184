import React from 'react'
import { Award } from 'react-feather'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
// import MapPicker from 'react-google-map-picker'
import { useState } from 'react';
import {Modal} from 'antd'
import { useEffect } from 'react';
import { activeandinactive, CheckUseConsumer, getAccount, getGa, getGaById, getProfilNameByAccountId, InsertCircle, Table_Get_All_City_by_State, Table_Get_All_Country, Table_Get_All_State_by_Country, UpdateCircle } from '../../../Common/Utils';
import { toast } from 'react-toastify';
import { BootstrapTooltip } from '../../../Common/common';
import swal from "sweetalert2";
import { param } from 'jquery';
window.Swal = swal;
const DefaultLocation = { lat:null , lng:null };
const DefaultZoom = 10;
export default function AddCircle() {
  const params = useParams();

    const [disable,setDisable]=useState(true)
	const [GaData,setGaData]=useState([])
	const [AccounData,setAccountData]=useState([])
    const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
    const [isModalVisible, setIsModalVisible] = useState(false);
const [AccountInput,serAccountInput]=useState({name:'',account:params?.accountId,ga:params?.gaId,country:'',state:'',city:''})
  const [location, setLocation] = useState(defaultLocation);
  const [zoom, setZoom] = useState(DefaultZoom);
  const [flagBtn, setflegBtn] = useState(false);
  const [circleId,setCircleId]=useState('')
  const locations=useLocation()
  const history = useNavigate();
  const [options,setoptions]=useState([])
  const [stateData,setstateData]=useState([])
  const [Citydata,setCitydata]=useState([])


  const getCountry=async()=>{
     const data=await Table_Get_All_Country()
     if(data.success==true){
        setoptions(data.data.data)
     }else{
        console.log('something want wrong')
     }
  }

  const getState=async(id)=>{
  const data=await Table_Get_All_State_by_Country(id)
  if(data.success==true){
     setstateData(data.data.data)
  }else{
     console.log('something want wrong')
  }
}

const getCity=async(id)=>{
  const data=await Table_Get_All_City_by_State(id)
  if(data.success==true){
     setCitydata(data.data.data)
  }else{
     console.log('something want wrong')
  }
}

useEffect(()=>{getCountry()},[])
  const userInfo=JSON.parse(window.localStorage.getItem("user_info")||{})

  useEffect(() => {
    if (locations?.state) {
       serAccountInput({name:locations?.state?.CATEGORY2_NAME,account:locations?.state?.CATEGORY0,country:locations?.state?.CATEGORY2_COUNTRY ,state:locations?.state?.CATEGORY2_STATE,city:locations?.state?.CATEGORY2_CITY,ga:locations?.state?.CATEGORY1,STATUS:locations?.state?.CATEGORY2_STATUS})
       setLocation({lat:locations?.state?.CATEGORY2_LATE_LONG.split(',')[0], lng:locations?.state?.CATEGORY2_LATE_LONG.split(',')[1]})
       setCircleId(locations?.state?.CATEGORY2_UNIQUE_ID)
       setDisable(false)
       getState(locations?.state?.CATEGORY2_COUNTRY)
       getCity(locations?.state?.CATEGORY2_STATE)
    }
 }, [locations]);
const ongetGaById=async(id)=>{
   const data1=await getGaById(id)
   if(data1.success===true){
       setGaData(data1.data.data)
   }
}
 useEffect(() => {
   if(AccountInput.account!==''){
      ongetGaById(AccountInput.account)
}
 },[AccountInput.account])

  const handleCancel = () => {
    setIsModalVisible(false);
    setLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
};
  function handleChangeLocation (lat, lng){
    setLocation({lat:lat, lng:lng});
  }
  
  function handleChangeZoom (newZoom){
    setZoom(newZoom);
  }

  function handleResetLocation(){
    setDefaultLocation({ ... DefaultLocation});
    setZoom(DefaultZoom);
  }
  const onHandaleSelection=async()=>{
    const data=await getAccount()
    if(data.success===true){
        setAccountData(data.data.data)
    }else{
      console.log('somthing want wrong')
    }
  }

  const [NameObj,setNameObj]=useState({})

  const ongetProfilNameByAccountId=async()=>{
     const data=await getProfilNameByAccountId({ID:params.accountId})
     if(data.success===true){
        setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME||'{}'))
     }else{
        console.log('something want wrong')
     }
  }
  useEffect(() => {
    onHandaleSelection()
    ongetProfilNameByAccountId()
  }, []);

  const onSubmitAccountData=async(e)=>{
    e.preventDefault()
    setflegBtn(true)
 if(AccountInput?.name===''||AccountInput?.account===''||AccountInput?.ga===''){
    toast.error('Please Fill Proper Details')
    setflegBtn(false)
 }else{
    if(circleId===''){
 const data=await InsertCircle({...AccountInput,lat:location?.lat||'',lng:location?.lng||'',add_by:userInfo?.unique_id})
 if(data.success===true){
    toast.success("Circle Add Success")
    history(`/circle/${params?.accountId}/${params?.gaId}`)
    setflegBtn(false)
 
 }else{
    toast.error("Something Wait Wrong")
    setflegBtn(false)
 
 }
    }
    else{
       const data=await UpdateCircle({...AccountInput,lat:location?.lat||'',lng:location?.lng||'',add_by:userInfo?.unique_id},circleId)
 if(data.success===true){
    toast.success("Update Account Success")
    history(`/circle/${params?.accountId}/${params?.gaId}`)
    setflegBtn(false)
 
 }else{
    toast.error("Something Wait Wrong")
    setflegBtn(false)
 
 }
    }
 }
   }

   const oninactive=async()=>{
      const check=await CheckUseConsumer({colName:'cat2_id',value:circleId})
      if(check.success===true&&check.data.data[0].valid>0){
         swal.fire({
            title: `There are ${check.data.data[0].valid} consumers related to this circle. Please Deactive those Consumeres to deactive this circle.`,
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "error",
            showCancelButton: true,
         })
      }else{
         swal.fire({
            title: "Are You Sure InActive circle?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'category2',colName:'CATEGORY2_STATUS',value:'0',matchId:'CATEGORY2_UNIQUE_ID'},circleId)
      if(data.success===true){
         toast.success('Success Inactive circle')
         history(`/circle/${params?.accountId}/${params?.gaId}`)
      }else{
         toast.error('something want wrong')
      }
   }
         })
   }
     }
     const onactive=async()=>{
      swal.fire({
         title: "Are You Sure Active circle?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await activeandinactive({tableName:'category2',colName:'category2_status',value:'1',matchId:'category2_unique_id'},circleId)
      if(data.success===true){
         toast.success('Success Active circle')
         history(`/circle/${params?.accountId}/${params?.gaId}`)
      }else{
         toast.error('something want wrong')
      }
   }
      })
     }
     
  return (
    <>
	<Modal title="Map" visible={isModalVisible} onOk={()=>(setIsModalVisible(false))} onCancel={handleCancel} width={800}>
    {/* <MapPicker defaultLocation={{ lat: parseFloat(location?.lat||'19.506765954448635'), lng: parseFloat(location?.lng||'76.46875000000001')}}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'500px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyAo0xl_P_6Ga-ee6ggeYe1JAE2jq3PxU5s'/> */}
    </Modal>
    <div className="main-content" style={{ minHeight: "562px" }}>
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
                            <Award className='header-logo' style={{ height: "16px" }} /> Profile
							</Link>
						</li>
						<li className="breadcrumb-item">
						<Link to={`/circle/${params?.accountId}/${params?.gaId}`}>
                  {NameObj.CATEGORY2||'CATEGORY2'}
						</Link>
					</li>
						<li className="breadcrumb-item">
							<Link >{circleId!==''?'Update '+(NameObj.CATEGORY2||'CATEGORY2'):'Add '+(NameObj.CATEGORY2||'CATEGORY2')}</Link>
						</li>
					</ol>
				</nav>
        <section class="section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>{circleId!==''?'Update '+(NameObj.CATEGORY2||'CATEGORY2'):'Add '+(NameObj.CATEGORY2||'CATEGORY2')}</h4>
                           <div class="card-header-action"> 
                           {userInfo?.role==='ROLE1650614500708'&&circleId!==''?<div  class="btn btn-info text-white btnedit mr-2"onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:circleId!==''&&locations?.state?.CATEGORY2_edit?.filter((items)=>(items===circleId)).length>0?<div  class="btn btn-info text-white btnedit mr-2"onClick={()=>(setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>:''}
										{userInfo?.role==='ROLE1650614500708'&&circleId!==''&&AccountInput?.STATUS==1?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:circleId!==''&&AccountInput?.STATUS==1&&locations?.state?.CATEGORY2_active.filter((item)=>(item===circleId)).length>0?<div class="btn btn-danger text-white btndeactive defauldbtn mr-2"  onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div>:''}
										{userInfo?.role==='ROLE1650614500708'&&circleId!==''&&AccountInput?.STATUS==0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:circleId!==''&&AccountInput?.STATUS==0&&locations?.state?.CATEGORY2_active.filter((item)=>(item===circleId)).length>0?<div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div>:''} 
                              <Link to={`/circle/${params?.accountId}/${params?.gaId}`} class="btn btn-info text-white">Back</Link>
                           </div>
                        </div>
                        <div class="card-body">
                           <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onSubmitAccountData}>
                              <div class="form-row">
                              <div class="form-group col-md-3">
                                    <label for="inputEmail4">Account<span class="text-danger">*</span></label>
                                    <select class="form-control form-select" name="accountname" id="accountname" value={params?.accountId} disabled={true}>
                                       <option value="">Select Account</option>
                                                                         {AccounData&&AccounData.map((item)=>(locations?.state?.Allpermision?.roles_permission_category0?.CATEGORY0_view?.filter((items) => (items === item.UNIQUE_ID)).length > 0||userInfo?.role==='ROLE1650614500708'?<option value={item?.UNIQUE_ID}>{item?.CATEGORY0}</option>:''))  }
                                                                           </select>
                                 </div>
                                 <div class="form-group col-md-3" id="grpeui">
                                    <label for="inputPassword4">{NameObj?.CATEGORY1||'CATEGORY1'}<span class="text-danger">*</span></label>
                                    <select id="inputState" class="form-control form-select" name="ganame" required=""  value={params?.gaId} disabled={true}>
                                        <option value="">Select GA</option>
                                                                             {GaData&&GaData.map((item)=>(locations?.state?.Allpermision?.roles_permission_category1?.CATEGORY1_view?.filter((items) => (items === item.CATEGORY1_UNIQUE_ID)).length > 0||userInfo?.role==='ROLE1650614500708'?<option value={item.CATEGORY1_UNIQUE_ID}>{item.CATEGORY1_NAME}</option>:''))}
                                                                                <option value="addga" class="bg-secondary">+ Add GA</option>
                                    </select>
                                    <span id="cat1id"></span>
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputPassword4">Name:<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" name="account_name" placeholder="Enter Account Name" required="" onChange={(e)=>(serAccountInput({...AccountInput,name:e.target.value}))} value={AccountInput?.name} disabled={!disable}/>
                                 </div>
                              </div>
                              <div class="row">
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                       <label for="inputPassword4">Country</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => {serAccountInput({ ...AccountInput, country: item.target.value })
                                    getState(item.target.value)}} value={AccountInput?.country} required="" name="meter_type" disabled={!disable}>
                                          <option value="">Select Country</option>
                                          {options && options.map((item) => <option value={item.id}>{item.country}</option>)}
                                       </select>
                                            
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                          <label for="inputPassword4">State</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => {serAccountInput({ ...AccountInput, state: item.target.value })
                                    getCity(item.target.value)}} value={AccountInput?.state} required="" name="meter_type" disabled={!disable} >
                                          <option value="">Select State</option>
                                          {stateData && stateData.map((item) => <option value={item.id}>{item.state}</option>)}
                                       </select>
                                           
                                             </div>
                                          </div>
                                          <div class="col-lg-3">
                                          <div class="form-group">
                                          <label for="inputPassword4">City</label>
                                       <select class="form-control second_meter_type form-select" onChange={(item) => (serAccountInput({ ...AccountInput, city: item.target.value }))} value={AccountInput?.city} required="" name="meter_type" disabled={!disable}>
                                          <option value="">Select City</option>
                                          {Citydata && Citydata.map((item) => <option value={item.id}>{item.city}</option>)}
                                       </select>
                                          </div>
                                          </div>
                                       </div>
                              <div class="form-row">
                                 <div class="form-group col-md-3">
                                    <label for="inputState">Latitude:</label>
                                    <input type="text" class="form-control MapLat" id="loca_lat" name="loca_lat" placeholder="Latitude" value={location?.lat||''} onChange={(e)=>(setLocation({...location,lat:e.target.value.replace(/[^0-9.]/gi, '')}))} disabled={!disable}/>    
                                    <p className='text-danger' 
 style={{ fontSize: "10px" }}>* Allowed Only Number (0-9) And Periods(.) And Format (D.D°)</p>

                                    <input type="hidden" class="form-control MapLat" id="btntypeforsubmit" name="submityper"/>    
                                 </div>
                                 <div class="form-group col-md-3">
                                    <label for="inputState">Longitude:</label>
                                    <input type="text" class="form-control MapLon" id="loca_long" name="loca_long" placeholder="Longitude" value={location?.lng||''} onChange={(e)=>(setLocation({...location,lng:e.target.value.replace(/[^0-9.]/gi, '')}))} disabled={!disable}/>  
                                    <p className='text-danger' 
 style={{ fontSize: "10px" }}>* Allowed Only Number (0-9) And Periods(.) And Format (D.D°)</p>

                                 </div>
                                 <div class="col-lg-1" style={{marginTop:"30px"}}>
                                    <a class="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="" onClick={()=>setIsModalVisible(true)} id="maplink" data-original-title="Map"><i class="fa fa-location-arrow"></i></a>
                                 </div>
                              </div>
                              <p style={{marginLeft:"25px"}} class="text-danger">Note : * Indicates Mandatory Fields</p>
                           <div class="form-group" style={{marginTop:"20px"}}>
                              <div class="text-left">
                              {userInfo?.role==='ROLE1650614500708'&&disable? <input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={circleId===''?'Submit':'Update'} id="submitvn"/>:circleId!==''&&disable? <input type="submit" name="submit" class={`btn btn-danger ${flagBtn?'btn-progress disabled':''} ml-4 mr-3`} value={circleId===''?'Submit':'Update'} id="submitvn"/>:''}
                                    <Link class={`btn btn-light text-dark ${flagBtn?'disabled':''}`} to={`/circle/${params?.accountId}/${params?.gaId}`}>Cancel</Link>  
                              </div>
                           </div>
                           </form></div>
                           
                     </div>
                  </div>
               </div>
            </div>
      </section>
      </div>
    </>
  )
}

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BootstrapTooltip } from "../../Common/common";
import { getRolesById, getNotificationList, getSlaveScriptData, getScriptdecodeId } from "../../Common/Utils";
import chroma from 'chroma-js';
import * as $ from "jquery";
import dt from "datatables.net-dt";
import Pagination from "../../Common/Pagination";
import moment from 'moment'
import exports from '../../assets/img/export.png'
$.fn.DataTable = dt;

export default function SlaveScript() {

	const [SlaveScriptData, setSlaveScriptData] = useState([])
	const [loader, setLoader] = useState(false)
	const [permision, setpermision] = useState({})
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
	const [activeTab, setActiveTab] = useState("Notification");
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [ScriptIdData, setScriptIdData] = useState([])
	const [script_id,setscript_id]=useState('')
	const history = useNavigate()

	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.role);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({ ...obj, ROLES_OTHER_PERMISSIONS: JSON.parse(obj.ROLES_OTHER_PERMISSIONS) });
		} else {
			console.log('Something Went Wrong')
		}
	}

	const getScriptDecodeIdList = async () => {
		const data = await getScriptdecodeId()
		if (data.success === true) {
			setScriptIdData(data.data.data)
		} else {
			console.log('somthing want wrong')
		}
	}

	const ongetSlaveScriptData = async () => {
		setLoader(true);
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		$(".loader").fadeOut("slow");
		const data = await getSlaveScriptData({ start: firstPageIndex, end: PageSize })
		if (data.success === true) {
			setSlaveScriptData(data.data.data)
			setnumberofPage(data.data.total)
			setLoader(false)
		} else {
			console.log('somthing want wrong')
			setLoader(false)
		}
	}

	useEffect(() => {
		getScriptDecodeIdList()
		ongetSlaveScriptData()
	}, []);
	const UpdateRolesUser = (item) => {
		history(`/add-Slave-Script-profile`, { state: { ...item } })
	}
	return (
		<div>
			{loader ? (
				<div className="main-content">
					<div class="loader"></div>
				</div>
			) : (
				""
			)}
			<div class="main-content">

				<div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb p-0 mb-0">
							<li class="breadcrumb-item">
								<a href="/">
									<i class="fa fa-dashboard fa-lg"></i> Home
								</a>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<a href="/" class="open_rightsidebar">
									<i class="fas fa-list"></i> Slave Script Profile{" "}
								</a>
							</li>
						</ol>
					</nav>
				</div>
				<section class="section">

					<div class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4><span className="mr-2">Slave Script Profile List</span><BootstrapTooltip title='Table Name is gsm_slave_index' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<Link to="/add-Slave-Script-profile" className="btn btn-info text-white mr-2">
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
										</div>
									</div>
									<div class="card-body">
										<div className="accordion" id="accordionExample5">
											{ScriptIdData?.map((items)=>(<div className="accordion-item bg-white border border-gray-200">
												<h2 className="accordion-header !tw-p-0 " id="headingOne5">
													<button className="accordion-button tw-relative tw-flex tw-items-center tw-w-full tw-py-4 tw-px-5 tw-text-base tw-text-gray-800 tw-text-left tw-bg-white tw-border-0 tw-rounded-none tw-transition focus:!tw-outline-none focus:tw-shadow-none" type="button" data-toggle="collapse" data-target={`#${items?.NAME}`} aria-expanded="true" aria-controls={items?.NAME} >
													 ({items?.MODEM_NAME+' _ '+items?.METER_NAME}) {items?.NAME}
													</button>
												</h2>
												<div id={items?.NAME} className={`accordion-collapse collapse`} aria-labelledby={items?.NAME}>
													<div className="accordion-body tw-py-4 tw-px-5">
																<div className="table-responsive">
																	<table id="table_id" className="table border table-borderless text-center table-striped font-12">
																		<thead>
																			<tr>
																				<th className="text-center" style={{ maxWidth: "100px" }}>sr_no</th>
																				<th className="text-center" style={{ minWidth: "130px" }}>created_at</th>
																				<th className="text-center" style={{ maxWidth: "100px" }}>created_by</th>
																				<th className="text-center">profile_name</th>
																				<th className="text-center">Current Version</th>
																				<th className="text-center">Letest Version</th>
																				<th className="text-center">script_json</th>
																				<th className="text-center">status</th>
																			</tr>
																		</thead>
																		<tbody>
																			{SlaveScriptData.length>0 &&
																				SlaveScriptData.filter((ssinfo)=>items?.NAME==ssinfo?.scriptdecode_id)?.map((item, id) => (
																					<tr style={{ height: "4rem" }} className="trheigth" key={id} onMouseEnter={() => {
																						var element = document.getElementById(item?.u_sr_no + 'GSM');
																						element.classList.add('within');
																						element.classList.remove('display-icon');
																					}} onMouseLeave={() => {
																						var element = document.getElementById(item?.u_sr_no + 'GSM');
																						element.classList.add('display-icon');
																						element.classList.remove('within');
																					}}>
																						<td className="GSM_Link tw-cursor-pointer">
																							<a className="text-center !tw-text-[#6777ef] " key={id} onClick={() => (UpdateRolesUser(item))}>{item?.u_sr_no}</a>
																							<a id={item?.u_sr_no + 'GSM'} className="text-center" key={id} onClick={() => (UpdateRolesUser(item))}><img src={exports} style={{ height: "10px" }} /></a></td>

																						<td className="text-center">{moment.utc(item.created_at).format('DD-MM-YYYY hh:mm:ss A')}</td>
																						<td className="text-center">{item.created_by}</td>
																						<td className="text-center">{item.profile_name}</td>
																						<td className="text-center">{item.current_version}</td>
																						<td className="text-center">{item.latest_version}</td>
																						<td className="text-center">{item.script_json}</td>
																						{item.status == 1 ? <td className="text-center">
																							<div className="badge badge-success badge-shadow text-white">Active</div>
																						</td> : <td className="text-center">
																							<div className="badge badge-danger badge-shadow text-white">Inactive</div>
																						</td>}
																					</tr>
																				))}
																		</tbody>
																	</table>
																	<>
																		{/* <div className="d-flex justify-content-between">
																			<div>Total Number Of Row {numberofPage}</div>
																			<div class="dataTables_length" id="table_id_length">
																				<label>
																					Show{" "}
																					<select
																						name="table_id_length"
																						aria-controls="table_id"
																						class=""
																						onChange={(e) => setPageSize(e.target.value)}
																						value={PageSize}
																					>
																						<option value={10}>10</option>
																						<option value={25}>25</option>
																						<option value={50}>50</option>
																						<option value={100}>100</option>
																					</select>{" "}
																					entries
																				</label>
																			</div>
																			<Pagination
																				className="pagination-bar"
																				currentPage={currentPage}
																				totalCount={numberofPage || 1}
																				pageSize={PageSize}
																				onPageChange={(page) => setCurrentPage(page)}
																			/>
																		</div> */}
																	</>
																</div>
													</div>
												</div>
											</div>))}


										</div>

									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	);
}

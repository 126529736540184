import React, { useState, useEffect, useRef } from "react";
import { Link, useParams, useHistory, useLocation, useSearchParams } from "react-router-dom";
import $ from "jquery";
import moment from "moment";
import { geModemIdWiseColumnName, GetBuferCountInByStatus, getErrorbinAndDuplicatbinCountList, getEventCountList, getGSMBufferInDeshboardAllCountByStatus, getGSMDataById, getHeatbeatStatusWiseCount, getmeter_update_data, GetModemStatusByTable, GetModemStatusByTableAndDate, GetModemStatusDurationByTable, GetModemStatusDurationByTableAndDATE, getProfilNameByAccountId, getRolesById, getRSSITotalGSM, getScriptCodeIdWiseColumnName, getStartAndEndDateByTable, GetTableNameWiseChartDataByDate, GetTableNameWiseChartDataByStartDateEndDate, GetTableNameWiseChartInRelayonTime, GetTableNameWiseChartInRelayonTimeByDate, GetTableNameWiseChartRSSIDataByStartDateEndDate, getTags, getTagsInGsm, LetestCountAndLastDateByTableName, UpdateTagsInGsm, getHeartbeatStatusByDate } from "../../Common/Utils";

import Chart from "react-apexcharts";
import { DatePicker, Modal, Space } from "antd";
import chroma from 'chroma-js';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import swal from "sweetalert2";
import signal2 from '../../assets/img/secondsignal.png'
import signal3 from '../../assets/img/signal3.png'
import signal4 from '../../assets/img/signal4.png'
import signal5 from '../../assets/img/signal5.png'
import { BootstrapTooltip } from "../../Common/common";
// import {
// 	withScriptjs,
// 	withGoogleMap,
// 	GoogleMap,
// 	Marker,
// 	InfoWindow
// } from "react-google-maps"
import mapicon from '../../assets/img/triangle-green.svg'
import mapRedicon from '../../assets/img/triangle-red.svg'
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Filler,
	Legend,
	Decimation, TimeScale
} from 'chart.js';
import { Bar, Line } from 'react-chartjs-2';
import zoomPlugin from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import heartbeat from '../../assets/img/heartbeat.png'
import hourglass from '../../assets/img/hourglass.png'
import calendar from '../../assets/img/calendar.png'
import timetable from '../../assets/img/timetable.png'
import alert from '../../assets/img/alert.png'
import server from '../../assets/img/server.png'
import { X } from "react-feather";
import ChartLoaders from "../../Common/Loaders/ChartLoader";
import { toast } from "react-toastify";
import Slave from "../Slave/Slave";
import ConsumerInfo from "./GsmViewDeshboard/ConsumerInfo";
import ModemData from "./GsmViewDeshboard/ModemData";
import Buffer from "./GsmViewDeshboard/Buffer";
import ConsumerTask from "./GsmViewDeshboard/ConsumerTask/ConsumerTask";
import Configration from "./GsmViewDeshboard/Configration";


window.Swal = swal;

export default function GSM_deshboard() {
	const colors = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9"]
	const colors15 = ["#67b7dcd9", "#6794dcd9", "#6771dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9", "#dc67ced9", "#6771dcd9", "#6794dcd9", "#67b7dcd9", "#8067dcd9", "#a367dcd9", "#c767dcd9"]
	const buttonArray = ["Dashboard", "Consumer Info", "Configuration", "Modem Data", "Buffer", "Extra", 'Tasks'];
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
	const subbuttonArray = ["Modem", "Slave"];
	const [btnText, setButtontext] = useState("Dashboard");
	const [btnSubText, setbtnSubText] = useState("Modem");
	const [btnMainText, setbtnMainText] = useState("Modem");
	const [GSMData, setGSMData] = useState({});
	const [TimesyncChart, setTimesyncChart] = useState({ total: [], date: [], res_code: '' })
	const [RelayOntimeChart, setRelayOntimeChart] = useState({ load1: [], load2: [], load3: [], date: [] })
	const [RelayOntimeChartBydate, setRelayOntimeChartBydate] = useState({ load1: [], load2: [], load3: [], date: [] })

	const [LoadsurvayChart, setLoadsurvayChart] = useState({ total: [], date: [] })
	const [DailyConsuptionChart, setDailyConsuptionChart] = useState({ total: [], date: [] })
	const [DailyChart, setDailyChart] = useState({ total: [], date: [] })
	const [DailyETChart, setDailyETChart] = useState({ total: [], date: [] })
	const [MonthlyChart, setMonthlyChart] = useState({ total: [], date: [] })
	const [ModemalertChart, setModemalertChart] = useState({ total: [], date: [] })
	const [UnloackReqChart, setUnloackReqChart] = useState({ total: [], date: [] })
	const [MeterAlertChart, setMeterAlertChart] = useState({ total: [], date: [] })
	const [HardbeatChart, setHardbeatChart] = useState({ total: [], date: [], res_code: '' })
	const [RSSIChart, setRSSIChart] = useState({ total: [], date: [] })
	const [Instantaneous15Chart, setInstantaneous15Chart] = useState({ total: [], date: [] })
	const [RSSIChartInDate, setRSSIChartInDate] = useState({ total: [], date: [] })
	const [ModemStatus, setModemStatus] = useState({ online_count: [], offline_count: [], date: [] })
	const [ModemStatusDuration, setModemStatusDuration] = useState({ online_count: [], offline_count: [], date: [] })
	const [ModemStatusChartInDate, setModemStatusChartInDate] = useState({ online_count: [], offline_count: [], date: [] })
	const [isModalVisibleModemStatus, setisModalVisibleModemStatus] = useState(false)
	const [ismodalOpenRelayonTime, setismodalOpenRelayonTime] = useState(false)
	const [BAT_PERChart, setBAT_PERChart] = useState({ total: [], date: [] })

	const [ModemSTChartInDate, setModemSTChartInDate] = useState({ online_count: [], offline_count: [], date: [] })

	const [isModalVisibleModemST, setisModalVisibleModemST] = useState(false)
	const [BufferChart, setBufferChart] = useState([])
	const [loader, setLoader] = useState(false);
	const [TimesyncCount, setTimesyncCount] = useState({})
	const [HardbeatCount, setHardbeatCount] = useState()
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [dates, setDates] = useState({ start: '', end: '' })
	const [ModelHeader, setModelHeader] = useState('')
	const [isModalTags, setisModalTags] = useState(false)
	const [tagsList, settagsList] = useState([])
	const [tagArray, setTagArray] = useState([])
	const [TagBtn, setTagBtn] = useState([])
	const [ChartLoader, setChartLoader] = useState(false)
	const [expireDate, setExpireDate] = useState({})
	const [isModalVisibleRssi, setisModalVisibleRssi] = useState(false)
	const [isModalVisibleMonth, setisModalVisibleMonth] = useState(false)
	const [isHeartStatus, setisHeartStatus] = useState(false)
	const [HeartbeateStatuseDetail, setHeartbeateStatuseDetail] = useState([])
	const [btnProccess, setbtnProccess] = useState(false)
	const [permision, setpermision] = useState({});
	const [ColumnName, setColumnName] = useState({})
	const [rolesGSMConfig, setrolesGSMConfig] = useState('false')
	const [isChartsLoading, setIsChartLoading] = useState({ Heartbeat: false, GSM_RSSI: true, Battery_Percentage: true, Hourly: false, Dailyload: false, Monthbill: false, instantaneous: false, buffer: false })
	const params = useParams();
	const [MeterInfo, setMeterInfo] = useState({})
	const [BufferModal, setBufferModal] = useState(false)//buffer count
	const userInfo = JSON.parse(window.localStorage.getItem('user_info'))
	const [searchParams] = useSearchParams();
	const onHandalebtn = (text) => {
		setButtontext(text);

	};
	const handleCancel = () => {
		setIsModalVisible(false);
		setInstantaneous15Chart({ total: [], date: [] })
		setChartLoader(false)
	};
	const handleCancelModalRSSI = () => {
		setisModalVisibleRssi(false);
		setRSSIChartInDate({ total: [], date: [] })
		setChartLoader(false)

	}
	const handleCancelMonth = () => {
		setisModalVisibleMonth(false);
		setChartLoader(false)
		setInstantaneous15Chart({ total: [], date: [] })
	}

	const onHandaleRoles = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");

		const data = await getRolesById(userInfo?.role);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({ ...obj, roles_other_permissions: JSON.parse(obj.roles_other_permission) });
			const permissionViewCard = JSON.parse(obj?.roles_other_permission)
			onDataInGsm(permissionViewCard, obj)
		} else {
			console.log('something want wrong')
			setLoader(false);
		}
	}

	const ongetScriptCodeIdWiseColumnName = async (SCRIPTDECODE_ID, MODEM_TYPE, METER_TYPE) => {

		const data = await geModemIdWiseColumnName(MODEM_TYPE)
		if (data.success === true) {
			if (data?.data?.data?.length > 0) {
				setColumnName(JSON.parse(data?.data?.data[0]?.COLUMN_NAME || '{}'))
			}
		} else {
			console.log('something want wrong');
		}

	}

	const onConsumerUpdateDate = async () => {
		const data = await getGSMDataById(params.GSMID);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					address: JSON.parse(objGSM?.address || '{}'),
					unique_table_name: JSON.parse(objGSM?.unique_table_name || '{}'),
					heartbeat_last_data: JSON.parse(objGSM?.heartbeat_last_data || '{}'),
					installation_details: JSON.parse(objGSM.installation_details||'{}'),

				});
				onGetMeterProfileData(objGSM?.METER_TYPE)
			} else {

			}
		} else {

		}

	}

	const onDataInGsm = async (permissionViewCard, permitObj) => {
		const data = await getGSMDataById(params.GSMID);
		if (data.success === true) {
			const objGSM = data.data.data[0];
			if (data.data.data.length > 0) {
				setGSMData({
					...objGSM,
					address: JSON.parse(objGSM?.address || '{}'),
					unique_table_name: JSON.parse(objGSM?.unique_table_name || '{}'),
					heartbeat_last_data: JSON.parse(objGSM?.heartbeat_last_data || '{}'),
					installation_details: JSON.parse(objGSM.installation_details||'{}'),

				});
				const groupObj = {
					CATEGORY0: objGSM?.cat0_id,
					CATEGORY1: objGSM?.cat1_id,
					CATEGORY2: objGSM?.cat2_id,
					CATEGORY3: objGSM?.cat3_id,
					CATEGORY4: objGSM?.cat4_id,
					CATEGORY5: objGSM?.cat5_id
				}
				var flagDul = 0
				if (userInfo?.role != 'ROLE1650614500708') {
					Object.entries(groupObj).map(([key, value], id) => {
						if (value === '') {
							if (flagDul == 0) {

								const permit = JSON.parse(permitObj['roles_permission_' + (Object.entries(groupObj)[id - 1][0]?.toLowerCase())] || '{}')
								setrolesGSMConfig(permit[Object.entries(groupObj)[id - 1][0] + '_config']?.filter((item) => (item == Object.entries(groupObj)[id - 1][1])).length > 0 ? 'true' : 'false')

								flagDul = 1
							}
							return true
						}
					})
				}
				onGetMeterProfileData(objGSM?.METER_TYPE)
				ongetScriptCodeIdWiseColumnName(objGSM?.SCRIPTDECODE_ID, objGSM?.MODEM_TYPE, objGSM?.METER_TYPE)
				ongetProfilNameByAccountId(objGSM?.CAT0_ID)
				
					onGetTableNameWiseChartDataByDate(JSON.parse(objGSM?.unique_table_name)?.timesync_alert, objGSM?.SCRIPTDECODE_ID)
				onLetestCountAndLastDateByTableName(JSON.parse(objGSM?.unique_table_name)?.timesync_alert)
				
				onGetTableHardbeatWiseChartDataByDate(JSON.parse(objGSM?.unique_table_name)?.heartbeat, objGSM?.SCRIPTDECODE_ID)
				onHardbeatLetestCountAndLastDateByTableName(JSON.parse(objGSM?.unique_table_name)?.heartbeat)
				onGetModemStatusDurationByTable(JSON.parse(objGSM?.unique_table_name)?.online_offline)
				onGetModemStatusByTable(JSON.parse(objGSM?.unique_table_name)?.online_offline)

				if (objGSM?.TAGS?.length > 0) {
					ongetTagsInGsm(JSON.parse(objGSM?.TAGS))
				}

				setLoader(false);
			} else {
				setLoader(false);

			}
		} else {
			console.log("something want wrong");
			setLoader(false);
		}
	};

	const onRefreshBtn = () => {
		setbtnProccess(true)
		onGetTableNameWiseChartDataByDate(GSMData?.unique_table_name.timesync_alert, GSMData?.SCRIPTDECODE_ID)
		onLetestCountAndLastDateByTableName(GSMData?.unique_table_name?.timesync_alert)
		onGetTableHardbeatWiseChartDataByDate(GSMData?.unique_table_name?.heartbeatdata, GSMData?.SCRIPTDECODE_ID)
		onHardbeatLetestCountAndLastDateByTableName(GSMData?.unique_table_name?.heartbeatdata)

		setbtnProccess(false)

	}

	const onGetMeterProfileData = async (id) => {
		const data = await getmeter_update_data(id)
		if (data.success == true) {
			setMeterInfo(data.data.data)
		} else {
			console.log('something went wrong')
		}
	}

	const secondtohours = (totalSecond) => {
		// 		var duration = moment.duration(totalSecond, 'seconds');
		// var formatted = duration.format("HH.mm");

		return moment.utc(1000 * totalSecond).format('HH.mm')
	}

	const onGetModemStatusByTable = async (tableName) => {
		setModemStatus({online_count:[],offline_count:[],date:[],res_code:''})
		const data = await GetModemStatusByTable({ date: 7, tableName: tableName })
		if (data.success === true) {
			setModemStatus({ online_count: data.data.data.map((item) => (item.online_count)), offline_count: data.data.data.map((item) => (item.offline_count)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
			setModemStatus({online_count:[],offline_count:[],date:[],res_code:data?.err?.response?.status})
		}
	}

	const onGetModemStatusDurationByTable = async (tableName) => {
		setModemStatusDuration({online_count:[],offline_count:[],date:[],res_code:''})
		const data = await GetModemStatusDurationByTable({ date: 7, tableName: tableName })
		if (data.success === true) {
			setModemStatusDuration({ online_count: data.data.data.map((item) => (item.online_total_duretion)), offline_count: data.data.data.map((item) => (item.offline_total_duretion)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
		} else {
			console.log('Something Went Wrong')
			setModemStatusDuration({online_count:[],offline_count:[],date:[],res_code:data?.err?.response?.status})
		}
	}

	const onGetTableNameWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setTimesyncChart({ total: [], date: [], res_code: '' })

		const data = await GetTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'scr_ts_entry', fild: 'count(*)' })
		if (data.success === true) {
			setTimesyncChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))), res_code: '' })
		} else {
			console.log('something want wrong')
			setTimesyncChart({ total: [], date: [], res_code: data?.err?.response?.status })
		}
	}

	const onGetTableHardbeatWiseChartDataByDate = async (tableName, SCRIPTDECODE_ID) => {
		setHardbeatChart({ total: [], date: [], res_code: '' })
		const data = await GetTableNameWiseChartDataByDate({ date: 7, tableName: tableName, scriptid: SCRIPTDECODE_ID, col: 'scr_ts_entry', fild: 'count(*)' })
		if (data.success === true) {
			setHardbeatChart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))), res_code: '' })
		} else {
			console.log('something want wrong')
			setHardbeatChart({ total: [], date: [], res_code: data?.err?.response?.status })
		}
	}

	
	
	useEffect(() => {
		onHandaleRoles()
		const active_tab=searchParams.get('active_tab')
		if(active_tab){
			setbtnSubText("Slave")
		}
	}, []);
	
	const onLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByTableName({ tableName: tableName, col: 'scr_ts_entry' })
		if (data.success === true) {
			setTimesyncCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}

	const onHardbeatLetestCountAndLastDateByTableName = async (tableName) => {
		const data = await LetestCountAndLastDateByTableName({ tableName: tableName, col: 'scr_ts_entry' })
		if (data.success === true) {
			setHardbeatCount(data.data.data)
		} else {
			console.log('something want wrong')
		}
	}



	const Timesyncstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: TimesyncChart.date.map((item) => ([item, `(${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Instantaneous",
				data: TimesyncChart.total
			}
		]
	};
	
	const Hardbeatstate = {
		options: {
			legend: {
				show: false,

			},

			plotOptions: {
				bar: {
					distributed: true,
					borderRadius: 5,
					borderRadiusApplication: 'end',
					dataLabels: {
						position: 'top',
						offsetY: 30,
						fontSize: '10px'
					},

				}
			},
			dataLabels: {
				offsetY: 10
			},
			xaxis: {
				floating: false,
				categories: HardbeatChart.date.map((item) => ([item, `(${moment(item, 'DD-MM-YYYY').format('ddd')})`])),
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				},
				// tickPlacement: 'on'
			},
			fill: {
				type: 'bar',
				gradient: {
					shade: 'dark',
					type: "vertical",
					shadeIntensity: 0.5,
					gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
					inverseColors: true,
					opacityFrom: 1,
					opacityTo: 1,
					stops: [0, 50, 100],
					colorStops: []
				}
			},
			chart: {
				toolbar: {
					show: false
				}
			},
			noData: {
				text: "Loading...",
				align: 'center',
				verticalAlign: 'middle',
				offsetX: 0,
				offsetY: 0,
				style: {
					color: "#000000",
					fontSize: '14px',
					fontFamily: "Helvetica"
				},
			},
			colors: colors
			// colors: [
			//     "#99ccdd", "#e9cbff", "#ffcfcb", "#99ccee", "#99bbee", "#aabbee", "#aaddee", "#a4daef"
			// ]
		},

		series: [
			{
				name: "Heartbeat",
				data: HardbeatChart.total
			}
		]
	};



	


	const expandChartData = {
		labels: Instantaneous15Chart.date,
		datasets: [
			{
				label: ModelHeader,
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: Instantaneous15Chart.total,
			},
		],
	};
	const expandChartoptions = {
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			y: {
				grid: {
					color: 'rgba(128,151,177, 0.3)',
					borderDash: [3, 3],
					drawTicks: false,
					borderColor: '#424b5f',
				},
				ticks: {
					align: "center",
					padding: 10,
				},
			},
			x: {
				grid: {
					color: 'rgba(128,151,177, 0.3)',
					borderDash: [3, 5],
					drawTicks: false,
					borderColor: '#424b5f'
				},
				ticks: {
					align: "center",
					padding: 10,
				}
			}
		},
		plugins: {
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "black",
				offset: 5,
				padding: {
					top: 0,
					bottom: 0
				},


			}
		},
		layout: {
			padding: {
				left: 50,
				right: 50,
				top: 20
			}
		},
	};

	
	const ModemStatusState = {
		series: [{
			name: 'Online',
			data: ModemStatus.online_count
		}, {
			name: 'Offline',
			data: ModemStatus.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					columnWidth: ModemStatus.date.length < 3 ? 10 + (60 / (1 + 30 * Math.exp(-ModemStatus.date.length / 3))) : '60%',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatus?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
				labels: {
					formatter: (value) => {
						return value.toFixed(0)
					},
				}
			}],
			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}

	const minutestohours = (totalMinutes) => {
		var minutes = Math.round(totalMinutes % 60);
		var hours = Math.round((totalMinutes - minutes) / 60);
		return hours + ':' + minutes;
	}

	const ModemStatusDurationState = {
		series: [{
			name: 'Online Duration',
			data: ModemStatusDuration.online_count
		}, {
			name: 'Offline Duration',
			data: ModemStatusDuration.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatusDuration?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			tooltip: {
				shared: true,
				intersect: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					console.log(series, seriesIndex, dataPointIndex, w);
					return `<div style={{padding:'5px'}}>` + w.config.xaxis.categories[dataPointIndex] + `<br/>` + 'online:' + series[seriesIndex][dataPointIndex] + ' ' + 'Minutes' + `(${minutestohours(series[seriesIndex][dataPointIndex])} Hours)` + `<br/>` + 'offline:' + series[seriesIndex + 1][dataPointIndex] + ' ' + 'Minutes' + `(${minutestohours(series[seriesIndex + 1][dataPointIndex])} Hours)` + `</div>`
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
			}],

			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}
	const ModemStatusDurationStateByDate = {
		series: [{
			name: 'Online Duration',
			data: ModemStatusChartInDate.online_count
		}, {
			name: 'Offline Duration',
			data: ModemStatusChartInDate.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					}
				},
			},
			xaxis: {
				categories: ModemStatusChartInDate?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			tooltip: {
				shared: true,
				intersect: false,
				custom: function ({ series, seriesIndex, dataPointIndex, w }) {
					console.log(series, seriesIndex, dataPointIndex, w);
					return `<div style={{padding:'5px'}}>` + w.config.xaxis.categories[dataPointIndex] + `<br/>` + series[seriesIndex][dataPointIndex] + ' ' + 'Minutes' + `<br/>` + 'offline:' + series[seriesIndex + 1][dataPointIndex] + ' ' + 'Minutes' + `</div>`
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
			}],

			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}

	const ModemStatusStatebyDate = {
		series: [{
			name: 'Online',
			data: ModemSTChartInDate.online_count
		}, {
			name: 'Offline',
			data: ModemSTChartInDate.offline_count
		}],
		options: {
			legend: {
				show: false,

			},
			chart: {
				type: 'bar',
				height: 350,
				stacked: true,
				toolbar: {
					show: false
				}
			},
			responsive: [{
				breakpoint: 480,
				options: {
					legend: {
						position: 'bottom',
						offsetX: -10,
						offsetY: 0
					}
				}
			}],
			plotOptions: {
				bar: {
					horizontal: false,
					borderRadius: 10,
					borderRadiusApplication: 'end',
					dataLabels: {
						total: {
							style: {
								fontSize: '13px',
								fontWeight: 900
							}
						}
					},
					columnWidth: '60%',
				},
			},
			xaxis: {
				categories: ModemSTChartInDate?.date,
				labels: {
					show: true,
					rotate: -90,
					rotateAlways: true,
					offsetX: 0,
					offsetY: 3,
				}
			},
			yaxis: [{
				allowDecimals: false,
				floating: false,
				labels: {
					formatter: (value) => {
						return value.toFixed(0)
					},
				}
			}],
			fill: {
				opacity: 1
			},
			colors: ['#00e396', '#feb019']
		},
	}



	const onOpenTagsModal = async () => {
		// const data = await getTags()
		// if (data.success === true) {
		// 	settagsList(data.data.data.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME, color: item?.TAG_COLOR })))
		// 	setisModalTags(true)
		// } else {
		// 	console.log('something want wrong');
		// }
	}
	const customStyles = {
		menu: provided => ({
			...provided, width: "max-content",
			minWidth: "100%", zIndex: 9999
		}),
		option: (styles, { data, isDisabled, isFocused, isSelected }) => {
			const color = chroma(data.color);
			return {
				...styles,
				backgroundColor: isSelected
					? color
					: isFocused
						? color.alpha(0.1).css()
						: undefined,
				color: color,

				':active': {
					...styles[':active'],
					backgroundColor: !isDisabled
						? isSelected
							? color
							: color.alpha(0.3).css()
						: undefined,
				},
			};
		},
		multiValue: (provided, { data }) => {
			console.log("Colorr ====== ", data)
			const color = chroma(data.color);
			return {
				...provided,
				width: 'min-content',
				backgroundColor: color.alpha(0.2).css(),
			};
		},
		multiValueLabel: (styles, { data }) => ({
			...styles,
			color: data.color,
		}),
		multiValueRemove: (styles, { data }) => ({
			...styles,
			color: data.color,
			':hover': {
				backgroundColor: data.color,
				color: 'white',
			},
		}),


	}
	const ongetTagsInGsm = async (item) => {
		// const data = await getTagsInGsm({ tags: item })
		// if (data.success === true) {
		// 	setTagBtn(data.data.data)
		// 	setTagArray(data.data.data.map((items) => (items?.UNIQUE_ID)))
		// } else {
		// 	console.log('something want wrong')
		// }
	}

	const onSubmitTags = async () => {
		// const data = await UpdateTagsInGsm({ tags: tagArray }, params.GSMID)
		// if (data.success === true) {
		// 	ongetTagsInGsm(tagArray)
		// 	swal.fire('Success', 'Update Tag Successfuly', 'success');
		// 	setisModalTags(false)
		// } else {
		// 	console.log('something want wrong')
		// }
	}
	const colorBuffer = ["#191d21", "#54ca68", "#6777ef", "#3abaf4", "#fc544b", "#ffa426", "#e17ed5"]

	const series = [
		{
			name: "Total",
			type: "column",
			data: BufferChart?.map((item) => (item?.total_length))
		}, {
			name: "Success",
			type: "column",
			data: BufferChart?.map((item) => (item?.success))
		}, {
			name: "Waiting for downlink",
			type: "column",
			data: BufferChart?.map((item) => (item?.wait_down))
		}, {
			name: "Cancelled by user",
			type: "column",
			data: BufferChart?.map((item) => (item?.cancel))
		},
		{
			name: "Timeout",
			type: "column",
			data: BufferChart?.map((item) => (item?.timeout))
		}, {
			name: "CRC Error",
			type: "column",
			data: BufferChart?.map((item) => (item?.crcerror))
		}, {
			name: "Waiting for Uplink",
			type: "column",
			data: BufferChart?.map((item) => (item?.wait_up))
		}

	];



	
	const seriesRelayTimeBydate = {
		labels: RelayOntimeChartBydate?.date,
		datasets: [
			{
				label: "Relay 1",
				data: RelayOntimeChartBydate?.load1,
				barPercentage: 1.0,
				backgroundColor: '#fc544b',
				borderRadius: 5,
			},
			{
				label: "Relay 2",
				data: RelayOntimeChartBydate?.load2,
				barPercentage: 1.0,
				backgroundColor: '#ffa426',
				borderRadius: 5,
			},
			{
				label: "Relay 3",
				data: RelayOntimeChartBydate?.load2,
				barPercentage: 1.0,
				backgroundColor: '#0000ff',
				borderRadius: 5,
			}
		],
	};

	const options = {
		chart: {
			id: "line"
		},

		plotOptions: {
			bar: {
				borderRadius: 5,
			}
		},
		dataLabels: {
			enabled: false
		},
		xaxis: {
			categories: BufferChart?.map((item) => ([moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"), moment(item.date, "YYYY-MM-DD").format("ddd")])),
			//will be displayed on the x-asis
		}
		,
		colors: colorBuffer
	};

	




	const sum = (arr) => {
		const result = arr.reduce((total, currentValue) => total = total + currentValue, 0);
		return result
	}

	const onOpenModal = (tableName, col, header) => {
		// onGetTableNameWiseChartDataBy15Date()
		ongetStartAndEndDateByTable(tableName, col)
		setModelHeader(header)
	}
	const onOpenModalInRelayontime = async (tableName, col, header) => {
		ongetStartAndEndDateByTable(tableName, col, true)
		setismodalOpenRelayonTime(true)

	}
	const onLoadDataRelayontime = async () => {

	}
	const ongetStartAndEndDateByTable = async (tableName, col, modal) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			if (!modal) {
				setIsModalVisible(true)
			}
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}

	const ongetInStatusStartAndEndDateByTable = async (tableName, col) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
	const onOpenModalRssi = (tableName, col, header) => {
		// onGetTableNameWiseChartDataBy15Date()
		ongetStartAndEndDateInRssiByTable(tableName, col)
		setModelHeader(header)
	}
	const ongetStartAndEndDateInRssiByTable = async (tableName, col) => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: tableName, col: col })
		if (data.success === true) {
			setExpireDate(data.data)
			setisModalVisibleRssi(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}
	const onChange = (date, dateString) => {
		setDates({ ...dates, start: dateString })
	};
	const onChanges = (date, dateString) => {
		setDates({ ...dates, end: dateString })
	};

	const onOpenHeartbeatstatus = async () => {
		document.body.style.cursor = 'want'
		const data = await getStartAndEndDateByTable({ tableName: GSMData.unique_table_name.heartbeatdata, col: 'ENTRYTS' })
		if (data.success === true) {
			setExpireDate(data.data)
			setisHeartStatus(true)
			document.body.style.cursor = 'default'
		} else {
			console.log("something want wrong")
			document.body.style.cursor = 'default'
		}
	}

	const onGetDateWiseInHeartbeatStatus = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)

		const data = await getHeartbeatStatusByDate({ start: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), end: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: GSMData.unique_table_name.heartbeatdata })
		if (data.success == true) {
			if (data?.data?.data?.length > 0) {
				setHeartbeateStatuseDetail(data.data.data)
			} else {
				toast.warn('Data Not Found')
			}
			setChartLoader(false)

		} else {
			setChartLoader(false)

		}
	}

	const onGetDateWiseChart = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		var tableName = ''
		var col = ''
		if (ModelHeader == 'Instantaneous') {
			tableName = GSMData.unique_table_name.instantaneous
			col = 'ENTRYTS'
		} else if (ModelHeader == 'Heartbeat') {
			tableName = GSMData.unique_table_name.heartbeatdata
			col = 'ENTRYTS'
		} else if (ModelHeader == 'Monthbill') {
			tableName = GSMData.unique_table_name.monthlydata
			col = 'METER_ENDTS'
		} else if (ModelHeader == 'Daily Data') {
			tableName = GSMData.unique_table_name.dailydata
			col = 'METER_ENDTS'
		} else if (ModelHeader == 'Hourly') {
			tableName = GSMData.unique_table_name.hourlydata
			col = 'METER_ENDTS'
		} else if (ModelHeader == 'Daily Data Backfill') {
			tableName = GSMData.unique_table_name.dailydata
			col = 'ENTRYTS'
		} else if (ModelHeader == 'Modem Alerts') {
			tableName = GSMData.unique_table_name.modemalert
			col = 'ENTRYTS'
		} else if (ModelHeader == 'Unlock Request') {
			tableName = GSMData.unique_table_name.unlockreq
			col = 'ENTRYTS'
		} else if (ModelHeader == 'Meter Alerts') {
			tableName = GSMData.unique_table_name.meteralert
			col = 'ENTRYTS'
		}
		else if (ModelHeader == 'Daily Consumtion History') {
			tableName = GSMData.unique_table_name.DAILY_LOAD
			col = 'EMT_TS_DL'
		}
		const data = await GetTableNameWiseChartDataByStartDateEndDate({ startDate: ModelHeader == 'Monthbill' ? dates.start : moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: ModelHeader == 'Monthbill' ? dates.end : moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: ModelHeader == 'Daily Consumtion History' ? 'EMT_PENRG_CUM_IMP_WH/' + (ColumnName?.DAILY_LOAD?.filter((item) => (item?.Column == 'EMT_PENRG_CUM_IMP_WH'))[0]?.DivisionNumber || 1000) : 'count(*)', col: col, scriptid: GSMData?.SCRIPTDECODE_ID, tableName: tableName, TYPE: ModelHeader == 'Monthbill' ? 'Monthly' : '' })
		if (data.success === true) {
			if (data.data.data.length == 0) {
				toast.error('Data Not Found')
			}
			setInstantaneous15Chart({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => (ModelHeader == 'Monthbill' ? moment(item.date, "YYYY-MM").format("MMMM-YYYY") : ([moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"), `(${moment(item.date, "YYYY-MM-DD").format("ddd")})`]))) })

			setChartLoader(false)

		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	const onGetDateWiseInRSSIChart = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		var tableName = GSMData.unique_table_name.heartbeatdata
		var col = 'ENTRYTS'
		var fild = ''
		if (ModelHeader == 'GSM RSSI') {
			fild = 'ENTRYTS as ENTRYTS,GSM_RSSI as lengths'
		} else if (ModelHeader == 'SNR') {
			fild = 'ENTRYTS as ENTRYTS,SNR as lengths'
		} else if (ModelHeader == 'SF') {
			fild = 'ENTRYTS as ENTRYTS,SF as lengths'
		} else if (ModelHeader == 'Battery Percentage') {
			fild = 'ENTRYTS as ENTRYTS,BAT_PER as lengths'
		}
		const data = await GetTableNameWiseChartRSSIDataByStartDateEndDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), fild: fild, col: col, scriptid: GSMData?.SCRIPTDECODE_ID, tableName: tableName })
		if (data.success === true) {
			if (data.data.data.length == 0) {
				toast.error('Data Not Found')
			}
			setRSSIChartInDate({ total: data.data.data.map((item) => (item.lengths)), date: data.data.data.map((item) => ([moment.utc(item.ENTRYTS).format("DD-MM-YYYY hh:mm:ss A"), `(${moment.utc(item.ENTRYTS).format("ddd")})`])) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	const onGetDateWiseInModemStatusChart = async () => {
		const x = new Date(moment(dates.start, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(dates.end, 'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x > y, x, y);
		if (x > y) {
			return toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
		}
		setChartLoader(true)
		var tableName = GSMData.unique_table_name.ONLINE_OFFLINE
		var col = 'ENTRYTS'
		const data = await GetModemStatusDurationByTableAndDATE({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: tableName })
		if (data.success === true) {
			if (data.data.data.length == 0) {
				toast.error('Data Not Found')
			}
			setModemStatusChartInDate({ online_count: data.data.data.map((item) => (item.online_total_duretion)), offline_count: data.data.data.map((item) => (item.offline_total_duretion)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}

	const onGetModemStatusByTableAndDate = async () => {
		setChartLoader(true)
		var tableName = GSMData.unique_table_name.ONLINE_OFFLINE
		var col = 'ENTRYTS'
		const data = await GetModemStatusByTableAndDate({ startDate: moment(dates.start, "DD-MM-YYYY").format("YYYY-MM-DD"), endDate: moment(dates.end, "DD-MM-YYYY").format("YYYY-MM-DD"), tableName: tableName })
		if (data.success === true) {
			if (data.data.data.length == 0) {
				toast.error('Data Not Found')
			}
			setModemSTChartInDate({ online_count: data.data.data.map((item) => (item.online_count)), offline_count: data.data.data.map((item) => (item.offline_count)), date: data.data.data.map((item) => (moment(item.date, "YYYY-MM-DD").format("DD-MM-YYYY"))) })
			setChartLoader(false)
		} else {
			console.log('something want wrong')
			setChartLoader(false)
		}
	}


	ChartJS.register(
		CategoryScale,
		LinearScale,
		PointElement,
		LineElement,
		Title,
		Tooltip,
		Filler,
		Legend,
		zoomPlugin, ChartDataLabels,
		Decimation, TimeScale
	);

	

	const optionsRelayontime = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const optionsRSSI = {
		// parsing: false,
		responsive: true,
		maintainAspectRatio: false,
		scales: {
			x: {
				// type: 'time',
				ticks: {

					maxRotation: 90,
					minRotation: 90,
					font: {
						size: 10
					},

				}
			},

			y: {
				beginAtZero: true
			},
		},

		// parsing: false,
		plugins: {
			decimation: {
				enabled: true,
				algorithm: 'lttb',
				samples: 800,
				threshold: 800
			},
			legend: {
				position: 'bottom',
				labels: {
					pointStyleWidth: 10,
					usePointStyle: true,
					pointStyle: 'rectRounded'
				}
			},
			zoom: {
				zoom: {
					wheel: {
						enabled: true,
						modifierKey: 'ctrl' // SET SCROOL ZOOM TO TRUE
					},
					mode: "x",
				},
				pan: {
					enabled: true,
					mode: "x",
				}
			},
			datalabels: {
				backgroundColor: function (context) {
					return context.dataset.borderColor;
				},
				borderRadius: 4,
				align: 'end',
				anchor: 'end',
				clamp: true,
				color: "white",
				padding: {
					top: 0,
					bottom: 0
				},
				font: function (context) {
					var w = context.chart.width;
					return {
						size: w < 512 ? 10 : 12,

					};
				},

			}
		},
		elements: {
			line: {
				fill: true,
				tension: 0.3
			}
		},
		layout: {
			padding: {
				top: 20
			}
		},
	};

	const dataRSSI = {
		labels: RSSIChartInDate.date,
		datasets: [
			{
				label: ModelHeader,
				data: RSSIChartInDate.total,
				fill: true,
				borderColor: 'rgb(53, 162, 235)',
				backgroundColor: (context) => {
					const ctx = context.chart.ctx;
					const gradient = ctx.createLinearGradient(0, 0, 0, 400);
					gradient.addColorStop(0, "rgba(53, 162, 235, 0.8)");
					gradient.addColorStop(1, "rgba(53, 162, 235, 0)");
					return gradient;
					// return pattern.draw('dot', gradient)
				},
				// backgroundColor: 'rgba(53, 162, 235, 0.5)',
			}
		]
	};
	const expandBat_per = {
		labels: RSSIChartInDate.date,
		datasets: [
			{
				label: ModelHeader,
				backgroundColor: ["rgba(103, 183, 220, 0.85)", "rgba(103, 148, 220, 0.85)", "rgba(103, 113, 220, 0.85)", "rgba(128, 103, 220, 0.85)", "rgba(163, 103, 220, 0.85)", "rgba(199, 103, 220, 0.85)", "rgba(220, 103, 206, 0.85)", "rgba(103, 113, 220, 0.85)"],
				data: RSSIChartInDate.total,
			},
		],
	};
	const chartRef = useRef()
	const myChart = chartRef.current;
	const zoomIn = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(1.2);
		}
	}
	const zoomOut = () => {
		if (myChart != undefined || myChart != null) {
			myChart.zoom(0.8);
		}
	}

	const panLeft = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: 400 }, undefined, 'default');
		}
	}
	const panRight = () => {
		if (myChart != undefined || myChart != null) {
			myChart.pan({ x: -400 }, undefined, 'default');
		}
	}
	setTimeout(() => {
		if (myChart != undefined) {
			myChart.zoom(1.49);
			myChart.pan({
				x: 500
			}, undefined, 'default');

			// myChart.pan({
			// 	x: - (Number.MAX_SAFE_INTEGER)
			// }, undefined, 'default');

		}

	}, 10)
	const [NameObj, setNameObj] = useState({})
	const ongetProfilNameByAccountId = async (id) => {
		const data = await getProfilNameByAccountId({ ID: id })
		if (data.success === true) {
			setNameObj(JSON.parse(data?.data?.data[0]?.PROFILE_NAME || '{}'))
		} else {
			console.log('something want wrong')
		}
	}
	return (
		<>

			<Modal title={'Relay On Time'} visible={ismodalOpenRelayonTime} maskClosable={false} footer='' onCancel={() => setismodalOpenRelayonTime(false)} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(0, 'days') >= current) || (moment(expireDate.end_date).add(1, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(0, 'days') >= current) || (moment(expireDate.end_date).add(1, 'days') <= current);
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onLoadDataRelayontime())}>Submit</a>
						</div>

					</div>

					<div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>

				</div>

				{RelayOntimeChart?.load1.length > 0 || RelayOntimeChart?.load2.length > 0 || RelayOntimeChart?.load3.length > 0 ? <div className=" tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={optionsRelayontime} data={seriesRelayTimeBydate} height={"500"} /> </div> : ''}

			</Modal>
			<Modal title="Tags" visible={isModalTags} footer='' maskClosable={false} onCancel={() => (setisModalTags(false))} width={500}>
				<div class="form-row">
					<div class="form-group col-md-12">
						<div>
							<label for="accountuser">Tags</label>
						</div>
						<Select placeholder='Select Tags...' defaultValue={TagBtn.map((item) => ({ value: item?.UNIQUE_ID, label: item.TAG_NAME, color: item?.TAG_COLOR }))} styles={customStyles} name='tags' isMulti options={tagsList} onChange={(e) => { setTagArray(e.map((item) => (item.value))) }} />
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Submit" name="filter_customer_form" onClick={onSubmitTags} />
					<Link class="btn btn-light text-dark ml-2" onClick={() => (setisModalTags(false))}>
						Close
					</Link>
				</div>
			</Modal>

			<Modal title="Buffer Data" visible={BufferModal} footer='' maskClosable={false} onCancel={() => (setBufferModal(false))} width={800}>
				{BufferChart.length > 0 && BufferChart.length > 0 ? <Chart options={options} type="bar" series={series} height={"300"} /> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
			</Modal>

			<Modal title={ModelHeader} visible={isModalVisible} footer='' maskClosable={false} onCancel={handleCancel} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
								}} /></div>
						</Space>
						<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseChart())}>Submit</a>
					</div>
					{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>}
				</div>
				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={expandChartoptions} data={expandChartData} /> </div> : ''}
			</Modal>


			<Modal title={ModelHeader} visible={isModalVisibleRssi} maskClosable={false} footer='' onCancel={handleCancelModalRSSI} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date) >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current)
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date) >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current)
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInRSSIChart())}>Submit</a>
						</div>

					</div>

					{RSSIChartInDate.total.length > 0 && RSSIChartInDate.date.length > 0 ? <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div> : ''}

				</div>
				{RSSIChartInDate.total.length > 0 && RSSIChartInDate.date.length > 0 && <>
					<div className=" tw-h-[560px] tw-max-h-screen tw-px-5">
						{ModelHeader == "Battery Percentage" ? <Bar ref={chartRef} options={expandChartoptions} data={expandBat_per} /> : <Line ref={chartRef} options={optionsRSSI} data={dataRSSI} />} </div>
				</>
				}
			</Modal>

			<Modal title={ModelHeader} visible={isModalVisibleMonth} maskClosable={false} footer='' onCancel={handleCancelMonth} width={1000}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='MM-YYYY' name='start' picker="month" disabledDate={(current) => current.isAfter(moment())} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='MM-YYYY' picker="month" disabledDate={(current) => current.isAfter(moment())} /></div>
						</Space>
						<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={onGetDateWiseChart}>Submit</a>
					</div>
					{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && sum(Instantaneous15Chart.total) > 0 && <div>
						<div>
							<div className="tw-mb-1">
								<small className='tw-text-red-300'><sup className="tw-text-sm !tw-top-0">*</sup> User can also do Zoom and Scroll using their mouse</small>
							</div>
							<div className="tw-flex tw-justify-end tw-items-center">
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomIn}><BootstrapTooltip title="ZoomIn">
									<div className="tw-flex tw-items-center fa-lg">
										<i class="fas fa-search-plus" aria-hidden="true"></i>
									</div>
								</BootstrapTooltip>
								</button>
								<button className={`tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-p-1`} onClick={zoomOut}>
									<BootstrapTooltip title='ZoomOut'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-search-minus" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>

								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panLeft}>
									<BootstrapTooltip title='Left scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-left" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
								<button className="tw-rounded-lg tw-bg-indigo-500 tw-border-transparent text-white tw-mx-1 tw-py-1 tw-px-2" onClick={panRight}>
									<BootstrapTooltip title='Right scroll'>
										<div className="tw-flex tw-items-center fa-lg">
											<i class="fas fa-caret-right" aria-hidden="true"></i>
										</div>
									</BootstrapTooltip>
								</button>
							</div>

						</div>

					</div>}
				</div>

				{Instantaneous15Chart.total.length > 0 && Instantaneous15Chart.date.length > 0 && sum(Instantaneous15Chart.total) > 0 ? <div className="tw-mt-2 tw-h-[560px] tw-max-h-screen tw-px-5"><Bar ref={chartRef} options={expandChartoptions} data={expandChartData} /> </div> : ''}
			</Modal>

			<Modal title={ModelHeader} visible={isModalVisibleModemStatus} footer='' maskClosable={false} onCancel={() => setisModalVisibleModemStatus(false)} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start'
								disabledDate={(current) => current.isAfter(moment())} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => current.isAfter(moment())} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInModemStatusChart())}>Submit</a>
				</div>
				{ModemStatusChartInDate.online_count.length > 0 && ModemStatusChartInDate.offline_count.length > 0 && ModemStatusChartInDate.date.length > 0 ? <Chart options={ModemStatusDurationStateByDate.options} type="bar" series={ModemStatusDurationStateByDate.series}
					height={"500"} /> : ''}
			</Modal>
			<Modal title={ModelHeader} visible={isModalVisibleModemST} footer='' maskClosable={false} onCancel={() => setisModalVisibleModemST(false)} width={1000}>
				<div className="d-flex">
					<Space direction="horizontal">
						<div className="d-flex flex-column">Start In
							<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start'
								disabledDate={(current) => {
									return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
								}} /></div>
						<div className="d-flex flex-column" >End In
							<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
								return (moment(expireDate.start_date).add(-1, 'days') >= current) || (moment(expireDate.end_date).add(0, 'days') <= current);
							}} /></div>
					</Space>
					<a className={`btn btn-danger text-white ml-3 mt-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetModemStatusByTableAndDate())}>Submit</a>

				</div>

				{ModemSTChartInDate.online_count.length > 0 && ModemSTChartInDate.offline_count.length > 0 && ModemSTChartInDate.date.length > 0 ? <Chart options={ModemStatusStatebyDate.options} type="bar" series={ModemStatusStatebyDate.series}
					height={"500"} /> : ''}
			</Modal>

			<Modal title={'Heartbeat Status'} visible={isHeartStatus} maskClosable={false} footer='' onCancel={() => setisHeartStatus(false)} width={1500}>
				<div className="d-flex tw-justify-between tw-items-center">
					<div className="d-flex tw-justify-center tw-items-end">
						<Space direction="horizontal">
							<div className="d-flex flex-column">Start In
								<DatePicker onChange={onChange} format='DD-MM-YYYY' name='start' disabledDate={(current) => {
									return (moment(expireDate.start_date) >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current)
								}} /></div>
							<div className="d-flex flex-column" >End In
								<DatePicker onChange={onChanges} name='end' format='DD-MM-YYYY' disabledDate={(current) => {
									return (moment(expireDate.start_date) >= current) || (moment(expireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current)
								}} /></div>
						</Space>
						<div>
							<a className={`btn btn-danger text-white ml-3 ${ChartLoader ? 'btn-progress disabled' : ''}`} onClick={() => (onGetDateWiseInHeartbeatStatus())}>Submit</a>
						</div>

					</div>
				</div>

				{HeartbeateStatuseDetail.length > 0 && <div className='mt-4' style={{ height: '90vh', overflow: 'scroll' }}>
					<div className="modal-table">
						<table className="table border table-borderless text-center" id="table-1">
							<thead style={{ position: 'sticky', top: '0', background: '#fff' }}>
								<tr>
									<th className="text-center">Date Time</th>
									<th>I2CSTATUS</th>
									<th>INT_DOOR_STATUS</th>
									<th>EXT_DOOR_STATUS</th>
									<th>RTC_DRIFT</th>
									<th>WAKE_UP_REASON</th>
								</tr>
							</thead>
							<tbody id="tbody">
								<>
									{HeartbeateStatuseDetail.length > 0 ?
										HeartbeateStatuseDetail.map((data, id) => (
											<tr style={{ height: "4rem", cursor: 'pointer' }} className="trheigth even:tw-bg-gray-100 hover:tw-bg-[#D8D8D8]" key={id}>
												<td className="text-center">{moment.utc(data?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A')}</td>
												<td><BootstrapTooltip title={data?.I2CSTATUS}><div><span className={`badge ${data?.I2CSTATUS != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{data?.I2CSTATUS != undefined ? data?.I2CSTATUS == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip></td>
												<td><BootstrapTooltip title={data?.INT_DOOR_STATUS}><div><span className={`badge ${data?.INT_DOOR_STATUS != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{data?.INT_DOOR_STATUS != undefined ? data?.INT_DOOR_STATUS == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip></td>
												<td><BootstrapTooltip title={data?.EXT_DOOR_STATUS}><div><span className={`badge ${data?.EXT_DOOR_STATUS != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{data?.EXT_DOOR_STATUS != undefined ? data?.EXT_DOOR_STATUS == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip></td>
												<td><BootstrapTooltip title={data?.RTC_DRIFT}><div><span className={`badge ${data?.RTC_DRIFT != 0 ? 'badge-danger' : 'badge-success'} badge-pill`}>{data?.RTC_DRIFT != undefined ? data?.RTC_DRIFT == 0 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip></td>
												<td><BootstrapTooltip title={data?.WAKE_UP_REASON}><div><span className={`badge ${data?.WAKE_UP_REASON != 4 ? 'badge-danger' : 'badge-success'} badge-pill`}>{data?.WAKE_UP_REASON != undefined ? data?.WAKE_UP_REASON == 4 ? <><i className='fas fa-check font-10'></i></> : (<i className="fas fa-times" />) : ''}</span></div></BootstrapTooltip></td>
											</tr>
										)) : <tr></tr>}
								</>
							</tbody>
						</table>

					</div>
				</div>}
			</Modal>
			<div className="main-content">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				<div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb p-0 mb-0">
							<li class="breadcrumb-item">
								<a href="all-dashboard" class="open_rightsidebar">
									<i class="fas fa-tachometer-alt"></i> Home
								</a>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<i class="fas fa-user-shield"></i> Consumer
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<Link to="/All-GSM" class="open_rightsidebar">
									<i class="fas fa-list"></i> GSM{" "}
								</Link>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								View GSM Consumer{" "}
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								{params.GSMID}
							</li>
						</ol>
					</nav>
				</div>
				<div class="row">
					<div class="col-xl-3 col-lg-5 col-md-5 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Consumer Info</h4>
								<div class="card-header-action">
									{GSMData?.latitude && GSMData?.longitude ? <a href={GSMData?.latitude && GSMData?.longitude ? `https://map.google.com/?q=${GSMData?.latitude},${GSMData?.longitude}` : '#'} target={'_blank'} class={`btn btn-success btn-sm text-white font-5 mr-2 ${GSMData?.latitude && GSMData?.longitude ? '' : ''}`}>
										<BootstrapTooltip title='Directions'>
											<a >
												<i class="fas fa-directions"></i>
											</a></BootstrapTooltip></a> : <BootstrapTooltip title='lat-long is not available'>
										<span className='mr-2'><a class={`btn btn-success btn-sm text-white font-5  ${GSMData?.latitude && GSMData?.longitude ? '' : 'tw-opacity-30'}`}>
											<a >
												<i class="fas fa-directions"></i>
											</a></a></span></BootstrapTooltip>}
									{GSMData?.latitude && GSMData?.longitude ? <a href={GSMData?.latitude && GSMData?.longitude ? `/gsm-map-view?${GSMData?.unique_id}` : '#'} target={'_blank'}
										class={`btn btn-danger btn-sm text-white font-5 ${GSMData?.latitude && GSMData?.longitude ? '' : ''}`}
									><BootstrapTooltip title='Location'>
											<a>
												<i class="fas fa-map-marker-alt"></i>
											</a>
										</BootstrapTooltip></a> : <BootstrapTooltip title='lat-long is not available'><span><a class={`btn btn-danger btn-sm text-white font-5 ${GSMData?.latitude && GSMData?.longitude ? '' : 'tw-opacity-30'}`}
										>
											<a
											>
												<i class="fas fa-map-marker-alt"></i>
											</a>
										</a></span></BootstrapTooltip>}
								</div>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '130px' }}>
								<div class="row font-10">
									<div class="col-lg-12">
										<b>Consumer Name : </b>
										{GSMData?.company_name}
										<br />
										{/* <b>Business Partner Number : </b>
										{GSMData?.BUSINESS_PARTNER_NUMBER}
										<br />
										<b>Installation Number : </b>
										{GSMData?.INSTALLATION_NUMBER}
										<br />
										<b>Customer ID : </b>
										{GSMData?.CUSTOMER_ID}
										<br /> */}
										<b>Consumer Unique ID : </b>
										{GSMData?.unique_id}
										<br />
										<b>Consumer Status : </b>
										{GSMData?.modem_status == 1 ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-green mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="currentColor"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Active
												</span>
												<br />
											</>
										) : GSMData?.modem_status == -1 ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-yellow mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="currentColor"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Pending Configration
												</span>
												<br />
											</>
										) : GSMData?.modem_status == 0 ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-red mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="currentColor"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													InActive
												</span>
												<br />
											</>
										) : GSMData?.modem_status == 2 ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-red mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="#3abaf4"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Under Maintenance
												</span>
												<br />
											</>
										) : (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-red mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill=""
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Stand By
												</span>
												<br />
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-3 col-lg-4 col-md-4 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Group Info</h4>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '133px' }}>
								<div class="row font-10">
									<div class="col-lg-12">
										<b>Account : </b>
										{GSMData?.ACCOUNT_NAME || "-"}
										<br />
										<b>{NameObj?.CATEGORY1 || "CATEGORY1"} : </b>
										{GSMData?.GA_NAME || "-"}
										<br />
										<b>{NameObj?.CATEGORY2 || "CATEGORY2"} : </b> {GSMData?.CIRCLE_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY3 || "CATEGORY3"} : </b> {GSMData?.DIVISION_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY4 || "CATEGORY4"} : </b> {GSMData?.SUB_DIVISION_NAME || "-"} <br />
										<b>{NameObj?.CATEGORY5 || "CATEGORY5"} : </b> {GSMData?.AREA_NAME || "-"}
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-xl-2 col-lg-4 col-md-4 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Modem Info</h4>

							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '133px' }}>
								<div class="row font-10">
									<div class="">
										<b>Modem Serial No : </b>
										{GSMData?.modem_serial_no}
										<br />
										<b>Panel Serial No : </b>
										{GSMData?.panel_serial_no}
										<br />
										<b>IMEI : </b>
										{GSMData?.imei}
										<br />

										<b>RSSI : </b>
										<div className="d-inline-block text-right ">
											{GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi >= 2 && GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi <= 9 ? (
												<>
													<img src={signal2} style={{ height: '15px', width: "24px", border: '0', boxShadow: 'none', borderRadius: 'none', marginTop: '-6px' }} />
													<span className="text-center ml-1">{GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi} </span>
												</>
											) : GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi >= 10 && GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi <= 14 ? (
												<>
													<img src={signal3} style={{ height: '15px', width: "24px", border: '0', borderRadius: '0', boxShadow: 'none', marginTop: '-6px' }} />
													<span className="text-center ml-1">{GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi} </span>
												</>
											) : GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi >= 15 && GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi <= 19 ? (
												<>
													<img src={signal4} style={{ height: '15px', width: "24px", border: '0', borderRadius: '0', boxShadow: 'none', marginTop: '-6px' }} />
													<span className="text-center ml-1">{GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi} </span>
												</>
											) : GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi >= 20 && GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi <= 31 ? (
												<>
													<img src={signal5} style={{ height: '15px', width: "24px", border: '0', borderRadius: '0', boxShadow: 'none', marginTop: '-6px' }} />
													<span className="text-center ml-1">{GSMData?.heartbeat_last_data?.mmd_info_gsm_rssi} </span>
												</>
											) : (
												"-"
											)}
										</div>
										<br />
										<b>Heartbeat Last Seen : </b>{GSMData?.heartbeat_ts ? moment.utc(GSMData.heartbeat_ts).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : ''}{" "}<br />
										<b>Device Status : </b>{GSMData?.mmd_status_online_offline === 'online' ? (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-green mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="currentColor"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Online
												</span>
												<br />
											</>
										) : (
											<>
												<span>
													<svg
														class="svg-inline--fa fa-circle col-red mr-1"
														aria-hidden="true"
														focusable="false"
														data-prefix="fas"
														data-icon="circle"
														role="img"
														xmlns="http://www.w3.org/2000/svg"
														viewBox="0 0 512 512"
														data-fa-i2svg=""
													>
														<path
															fill="currentColor"
															d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256z"
														></path>
													</svg>
													Offline
												</span>
												<br />
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12">
						<div class="card">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4>Tags</h4>
								<div class="card-header-action">
									<BootstrapTooltip title='Tags'>
										<div>
											<a
												onClick={onOpenTagsModal}
												class="btn btn-primary text-white"
												data-toggle="tooltip"
												data-placement="Top"
												title=""
												data-original-title="Tags"
											>
												<i class="fa fa-tag"></i>
											</a>
										</div>
									</BootstrapTooltip>
								</div>
							</div>
							<div class="card-body" style={{ paddingTop: "0px", paddingBottom: "10px", paddingLeft: "18px", height: '130px' }}>
								<div class="row" style={{ marginTop: "10px" }}>
									<div class="col-lg-12">
										{TagBtn.length > 0 && TagBtn?.map((item) => (
											<div class={`btn btn-icon icon-left btn-sm  ml-1`} style={{ backgroundColor: item?.TAG_COLOR, color: "white" }}><i class="fa fa-tag fa-lg" style={{ marginRight: '4px' }}></i>{item.TAG_NAME}</div>
										))}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12">
						<div className="card pb-2">
							<div className="col-12" style={{ marginTop: "15px" }}>
								<div className="buttons">
									{subbuttonArray.map((item, id) => (
										<button
											style={{
												backgroundColor: subbuttonArray.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
												color: subbuttonArray.findIndex((data) => data === btnSubText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
												borderBottom: `1px solid ${btnColor[id]}`,
												boxShadow: subbuttonArray.findIndex((data) => data === btnSubText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
											}}
											className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${subbuttonArray.findIndex((data) => data === btnSubText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

											onClick={() => setbtnSubText(item)}>
											{item}
										</button>
									))}

								</div>
							</div>
						</div>
					</div>
				</div>

				{btnSubText === 'Modem' && <div className="row">
					<div className="col-12">
						<div className="card pb-2">
							<div className="col-12" style={{ marginTop: "15px" }}>
								<div className="buttons">
									{buttonArray.map((item, id) => (
										item == 'Configuration' ? rolesGSMConfig === 'true' || userInfo?.role == 'ROLE1650614500708' ? <button
											style={{
												backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
												color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
												borderBottom: `1px solid ${btnColor[id]}`,
												boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
											}}
											className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}
											onClick={() => onHandalebtn(item)}>
											{item}
										</button> : '' : <button
											style={{
												backgroundColor: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).alpha(0.4).css() : '',
												color: buttonArray.findIndex((data) => data === btnText) === id ? chroma(btnColor[id]).darken(1.5).css() : btnColor[id],
												borderBottom: `1px solid ${btnColor[id]}`,
												boxShadow: buttonArray.findIndex((data) => data === btnText) === id ? `0 2px 8px -3px ${btnColor[id]}` : '',
											}}
											className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${buttonArray.findIndex((data) => data === btnText) === id ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`}

											onClick={() => onHandalebtn(item)}>
											{item}
										</button>
									))}
									<div class="float-right text-right">
										{btnText === "Dashboard" && <a class={`btn btn-info text-white ${btnProccess && 'btn-progress disabled'} align-left`} onClick={onRefreshBtn}>
											<BootstrapTooltip title="Refresh" placement="top"><i className="fas fa-redo"></i></BootstrapTooltip>
										</a>}
										<Link to="/All-GSM" class="btn btn-info text-white align-left">
											Back
										</Link>
									</div>
								</div>
							</div>
							{btnText === "Configuration" && <Configration GSMdata={GSMData} permision={permision} onConsumerUpdateDate={onConsumerUpdateDate}/>}
							{btnText === "Modem Data" && <ModemData GSMdata={GSMData} onConsumerUpdateDate={onConsumerUpdateDate} />}
							{/* {btnText === "Extra" && <Extra GSMdata={GSMData} />} */}

						</div>
					</div>
				</div>}

				{btnSubText === 'Slave'&&Object.keys(GSMData).length>0 && <Slave GSMdata={GSMData} />}
				{btnSubText === 'Modem' && btnText === "Buffer" && <Buffer GSMdata={GSMData} />}
				{/* {btnText === "Tasks" && <ConsumerTask GSMdata={GSMData} permision={permision} />} */}

				{btnSubText === 'Modem' && btnText === "Consumer Info" && <ConsumerInfo GSMdata={GSMData} permision={permision} onConsumerUpdateDate={onConsumerUpdateDate} />}
				{btnSubText === 'Modem' &&btnText === "Dashboard" && (<>
					<div class="row">
					<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
						<div class="card card-info">
							<div class="card-header" style={{ padding: "10px 15px" }}>
								<h4><span className="mr-2">Heartbeat</span> <BootstrapTooltip title='Find Data In heartbeatdata table scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>

								<div class="card-header-action">
									<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.heartbeatdata, 'scr_ts_entry', 'Heartbeat')}>Expand</a>
								</div>
							</div>
							<div class="card-body" style={{ padding: "0px" }}>
								{HardbeatChart.date.length > 0 && HardbeatChart.total.length > 0 ? <Chart options={Hardbeatstate.options} type="bar" series={Hardbeatstate.series} height={"300"} /> : HardbeatChart.res_code == 503 ? <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]">Table Not Found</div> : <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
							</div>
							<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
						</div>
					</div>
					<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-success">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className="mr-2">Timesync Alert</span><BootstrapTooltip title='Find Data In timesync table scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => onOpenModal(GSMData.UNIQUE_TABLE_NAME.instantaneous, 'ENTRYTS', 'Instantaneous')}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{TimesyncChart.date.length > 0 && TimesyncChart.total.length > 0 ? <Chart options={Timesyncstate.options} type="bar" series={Timesyncstate.series} height={"300"} /> :TimesyncChart?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>

							</div>

							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-info">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className='mr-2'>Online/Offline Status</span><BootstrapTooltip title='Find Data In online_offline table(column mmd_status_online_offline) scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => {
												setisModalVisibleModemST(true)
												setModelHeader('Onlie/Offline Status')
												ongetInStatusStartAndEndDateByTable(GSMData.UNIQUE_TABLE_NAME.ONLINE_OFFLINE, 'SCR_TS_ENTRY')
											}}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{ModemStatus.date.length > 0 && ModemStatus.offline_count.length > 0 && ModemStatus.online_count.length > 0 ? <Chart options={ModemStatusState.options} type="bar" series={ModemStatusState.series} height={"300"} /> :  ModemStatus?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
							<div class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-xs-12">
								<div class="card card-info">
									<div class="card-header" style={{ padding: "10px 15px" }}>
										<h4><span className='mr-2'>Online/Offline Status Duration</span><BootstrapTooltip title='Find Data In online_offline table(column mmd_status_online_offline) scr_ts_entry Wise' placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
										<div class="card-header-action">
											<a class="btn btn-info text-white" data-toggle="tooltip" data-placement="Top" title="Expand" onClick={() => {
												setisModalVisibleModemStatus(true)
												setModelHeader('Onlie/Offline Status Duration')
											}}>Expand</a>
										</div>
									</div>
									<div class="card-body" style={{ padding: "0px" }}>
										{ModemStatusDuration.date.length > 0 && ModemStatusDuration.offline_count.length > 0 && ModemStatusDuration.online_count.length > 0 ? <Chart options={ModemStatusDurationState.options} type="bar" series={ModemStatusDurationState.series} height={"300"} /> :  ModemStatusDuration?.res_code==503?<h4 className="tw-flex tw-justify-center tw-items-center tw-min-h-[300px]">Table Not Found</h4>: <div className="tw-flex tw-justify-center tw-items-center tw-min-h-[315px]"><ChartLoaders /></div>}
									</div>
									<p class="text-right" style={{ marginRight: "20px", lineHeight: "10px" }}>Bar chart</p>
								</div>
							</div>
							</div>

							<div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-3 row-cols-xl-5">
							<div class="col">
								<div class="card card-info">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">Heartbeat</h5>
														<h2 class="font-18 col-blue">{HardbeatCount?.total || 0}</h2>
													</div>
												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={heartbeat}
															alt=""
															style={{ paddingTop: "15px",height:"45px" }}
															
														/>
													</div>
												</div>
											</div>
											<div class="row ">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={HardbeatCount?.ENTRYTS ? moment(HardbeatCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{HardbeatCount?.ENTRYTS ? moment.utc(HardbeatCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<div class="col">
								<div class="card card-success">
									<div class="card-statistic-4">
										<div class="align-items-center justify-content-between">
											<div class="row ">
												<div class="col-lg-8 col-md-8 col-sm-8 col-xs-8 pr-0">
													<div class="card-content">
														<h5 class="font-14">TimeSync Count</h5>
														<h2 class="font-18 col-blue">{TimesyncCount?.total || 0}</h2>
													</div>

												</div>
												<div class="col-lg-4 col-md-4 col-sm-4 col-xs-4 pl-0">
													<div class="banner-img">
														<img
															src={hourglass}
															alt=""
															style={{ paddingTop: "15px",height:"45px" }}
															
														/>
													</div>
												</div>
											</div>
											<div class="row">
												<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-0">
													<p class="mb-0 font-10">
														<BootstrapTooltip placement="top" title={TimesyncCount?.ENTRYTS ? moment.utc(TimesyncCount?.ENTRYTS).format('DD-MM-YYYY hh:mm:ss A') : 0}><span class="col-grey">{TimesyncCount?.ENTRYTS ? moment.utc(TimesyncCount?.ENTRYTS).add(-moment().utcOffset(), "m").fromNow('ss:hh:mm') : 0}</span></BootstrapTooltip>
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
				</>)}
				{btnSubText === 'Modem' &&btnText === "Tasks" && <ConsumerTask GSMdata={GSMData} permision={permision} />}
				

			</div>
		</>
	);
}
// export const ConsumerArticlesMap = withScriptjs(withGoogleMap((props) => {
// 	const [jobDetail, setJobDetail] = useState({ activeMarker: {}, isOpen: false, Info: {} })
// 	const onMarkerClicked = (data, marker) => {
// 		console.log(data, marker);
// 		setJobDetail({
// 			activeMarker: { lat: data?.latLng?.lat(), lng: data?.latLng?.lng() },
// 			isOpen: true,
// 			Info: marker
// 		})
// 	}

// 	const onToggleOpen = () => {
// 		setJobDetail({ ...jobDetail, isOpen: false })
// 	}
// 	function getSecondsTodayBydate(ts) {
// 		var now = moment().add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
// 		var then = moment.utc(ts).add(-moment().utcOffset(), "m").format("DD/MM/YYYY HH:mm:ss");
// 		return moment(now, "DD/MM/YYYY HH:mm:ss").diff(moment(then, "DD/MM/YYYY HH:mm:ss"), 'second')
// 	}

// 	return (<>
// 		<GoogleMap
// 			defaultZoom={5}
// 			defaultCenter={{ lat: props.curentpos.lat, lng: props.curentpos.lng }}
// 			onClick={(e) => { console.log("e.latLng.lat()", e.latLng.lat(), "e.latLng.lng()", e.latLng.lng()) }}
// 		>
// 			<><Marker
// 				defaultZoomOnClick={4}
// 				icon={{
// 					url: getSecondsTodayBydate(props.mapInfo.HEARTBEAT_TS) < 86400 ? mapicon : mapRedicon,
// 					scaledSize: new window.google.maps.Size(25, 25)
// 				}}
// 				onMouseOver={(data) => onMarkerClicked(data, props?.mapInfo)}
// 				position={{ lat: parseFloat(props?.mapInfo?.address?.address_lattitude || ''), lng: parseFloat(props?.mapInfo?.address?.address_longitude || '') }}
// 			/></>
// 		</GoogleMap>
// 		{jobDetail?.activeMarker?.lat && jobDetail.isOpen &&
// 			<InfoWindow position={{ lat: jobDetail?.activeMarker?.lat, lng: jobDetail?.activeMarker?.lng }} onCloseClick={onToggleOpen} options={{
// 				pixelOffset: new window.google.maps.Size(
// 					0, -15
// 				)
// 			}}>
// 				<div className="detailCard">
// 					<div className="d-flex main-div">
// 						<div className="d-inline-block">
// 							{console.log("first == ", jobDetail?.Info?.address.address_compony_name)}
// 							<div className="text-black name">{jobDetail?.Info?.address.address_compony_name}
// 							</div>
// 						</div>
// 					</div>
// 				</div>
// 			</InfoWindow>}
// 	</>
// 	);
// }))

import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BootstrapTooltip } from '../../../../Common/common'
import { UpdateGsmWebConfigDetails, getAll_Active_alarm_template } from '../../../../Common/Utils'
import { Switch } from 'antd'

export default function WebConfig(props) {

   const [config, setConfig] = useState({ BURNING_KWH: '', UNIT_RATE: '', DAILY_REQUIRED_CONS: { MIN: '', MAX: '' }, ALARMS: {} })
   const [disable, setDisable] = useState(true)
   const [btnFlag, setbtnFlag] = useState(false)
   const [AlarmList, setAlarmList] = useState(false)
   useEffect(() => {
      setDisable(false)
      ongetalarm_template()
      setConfig({ BURNING_KWH: props?.GSMdata?.BURNING_KWH, UNIT_RATE: props?.GSMdata?.UNIT_RATE, DAILY_REQUIRED_CONS: JSON.parse(props?.GSMdata?.DAILY_REQUIRED_CONS || '{"MIN":0,"MAX":0}')?.Daily_Req_Cons, ALARMS: JSON.parse(props?.GSMdata?.alarms || '{}') })
   }, [])

   const onHandalUpdate = async () => {
      setbtnFlag(true)
      const data = await UpdateGsmWebConfigDetails(config, props?.GSMdata?.unique_id)
      if (data.success === true) {
         toast.success('Successfully Updated Details')
         setDisable(false)
         setbtnFlag(false)
         props.onRefreshData()
      } else {
         toast.error('Something Went Wrong')
         setbtnFlag(false)
      }
   }

   const ongetalarm_template = async () => {
      const data = await getAll_Active_alarm_template()
      if (data.success == true) {
         setAlarmList(data.data.data)
         var obj = {}
         if (props?.GSMdata?.alarms) {

         } else {
            data?.data?.data?.map((item) => (
               obj[item?.notification_type_id] = 0
            ))
            setConfig({ ...config, ALARMS: obj })
         }
      } else {

      }
   }

   const onHandalswitch = (e, id) => {
      if (e == true) {
         setConfig({ ...config, ALARMS: { ...config.ALARMS, [id]: 1 } });
      } else {
         setConfig({ ...config, ALARMS: { ...config.ALARMS, [id]: 0 } });

      }
   }

   return (
      <div>
         <section class="card-diff-section">
            <div class="section-body">
               <div class="row">
                  <div class="col-12">
                     <div class="card">
                        <div class="card-header">
                           <h4>Web Configration</h4>
                           <div class="card-header-action">
                              <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'>
                                 <span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" >
                                    <i class="fas fa-edit  text-white"></i>
                                 </span></BootstrapTooltip>
                              </div>
                           </div>
                        </div>
                        <div className='card-body'>
                           {/* <div className='d-flex'>
                              <div className=' d-flex tw-flex-col tw-justify-center' style={{ fontSize: '15px', width: '30%' }}><b>1] Daily Required Consumption :- </b></div>
                              <div class="form-row pl-3 col-10" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                 
                                 <div class="form-group col-md-2 d-flex mb-0">
                                    <label className='d-flex tw-flex-col tw-justify-center'>Min:- </label>
                                    <input class="form-control ml-3 d-flex tw-flex-col tw-justify-center" id="installationo" name="installation_number" type="number" placeholder=" Min" disabled={!disable} onChange={(e) => (setConfig({ ...config, DAILY_REQUIRED_CONS: { ...config.DAILY_REQUIRED_CONS, MIN: e.target.value } }))} value={config?.DAILY_REQUIRED_CONS?.MIN} />

                                 </div>
                                 <div class="form-group col-md-2 d-flex mb-0">
                                    <label className='d-flex tw-flex-col tw-justify-center'>Max:- </label>
                                    <input class="form-control ml-3 d-flex tw-flex-col tw-justify-center" id="installationo" name="installation_number" type="number" placeholder="  Max" disabled={!disable} onChange={(e) => (setConfig({ ...config, DAILY_REQUIRED_CONS: { ...config.DAILY_REQUIRED_CONS, MAX: e.target.value } }))} value={config?.DAILY_REQUIRED_CONS?.MAX} />

                                 </div>
                              </div>
                           </div> */}
                           <div class="d-flex mt-4">
                              <div className=' ' style={{ fontSize: '15px', width: '16%' }}><b>1] Alarms :- </b></div>
                              <div >
                                 {AlarmList && AlarmList.map((item) => (
                                    <>
                                       <div class='d-flex ml-5'>
                                          <Switch size="small" checked={config?.ALARMS[item?.notification_type_id] == 1 ? true : false} onChange={(e) => (onHandalswitch(e, item?.notification_type_id))} disabled={!disable} />
                                          <h6 class='ml-2'>{item?.notification_title}</h6>
                                       </div>
                                    </>
                                 ))}
                              </div>
                           </div>
                           {/* <div className='d-flex'>
                              <div className=' d-flex tw-flex-col tw-justify-center' style={{ fontSize: '15px', width: '30%' }}><b>3] Unit Rate:- </b></div>
                              <div class="form-row pl-3 col-10" style={{ marginLeft: "10px", borderColor: "#e3e6fc !important", position: "relative", marginTop: "5px" }}>
                                 <div class="form-group col-md-3 mb-0">
                                    <input class="form-control" id="installationo" name="installation_number" type="number" placeholder="RS" disabled={!disable} onChange={(e) => (setConfig({ ...config, UNIT_RATE: e.target.value }))} value={config?.UNIT_RATE} />
                                 </div>
                              </div>
                           </div> */}
                           {disable ? <>
                              <div class="text-left mb-3 ml-3 mt-3">
                                 <input type="submit" name="Update" class={`btn btn-danger ${btnFlag ? 'disabled btn-progress' : ''} mr-4`} value="Submit" id="submitvn" onClick={onHandalUpdate} />

                                 <Link class="btn btn-light text-dark " onClick={() => setDisable(false)}>Cancel</Link>
                              </div></> : ''}
                        </div>

                     </div>
                  </div>
               </div>
            </div>
         </section>
      </div>
   )
}

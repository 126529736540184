import React from 'react'
import { Award } from 'react-feather'
import { Link, useNavigate, useLocation } from 'react-router-dom'
// import MapPicker from 'react-google-map-picker'
import { useState } from 'react';
import { toast } from 'react-toastify'
import { Modal } from 'antd'
import { activeandinactive, CheckUseConsumer, getAccountWiseModemTableViewCoumnName, getAccountWiseTableViewCoumnName, getAllMeter, getAllModem, getCategoryParameters, getScriptdecodeId, getslave_all_register_profile, InsertAccount, InsertAccountWiseModemTableViewCoumnName, InsertAccountWiseTableViewCoumnName, rebrandingCompanyPhotos, Table_Get_All_City_by_State, Table_Get_All_Country, Table_Get_All_State_by_Country, UpdateAccount } from '../../../Common/Utils';
import { useEffect } from 'react';
import { BootstrapTooltip } from '../../../Common/common';
import swal from "sweetalert2";
import chroma from 'chroma-js';
import DraggabalTable from '../../../Common/DraggabalTable';
window.Swal = swal;

const DefaultLocation = { lat: '', lng: '' };
const DefaultZoom = 10;
export default function AddAccount() {
   const [disable, setDisable] = useState(true)
   const [defaultLocation, setDefaultLocation] = useState(DefaultLocation);
   const [isModalVisible, setIsModalVisible] = useState(false);
   const [flagBtn, setflegBtn] = useState(false);
   const [AccountInput, serAccountInput] = useState({ name: '', country: '', state: '', city: '', URL: '', company_name: '' })
   const [fetchDetails, setfetchDetails] = useState({ modem_id: '', slave_id: '', slave_version: '' })
   const [location, setLocation] = useState(defaultLocation);
   const [zoom, setZoom] = useState(DefaultZoom);
   const [accountId, setAccountId] = useState('')
   const locations = useLocation()
   const history = useNavigate();
   const [options, setoptions] = useState([])
   const [stateData, setstateData] = useState([])
   const [Citydata, setCitydata] = useState([])
   const [imgs, setimgs] = useState('')
   const [profilesPhoto, setprofilesPhoto] = useState()
   const [Favimgs, setFavimgs] = useState()
   const [FavPhoto, setFavPhoto] = useState()
   const [activeTab, setActiveTab] = useState('Account Details')
   const [Modem, setModem] = useState([])
   const [Meter, setMeter] = useState([])
   const [RegiterProfile, setRegiterProfile] = useState([])
   const [ScriptIdData, setScriptIdData] = useState([])
   const [ChangesJson,setChangesJson]=useState({})
   const [TableKey,setTableKey]=useState()
   const [arrayParameter,setarrayParameter]=useState([])
   const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
   const columns = [{
      Header: "Id",
      accessor: "Order"
   }, {
      Header: "Database Column",
      accessor: "Column"
   }, {
      Header: "Fectory Default Column",
      accessor: "Header"
   }]
   

   const getCountry = async () => {
      const data = await Table_Get_All_Country()
      if (data.success == true) {
         setoptions(data.data.data)
      } else {
         console.log('something want wrong')
      }
   }

   const getState = async (id) => {
      const data = await Table_Get_All_State_by_Country(id)
      if (data.success == true) {
         setstateData(data.data.data)
      } else {
         console.log('something want wrong')
      }
   }

   const getCity = async (id) => {
      const data = await Table_Get_All_City_by_State(id)
      if (data.success == true) {
         setCitydata(data.data.data)
      } else {
         console.log('something want wrong')
      }
   }
   const onHandaleSelection = async () => {
      const modem_data = await getAllModem()
      if (modem_data.success === true) {
         setModem(modem_data.data.data)
         const data1 = await getAllMeter()
         if (data1.success === true) {
            setMeter(data1.data.data)
         }
      } else {
         console.log('somthing want wrong')
      }
      const data = await getScriptdecodeId()
      if (data.success === true) {
         setScriptIdData(data.data.data)
         console.log(locations?.state)


      } else {
         console.log('somthing want wrong')
      }
   }

   const getSlaveAddressInfo = async (slave_id, version_id) => {
      if (slave_id) {
         const data = await getslave_all_register_profile({ slave_type: slave_id })
         if (data.success == true) {
            setRegiterProfile(data?.data?.data)

         } else {

         }
      } else {
         setRegiterProfile([])
      }
   }

   const ongetCategoryParameters = async (METER_CATEGORY) => {
      if(METER_CATEGORY){
      const data = await getCategoryParameters({ tableName: `${METER_CATEGORY}_parameters_index` })
      if (data.success == true) {
         setarrayParameter(data?.data?.data)
      } else {

      }
   }else{
      setarrayParameter([])
   }
   }
   useEffect(() => {
      getCountry()
      onHandaleSelection()
   }, [])
   useEffect(() => {
      if (locations?.state) {
         serAccountInput({ name: locations?.state?.CATEGORY0, country: locations?.state?.COUNTRY, state: locations?.state?.STATE, city: locations?.state?.CITY, STATUS: locations?.state?.STATUS, URL: locations?.state?.COMPANY_PORTAL_URL, company_name: locations?.state?.COMPANY_NAME })
         setLocation({ lat: locations?.state?.LAT_LONG.split(',')[0] || '', lng: locations?.state?.LAT_LONG.split(',')[1] || '' })
         setAccountId(locations?.state?.UNIQUE_ID)
         setDisable(false)
         getState(locations?.state?.COUNTRY)
         getCity(locations?.state?.STATE)
         setFavimgs(locations?.state?.COMPANY_FAVICON)
         setimgs(locations?.state?.COMPANY_LOGO)
      }
   }, [locations]);

   function isImage(icon) {
      const ext = ['.jpg', '.jpeg', '.bmp', '.gif', '.png', '.svg'];
      return ext.some(el => icon.endsWith(el));
   }



   const onHandaleFile = (e) => {
      var totalSizeMB = e.target.files[0].size / Math.pow(1024, 2)
      if (totalSizeMB >= 2 || !isImage(e.target.files[0].name)) {
         swal.fire('', 'File size should be under 2 MB and File Extension Should only be PNG , JPG , JPEG , JIF . Please Try Again', 'error');
      } else {
         var reader = new FileReader();
         reader.onloadend = function () {
            var base64data = reader.result;
            setimgs(base64data)
         }
         setprofilesPhoto(e.target.files[0])
         reader.readAsDataURL(e.target.files[0]);
      }
   }

   const onHandaleFavFile = (e) => {
      var totalSizeMB = e.target.files[0].size / Math.pow(1024, 2)
      if (totalSizeMB >= 2 || !isImage(e.target.files[0].name)) {
         swal.fire('', 'File size should be under 2 MB and File Extension Should only be PNG , JPG , JPEG , JIF . Please Try Again', 'error');
      } else {
         var reader = new FileReader();
         reader.onloadend = function () {
            var base64data = reader.result;
            setFavimgs(base64data)
         }
         setFavPhoto(e.target.files[0])
         reader.readAsDataURL(e.target.files[0]);
      }
   }

   const userInfo = JSON.parse(window.localStorage.getItem("user_info") || {})
   const handleCancel = () => {
      setIsModalVisible(false);
      setLocation({ DefaultLocation });
      setZoom(DefaultZoom);
   };
   function handleChangeLocation(lat, lng) {
      setLocation({ lat: lat, lng: lng });
   }

   function handleChangeZoom(newZoom) {
      setZoom(newZoom);
   }

   function handleResetLocation() {
      setDefaultLocation({ ...DefaultLocation });
      setZoom(DefaultZoom);
   }

   const onSubmitAccountData = async (e) => {
      e.preventDefault()
      setflegBtn(true)
      if (AccountInput?.name === '') {
         toast.error('Please Fill Name')
         setflegBtn(false)
      } else {
         if (accountId === '') {
            var obj_photo = {
               logo_upload: imgs,
               fav_upload: Favimgs
            }
            if (profilesPhoto) {
               const formData = new FormData()
               formData.append('Images', profilesPhoto)
               const logo_upload = await rebrandingCompanyPhotos(formData)
               if (logo_upload?.success == true) {
                  obj_photo['logo_upload'] = logo_upload?.data?.data
               } else {
                  return setflegBtn(false)
               }
            }

            if (FavPhoto) {
               const formData = new FormData()
               formData.append('Images', FavPhoto)
               const fav_upload = await rebrandingCompanyPhotos(formData)
               if (fav_upload?.success == true) {
                  obj_photo['fav_upload'] = fav_upload?.data?.data

               } else {
                  return setflegBtn(false)
               }
            }

            const data = await InsertAccount({ ...AccountInput, lat: location?.lat || '' + ',' + location?.lng || '', add_by: userInfo?.unique_id })
            if (data.success === true) {
               toast.success("Account Add Success")
               history('/account')
               setflegBtn(false)

            } else {
               toast.error("Something Wait Wrong")
               setflegBtn(false)

            }
         }
         else {
            var obj_photo = {
               logo_upload: imgs,
               fav_upload: Favimgs
            }
            if (profilesPhoto) {
               const formData = new FormData()
               formData.append('Images', profilesPhoto)
               const logo_upload = await rebrandingCompanyPhotos(formData)
               if (logo_upload?.success == true) {
                  obj_photo['logo_upload'] = logo_upload?.data?.data
               } else {
                  return setflegBtn(false)
               }
            }

            if (FavPhoto) {
               const formData = new FormData()
               formData.append('Images', FavPhoto)
               const fav_upload = await rebrandingCompanyPhotos(formData)
               if (fav_upload?.success == true) {
                  obj_photo['fav_upload'] = fav_upload?.data?.data
               } else {
                  return setflegBtn(false)
               }
            }
            const data = await UpdateAccount({ ...AccountInput, ...obj_photo, lat: location?.lat || '' + ',' + location?.lng || '', add_by: userInfo?.unique_id }, accountId)
            if (data.success === true) {
               toast.success("Update Account Success")
               history('/account')
               setflegBtn(false)

            } else {
               toast.error("Something Wait Wrong")
               setflegBtn(false)

            }
         }
      }
   }

   const oninactive = async () => {
      const check = await CheckUseConsumer({ colName: 'cat0_id', value: accountId })
      if (check.success === true && check.data.data[0].valid > 0) {
         swal.fire({
            title: `There are ${check.data.data[0].valid} consumers related to this Account. Please Deactive those Consumeres to deactive this Account.`,
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "error",
            showCancelButton: true,
         })
      } else {
         swal.fire({
            title: "Are You Sure InActive Account?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
         }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
               const data = await activeandinactive({ tableName: 'category0', colName: 'status', value: '0', matchId: 'unique_id' }, accountId)
               if (data.success === true) {
                  toast.success('Success Inactive Account')
                  history('/account')
               } else {
                  toast.error('something want wrong')
               }
            }
         })
      }
   }
   const onactive = async () => {
      swal.fire({
         title: "Are You Sure Active Account?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            const data = await activeandinactive({ tableName: 'category0', colName: 'status', value: '1', matchId: 'unique_id' }, accountId)
            if (data.success === true) {
               toast.success('Success Active Account')
               history('/account')
            } else {
               toast.error('something want wrong')
            }
         }
      })
   }

   const onfetchTableViewData = async () => {
      if(fetchDetails?.modem_id&&fetchDetails?.slave_id&&fetchDetails?.slave_version){
      const data = await getAccountWiseTableViewCoumnName({ tableName: `${accountId}_slave_table_view`, modem_id: fetchDetails?.modem_id, slave_id: fetchDetails?.slave_id, slave_version: fetchDetails?.slave_version })
      if (data.success === true) {
         if(data?.data?.data?.length>0){
            let slave_table_json=RegiterProfile?.filter((item)=>item?.unique_id==fetchDetails?.slave_version)[0]||{}
            let modem_table_json=Modem?.filter((item)=>item?.MODEM_UNIQUE_ID==fetchDetails?.modem_id)[0]||{}
            var column_name={}
            const table_json=JSON.parse(data?.data?.data[0]?.column_json||'{}')
            Object.keys(JSON.parse(modem_table_json?.COLUMN_NAME||'{}')).map((key)=>{
                if(JSON.parse(slave_table_json?.column_json||'{}')[key]){
                  
                    column_name[key]=[...(JSON.parse(slave_table_json?.column_json||'{}')[key]||[]).map((item)=>({...item,u_name:table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.u_name,"Division":table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.Division||false,"FloatPoint":table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.FloatPoint||0,"DivisionNumber":table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.DivisionNumber||0,"Float":table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.Float||false}))]
                }
            })
         setChangesJson(column_name)
         }else{
            let slave_table_json=RegiterProfile?.filter((item)=>item?.unique_id==fetchDetails?.slave_version)[0]||{}
            let modem_table_json=Modem?.filter((item)=>item?.MODEM_UNIQUE_ID==fetchDetails?.modem_id)[0]||{}
            var column_name={}
            Object.keys(JSON.parse(modem_table_json?.COLUMN_NAME||'{}')).map((key)=>{
                if(JSON.parse(slave_table_json?.column_json||'{}')[key]){
                    column_name[key]=[...(JSON.parse(slave_table_json?.column_json||'{}')[key]||[]).map((item)=>({...item,u_name:arrayParameter.filter((col)=>col?.unique_id==item?.Header&&col?.table_type==key)[0]?.name}))]
                }
            })
            setChangesJson(column_name)
         }
      } else {
         console.log('something want wrong')
      }
   }else{
      toast.error('Please Input Require Fild')
   }
   }

   const onfetchModemTableViewData = async () => {
      if(fetchDetails?.modem_id){
      const data = await getAccountWiseModemTableViewCoumnName({ tableName: `${accountId}_modem_table_view`, modem_id: fetchDetails?.modem_id, slave_id: fetchDetails?.slave_id, slave_version: fetchDetails?.slave_version })
      if (data.success === true) {
         if(data?.data?.data?.length>0){
            let modem_table_json=Modem?.filter((item)=>item?.MODEM_UNIQUE_ID==fetchDetails?.modem_id)[0]||{}
            var column_name={}
            const table_json=JSON.parse(data?.data?.data[0]?.column_json||'{}')
            Object.keys(JSON.parse(modem_table_json?.COLUMN_NAME||'{}')).map((key)=>{
                
                    column_name[key]= [...(JSON.parse(modem_table_json?.COLUMN_NAME||'{}')[key]||[]).map((item)=>({...item,u_name:table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.u_name,"Division":table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.Division||false,"FloatPoint":table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.FloatPoint||0,"DivisionNumber":table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.DivisionNumber||0,"Float":table_json[key]?.filter((col)=>col?.Column==item?.Column)[0]?.Float||false}))]
                
            })
         setChangesJson(column_name)
         }else{
            let slave_table_json=RegiterProfile?.filter((item)=>item?.unique_id==fetchDetails?.slave_version)[0]||{}
            let modem_table_json=Modem?.filter((item)=>item?.MODEM_UNIQUE_ID==fetchDetails?.modem_id)[0]||{}
            var column_name={}
            Object.keys(JSON.parse(modem_table_json?.COLUMN_NAME||'{}')).map((key)=>{
                    column_name[key]= [...(JSON.parse(modem_table_json?.COLUMN_NAME||'{}')[key]||[]).map((item)=>({...item,u_name:item?.Header}))]
            })
            setChangesJson(column_name)
         }
      } else {
         console.log('something want wrong')
      }
   }else{
      toast.error('Please Input Require Fild')
   }
   }

   const onInsertAccountWiseColumn=async()=>{
      const data=await InsertAccountWiseTableViewCoumnName({tableName: `${accountId}_slave_table_view`,sr_no_u:locations?.state?.SRNO,column_json:ChangesJson,...fetchDetails,add_by:userInfo?.unique_id})
      if(data.success===true){
         toast.success('Success Add Table View Column')
         onfetchTableViewData()
      }else{
         toast.error('Server Issue')

      }
   }

   
   const onInsertAccountWiseModemColumn=async()=>{
      const data=await InsertAccountWiseModemTableViewCoumnName({tableName: `${accountId}_modem_table_view`,sr_no_u:locations?.state?.SRNO,column_json:ChangesJson,...fetchDetails,add_by:userInfo?.unique_id})
      if(data.success===true){
         toast.success('Success Add Table View Column')
         onfetchModemTableViewData()
      }else{
         toast.error('Server Issue')

      }
   }

   const handleDragEnd = () => {
      setChangesJson({
         ...ChangesJson,
            [TableKey]: ChangesJson[TableKey]
      });
   };

   useEffect(() => {
      setChangesJson(JSON.parse(JSON.stringify({})))
      setfetchDetails({ modem_id: '', slave_id: '', slave_version: '' })
   },[activeTab])

  
   return (
      <>

         <Modal title="Map" visible={isModalVisible} onOk={() => (setIsModalVisible(false))} onCancel={handleCancel} width={800}>
            {/* <MapPicker defaultLocation={{ lat: parseFloat(location?.lat||'19.506765954448635'), lng: parseFloat(location?.lng||'76.46875000000001')}}
    zoom={zoom}
    mapTypeId="roadmap"
    style={{height:'500px'}}
    onChangeLocation={handleChangeLocation} 
    onChangeZoom={handleChangeZoom}
    apiKey='AIzaSyAo0xl_P_6Ga-ee6ggeYe1JAE2jq3PxU5s'/> */}
         </Modal>
         <div className="main-content" style={{ minHeight: "562px" }}>
            <nav aria-label="breadcrumb">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="/all-dashboard">
                        <i className="fas fa-tachometer-alt"></i> Home
                     </Link>
                  </li>
                  <li className="breadcrumb-item">
                     <Link >
                        <Award className='header-logo' style={{ height: "16px" }} /> Profile
                     </Link>
                  </li>
                  <li className="breadcrumb-item">
                     <Link to='account'>
                        Account
                     </Link>
                  </li>
                  <li className="breadcrumb-item">
                     <Link >{accountId === '' ? 'Add Account' : 'Update Account'}</Link>
                  </li>
               </ol>
            </nav>
            <section class="section">
               <div class="section-body">
                  <div class="row">
                     <div class="col-12">
                        <div class="card">
                           <div class="card-header">
                              <h4>{accountId === '' ? 'Add Account' : 'Update Account'}</h4>
                              <div class="card-header-action">
                                 {userInfo?.role === 'ROLE1650614500708' && accountId !== '' ? <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div> : accountId !== '' && locations?.state?.CATEGORY0_edit?.filter((items) => (items === accountId)).length > 0 ? <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div> : ''}
                                 {userInfo?.role === 'ROLE1650614500708' && AccountInput?.STATUS == 1 ? <div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={() => (oninactive())}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div> : accountId !== '' && AccountInput?.STATUS == 1 && locations?.state?.CATEGORY0_active.filter((item) => (item === accountId)).length > 0 ? <div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={() => (oninactive())}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div> : ''}
                                 {userInfo?.role === 'ROLE1650614500708' && AccountInput?.STATUS == 0 ? <div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={() => (onactive())}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div> : accountId !== '' && (AccountInput?.STATUS == 0 && locations?.state?.CATEGORY0_active.filter((item) => (item === accountId)).length > 0) ? <div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={() => (onactive())}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div> : ''}
                                 <Link to="/account" class="btn btn-info text-white">Back</Link>
                              </div>
                           </div>
                           <div class="card-body">
                              <ul class="nav nav-pills tw-gap-2 mb-3" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>
                                 <li>
                                    <button style={{
                                       backgroundColor: activeTab === "Account Details" ? chroma(btnColor[0]).alpha(0.4).css() : '',
                                       color: activeTab === "Account Details" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
                                       borderBottom: `1px solid ${btnColor[0]}`,
                                       boxShadow: activeTab === "Account Details" ? `0 2px 8px -3px ${btnColor[0]}` : '',
                                    }}
                                       className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Account Details" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => (setActiveTab('Account Details'))}>Account Details</button>
                                 </li>
                                 <li>
                                    <button style={{
                                       backgroundColor: activeTab === "Slave Table View Setting" ? chroma(btnColor[1]).alpha(0.4).css() : '',
                                       color: activeTab === "Slave Table View Setting" ? chroma(btnColor[1]).darken(1.5).css() : btnColor[1],
                                       borderBottom: `1px solid ${btnColor[1]}`,
                                       boxShadow: activeTab === "Slave Table View Setting" ? `0 2px 8px -3px ${btnColor[1]}` : '',
                                    }}
                                       className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Slave Table View Setting" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => (setActiveTab('Slave Table View Setting'))}>Slave Table View Setting</button>
                                 </li>
                                 <li>
                                    <button style={{
                                       backgroundColor: activeTab === "Modem Table View Setting" ? chroma(btnColor[2]).alpha(0.4).css() : '',
                                       color: activeTab === "Modem Table View Setting" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
                                       borderBottom: `1px solid ${btnColor[2]}`,
                                       boxShadow: activeTab === "Modem Table View Setting" ? `0 2px 8px -3px ${btnColor[2]}` : '',
                                    }}
                                       className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Modem Table View Setting" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => (setActiveTab('Modem Table View Setting'))}>Modem Table View Setting</button>
                                 </li>
                              </ul>
                              {activeTab === "Account Details" && <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onSubmitAccountData}>
                                 <div class="form-row">
                                    <div class="form-group col-md-3">
                                       <label for="inputPassword4">Name:<span class="text-danger">*</span></label>
                                       <input type="text" class="form-control" name="account_name" placeholder="Enter Account Name" required="" onChange={(e) => (serAccountInput({ ...AccountInput, name: e.target.value }))} value={AccountInput?.name} disabled={!disable} />
                                    </div>
                                 </div>
                                 <div class="row">
                                    <div class="col-lg-3">
                                       <div class="form-group">
                                          <label for="inputPassword4">Country</label>
                                          <select class="form-control second_meter_type form-select" onChange={(item) => {
                                             serAccountInput({ ...AccountInput, country: item.target.value })
                                             getState(item.target.value)
                                          }} value={AccountInput?.country} required="" name="meter_type" disabled={!disable}>
                                             <option value="">Select Country</option>
                                             {options && options.map((item) => <option value={item.id}>{item.country}</option>)}
                                          </select>

                                       </div>
                                    </div>
                                    <div class="col-lg-3">
                                       <div class="form-group">
                                          <label for="inputPassword4">State</label>
                                          <select class="form-control second_meter_type form-select" onChange={(item) => {
                                             serAccountInput({ ...AccountInput, state: item.target.value })
                                             getCity(item.target.value)
                                          }} value={AccountInput?.state} required="" name="meter_type" disabled={!disable} >
                                             <option value="">Select State</option>
                                             {stateData && stateData.map((item) => <option value={item.id}>{item.state}</option>)}
                                          </select>

                                       </div>
                                    </div>
                                    <div class="col-lg-3">
                                       <div class="form-group">
                                          <label for="inputPassword4">City</label>
                                          <select class="form-control second_meter_type form-select" onChange={(item) => (serAccountInput({ ...AccountInput, city: item.target.value }))} value={AccountInput?.city} required="" name="meter_type" disabled={!disable}>
                                             <option value="">Select City</option>
                                             {Citydata && Citydata.map((item) => <option value={item.id}>{item.city}</option>)}
                                          </select>
                                       </div>
                                    </div>
                                 </div>

                                 <div class="form-row">
                                    <div class="form-group col-md-3">
                                       <label for="inputState">Latitude:</label>
                                       <input type="text" class="form-control MapLat" id="loca_lat" name="loca_lat" placeholder="Latitude" value={location?.lat || ''} onChange={(e) => (setLocation({ ...location, lat: e.target.value.replace(/[^0-9.]/gi, '') }))} disabled={!disable} />
                                       <p className='text-danger'
                                          style={{ fontSize: "10px" }}>* Allowed Only Number (0-9) And Periods(.) And Format (D.D°)</p>

                                       <input type="hidden" class="form-control MapLat" id="btntypeforsubmit" name="submityper" />
                                    </div>
                                    <div class="form-group col-md-3">
                                       <label for="inputState">Longitude:</label>
                                       <input type="text" class="form-control MapLon" id="loca_long" name="loca_long" placeholder="Longitude" value={location?.lng || ''} onChange={(e) => (setLocation({ ...location, lng: e.target.value.replace(/[^0-9.]/gi, '') }))} disabled={!disable} />
                                       <p className='text-danger'
                                          style={{ fontSize: "10px" }}>* Allowed Only Number (0-9) And Periods(.) And Format (D.D°)</p>

                                    </div>
                                    <div class="col-lg-1" style={{ marginTop: "30px" }}>
                                       <a class="btn btn-primary" data-toggle="tooltip" data-placement="bottom" title="" onClick={() => setIsModalVisible(true)} id="maplink" data-original-title="Map"><i class="fa fa-location-arrow"></i></a>
                                    </div>
                                 </div>

                                 <div className=" ">
                                    <div className=' d-flex pl-0'>
                                       <div class="form-group col-md-3">
                                          <label for="inputState">Company Portal URL:</label>
                                          <input type="text" class="form-control MapLon" id="loca_long" name="loca_long" placeholder="https://abcd.com/" disabled={!disable} onChange={(e) => (serAccountInput({ ...AccountInput, URL: e.target.value }))} value={AccountInput?.URL} />

                                       </div>
                                       <div class="form-group col-md-3">
                                          <label for="inputState">Company Name:</label>
                                          <input type="text" class="form-control MapLon" id="loca_long" name="loca_long" placeholder="abcd" disabled={!disable} onChange={(e) => (serAccountInput({ ...AccountInput, company_name: e.target.value }))} value={AccountInput?.company_name} />

                                       </div>
                                    </div>
                                    <div className=" pl-0">
                                       <div className="form-row">
                                          <div className="form-group col-md-2">
                                             <h6 for="">Logo:</h6>
                                             <div className="change_profile_image text-right float-left">
                                                <img src={imgs} name="image" height="100px" width="100px" className="profileimagedata" id="image_d" />
                                                <div className="file_upload">
                                                   <input type="file" name="image" id="profileimage" onChange={onHandaleFile} disabled={!disable} value='' style={{ display: `${!disable ? 'none' : ''}` }} />
                                                   <label htmlFor="profileimage" ><i className="fas fa-camera" ></i></label>
                                                </div>
                                             </div>
                                          </div>

                                          <div className="form-group col-md-2">
                                             <h6 for="">Favicon:</h6>
                                             <div className="change_profile_image text-right float-left">
                                                <img src={Favimgs} name="image" height="100px" width="100px" className="profileimagedata" id="image_d" />
                                                <div className="file_upload">
                                                   <input type="file" name="image" id="profileimage" onChange={onHandaleFavFile} disabled={!disable} value='' style={{ display: `${!disable ? 'none' : ''}` }} />
                                                   <label htmlFor="profileimage" ><i className="fas fa-camera" ></i></label>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <p style={{ marginLeft: "25px" }} class="text-danger">Note : * Indicates Mandatory Fields</p>
                                 <div class="form-group" style={{ marginTop: "20px" }}>
                                    <div class="text-left">
                                       {userInfo?.role === 'ROLE1650614500708' ? <input type="submit" name="submit" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={accountId === '' ? 'Submit' : 'Update'} id="submitvn" /> : accountId !== '' ? <input type="submit" name="submit" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={accountId === '' ? 'Submit' : 'Update'} id="submitvn" /> : ''}
                                       <Link class={`btn btn-light text-dark ${flagBtn ? 'disabled' : ''}`} to="/account">Cancel</Link>
                                    </div>
                                 </div>
                              </form>}
                              {activeTab === "Slave Table View Setting" && <div>
                                 <div class="row">
                                    <div class="form-group col-md-3" id="grpeui">
                                       <label for="inputPassword4">Modem Profile: <span class="text-danger">*</span></label>
                                       <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e) => {setfetchDetails({ ...fetchDetails, modem_id: e.target.value })
                                          setChangesJson({})
                                       }} value={fetchDetails?.modem_id}>
                                          <option value="">Select Modem</option>
                                          {Modem && Modem.map((item) => (<option value={item?.MODEM_UNIQUE_ID}>{item?.MODEM_PROFILE_NAME}</option>))}
                                       </select>
                                    </div>
                                    <div class="form-group col-md-3" id="grpeui">
                                       <label for="inputPassword4">Meter Profile : <span class="text-danger">*</span></label>
                                       <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e) => {
                                          setfetchDetails({ ...fetchDetails, slave_id: e.target.value })
                                          getSlaveAddressInfo(e.target.value)
                                          ongetCategoryParameters(Meter?.filter((item)=>item?.METER_UNIQUE_ID==e.target.value)[0]?.METER_CATEGORY||'')
                                          setChangesJson({})
                                       }} value={fetchDetails?.slave_id} >
                                          <option value="">Select Meter</option>
                                          {Meter && Meter.map((item) => (<option value={item?.METER_UNIQUE_ID}>{item?.METER_PROFILE_NAME}</option>))}

                                       </select>
                                    </div>
                                    <div class="form-group col-md-3" id="grpeui">
                                       <label for="inputPassword4">Version : <span class="text-danger">*</span></label>
                                       <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e) => {
                                          setfetchDetails({...fetchDetails,slave_version:e.target.value})
                                          setChangesJson({})
                                       }} value={fetchDetails?.slave_version}>
                                          <option value="">Select Version</option>
                                          {RegiterProfile && RegiterProfile.map((item) => (<option value={item?.unique_id}>{item?.version}</option>))}

                                       </select>
                                    </div>
                                    <div class="form-group col-md-3 m-0 d-flex tw-items-center" id="grpeui"><input type="submit" name="submit" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={'Fetch Column'} id="submitvn" onClick={onfetchTableViewData}/></div>
                                 </div>
                                 {fetchDetails?.modem_id&&Object.keys(ChangesJson).length > 0?<div className='d-flex'>
                                                <div class="form-group col-md-3 d-flex" id="grpeui" style={{ paddingLeft: '0px' }}>
                                                    <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e) => (setTableKey(e.target.value))} value={TableKey}>
                                                    <option value='' >Select Table </option>
                                                        {Object.keys(ChangesJson).length > 0 && Object.keys(ChangesJson).map((item) => (<option value={item} >{item}</option>))}
                                                    </select>
                                                    <div id="meteid"></div>
                                                   
                                                </div></div>:'' }
                                 {fetchDetails?.modem_id&&ChangesJson && ChangesJson[TableKey]?.length > 0
                                       ? (
                                          <DraggabalTable
                                             columns={columns}
                                             data={ChangesJson[TableKey]}
                                             setitem={(e) => setChangesJson({ ...ChangesJson, [TableKey]: e })}
                                             onDragEnd={handleDragEnd}
                                             arrayParameter={arrayParameter.filter(item=>item?.table_type==TableKey)}
                                             isDefaultCol={true}
                                          />
                                       )
                                       : ''}
                                       <div class="form-group" style={{ marginTop: "20px" }}>
                                       {fetchDetails?.modem_id&&Object.keys(ChangesJson).length > 0?<div class="text-left">
                                       <input type="submit" name="submit" class={`btn btn-danger ml-4 mr-3`} value={'Save Slave Table View'} id="submitvn" onClick={onInsertAccountWiseColumn}/>  
                                    </div>:''}
                                 </div>
                              </div>}
                              {activeTab === "Modem Table View Setting" && <div>
                                 <div class="row">
                                    <div class="form-group col-md-3" id="grpeui">
                                       <label for="inputPassword4">Modem Profile: <span class="text-danger">*</span></label>
                                       <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e) => {setfetchDetails({ ...fetchDetails, modem_id: e.target.value })
                                          setChangesJson({})
                                       }} value={fetchDetails?.modem_id}>
                                          <option value="">Select Modem</option>
                                          {Modem && Modem.map((item) => (<option value={item?.MODEM_UNIQUE_ID}>{item?.MODEM_PROFILE_NAME}</option>))}
                                       </select>
                                    </div>
                                    
                                    <div class="form-group col-md-3 m-0 d-flex tw-items-center" id="grpeui"><input type="submit" name="submit" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={'Fetch Column'} id="submitvn" onClick={onfetchModemTableViewData}/></div>
                                 </div>
                                {fetchDetails?.modem_id&&Object.keys(ChangesJson).length > 0? <div className='d-flex'>
                                                <div class="form-group col-md-3 d-flex" id="grpeui" style={{ paddingLeft: '0px' }}>
                                                    <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e) => (setTableKey(e.target.value))} value={TableKey}>
                                                    <option value='' >Select Table </option>
                                                        {Object.keys(ChangesJson).length > 0 && Object.keys(ChangesJson).map((item) => (<option value={item} >{item}</option>))}
                                                    </select>
                                                    <div id="meteid"></div>
                                                   
                                                </div></div>:"" }
                                 {fetchDetails?.modem_id&&ChangesJson && ChangesJson[TableKey]?.length > 0
                                       ? (
                                          <DraggabalTable
                                             columns={columns}
                                             data={ChangesJson[TableKey]}
                                             setitem={(e) => setChangesJson({ ...ChangesJson, [TableKey]: e })}
                                             onDragEnd={handleDragEnd}
                                             arrayParameter={arrayParameter.filter(item=>item?.table_type==TableKey)}
                                             isDefaultCol={true}
                                          />
                                       )
                                       : ''}
                                       <div class="form-group" style={{ marginTop: "20px" }}>
                                       {fetchDetails?.modem_id&&Object.keys(ChangesJson).length > 0? <div class="text-left">
                                       <input type="submit" name="submit" class={`btn btn-danger ml-4 mr-3`} value={'Save Modem Table View'} id="submitvn" onClick={onInsertAccountWiseModemColumn}/>  
                                    </div>:""}
                                 </div>
                              </div>}
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   )
}

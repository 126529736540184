import React from 'react'
import Slave from '../Slave/Slave'

export default function SlaveDetails() {
  return (
   <>
   <Slave component_type={'Group'}/>
   </>
  )
}


import { Navigate, Outlet, useLocation } from 'react-router-dom';

export function PrivateRoutes() {
    const location = useLocation();
    let token = window.localStorage.getItem("token");

    // console.log("user_info = ", (token))

    //   if (token === undefined) {
    //     return null; // or loading indicator/spinner/etc
    //   }

    return (token&&token!==""? <Outlet /> : <Navigate to="/login" replace state={{ from: location }} />)
}

export default PrivateRoutes;
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Lock } from 'react-feather'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import { BootstrapTooltip } from '../../../Common/common'
import { activeandinactive, DeleteCategoryParameters, getCategoryParameters, InsertCategoryParameters, InsertFirmware, Insertmeter_category, UpdateCategoryParameters, UpdateFirmware, Updatemeter_category } from '../../../Common/Utils'
import swal from "sweetalert2";
window.Swal = swal;
export default function AddCategory() {

   const [name, setName] = useState('')
   const [FirmwareId, setFirmwareId] = useState('')
   const userInfo = JSON.parse(window.localStorage.getItem("user_info") || {})
   const [flagBtn, setflegBtn] = useState(false);
   const [disable, setDisable] = useState(true)
   const [status, setStatus] = useState(null)
   const [arrayParameter, setarrayParameter] = useState([])
   const [TableType, setTableType] = useState(['instantaneous'])
   const [selectTtype, setselectTtype] = useState('instantaneous')
   const [parameterName,setparameterName]=useState('')
   const [parameterValue,setparameterValue]=useState(null)
   const history = useNavigate();
   const locations = useLocation()

   useEffect(() => {
      if (locations?.state) {
         setName(locations?.state?.METER_CATEGORY_NAME)
         setFirmwareId(locations?.state?.UNIQUE_ID)
         setDisable(false)
         setStatus(locations?.state?.STATUS)
         ongetCategoryParameters()
      }
   }, [locations]);

   const ongetCategoryParameters = async () => {
      const data = await getCategoryParameters({ tableName: `${locations?.state?.UNIQUE_ID}_parameters_index` })
      if (data.success == true) {
         setarrayParameter(data?.data?.data)
      } else {

      }
   }

   const onHandaleSubmitData = async (e) => {
      e.preventDefault()
      setflegBtn(true)
      if (name === '') {
         setflegBtn(false)
         toast.error('Please Enter Name')
      } else {
         if (FirmwareId === '') {
            const data = await Insertmeter_category({ name: name, add_by: userInfo?.unique_id })
            if (data.success === true) {
               toast.success('Add Slave Category Success')
               setflegBtn(false)
               history('/all-category')
            } else {
               toast.error('Something Wait Wrong')
               setflegBtn(false)

            }
         } else {
            const data2 = await Updatemeter_category({ name: name }, FirmwareId)
            if (data2.success === true) {
               toast.success('Update Slave Category Success')
               setflegBtn(false)
               history('/all-category')
            } else {
               toast.error('Something Wait Wrong')
               setflegBtn(false)

            }
         }
      }
   }

   const oninactive = async () => {
      swal.fire({
         title: "Are You Sure InActive Slave Category?",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            const data = await activeandinactive({ tableName: 'gsm_slave_category', colName: 'status', value: '0', matchId: 'unique_id' }, FirmwareId)
            if (data.success === true) {
               toast.success('Success Inactive Slave Category')
               history('/all-category')
            } else {
               toast.error('something want wrong')
            }
         }
      })
   }
   const onactive = async () => {
      swal.fire({
         title: "Are You Sure Slave Category?",
         // text: "Once deleted, you will not be able to recover this User Details!",
         icon: "warning",
         showCancelButton: true,
      }).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
            const data = await activeandinactive({ tableName: 'gsm_slave_category', colName: 'status', value: '1', matchId: 'unique_id' }, FirmwareId)
            if (data.success === true) {
               toast.success('Success Active Slave Category')
               history('/all-category')
            } else {
               toast.error('something want wrong')
            }
         }
      })
   }

   const addNewParameter=()=>{
      const obj={
         name:'',
         table_type:selectTtype
      }
      setarrayParameter([...arrayParameter,obj])
   }

   const onSubmitParameter=async()=>{
      if(parameterName){
      const data=await InsertCategoryParameters({ srno:locations?.state?.SRNO,name:parameterName, add_by:userInfo?.unique_id,table_type:selectTtype,tableName: `${locations?.state?.UNIQUE_ID}_parameters_index`})
      if(data.success==true){
         toast.success('Success Added Parameter')
         ongetCategoryParameters()
         setparameterName('')
      }else{
         toast.error('Server Issue')
      }
   }else{
      toast.error('Enter Parameter Name')

   }
   }

   const onSetEdit=(info)=>{
      setparameterValue(info)
   }

   const onUpdateParameter=async()=>{
      if(parameterValue){
      const data=await UpdateCategoryParameters({srno:parameterValue?.sr_no_u,name:parameterValue?.name, add_by:userInfo?.unique_id,table_type:selectTtype,tableName: `${locations?.state?.UNIQUE_ID}_parameters_index`},parameterValue?.unique_id)
      if(data.success==true){
         toast.success('Success Updated Parameter')
         ongetCategoryParameters()
         setparameterValue(null)
      }else{
         toast.error('Server Issue')
      }
   }else{
      toast.error('Enter Parameter Name')

   }
   
   }
   const onDeleteParameter=async(info)=>{
      swal.fire({
			title: "Are You Sure Delete Parameter?",
			// text: "Once deleted, you will not be able to recover this User Details!",
			icon: "warning",
			showCancelButton: true,
		}).then(async (willDelete) => {
         if (willDelete.isConfirmed) {
      const data=await DeleteCategoryParameters({tableName: `${locations?.state?.UNIQUE_ID}_parameters_index`},info?.unique_id)
      if(data.success==true){
         toast.success('Success Delete Parameter')
         ongetCategoryParameters()
      }else{
         toast.error('Server Issue')
      }
      }
      })
   }
   return (
      <>
         <div className="main-content">
            <nav aria-label="breadcrumb">
               <ol className="breadcrumb">
                  <li className="breadcrumb-item">
                     <Link to="/all-dashboard">
                        <i className="fas fa-tachometer-alt"></i> Home
                     </Link>
                  </li>
                  <li className="breadcrumb-item">
                     <Link >
                        <Lock className='header-logo' style={{ height: "16px" }} /> Admin
                     </Link>
                  </li>
                  <li className="breadcrumb-item">
                     <Link to='/all-category'>
                        Slave Driver
                     </Link>
                  </li>
                  <li className="breadcrumb-item">
                     <Link >Slave Category</Link>
                  </li>
                  <li className="breadcrumb-item">
                     <Link >{FirmwareId === '' ? 'Add Slave Category' : 'Update Slave Category'}</Link>
                  </li>
               </ol>
            </nav>
            <section class="section">
               <div class="section-body">
                  <div class="row">
                     <div class="col-12">
                        <div class="card">
                           <div class="card-header">
                              <h4>{FirmwareId === '' ? 'Add Slave Category' : 'Update Slave Category'}</h4>
                              <div class="card-header-action">
                                 {FirmwareId !== '' ? <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div> : ''}
                                 {FirmwareId !== '' && status == 1 ? <div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div> : ''}
                                 {FirmwareId !== '' && status == 0 ? <div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div> : ''}
                                 <Link to="/all-category" class="btn btn-info text-white align-left">
                                    Back
                                 </Link>
                              </div>
                           </div>
                           <div class="card-body">
                              <form method="post" name="main_menu_add" id="main_menu_add" enctype="multipart/form-data" onSubmit={onHandaleSubmitData}>
                                 <div class="form-row" style={{ marginLeft: "10px" }}>
                                    <div class="form-group col-md-3" id="grpeui">
                                       <label for="inputPassword4">Slave Category Name : <span class="text-danger">*</span></label>
                                       <input class="form-control" name="metermakename" type="text" placeholder="Enter Slave Category Name" required="" onChange={(e) => (setName(e.target.value))} value={name} disabled={!disable} />
                                       <input type="hidden" class="form-control MapLat" id="btntypeforsubmit" name="submityper" />
                                    </div>
                                 </div>
                                 <p style={{ marginLeft: "25px" }} class="text-danger">Note : * Indicates Mandatory Fields</p>
                                 <div class="text-left">
                                    {disable ? <input type="submit" name="submit" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={FirmwareId === '' ? 'Submit' : 'Update'} id="submitvn" /> : ''}
                                    {disable ?<Link class={`btn btn-light text-dark ${flagBtn ? 'disabled' : ''}`} to="/all-category">Cancel</Link>:''}
                                 </div>
                              </form></div>
                              {FirmwareId !== ''?<><div class="form-group col-md-3 d-flex" id="grpeui" style={{ paddingLeft: '0px' }}>
                              <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onClick={(e) => (setselectTtype(e.target.value))} value={selectTtype}>
                                 {TableType.length > 0 && TableType.map((item) => (<option value={item} >{item}</option>))}
                              </select>
                              <div id="meteid"></div>

                           </div>
                           <div className="table-responsive  tw-max-h-screen ml-2" style={{ width: '700px' }}>
                              <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                 <table id="table_id" className="table text-center table-borderless table-striped font-12">
                                    <thead className='tw-sticky tw-top-0 tw-bg-gray-100 tw-z-[999]'>
                                       <tr>
                                          <th>No</th>
                                          <th>Parameters Name</th>
                                          <th>Action</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       {arrayParameter?.map((item) => (
                                          item?.sr_no_u?<tr>
                                             <td style={{ height: '45px' }}>{item?.sr_no_u}</td>
                                             {parameterValue&&parameterValue?.unique_id==item?.unique_id?<td style={{ height: '45px' }}><input type="text" class="form-control" placeholder="Name" onChange={(e) => {setparameterValue({...parameterValue,name:e.target.value})}} value={parameterValue?.name}/></td>:<td style={{ height: '45px' }}>{item?.name}</td>}
                                         {parameterValue&&parameterValue?.unique_id==item?.unique_id?<td><button type="button" class="btn btn-danger mr-2" onClick={onUpdateParameter}>Update</button><button type="button" class="btn btn-info" onClick={()=>(setparameterValue(null))}>Cancel</button></td>:<td style={{ height: '45px' }}><button type="button" class="btn btn-info"onClick={()=>onSetEdit(item)}><span><i class="far fa-edit text-white"></i></span></button><button type="button" class="btn btn-danger ml-2"  onClick={()=>(onDeleteParameter(item))}><span><i class="far fa-trash-alt text-white"></i></span></button></td>}
                                          </tr>:
                                          <tr>
                                          <td style={{ height: '45px' }}></td>
                                          <td style={{ height: '45px' }}><input type="text" class="form-control" placeholder="Name" onChange={(e) => {setparameterName(e.target.value)}} value={parameterName}/></td>
                                          <td style={{ height: '45px' }}><button type="button" class="btn btn-primary" onClick={onSubmitParameter}>Submit</button></td>
                                       </tr>
                                       ))}
                                    </tbody>

                                 </table>
                              </div>
                              <button type="button" className="btn btn-primary mr-1 mb-4" onClick={() => (addNewParameter())}>
                                 <div className='tw-flex tw-items-center tw-gap-1 '>
                                    <i class="fas fa-plus" aria-hidden="true"></i>
                                    <span>
                                       New
                                    </span>
                                 </div>
                              </button>
                           </div></>:""}
                        </div>
                     </div>
                  </div>
               </div>
            </section>
         </div>
      </>
   )
}

import * as React from "react"
import * as TabsPrimitive from "@radix-ui/react-tabs"

import { cn } from "../../lib/utils"
import { cva } from "class-variance-authority";

const tabsVariants = cva(
  "tw-inline-flex tw-items-center tw-justify-center tw-whitespace-nowrap tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-ring-offset-background tw-duration-150 tw-ease-linear focus-visible:tw-outline-none disabled:tw-pointer-events-none disabled:tw-opacity-50 tw-border-b-[1px] hover:tw-bg-gray-100 hover:tw-rounded data-[state=active]:tw-rounded",
  {
    variants: {
      variant: {
        indigo: "data-[state=active]:tw-bg-indigo-200 data-[state=active]:tw-font-bold hover:tw-bg-indigo-100 data-[state=active]:tw-text-indigo-700 tw-text-indigo-500 tw-border-indigo-500 ",
        Blue: "data-[state=active]:tw-bg-Sky-100 data-[state=active]:tw-font-bold hover:tw-bg-blue-50 data-[state=active]:tw-text-blue-700 tw-text-blue-500 tw-border-blue-500 ",
        Purple: "data-[state=active]:tw-bg-purple-200 data-[state=active]:tw-font-bold hover:tw-bg-purple-100 data-[state=active]:tw-text-purple-700 tw-text-purple-500 tw-border-purple-500 ",
        success: "data-[state=active]:tw-bg-green-200 data-[state=active]:tw-text-green-700 tw-text-green-500 tw-border-green-500",
        danger: "data-[state=active]:tw-bg-red-200 data-[state=active]:tw-text-red-700 tw-text-red-500 tw-border-red-500",
        yellow: "data-[state=active]:tw-bg-yellow-200 data-[state=active]:tw-text-yellow-700 tw-text-yellow-500 tw-border-yellow-500",
        cyan: "data-[state=active]:tw-bg-cyan-200 data-[state=active]:tw-text-cyan-700 tw-text-cyan-500 tw-border-cyan-500",
        fuchsia: "data-[state=active]:tw-bg-fuchsia-200 data-[state=active]:tw-text-fuchsia-700 tw-text-fuchsia-500 tw-border-fuchsia-500",
        blue: "data-[state=active]:tw-bg-blue-200 data-[state=active]:tw-font-bold data-[state=active]:tw-text-blue-700 tw-text-blue-500 tw-border-blue-500",
        tabIndigo: "!tw-text-indigo-500 !tw-border-none hover:tw-bg-indigo-50 tw-font-semibold tw-text-sm !tw-leading-7 md:tw-px-[15px] md:tw-m-0 data-[state=active]:tw-bg-indigo-500 data-[state=active]:!tw-text-[#fff] data-[state=active]:tw-shadow-custom-pri",
        tabPrime: "data-[state=active]:tw-bg-[#b8beec] data-[state=active]:tw-text-[#212284] data-[state=active]:tw-shadow-custom-pri tw-text-[#4f5ece] tw-border-[#4f5ece]",
        tabWarning: "data-[state=active]:tw-bg-[#fad9a9] data-[state=active]:tw-text-[#a2591b] data-[state=active]:tw-shadow-custom-war tw-text-[#f59e0c] tw-border-[#f59e0c]",
        tabblue: "data-[state=active]:tw-bg-[#a4e3fb] data-[state=active]:tw-text-bprimarydark data-[state=active]:tw-shadow-custom-blue tw-text-[#44baf7] tw-border-[#44baf7]",
        tabGreen: "data-[state=active]:tw-bg-[#b4eac3] data-[state=active]:tw-text-[#008024] data-[state=active]:tw-shadow-custom-green tw-text-[#54ca68] tw-border-[#54ca68]",
        tabDark: 'data-[state=active]:tw-bg-[#a3a5a6] data-[state=active]:tw-text-[#040e48] data-[state=active]:tw-shadow-custom-bark tw-text-[#14002c] tw-border-[#14002c]',
        tabPurpul: "data-[state=active]:tw-bg-[#cabad5] data-[state=active]:tw-text-[#381351] data-[state=active]:tw-shadow-custom-purpul tw-text-[#7c5295] tw-border-[#7c5295]",
      },
      backDrop:{
        indigoLight:"data-[state=active]:tw-bg-indigo-200 tw-bg-indigo-50 hover:tw-bg-indigo-200 data-[state=active]:tw-shadow",
        dangerLight:"data-[state=active]:tw-bg-red-200 tw-bg-red-50 hover:tw-bg-red-200 data-[state=active]:tw-shadow",
        cyanLight:"data-[state=active]:tw-bg-cyan-200 tw-bg-cyan-50 hover:tw-bg-cyan-200 data-[state=active]:tw-shadow"
      }
    },
    defaultVariants: {
      variant: "indigo",
    },
  }
);

const Tabs = TabsPrimitive.Root

const TabsList = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "tw-inline-flex tw-h-10 tw-items-center tw-justify-center tw-rounded-md tw-bg-muted tw-p-1 tw-text-muted-foreground",
      className
    )}
    {...props} />
))
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = React.forwardRef(({ className, variant,backDrop, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(tabsVariants({ variant ,backDrop, className }))}
    {...props}
  />
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = React.forwardRef(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "tw-mt-2 tw-ring-offset-background focus-visible:tw-outline-none focus-visible:tw-ring-2 focus-visible:tw-ring-ring focus-visible:tw-ring-offset-2",
      className
    )}
    {...props} />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }

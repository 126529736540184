import { Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { saveFilterToDb } from '../../Common/Utils';
import { toast } from 'react-toastify';
import MultiInput from '../../Common/MultiInput/MultiInput';

const FilterDisplay = ({ filter, onClearFilter, onClearIndividualFilter }) => {
    if (Object.entries(filter).length === 0) {
        return null
    }
    const handleClearIndividualFilter = (key, value) => {
        onClearIndividualFilter(key, value);
    };
    return (
        <div className="tw-px-1 tw-flex tw-items-center tw-gap-2">
            <div className="tw-flex tw-flex-wrap tw-gap-1 tw-text-xs tw-items-center">
                {Object.entries(filter).length > 0 && Object.entries(filter).map(([key, value]) => (
                    <>
                        <strong className="tw-mr-0.5">{key}:</strong>
                        {Array.isArray(value) ? key=='taskid'?(
                            value.map((v, i) => (
                                <span key={i} className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                    {v}

                                    <button onClick={() => handleClearIndividualFilter(key, v)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1.5">×</button>
                                </span>
                            ))
                        ):(
                            value.map((v, i) => (
                                <span key={i} className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                    {v?.label}

                                    <button onClick={() => handleClearIndividualFilter(key, v?.value)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1.5">×</button>
                                </span>
                            ))
                        ) :
                            (typeof value === 'object' &&
                                !Array.isArray(value) &&
                                value !== null
                            ) ? <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                {(value?.label)}
                            </span> : (
                                <span className="border tw-rounded tw-px-2 tw-py-1 tw-mx-1 border !tw-border-indigo-500">
                                    {JSON.stringify(value)}
                                    <button onClick={() => handleClearIndividualFilter(key, value)} class="tw-border-none tw-font-bold tw-ml-1 tw-bg-transparent tw-transition-all tw-rounded hover:tw-bg-red-500/30 hover:tw-text-red-600 tw-px-1">×</button>
                                </span>
                            )}
                    </>
                ))}
                <div className=''>
                    <button onClick={onClearFilter} className='tw-bg-red-100 tw-whitespace-nowrap tw-rounded-md tw-text-xs tw-px-2 tw-py-1 border !tw-border-red-500 hover:tw-bg-red-200 tw-transition-all'>
                        Clear Filter
                    </button>
                </div>
            </div>
        </div>
    );
}


const TaskFilter = ({isFilterModalOpen,setIsFilterModalOpen, handleFilter, tagsTypsOptions, taskAssignToOptions, currentUser, currentFilter, handleShowFilter, currentShowFilter, onAddFavoriteFilter,getFavFilters,storedFilterType,storedFilterS,UpdateTrigger,setUpdateTrigger }) => {
    const taskPriorityOptions = [
        { value: 'High', label: 'High' },
        { value: 'medium', label: 'Medium' },
        { value: 'Low', label: 'Low' },
    ];

    const taskStatusOptions = [
        { value: "-1", label: 'Open' },
        { value: "0", label: 'Working On It' },
        { value: "2", label: 'In Review' },
        { value: '3', label: 'On Hold' },
        { value: "1", label: 'Complete' },
        { value: "4", label: 'Cancel' },
    ];

    const taskTypeOptions = [
        { value: 'Development', label: 'Development' },
        { value: 'Action', label: 'Action' },
        { value: 'Bug', label: 'Bug' },
    ];

    const taskPlan = [
        { value: -1, label: 'Yet To Decide', color: '#54ca68' },
       { value: 1, label: 'Today', color: '#fc544b' },
       { value: 0, label: 'This Week', color: '#ffa426' }
   ]

    const taskConsumerTypeOptions = [
        { value: 'gsm', label: 'GSM' },
        { value: 'lorawan', label: 'LORAWAN' },
    ];

    const allFilterOptions = {
        taskStatus: taskStatusOptions,
        taskAssignTo: taskAssignToOptions,
        taskPriority: taskPriorityOptions,
        taskType: taskTypeOptions,
        taskConsumerType: taskConsumerTypeOptions,
        taskTags: tagsTypsOptions,
        taskCreatedBy: taskAssignToOptions,
        Plan:taskPlan,
        SharedWith:taskAssignToOptions
    };



    const [filters, setFilters] = useState({
        taskTitle: '',
        taskAssignTo: [taskAssignToOptions.filter(item => item.value === currentUser)[0]?.value],
        taskConsumerType: [],
        taskStatus: ["-1",'0','3','2'],
        taskPriority: [],
        taskType: [],
        taskTags: [],
        overdue: false,
        todays: false,
        taskCreatedBy: [],
        taskid:[],
        SharedWith:[]
    });

    const [showFilterObj, setShowFilterObj] = useState({
        taskAssignTo: [taskAssignToOptions.filter(item => item.value === currentUser)[0]],
        taskStatus:[{ value: "-1", label: 'Open'},{ value: "0", label: 'Working On It'},{ value: "3", label: 'On Hold'},{ value: "2", label: 'In Review'}],
    })
    const [isSaveFilter, setIsSaveFilter] = useState(false)
    const [filterName, setFilterName] = useState('')
    const [PropFilterName, setPropFilterName] = useState('')
    // const [isFilterModalOpen, setIsFilterModalOpen] = useState(false)
	const [searchTerms, setSearchTerms] = useState([]);

useEffect(()=>{
if(storedFilterType=='Custom Filters'&&UpdateTrigger==true){
    if(storedFilterS){
console.log(storedFilterS)
setPropFilterName(storedFilterS?.value)
setFilterName(storedFilterS?.value)
setIsSaveFilter(true)
    }
}
},[UpdateTrigger])
   
    const onFilterModalOpen = () => {
        setIsFilterModalOpen(true)
    }

    const onFilterModalClose = () => {
        setIsFilterModalOpen(false)
        setUpdateTrigger(false)
        setIsSaveFilter(false)
        setFilterName('')
        setPropFilterName('')
    }
    const transformFavoriteFilter = (favoriteFilter, allFilterOptions) => {
        const findLabelByValue = (options, value) => options.find(option => option.value === value)?.label;

        const transformed = {};

        Object.keys(favoriteFilter).forEach((key) => {
            const filterValue = favoriteFilter[key];

            if (Array.isArray(filterValue) && filterValue.length > 0) {
                const filterOptions = allFilterOptions[key];
                if (filterOptions) {
                    transformed[key] = filterValue.map(value => ({ value, label: findLabelByValue(filterOptions, value) }));
                } else {
                    transformed[key] = filterValue;
                }
            } else if (typeof filterValue === 'string' && filterValue !== '') {
                transformed[key] = filterValue;
            } else if (typeof filterValue === 'boolean' && filterValue === true) {
                transformed[key] = filterValue;
            }
        });

        return transformed;
    };


    useEffect(() => {
        if (currentFilter) {
            console.log("Current Filter", currentFilter)
            setFilters(currentFilter)
            const transformedFavoriteFilter = transformFavoriteFilter(currentFilter, allFilterOptions);
            setShowFilterObj(transformedFavoriteFilter);
        }
        // if (currentShowFilter) {

        //     console.log("Current Show Filter", currentShowFilter)
        //     setShowFilterObj(currentShowFilter)
        // }
    }, [currentFilter, currentShowFilter])


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
        if (value === '') {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }
    };

    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: checked,
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: checked,
        }));
        if (!checked) {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }
    };

    const handleSaveFilterCheckboxChange = (e) => {
        setIsSaveFilter(e.target.checked)
    }

    const handleFilterNameChange = (e) => {
        setFilterName(e.target.value)
    }

    const handleSaveFilter = async () => {
        onAddFavoriteFilter(filterName, filters)
        if(filterName){
        const addFilter = await saveFilterToDb({ filterName: filterName, filterData: filters, userId: currentUser,IsEdit:PropFilterName?true:false,PropFilterName:PropFilterName })
        if (addFilter.success === true) {
            toast.success("Filter Saved Successfully")
            setIsSaveFilter(false)
            setFilterName('')
            setPropFilterName('')
            getFavFilters()
        }
        else {
            toast.error("Something went wrong")
        }
    }else{
        toast.error("Enter Filter Name")
    }
    }

    const handleSelectChange = (selectedOptions, { name }) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: selectedOptions.map(item => item.value),
        }));
        setShowFilterObj((prevFilters) => ({
            ...prevFilters,
            [name]: selectedOptions,
        }));
        if (selectedOptions.length === 0) {
            setShowFilterObj((prevFilters) => {
                const updatedFilters = { ...prevFilters };
                delete updatedFilters[name]
                return updatedFilters
            });
        }
    };

    const handleSubmit = () => {
        handleFilter(filters);
        handleShowFilter(showFilterObj);
        onFilterModalClose()
        
    };

    const onClearFilter = async () => {
        setFilters({
            taskTitle: '',
            taskAssignTo: [],
            taskConsumerType: [],
            taskStatus: [],
            taskPriority: [],
            taskType: [],
            taskTags: [],
            overdue: false,
            todays: false,
            taskCreatedBy: [],
            taskid:[],
            SharedWith:[]
        });
        handleFilter({
            taskTitle: '',
            taskAssignTo: [],
            taskConsumerType: [],
            taskStatus: [],
            taskPriority: [],
            taskType: [],
            taskTags: [],
            overdue: false,
            todays: false,
            taskCreatedBy: [],
            taskid:[],
            SharedWith:[]

        });

        setShowFilterObj({});
        handleShowFilter({});
    }

    const onClearIndividualFilter = (key, value) => {
        const newFilters = { ...filters };
        let updatedShowFilters = { ...showFilterObj };
console.log("new filters = ",newFilters)
console.log("ehfehgf", updatedShowFilters)
        if (value) {
            if (Array.isArray(newFilters[key])) {
                newFilters[key] = newFilters[key].filter(v => v !== value);
                updatedShowFilters[key] = updatedShowFilters[key].filter(v => v.value !== value);
            } else {
                newFilters[key] = key === 'taskTitle' ? '' : false;
                delete updatedShowFilters[key];
            }
        } else {
            delete newFilters[key];
            delete updatedShowFilters[key];
        }
        if (updatedShowFilters[key]?.length === 0) {
            delete updatedShowFilters[key];

        }

        setFilters(newFilters);
        handleFilter(newFilters);
        setShowFilterObj(updatedShowFilters);
        handleShowFilter(updatedShowFilters);
    };


    const onSearchMultiFilterJson = (newSearchTerms,json_name) => {
        console.log('fdgs',newSearchTerms)
		setFilters((prevFilters) => ({
            ...prevFilters,
             taskid: newSearchTerms 
        }))
		
	};
	
	const onItemClearedFilterJson  = (clearedIndex,json_name) => {
        console.log('fdgs1',clearedIndex)
		// Create a new array without the cleared item
		const newSearchTerms = filters?.taskid.filter((_, index) => index !== clearedIndex);
		// Update the search results based on the new search terms
		onSearchMultiFilterJson(newSearchTerms,json_name);
	};
	const onClearAllItemsFilterJson  = (arr,json_name) => {
        console.log('fdgs2')
		// Reset the search results to the original data
		onSearchMultiFilterJson([],json_name);
	
	};
    return (
        <>
            <Modal title="Filter" style={{ top: 0 }} visible={isFilterModalOpen} footer='' onCancel={onFilterModalClose} width={1030}>

                    <div className='tw-grid tw-grid-cols-2 tw-gap-4 tw-my-4'>
                    <div class="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <span className="tw-w-1/3 col-form-label tw-font-medium" >Task ID:</span>
                            <div class="tw-w-2/3">
                            <MultiInput
									placeholder="search and enter..."
									onChange={(arr)=>onSearchMultiFilterJson(arr,"Installation_Number")}
									maxItems={null} // Allow any number of items
									onClear={(arr)=>onItemClearedFilterJson(arr,"Installation_Number")}
									onClearAll={(arr)=>onClearAllItemsFilterJson(arr,"Installation_Number")}
								/>
                            </div>

                        </div>
                        <div class="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskTitle">Task Title:</label>
                            <div class="tw-w-2/3">
                                <input className='form-control' type="text" name="taskTitle" value={filters.taskTitle} onChange={handleInputChange} />
                            </div>

                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskAssignTo">Task Assign To:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    className='tw-w-full'
                                    isMulti
                                    value={showFilterObj?.taskAssignTo || []}
                                    name="taskAssignTo"
                                    options={taskAssignToOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>

                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskConsumerType">Task Consumer Type:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskConsumerType"
                                    value={showFilterObj?.taskConsumerType || []}
                                    options={taskConsumerTypeOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskStatus">Task Status:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskStatus"
                                    value={showFilterObj?.taskStatus || []}
                                    options={taskStatusOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskPriority">Task Priority:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskPriority"
                                    value={showFilterObj?.taskPriority || []}
                                    options={taskPriorityOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskType">Task Type:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskType"
                                    value={showFilterObj?.taskType || []}
                                    options={taskTypeOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskTags">Task Tags:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskTags"
                                    value={showFilterObj?.taskTags || []}
                                    options={tagsTypsOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="SharedWith">Shared With:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    className='tw-w-full'
                                    isMulti
                                    value={showFilterObj?.SharedWith || []}
                                    name="SharedWith"
                                    options={taskAssignToOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>

                        </div>
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <label className="tw-w-1/3 col-form-label tw-font-medium" htmlFor="taskDueDate">Task Created by:</label>
                            <div class="tw-w-2/3">
                                <Select
                                    isMulti
                                    name="taskCreatedBy"
                                    value={showFilterObj?.taskCreatedBy || []}
                                    options={taskAssignToOptions}
                                    onChange={handleSelectChange}
                                />
                            </div>
                        </div>
                        <div class="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                                <label for="" class="tw-w-1/3 col-form-label tw-font-mediu">Plan:</label>
                                <div class="tw-w-2/3">
                                    <Select isMulti isClearable id='task-priority' options={taskPlan} onChange={(e) => { setFilters({ ...filters, Plan: e?.map(item => item.value) }) }} value={taskPlan?.filter((items) => (filters?.Plan?.includes(items.value) )) || []} />

                                </div>
                            </div>
                        
                        <div className="tw-flex tw-flex-wrap tw-mb-4 tw-mx-6">
                            <div className='tw-w-1/2 tw-flex tw-items-center tw-gap-2'>
                                <label className='tw-mb-0' htmlFor="overdue">Overdue Tasks:</label>
                                <input type="checkbox" name="overdue" checked={showFilterObj?.overdue || false} onChange={handleCheckboxChange} />
                            </div>

                            <div className='tw-w-1/2 tw-flex tw-items-center tw-gap-2'>
                                <label className='tw-mb-0' htmlFor="todays">Today's Tasks:</label>
                                <input type="checkbox" name="todays" checked={showFilterObj?.todays || false} onChange={handleCheckboxChange} />
                            </div>
                        </div>

                    </div>
                    <div className='border-top !tw-border-b-gray-300 '>

                        <div className='tw-flex tw-items-center tw-pt-2 tw-gap-2'>
                            <input type="checkbox" name="saveFilter" id='saveFilter' onChange={handleSaveFilterCheckboxChange} checked={isSaveFilter}/>
                            <label className='tw-mb-0' htmlFor="saveFilter">Save this filter as a favorite</label>
                            {
                                isSaveFilter && (
                                    <>

                                        <div className='tw-w-1/3 '>
                                            <input type='text' className='form-control' name="filterName" value={filterName} onChange={handleFilterNameChange} placeholder='Filter Name' />
                                        </div>


                                        <div className='tw-w-1/3 '>
                                            <button type='button' className='btn btn-primary' onClick={handleSaveFilter}>Save</button>
                                        </div>
                                    </>
                                )
                            }

                        </div>

                        <div className='tw-flex tw-items-center '>
                            <div class="buttons tw-mt-5">
                                <button type='button' class="btn btn-danger" onClick={handleSubmit}>Filter</button>
                                <button type='button' class="btn btn-light text-dark" onClick={onFilterModalClose}>Cancel</button>
                            </div>
                        </div>
                    </div>

            </Modal>


            <div className='tw-flex tw-items-center'>
                <button className='tw-border tw-border-indigo-500 tw-bg-transparent hover:tw-bg-indigo-100 tw-rounded' onClick={onFilterModalOpen}>
                    <i class="fas fa-filter tw-text-indigo-500"></i>
                </button>
                <span className='tw-ml-1 tw-font-semibold tw-whitespace-nowrap'>FILTERS : </span>

                <FilterDisplay filter={showFilterObj} onClearFilter={onClearFilter} onClearIndividualFilter={onClearIndividualFilter} />
            </div>

        </>
    );
};

export default TaskFilter;

import React, { useEffect, useMemo, useState } from 'react'
import { Tabs, TabsList, TabsTrigger } from '../../Common/ui/tabs'
import { Button } from '../../Common/ui/button';
import IotModel from './DashboardModal';
import { useForm, useWatch } from 'react-hook-form';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '../../Common/ui/form';
import { AlertCircle, ChevronLeft, Edit, Filter, Layout, LoaderCircle, Plus, Settings } from 'lucide-react';
import { Input, InputBlock } from '../../Common/ui/input';
import { default as ReactMultiSelector } from 'react-select';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../../Common/ui/select';
import { RadioGroup, RadioGroupItem } from '../../Common/ui/radio-group';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import IOTCard from './DashBoardCard';
import { getConfigurationDashDashboardCard, getConfigurationDashboardTab, getSlaveDataByGroupId, getSlaveGroups, InsertConfigurationDashboardCard, insertConfigurationDashboardTab, UpdateConfigurationDashboardCard, UpdateConfigurationDashboardTab, IndividualCardRefresh } from '../../Common/Utils';
import { toast } from 'react-toastify';
import DotLoader from '../../Common/Loaders/DotLoader';
import { Textarea } from '../../Common/ui/textarea';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { DropdownMenu, DropdownMenuCheckboxItem, DropdownMenuContent, DropdownMenuSeparator, DropdownMenuTrigger } from "../../Common/ui/dropdown-menu";
import './Dashboard.css'
import { Modal } from 'react-bootstrap';
import { Table, TableHeader, TableBody, TableHead, TableRow, TableCell } from '../../Common/ui/table';



// Define the validation schema
const schema = yup.object().shape({
    tab_name: yup.string().required('Tab Name is required').min(3, 'Tab Name must be at least 3 characters'),
    tab_description: yup.string().max(500, 'Description must not exceed 500 characters'),
});

// Define the validation schema
const Cardschema = yup.object().shape({
    card_name: yup.string().required('Card Name is required'),
    card_description: yup.string(),
    cardView: yup.string(),
    cardColor: yup.string(),
    group_id: yup.array().min(1, 'At least one Modem must be selected').required('Group is required'),
    slaveType: yup.string().oneOf(['Addition', 'Substraction']).required('Slave Type is required'),
    addition_slave_list: yup.array().when('slaveType', {
        is: 'Addition',
        then: (schema) => schema.min(1, 'At least one addition slave must be selected').required('Addition Slave List is required'),
        otherwise: (schema) => schema
    }),
    substraction_slave_list: yup.array().when('slaveType', {
        is: 'Substraction',
        then: (schema) => schema.min(1, 'At least one substraction slave must be selected').required('Substraction Slave List is required'),
        otherwise: (schema) => schema
    }),
});



export default function IOTDashboard() {

    const queryClient = useQueryClient();
    const [currentTabs, setCurrentTabs] = useState(null)
    const [showTabModal, setShowTabModal] = useState(false);
    const [isTabEdit, setIsTabEdit] = useState(false);
    const [status, setStatus] = useState("1");
    const [Tabstatus, setTabStatus] = useState("1");
    const [isTabshow, setisTabshow] = useState(false)
    const [RefreshIndi, setRefreshIndi] = useState(false)
    const [RefreshId, setRefreshId] = useState(null)
    const [isTableInfoModalOpen, setIsTableInfoModalOpen] = useState(false)
    const [activeTab, setActiveTab] = useState("available")
    const [availableData, setavailableData] = useState([])
    const [unavailableData, setunavailableData] = useState([])
    const [totalData, settotalData] = useState({})

    const addCardForm = useForm({
        resolver: yupResolver(Cardschema),
        defaultValues: {
            card_name: '',
            card_description: '',
            multification_fector: '',
            cardView: 'List View',
            cardColor: '#ffffff',
            group_id: [],
            slaveType: 'Addition',
            addition_slave_list: [],
            substraction_slave_list: [],
        },
    });


    const group_idData = useWatch({
        control: addCardForm.control,
        name: 'group_id',
    });

    const slaveTypeValue = useWatch({
        control: addCardForm.control,
        name: 'slaveType',
    });

    // fetch Tabs DATA
    const {
        data: TabsData,
        isLoading: isTabsLoading,
        error: TabsError,
    } = useQuery({
        queryKey: ["Conf-Tabs", Tabstatus],
        queryFn: () => getConfigurationDashboardTab(Tabstatus),
    });


    // fetch getSlaveGroups
    const {
        data: SlaveGroupsData,
        error: SlaveGroupsError,
    } = useQuery({
        queryKey: ["getSlaveGroups"],
        queryFn: getSlaveGroups,
    });

    // fetch getSlaveGroups
    const {
        data: SlaveDataByGroupId,
        error: SlaveDataByGroupIdError,
    } = useQuery({
        queryKey: ["getSlaveGroups", group_idData],
        queryFn: () => getSlaveDataByGroupId({ group_id: group_idData }),
        enabled: group_idData?.length > 0
    });

    const handleTableCardInfoModal = () => {
        setIsTableInfoModalOpen(false)
    }

    const handleModalOpen = (result_data) => {
        console.log({ result_data })
        setIsTableInfoModalOpen(true)
        setunavailableData(JSON.parse(result_data.data_found_slave || '[]').filter(info => JSON.parse(result_data?.substraction_slave_list || '[]').includes(info.unique_id)))
        setavailableData(JSON.parse(result_data.data_found_slave || '[]').filter(info => JSON.parse(result_data?.addition_slave_list || '[]').includes(info.unique_id)))
        settotalData(result_data)
    }


    // fetch card Data by Tab ID
    const {
        data: cardData,
        isLoading: isCardDataLoading,
        error: CardError,
    } = useQuery({
        queryKey: ["Card-Data", currentTabs, status],
        queryFn: () => getConfigurationDashDashboardCard(currentTabs, status),
        enabled: !!currentTabs,
        refetchInterval: 30000
    });

    // add useEffect for track error 
    useEffect(() => {
        if (TabsError) {
            toast.error(`Error fetching accounts: ${JSON.stringify(TabsError)}`);
        }
        if (SlaveGroupsError) {
            toast.error(`Error fetching SlaveGroups: ${JSON.stringify(SlaveGroupsError)}`);
        }
        if (SlaveDataByGroupIdError) {
            toast.error(`Error fetching Slave Data By GroupId: ${JSON.stringify(SlaveDataByGroupIdError)}`);
        }
        if (CardError) {
            toast.error(`Error fetching Slave Data By CardError: ${JSON.stringify(CardError)}`);
        }
    }, [TabsError, SlaveGroupsError, SlaveDataByGroupIdError, CardError])

    const tabsOptions = useMemo(() => {
        if (!TabsData?.data?.data) return [];
        const data = TabsData?.data?.data.map(({ TAB_NAME, UNIQUE_ID }) => ({
            value: UNIQUE_ID,
            label: TAB_NAME,
        }))
        setCurrentTabs(currentTabs || data[0]?.value)
        return data
    }, [TabsData]);

    const SlaveOptions = useMemo(() => {
        if (!SlaveGroupsData?.data?.data) return [];
        return SlaveGroupsData?.data?.data.map(({ group_name, unique_id }) => ({
            value: unique_id,
            label: group_name,
        }))

    }, [SlaveGroupsData]);

    const SlaveDataByGroupIdOptions = useMemo(() => {
        if (!SlaveDataByGroupId?.data?.data) return [];
        return SlaveDataByGroupId?.data?.data.filter((v) => v?.slave_status === 1).map(({ unique_id, feeder_name, slave_address }) => ({
            value: unique_id,
            label: `${feeder_name || ""}  ${slave_address}`,
        }))

    }, [SlaveDataByGroupId]);

    const cardDataArray = useMemo(() => {
        if (!cardData?.data?.data) return [];
        return cardData?.data?.data.map((v) => v)

    }, [cardData]);

    useEffect(() => {
        setCurrentTabs(tabsOptions[0]?.value)
    }, [Tabstatus])


    const statusOption = [
        { value: "1", label: 'Active' },
        { value: "0", label: 'InActive' },
    ];



    const statusFilterOptions = [
        { value: null, label: "All" },
        { value: "1", label: "Active" },
        { value: "0", label: "Inactive" }
    ];


    //form for tabs
    const form = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            tab_name: '',
            tab_description: '',
        },
    });

    //handle edit Tab Name
    const HandleTabEdit = async () => {
        setShowTabModal(true)
        setIsTabEdit(true)
        const editData = TabsData?.data?.data?.find((v) => v?.UNIQUE_ID === currentTabs)
        console.log(editData)
        form.reset({
            tab_name: editData?.TAB_NAME,
            tab_description: editData?.TAB_DESCRIPTION,
            status: editData?.STATUS.toString(),
            id: editData?.UNIQUE_ID,
        });
    }

    //handle tab add model 
    const HandleTabCloseFilter = async () => {
        setShowTabModal(false)
        setIsTabEdit(false)
        form.reset({
            tab_name: '',
            tab_description: '',
        });
    }

    const handleSubmit = (data) => {
        if (isTabEdit) {
            updateTabMutation.mutate(data)
        } else {
            createTabMutation.mutate(data)
        }

    };

    //state for add card 
    const [showAddCardModal, setShowAddCardModal] = useState(false);
    const [isCardEdit, setIsCardEdit] = useState(false);

    //current Tabs Chnage handler
    const handleTabChange = (tab) => {
        setCurrentTabs(tab);
        setisTabshow(false)
    }



    //Handle Card Edit
    const HandleEditCard = (id) => {

        const editData = cardData?.data?.data?.find((v) => v?.unique_id === id)
        addCardForm.reset({
            card_name: editData?.card_name,
            card_description: editData?.card_description,
            multification_fector: editData?.multification_fector,
            cardView: editData?.cardView,
            cardColor: editData?.cardColor,
            group_id: JSON.parse(editData?.group_id),
            slaveType: 'Addition',
            addition_slave_list: JSON.parse(editData?.addition_slave_list),
            substraction_slave_list: JSON.parse(editData?.substraction_slave_list),
            status: editData?.status.toString(),
            cardId: editData?.unique_id,

        });
        setIsCardEdit(true);
        setShowAddCardModal(true)
    }

    const onRefreshBtn = (id) => {
        setRefreshIndi(true)
        setRefreshId(id)
        onIndividualCardRefresh.mutate(id)
    }

    //handle card add model 
    const handleAddCardSubmit = (data) => {
        if (isCardEdit) {
            updateCardMutation.mutate(data)
        } else {
            createCardMutation.mutate({ ...data, tab_id: currentTabs })
        }

    };

    //handle tab add model 
    const HandleCardCloseFilter = async () => {
        setIsCardEdit(false);
        setShowAddCardModal(false)
        addCardForm.reset({
            card_name: '',
            card_description: '',
            multification_fector: '',
            cardView: 'List View',
            cardColor: '#ffffff',
            group_id: [],
            slaveType: 'Addition',
            addition_slave_list: [],
            substraction_slave_list: [],
        });
    }

    // function for add tab
    const createTabMutation = useMutation({
        mutationFn: insertConfigurationDashboardTab,
        onSuccess: (e) => {
            toast.success('Tab Added successfully');
            HandleTabCloseFilter();
            queryClient.invalidateQueries({ queryKey: ['Conf-Tabs'] });
        },
        onError: (error) => {
            console.error('Error During Add  Tab', error);
            toast.error(`Failed to Add Tab: ${error}`)
        }
    });

    // function for update tab
    const updateTabMutation = useMutation({
        mutationFn: UpdateConfigurationDashboardTab,
        onSuccess: () => {
            HandleTabCloseFilter();
            toast.success('Tab updated successfully');
            queryClient.invalidateQueries({ queryKey: ['Conf-Tabs'] });
        },
        onError: (error) => {
            console.log("Error During Tab Update: ", error);
            toast.error(`Failed to add Tab: ${error}`)
        }
    });


    // function for add Card
    const createCardMutation = useMutation({
        mutationFn: InsertConfigurationDashboardCard,
        onSuccess: (e) => {
            toast.success('Card Added successfully');
            HandleCardCloseFilter();
            queryClient.invalidateQueries({ queryKey: ['Card-Data'] });
        },
        onError: (error) => {
            console.error('Error During Add  Card', error);
            toast.error(`Failed to Add Card: ${error}`)
        }
    });

    // function for update Card
    const updateCardMutation = useMutation({
        mutationFn: UpdateConfigurationDashboardCard,
        onSuccess: () => {
            HandleCardCloseFilter();
            toast.success('Card updated successfully');
            queryClient.invalidateQueries({ queryKey: ['Card-Data'] });
        },
        onError: (error) => {
            console.log("Error During Card Update: ", error);
            toast.error(`Failed to add Card: ${error}`)
        }
    });

    const onIndividualCardRefresh = useMutation({
        mutationFn: IndividualCardRefresh,
        onSuccess: () => {
            ;
            toast.success('Card Refresh successfully');
            setRefreshIndi(false)
            setRefreshId(false)
            queryClient.invalidateQueries({ queryKey: ['Card-Data'] });
        },
        onError: (error) => {
            console.log("Error During Card Update: ", error);
            toast.error(`Failed to add Card: ${error}`)
        }
    });



    if (isTabsLoading) return <div className='tw-w-full  tw-h-[80dvh]' ><DotLoader /></div>

    return (
        <>
            {/* // model for add tabs */}
            <IotModel
                isModalOpen={showTabModal}
                onOpenChange={HandleTabCloseFilter}
                modalTitle={isTabEdit ? "Update New Tab" : "Add New Tab"}
                AddEditModelClassName={"lg:tw-min-w-[35%] lg:tw-max-w-[35%] md:tw-w-[90%]"}
            >
                <Form {...form}>
                    <form onSubmit={form.handleSubmit(handleSubmit)} className="tw-flex tw-flex-col tw-gap-2">
                        <FormField
                            control={form.control}
                            name="tab_name"
                            rules={{ required: "Tab Name is required" }}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Name : <span className='tw-text-red-500 tw-text-sm' >*</span></FormLabel>
                                    <FormControl>
                                        <div className="relative">
                                            <InputBlock
                                                leftIcon={<Layout />}
                                                placeholder="Tab Name"
                                                {...field}
                                                className="pl-10"
                                                variant='none'
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={form.control}
                            name="tab_description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Description :</FormLabel>
                                    <FormControl>
                                        <div className="relative">
                                            <Textarea
                                                {...field}
                                            />
                                        </div>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {
                            isTabEdit &&

                            <FormField
                                control={form.control}
                                name="status"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Status</FormLabel>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select a view" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {
                                                    statusOption?.map((v) => (
                                                        <SelectItem value={v?.value}>{v?.label}</SelectItem>
                                                    ))
                                                }

                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />
                        }
                        <div className="tw-flex tw-justify-start tw-mt-4 tw-space-x-2">
                            <Button disabled={createTabMutation.isPending || updateTabMutation.isPending} type="submit" className='tw-bg-red-500 hover:!tw-bg-red-600' >
                                {
                                    createTabMutation.isPending || updateTabMutation.isPending ? <LoaderCircle className="tw-size-5 tw-animate-spin" /> : "Submit"
                                }

                            </Button>
                            <Button variant="outline" onClick={HandleTabCloseFilter}>
                                Cancel
                            </Button>

                        </div>
                    </form>
                </Form>
            </IotModel>

            {/* // modal for card */}
            <IotModel
                isModalOpen={showAddCardModal}
                onOpenChange={HandleCardCloseFilter}
                modalTitle={isCardEdit ? "Edit Card" : "Add Card"}
                AddEditModelClassName="lg:tw-min-w-[40%] lg:tw-max-w-[40%] md:tw-w-[90%] "
            >
                <Form {...addCardForm}>
                    <form onSubmit={addCardForm.handleSubmit(handleAddCardSubmit)} className="tw-space-y-4 tw-flex tw-flex-col tw-gap-y-2">
                        <FormField
                            control={addCardForm.control}
                            name="card_name"
                            rules={{ required: "Card Name is required" }}
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Card Name</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Card Name" {...field} />
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        <FormField
                            control={addCardForm.control}
                            name="card_description"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Card Description</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Card Description" {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        />
                        {/* <FormField
                            control={addCardForm.control}
                            name="multification_fector"
                            render={({ field }) => (
                                <FormItem>
                                    <FormLabel>Multification Factor</FormLabel>
                                    <FormControl>
                                        <Input placeholder="Card Description" {...field} />
                                    </FormControl>
                                </FormItem>
                            )}
                        /> */}
                        {/* <FormField
                          control={addCardForm.control}
                          name="cardView"
                          render={({ field }) => (
                              <FormItem>
                                  <FormLabel>Card View</FormLabel>
                                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                                      <FormControl>
                                          <SelectTrigger>
                                              <SelectValue placeholder="Select a view" />
                                          </SelectTrigger>
                                      </FormControl>
                                      <SelectContent>
                                          <SelectItem value="List View">List View</SelectItem>
                                          <SelectItem value="Grid View">Grid View</SelectItem>
                                      </SelectContent>
                                  </Select>
                              </FormItem>
                          )}
                      />
                      <FormField
                          control={addCardForm.control}
                          name="cardColor"
                          render={({ field }) => (
                              <FormItem>
                                  <FormLabel>Card Color</FormLabel>
                                  <FormControl className='tw-relative' >
                                      <ColorPicker {...field} />
                                  </FormControl>
                                  <FormMessage />
                              </FormItem>
                          )}
                      /> */}
                        <MultiSelector
                            name="group_id"
                            label="Modem List"
                            options={SlaveOptions}
                            control={addCardForm.control}
                        />
                        <FormField
                            control={addCardForm.control}
                            name="slaveType"
                            render={({ field }) => (
                                <FormItem className="space-y-3">
                                    <FormLabel>Slave Type</FormLabel>
                                    <FormControl>
                                        <RadioGroup
                                            onValueChange={field.onChange}
                                            defaultValue={field.value}
                                            className="tw-flex  tw-space-x-1"
                                        >
                                            <FormItem className="tw-flex tw-items-center tw-space-x-3 tw-space-y-0">
                                                <FormControl>
                                                    <RadioGroupItem value="Addition" />
                                                </FormControl>
                                                <FormLabel className="tw-font-normal">
                                                    Addition Of Slaves
                                                </FormLabel>
                                            </FormItem>
                                            <FormItem className="tw-flex tw-items-center tw-space-x-3 tw-space-y-0">
                                                <FormControl>
                                                    <RadioGroupItem value="Substraction" />
                                                </FormControl>
                                                <FormLabel className="tw-font-normal">
                                                    Substraction Of Slaves
                                                </FormLabel>
                                            </FormItem>
                                        </RadioGroup>
                                    </FormControl>
                                    <FormMessage />
                                </FormItem>
                            )}
                        />
                        {
                            slaveTypeValue === 'Addition' &&
                            <MultiSelector
                                name="addition_slave_list"
                                label="Addition Salve"
                                options={SlaveDataByGroupIdOptions}
                                control={addCardForm.control}
                            />
                        }

                        {
                            slaveTypeValue === 'Substraction' &&
                            <MultiSelector
                                name="substraction_slave_list"
                                label="Substraction Salve"
                                options={SlaveDataByGroupIdOptions}
                                control={addCardForm.control}
                            />
                        }

                        {
                            isCardEdit &&

                            <FormField
                                control={addCardForm.control}
                                name="status"
                                render={({ field }) => (
                                    <FormItem>
                                        <FormLabel>Status</FormLabel>
                                        <Select onValueChange={field.onChange} value={field.value}>
                                            <FormControl>
                                                <SelectTrigger>
                                                    <SelectValue placeholder="Select a view" />
                                                </SelectTrigger>
                                            </FormControl>
                                            <SelectContent>
                                                {
                                                    statusOption?.map((v) => (
                                                        <SelectItem value={v?.value}>{v?.label}</SelectItem>
                                                    ))
                                                }

                                            </SelectContent>
                                        </Select>
                                    </FormItem>
                                )}
                            />
                        }


                        <div className="tw-flex tw-justify-start tw-mt-4 tw-space-x-2">
                            <Button disabled={createCardMutation.isPending || updateCardMutation.isPending} type="submit" className='tw-bg-red-500 hover:!tw-bg-red-600' >
                                {
                                    createCardMutation.isPending || updateCardMutation.isPending ? <LoaderCircle className="tw-size-5 tw-animate-spin" /> : "Submit"
                                }

                            </Button>
                            <Button variant="outline" onClick={HandleCardCloseFilter}>
                                Cancel
                            </Button>
                        </div>
                    </form>
                </Form>
            </IotModel>
            <Modal className='' show={isTableInfoModalOpen}  >
                <Modal.Header>
                    <Modal.Title>{totalData?.card_name}</Modal.Title>
                    <button type="button" class="close" onClick={handleTableCardInfoModal}><span aria-hidden="true">×</span><span class="sr-only">Close</span></button>
                </Modal.Header>
                <Modal.Body>
                    <Tabs className='mb-4' value={activeTab} onValueChange={setActiveTab} >
                        <TabsList className='tw-gap-x-2 tw-bg-transparent ' >
                            <TabsTrigger backDrop="indigoLight" variant="indigo" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="available">Available Data List</TabsTrigger>
                            <TabsTrigger backDrop="dangerLight" variant="danger" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="unavailable">Unavailable Data List</TabsTrigger>
                            <TabsTrigger backDrop="cyanLight" variant="cyan" className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start' value="all">All Slave List</TabsTrigger>
                        </TabsList>
                        <div className="space-y-8 mt-3">
                            {activeTab === "available" &&
                                <div className='tw-border-[1px] tw-border-solid tw-border-gray-300 tw-rounded tw-overflow-hidden tw-shadow ' >
                                    <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2  ' >Addition Of Salves :-</h4>
                                    {renderTable(availableData)}
                                    {unavailableData.length ? <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2' >Substraction Of Salves :-</h4> : ''}
                                    {unavailableData.length ? renderTable(unavailableData) : ''}
                                    <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300  tw-py-3 tw-px-2' >Total :-</h4>
                                    <Table parentClassname="tw-rounded tw-shadow tw-max-h-[45dvh] tw-overflow-auto  ">
                                        <TableHeader className='tw-sticky tw-top-0 tw-z-[99999] tw-border-b-gray-300' >
                                            <TableRow className="tw-bg-gray-100">
                                            <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase tw-w-[150px]">
                        
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase tw-w-[150px]" >
                        
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        voltage_rn
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        voltage_yn
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        voltage_bn
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        current_ir
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        current_iy
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        current_ib
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        active_power
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        pf
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        apparent_power
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        total_energy
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        frequency
                    </TableHead>
                                            </TableRow>
                                        </TableHeader>
                                        <TableBody>
                                            {/* // for total col */}
                                            
                                            <TableRow className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-white  ' >
                        <TableCell colSpan={2} className="tw-font-semibold tw-text-center tw-p-3 ">
                            Total
                        </TableCell>

                        <TableCell className="tw-font-semibold tw-p-3">
                        {Number(totalData?.voltage_rn)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.voltage_yn)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.voltage_bn)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.current_ir)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.current_iy)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.current_ib)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.active_power)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.pf)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.apparent_power)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.total_energy)?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {Number(totalData?.frequency)?.toFixed(2)}
                        </TableCell>
                    </TableRow>   

                                        </TableBody>
                                    </Table>
                                </div>}
                            {activeTab === "unavailable" && <h4 className='tw-flex tw-justify-center tw-h-48 tw-items-center' >No Data Available</h4>}
                            {activeTab === "all" && (
                                <div className='tw-border-[1px] tw-border-solid tw-border-gray-300 tw-rounded tw-overflow-hidden tw-shadow ' >
                                    <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300 tw-text-center tw-py-3 ' >Addition Of Salves</h4>
                                    {renderTable(availableData)}
                                    <h4 className='tw-border-b-[1px] tw-mb-0 tw-border-gray-300 tw-text-center tw-py-3' >Substraction Of Salves</h4>
                                    {renderTable(unavailableData)}
                                </div>
                            )}
                        </div>
                    </Tabs>
                </Modal.Body>
            </Modal>
            <div className='main-content' >
                <div className='tw-flex  tw-flex-row tw-justify-between tw-items-start md:tw-gap-x-4' >

                    <div className={`lg:tw-w-[250px] tw-bg-[#fff] md:tw-w-[200px]  tw-shadow-custom tw-rounded-lg tw-max-h-[88dvh] tw-h-[88dvh] md:tw-overflow-visible ${isTabshow ? "tw-w-full" : "tw-w-0 tw-overflow-hidden"} `} >
                        <div className='tw-h-full tw-w-full tw-flex tw-flex-col tw-flex-1' >
                            <Tabs className='tw-h-full tw-flex tw-items-start tw-flex-grow tw-overflow-y-auto' defaultValue={currentTabs} value={currentTabs} onValueChange={handleTabChange}>
                                <TabsList className='tw-flex-col tw-flex tw-h-auto tw-bg-[#fff] tw-gap-y-2 tw-w-full' >
                                    {
                                        tabsOptions.map(({ value, label }) => (
                                            <TabsTrigger
                                                variant="blue"
                                                className='tw-font-medium tw-border-none tw-w-full tw-justify-start tw-items-start '
                                                value={value}
                                            >
                                                {label}
                                            </TabsTrigger>
                                        ))
                                    }
                                </TabsList>
                            </Tabs>
                            <div className='tw-mt-2 tw-w-full tw-items-center tw-p-1 tw-flex tw-gap-2' >
                                <div className='tw-w-12' >

                                    <DropdownMenu className='' >
                                        <DropdownMenuTrigger asChild >
                                            <Button variant='indigo' size='icon'><Filter size={16} /></Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="tw-px-0 tw-py-2 tw-w-[50px] -tw-translate-x-2 tw-bg-white" >

                                            {
                                                statusFilterOptions?.map((v) => (
                                                    <DropdownMenuCheckboxItem key={v?.value} onClick={() => setTabStatus(v?.value)} className={`${Tabstatus === v?.value && 'tw-bg-slate-100'} tw-py-3 tw-cursor-pointer tw-flex tw-gap-x-1 tw-items-center tw-text-[13px] tw-leading-3  !tw-px-5 hover:tw-text-indigo-500 hover:tw-bg-indigo-50 tw-duration-200`} >
                                                        {v?.label}
                                                    </DropdownMenuCheckboxItem>
                                                ))
                                            }


                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                </div>

                                <Button variant='dotten' border='indigo' className='tw-w-full  tw-border-[2px] ' onClick={() => setShowTabModal(true)} >+ Add New Tab</Button>
                            </div>
                        </div>

                    </div>

                    <div className={` md:tw-w-full tw-max-h-[88dvh] tw-h-[88dvh] md:tw-overflow-visible ${isTabshow ? "tw-w-0 tw-overflow-hidden" : "tw-w-full"}`}>
                        <div className='tw-h-full tw-w-full tw-flex tw-flex-col tw-flex-1 ' >
                            <div className='tw-shadow-custom tw-p-4 tw-rounded-md tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-gap-2 tw-z-20 tw-bg-white' >

                                <Button className='tw-visible md:tw-hidden' variant='info' size='icon' onClick={() => setisTabshow(true)} ><ChevronLeft size={14} /> </Button>

                                <h2 className='tw-text-lg tw-font-bold tw-mb-0' >
                                    {tabsOptions?.find((v) => v?.value === currentTabs)?.label}
                                </h2>



                                <div className='tw-flex tw-flex-wrap tw-justify-between tw-items-center tw-w-auto tw-gap-2' >
                                    <div className='tw-w-[100px]' >
                                        <Select onValueChange={(e) => {
                                            setStatus(e)
                                        }} value={status}  >
                                            <SelectTrigger >
                                                <SelectValue />
                                            </SelectTrigger>
                                            <SelectContent>
                                                {
                                                    statusFilterOptions?.map((v) => (
                                                        <SelectItem key={v?.value} value={v?.value}>{v?.label}</SelectItem>
                                                    ))
                                                }

                                            </SelectContent>
                                        </Select>
                                    </div>

                                    <DropdownMenu className='' >
                                        <DropdownMenuTrigger asChild >
                                            <Button variant='info' size='icon' ><Settings size={14} /></Button>
                                        </DropdownMenuTrigger>
                                        <DropdownMenuContent className="tw-px-0 tw-py-2 tw-w-[50px] -tw-translate-x-2 tw-bg-white" >
                                            <DropdownMenuCheckboxItem onClick={() => HandleTabEdit()} className='tw-cursor-pointer tw-flex tw-gap-x-1 tw-items-center tw-text-[13px] tw-leading-3  !tw-px-5 hover:tw-text-indigo-500 hover:tw-bg-indigo-50 tw-duration-200' >
                                                <Edit size={16} /> Edit
                                            </DropdownMenuCheckboxItem>
                                        </DropdownMenuContent>
                                    </DropdownMenu>
                                    <Button variant='info' size='icon' onClick={() => setShowAddCardModal(true)} ><Plus size={14} /> </Button>
                                </div>
                            </div>

                            {
                                cardDataArray.length === 0 ?
                                    <div className="  tw-mt-4 tw-rounded-xl">
                                        <div className="tw-text-center tw-flex tw-flex-grow tw-h-[78dvh] tw-bg-white tw-items-center tw-justify-center tw-z-10 tw-text-gray-500 tw-shadow-custom">
                                            <div>
                                                <AlertCircle size={40} className="tw-mx-auto tw-mb-2" />
                                                <p className="tw-mb-0 fs-4 tw-text-gray-600  tw-capitalize !tw-font-bold">Data Not Found</p>
                                            </div>

                                        </div>
                                    </div> :
                                    <div className='tw-flex-grow tw-overflow-y-auto' >


                                        {
                                            isCardDataLoading ? <div className='tw-w-full  tw-h-[50dvh]' ><DotLoader /></div>
                                                : <div className='tw-my-4 tw-mb-6 tw-grid xl:tw-grid-cols-3 md:tw-grid-cols-2 tw-grid-cols-1 lg:tw-gap-6 tw-gap-3' >
                                                    {
                                                        cardDataArray?.map((v, i) => (
                                                            <IOTCard data={v} onhandleTabChange={(e) => HandleEditCard(e)} onRefreshBtn={(id) => onRefreshBtn(id)} RefreshId={RefreshId} RefreshIndi={RefreshIndi} onHandleTableModal={handleModalOpen} />
                                                        ))
                                                    }
                                                </div>
                                        }
                                    </div>
                            }


                        </div>



                    </div>
                </div>
            </div>

        </>

    )
}


const ColorPicker = React.forwardRef(({ value, onChange, ...props }, ref) => {
    const [showPicker, setShowPicker] = useState(false);

    return (
        <div className="tw-relative tw-flex tw-justify-between tw-gap-2">
            <Input
                type="text"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                ref={ref}
                {...props}
            />
            <input
                type="color"
                value={value}
                onChange={(e) => {
                    onChange(e.target.value);
                }}
                className="tw-size-[35px]"
            />
        </div>
    );
});

const MultiSelector = ({ name, label, options, control }) => (
    <FormField
        control={control}
        name={name}
        render={({ field }) => (
            <FormItem className="">
                <FormLabel className=" tw-font-medium">{label}:</FormLabel>
                <FormControl>
                    <ReactMultiSelector
                        {...field}
                        options={options}
                        isMulti
                        className=""
                        classNamePrefix="react-select"
                        value={
                            field.value
                                ? options.filter((option) =>
                                    field.value.includes(option?.value)
                                )
                                : []
                        }
                        onChange={(value) =>
                            field.onChange(
                                value ? value.map((item) => item.value) : []
                            )
                        }
                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                borderColor: state.isFocused ? '#6366F1' : '#A5B4FC',
                                '&:hover': {
                                    borderColor: '#6366F1',
                                }
                            }),
                        }}
                    />
                </FormControl>
                <FormMessage />
            </FormItem>
        )}
    />
);


const renderTable = (data, showTotal = true) => (
    <div className=' tw-rounded tw-shadow '  >
        <Table parentClassname="tw-w-full  tw-border-b-gray-300 tw-max-h-[45dvh] tw-overflow-auto ">
            <TableHeader className='tw-sticky tw-top-0 tw-z-[1000] tw-border-b-gray-300'  >
                <TableRow className="tw-bg-gray-100">
                <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        srno
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        unique_id
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        voltage_rn
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        voltage_yn
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        voltage_bn
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        current_ir
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        current_iy
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        current_ib
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        active_power
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        pf
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        apparent_power
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        total_energy
                    </TableHead>
                    <TableHead className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                        frequency
                    </TableHead>
                </TableRow>
            </TableHeader>
            <TableBody>
                {data.map((row, index) => (
                    <TableRow key={index} className='tw-border-b-gray-300' >
                        <TableCell className="tw-text-sm tw-w-[100px] tw-p-3">{index + 1}</TableCell>
                        {/* <TableCell className="tw-text-sm tw-w-[100px] tw-p-3">{row?.imei}</TableCell> */}
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.unique_id}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.voltage_rn?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.voltage_yn?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.voltage_bn?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.current_ir?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.current_iy?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.current_ib?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.active_power?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.pf?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.apparent_power?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.total_energy?.toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-text-xs tw-font-[700] tw-text-gray-600 tw-uppercase">
                            {row?.frequency?.toFixed(2)}
                        </TableCell>

                    </TableRow>
                ))}

                {/* // for total col */}
                {showTotal && (
                    <TableRow className='tw-border-t-gray-300 tw-sticky tw-bottom-0 tw-z-[1000] tw-bg-white  ' >
                        <TableCell colSpan={2} className="tw-font-semibold tw-text-center tw-p-3 ">
                            Total
                        </TableCell>

                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.voltage_rn),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.voltage_yn),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.voltage_bn),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.current_ir),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.current_iy),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.current_ib),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.active_power),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.pf),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.apparent_power),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.total_energy),0).toFixed(2)}
                        </TableCell>
                        <TableCell className="tw-font-semibold tw-p-3">
                        {data.reduce((acc,obj)=>(acc+=obj.frequency),0).toFixed(2)}
                        </TableCell>
                    </TableRow>
                )}
            </TableBody>
        </Table>
    </div>

)
import React, { version } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Lock } from 'react-feather'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { activeandinactive,  getAllMeter,  getAllModem,  getScriptdecodeId, getslave_all_register_profile, getSlaveAllRegisterProfileBySlaveId, getUsersList,  InsertSlaveScript ,UpdateSlaveScript } from '../../Common/Utils'
import { toast } from 'react-toastify'
import { AttachmanyUrl, BootstrapTooltip, MeterUrl } from '../../Common/common'
import swal from "sweetalert2";
import Select from 'react-select'
import moment from 'moment'
import fileDownload from 'js-file-download'
import { Modal } from 'antd'
import axios from 'axios'
import DraggabalTable from '../../Common/DraggabalTable'
window.Swal = swal;
export default function AddSlaveScript() {

    const [ScriptIdData, setScriptIdData] = useState([])
    const [UserList, setUserList] = useState([])
    const [loginErrors, setValidation] = useState({});
    const [flagBtn, setflegBtn] = useState(false);
    const [ModemId, setModemId] = useState('')
    const [disable, setDisable] = useState(true)
    const [status, setStatus] = useState(null)
    const [UrlImg, setUrlImg] = useState('')
    const [ChangesJson, setChangesJson] = useState({})

    const [inputs, setInputs] = useState({ profile_name: '', user_id: [], scriptdecode_id: '', script_json: [],FC:'',modemId:'',meterId:'' })
    const [JsonSelect, setJsonSelect] = useState({})
    const history = useNavigate();
    const userInfo = JSON.parse(window.localStorage.getItem("user_info") || {})
    const [Attachment, setAttachment] = useState([]);
    const [isModalImage, setisModalImage] = useState(false)
    const [imageName, setImageName] = useState('')
    const [TableKey, setTableKey] = useState('dailydata')
    const [AttachLoader, setAttachLoader] = useState(false)
    const [VersionId,setVersionId]=useState('')
    const [Modem,setModem]=useState([])
    const [Meter,setMeter]=useState([])
    const [RegiterProfile,setRegiterProfile]=useState([])
    const locations = useLocation()
    useEffect(() => {
        if (locations?.state) {

            setInputs({ profile_name: locations?.state?.profile_name, user_id: JSON.parse(locations?.state?.user_id||'[]'), scriptdecode_id: locations?.state?.scriptdecode_id, script_json: JSON.parse(locations?.state?.script_json||'[]'),FC:JSON.parse(locations?.state?.script_json||'[]')[0][1] ,modemId:locations?.state?.scriptdecode_id.split('_')[0],meterId:locations?.state?.scriptdecode_id.split('_')[1]})
            setModemId(locations?.state?.unique_id)
            setDisable(false)
            setStatus(locations?.state?.status)
             const updateSlaveRegister=async()=>{
          await  getSlaveAddressInfo(locations?.state?.scriptdecode_id.split('_')[1])
          setVersionId(locations?.state?.version_id)
            }
            updateSlaveRegister()

        }
    }, [locations]);

    const onHandaleSelection = async () => {
        const modem_data=await getAllModem()
        if(modem_data.success===true){
            setModem(modem_data.data.data)
            const data1=await getAllMeter()
        if(data1.success===true){
            setMeter(data1.data.data)
        }
        }else{
          console.log('somthing want wrong')
        }
        const data = await getScriptdecodeId()
        if (data.success === true) {
            setScriptIdData(data.data.data)
            console.log(locations?.state)
            
            
        } else {
            console.log('somthing want wrong')
        }
    }




    useEffect(() => {
        onHandaleSelection()
    }, []);

    const validation = (name, value) => {
        switch (name) {
            case 'profile_name':
                if (!value) {
                    return 'Please input Profile Name'
                } else {
                    return '';
                }
            case 'modemId':
                if (!value) {
                    return 'Please Select Modem Type'
                } else {
                    return '';
                }
            case 'meterId':
                if (!value) {
                return 'Please Select Meter Type'
                } else {
                 return '';
                }
            case 'script_json':
                if (value.length==0) {
                    return 'Please Enter Script Json'
                } else {
                    return '';
                }
            default: {
                return null;
            }
        }
    }

    const getSlaveAddressInfo=async(slave_id,version_id)=>{
        const data=await getslave_all_register_profile({slave_type:slave_id})
        if(data.success==true){
            setRegiterProfile(data?.data?.data)
            
        }else{

        }
    }

    useEffect(() => {
        if(inputs?.modemId&&inputs?.meterId&&ScriptIdData.length>0){
            if(Meter.filter(item => (item.METER_UNIQUE_ID == inputs.meterId)).length>0){
                setInputs({...inputs,scriptdecode_id:inputs.modemId+'_'+inputs.meterId})
                getSlaveAddressInfo(inputs?.meterId)
            }else{
                toast.error('Scriptdecode Id Not Found')
            }
        }
      }, [inputs?.modemId,inputs?.meterId,ScriptIdData]);

      useEffect(()=>{
        setJsonSelect(JSON.parse(RegiterProfile.filter((item)=>item?.unique_id==VersionId)[0]?.register_json||'{}'))
      },[VersionId])

    const onHandaleSubmitData = async (e) => {
        setflegBtn(true)
        let allErrors = {};
        Object.keys(inputs).forEach(key => {
            const error = validation(key, inputs[key]);
            if (error && error.length) {
                allErrors[key] = error
            }
        });

        if(!inputs?.scriptdecode_id){
            toast.error('Scriptdecode Id Not Found')
        }

        if (Object.keys(allErrors).length) {
            setflegBtn(false)
            return setValidation(allErrors)
        } else {
            if (ModemId === '') {
                const data=await InsertSlaveScript({ ...inputs,VersionId:VersionId, ChangesJson: JSON.stringify(ChangesJson),add_by:userInfo?.unique_id })
                if (data.success === true) {
                    toast.success('Add Meter Success')
                    setflegBtn(false)
                    history('/Slave-Script-profile')
                } else {
                    toast.error('Something Wait Wrong')
                    setflegBtn(false)
                }
            } else {
                const data2 = await UpdateSlaveScript({ ...inputs,VersionId:VersionId, ChangesJson: JSON.stringify(ChangesJson),add_by:userInfo?.unique_id }, ModemId)
                if (data2.success === true) {
                    toast.success('Update Meter Success')
                    setflegBtn(false)
                    history('/Slave-Script-profile')
                } else {
                    toast.error('Something Wait Wrong')
                    setflegBtn(false)

                }

            }
        }
    }
    const columns = [{
        Header: "Id",
        accessor: "Order"
    }, {
        Header: "Database Column",
        accessor: "Column"
    }, {
        Header: "Header Column",
        accessor: "Header"
    }]
    const oninactive = async () => {
        swal.fire({
            title: "Are You Sure InActive Meter?",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await activeandinactive({ tableName: 'gsm_slave_index', colName: 'meter_status', value: '0', matchId: 'meter_unique_id' }, ModemId)
                if (data.success === true) {
                    toast.success('Success Inactive Meter')
                    history('/Slave-Script-profile')
                } else {
                    toast.error('something want wrong')
                }
            }
        })
    }
    const onactive = async () => {
        swal.fire({
            title: "Are You Sure Meter?",
            // text: "Once deleted, you will not be able to recover this User Details!",
            icon: "warning",
            showCancelButton: true,
        }).then(async (willDelete) => {
            if (willDelete.isConfirmed) {
                const data = await activeandinactive({ tableName: 'gsm_slave_index', colName: 'meter_status', value: '1', matchId: 'meter_unique_id' }, ModemId)
                if (data.success === true) {
                    toast.success('Success Active Meter')
                    history('/Slave-Script-profile')
                } else {
                    toast.error('something want wrong')
                }
            }
        })
    }

    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    var imageExtensions = /(\.mp4|\.webm|\.ogg|\.mkv|\.mpg|\.m4v|\.m4p |\.mpv|\.vob|\.mpeg)$/i;

    const onAddScriptData = async () => {
        setInputs({ ...inputs, script_json: [...inputs.script_json, []] })
    }

    const onDeleteEntryLight = (id) => {
        const script_data = inputs?.script_json?.filter((item, index) => (index !== id))
        setInputs({ ...inputs, script_json: script_data })
    }
    return (
        <>
            <Modal title="Image" visible={isModalImage} footer='' onCancel={() => (setisModalImage(false))} width={800}>
                {allowedExtensions.exec(imageName) ?
                    <img src={`${UrlImg}`} height={500} width={"100%"}></img> : imageExtensions.exec(imageName) ? <video height={500} width={"100%"} controls><source src={`${UrlImg}`} type="video/mp4" /><source src={`${UrlImg}`} type="video/ogg" /></video> : ''
                }
            </Modal>
            <div className="main-content">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/all-dashboard">
                                <i className="fas fa-tachometer-alt"></i> Home
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link >
                                <Lock className='header-logo' style={{ height: "16px" }} /> Admin
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to='/Slave-Script-profile'>
                                Slave Script Profile
                            </Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link >{ModemId === '' ? 'Add Slave Script Profile' : 'Update Slave Script Profile'}</Link>
                        </li>
                    </ol>
                </nav>
                <section class="section">
                    <div class="section-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="card">
                                    <div class="card-header">
                                        <h4>{ModemId === '' ? 'Add Slave Script Profile' : 'Update Slave Script Profile'}</h4>
                                        <div class="card-header-action">
                                            {ModemId !== '' ? <div class="btn btn-info text-white btnedit mr-2" onClick={() => (setDisable(true))}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div> : ''}
                                            {ModemId !== '' && status == 1 ? <div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={oninactive}><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-ban  text-white"></i></span></BootstrapTooltip></div> : ''}
                                            {ModemId !== '' && status == 0 ? <div class="btn btn-success text-white btndeactive defauldbtn mr-2" onClick={onactive}><BootstrapTooltip title='Active'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="fas fa-check  text-white"></i></span></BootstrapTooltip></div> : ''}
                                            <Link to="/Slave-Script-profile" class="btn btn-info text-white align-left">
                                                Back
                                            </Link>
                                        </div>
                                    </div>
                                    <div class="card-body">

                                        <div class="form-row" style={{ marginLeft: "10px" }}>
                                        <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Modem Profile: <span class="text-danger">*</span></label>
                                            <select class="form-control form-select modemtypename anothermodemname" required="" name="modem_profile_id" id="modem_profile_id" onChange={(e)=>(setInputs({...inputs,modemId:e.target.value}))} value={inputs.modemId} disabled={!disable}>
                                                <option value="">Select Modem</option>
                                                {Modem&&Modem.map((item)=>(<option value={item?.MODEM_UNIQUE_ID}>{item?.MODEM_PROFILE_NAME}</option>))}       
                                             </select>
                                             <span className='text-danger'>{loginErrors?.modemId}</span>
                                        </div>
                                        <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Meter Profile : <span class="text-danger">*</span></label>
                                            <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e)=>(setInputs({...inputs,meterId:e.target.value}))} value={inputs.meterId} disabled={!disable}>
                                                <option value="">Select Meter</option>
                                                {Meter&&Meter.map((item)=>(<option value={item?.METER_UNIQUE_ID}>{item?.METER_PROFILE_NAME}</option>))} 
                                                                                                    
                                                                                            </select>
                                               <span className='text-danger'>{loginErrors?.meterId}</span>
                                        </div>
                                            {/* <div class="form-group col-md-3">
                                                <label for="inputPassword4">Script Decode Id:<span class="text-danger">*</span></label>
                                                <select class="form-control form-select" name="modemtype" id="modemtype" onChange={(e) => {
                                                    setInputs({ ...inputs, scriptdecode_id: e.target.value, script_json: (e.target.value ? [[]] : []) })
                                                    setJsonSelect(JSON.parse(ScriptIdData.filter(item => item.NAME == e.target.value)[0]?.slave_address_info || '{}'))
                                                }} value={inputs.scriptdecode_id} disabled={!disable}>
                                                    <option value="">Select Script Decode Id</option>
                                                    {ScriptIdData && ScriptIdData.map((item) => (<option value={item.NAME}>{item?.NAME}</option>))}
                                                </select>
                                                <span className='text-danger'>{loginErrors?.scriptdecode_id}</span>
                                            </div> */}

                                            {/* <div class="form-group col-md-3">
                                                <label for="inputPassword4">Users:<span class="text-danger">*</span></label>
                                                <Select name='account' isMulti options={UserList} onChange={(e) => (setInputs({ ...inputs, user_id: e.map((item) => (item.value)) }))} value={UserList.filter((item) => inputs?.user_id?.includes(item?.value))} isDisabled={!disable} />


                                                <span className='text-danger'>{loginErrors?.user_id}</span>

                                            </div> */}
                                            <div class="form-group col-md-3" id="grpeui">
                                                <label for="inputPassword4">Profile Name: <span class="text-danger">*</span></label>
                                                <input class="form-control" name="modemname" id="modemname" type="text" placeholder="Enter Profile Name" required="" onChange={(e) => (setInputs({ ...inputs, profile_name: e.target.value }))} value={inputs.profile_name} disabled={!disable} />
                                                <span className='text-danger'>{loginErrors?.profile_name}</span>
                                            </div>
                                            
                                            <div class="form-group col-md-3" id="grpeui">
                                            <label for="inputPassword4">Version : <span class="text-danger">*</span></label>
                                            <select class="form-control form-select second_meter_type" required="" name="meter_profile_id" id="meter_profile_id" onChange={(e)=>{setVersionId(e.target.value)
                                     setJsonSelect(JSON.parse(RegiterProfile.filter((item)=>item?.unique_id==e.target.value)[0]?.register_json||'{}'))

                                            }} value={VersionId} disabled={!disable}>
                                                <option value="">Select Version</option>
                                                {RegiterProfile&&RegiterProfile.map((item)=>(<option value={item?.unique_id}>{item?.version}</option>))} 
                                                                                                    
                                                                                            </select>
                                               <span className='text-danger'>{loginErrors?.meterId}</span>
                                        </div>
                                        </div>
                                        <div className="table-responsive  tw-max-h-screen">
                                            <div id="table-1_wrapper" class="dataTables_wrapper no-footer">
                                                <table id="table_id" className="table text-center table-borderless table-striped font-12">
                                                    <thead className='tw-sticky tw-top-0 tw-bg-gray-100'>
                                                        <tr className="tw-border-b">
                                                            <th style={{ width: '100px' }}>No.</th>
                                                            <th style={{ width: '200px' }}>Function Code</th>
                                                            <th style={{ width: '200px' }}>Start Address</th>
                                                            <th style={{ width: '200px' }}>End Address</th>
                                                            <th style={{ width: '200px' }}>Start Adress</th>
                                                            <th style={{ width: '200px' }}>Size</th>
                                                            <th style={{ width: '200px' }}>Info</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody >
                                                        {inputs?.script_json?.map((item, id) => (
                                                            <tr>
                                                                <td><h6 className="d-flex tw-justify-center tw-items-center" id="strdate">
                                                                    {id + 1} .
                                                                </h6></td>
                                                                <td >
                                                <select class="form-control form-select" name="modemtype" id="modemtype" onChange={(e) => {
                                                    var data = inputs.script_json || []
                                                    data[id][1]=Number(e.target.value)
                                                    setInputs({ ...inputs, FC: e.target.value, script_json: data })
                                                     }} value={inputs.script_json[id][1]} disabled={!disable}>
                                                    <option value="">Select Function Code</option>
                                                    {Object.keys(JsonSelect).length &&
                                                    Object.keys(JsonSelect).map((item) => <option value={item}>{item}</option>)}
                                                </select>
                                                </td>
                                                                <td className=' tw-items-center tw-justify-center'><select
                                                                    class="form-select form-control "
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => {
                                                                        var data = inputs.script_json || []
                                                                        data[id][0] = e.target.value + '-' + e.target.value
                                                                        data[id][2] = Number(e.target.value)
                                                                        data[id][3] = JsonSelect[data[id][1]][e.target.value]["Size"]
                                                                        setInputs({ ...inputs, script_json: data })
                                                                    }}
                                                                    style={{ width: '200px' }}
                                                                    value={inputs?.script_json[id][0]?.split('-')[0] || ''}
                                                                    disabled={!disable}
                                                                >
                                                                    <option value={''}>Select Start Address</option>
                                                                    {Object.keys(JsonSelect[inputs.script_json[id][1]]||{}).length &&
                                                                        Object.keys(JsonSelect[inputs.script_json[id][1]]||{}).map((item) => <option value={item}>{item}</option>)}
                                                                </select></td>
                                                                <td className='tw-items-center tw-justify-center'><select
                                                                    class="form-select form-control"
                                                                    aria-label="Default select example"
                                                                    onChange={(e) => {
                                                                        var data = inputs.script_json || []
                                                                        var size_total = 0
                                                                        data[id][0] = data[id][0].split('-')[0] + '-' + e.target.value
                                                                        // console.log(e.target.value)
                                                                        for (let address_size = data[id][0].split('-')[0]; address_size <= Number(e.target.value);) {
                                                                            size_total += Number(JsonSelect[inputs.script_json[id][1]][address_size]?.Size||0)
                                                                            
                                                                            console.log(address_size)
                                                                            ++address_size
                                                                        }
                                                                        data[id][3] = size_total
                                                                        setInputs({ ...inputs, script_json: data })
                                                                    }}
                                                                    style={{ width: '200px' }}
                                                                    value={inputs.script_json[id][0]?.split('-')[1] || ''}
                                                                    disabled={!disable}
                                                                >
                                                                    <option value={''}>Select End Address</option>
                                                                    {Object.keys(JsonSelect[inputs.script_json[id][1]]||{}).length &&
                                                                        Object.keys(JsonSelect[inputs.script_json[id][1]]||{}).map((item) => <option value={item}>{item}</option>)}
                                                                </select></td>

                                                                
                                                                <td>
                                                                    {inputs.script_json[id][2] }
                                                                </td>
                                                                <td>
                                                                    {inputs.script_json[id][3]}
                                                                </td>
                                                                <td>
                                                                    {id !== 0 ? <div class="btn btn-danger text-white btndeactive defauldbtn mr-2" onClick={() => onDeleteEntryLight(id)} ><BootstrapTooltip title='Inactive'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Inactive"><i class="far fa-trash-alt text-white"></i></span></BootstrapTooltip></div> : ''}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>


                                        <div className='d-flex tw-justify-end'>
                                            <button type="button" className="btn btn-primary mr-1" onClick={() => (onAddScriptData())}>
                                                <div className='tw-flex tw-items-center tw-gap-1 '>
                                                    <i class="fas fa-plus" aria-hidden="true"></i>
                                                    <span>
                                                        New
                                                    </span>
                                                </div>
                                            </button>
                                        </div>

                                        <p style={{ marginLeft: "25px" }} class="text-danger">Note : * Indicates Mandatory Fields</p>
                                        <div class="text-left">
                                           {disable==true?<input type="submit" name="submit" class={`btn btn-danger ${flagBtn ? 'btn-progress disabled' : ''} ml-4 mr-3`} value={ModemId === '' ? 'Submit' : 'Update'} id="submitvn" onClick={onHandaleSubmitData} />:''}
                                            <Link class="btn btn-light text-dark" to="/Slave-Script-profile">Cancel</Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

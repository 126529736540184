import { DatePicker, Modal, Spin } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import Pagination from '../../../../Common/Pagination';
import { geModemIdWiseColumnName, getAccountWiseModemTableViewCoumnName, getHeardbeatData, getScriptCodeIdWiseColumnName, getStartAndEndDateByTable, reportCreacteInTableName } from '../../../../Common/Utils';
import { BootstrapTooltip, exportCSVFile, exportExcelDownload } from '../../../../Common/common';
import { toast } from 'react-toastify';

export default function TimeSync(props) {

    const [PageSize, setPageSize] = useState(50);
	const [numberofPage, setnumberofPage] = useState(1);
	const [currentPage, setCurrentPage] = useState(1);
	const [loader, setLoader] = useState(false);
	const [heartData, setHeartData] = useState([])
    const [ColumnName,setColumnName]=useState({})
	const [sortField,setsortField]=useState('U_SR_NO')
	const [sortDirection,setsortDirection]=useState('desc')
	const [UI_LOOKUP,setUI_LOOKUP]=useState({})
	const [StatusCode,setStatusCode]=useState('')

    const ongetHeardbeatData = async () => {
		setLoader(true)
		const firstPageIndex = (currentPage - 1) * PageSize;
		const lastPageIndex = firstPageIndex + PageSize;
		const data = await getHeardbeatData({ start: firstPageIndex, end: PageSize }, { tableName: props?.GSMdata?.unique_table_name?.timesync_alert,changeCol:true,colName:sortField,sortDirection:sortDirection })
		if (data.success === true) {
			setHeartData(data.data.data)
			setnumberofPage(data.data.total)
			setLoader(false)
		} else {
			console.log('something want wrong')
			setLoader(false)
			setStatusCode(data?.err?.response?.status)

		}
	}

	useEffect(()=>{
		ongetHeardbeatData()
	},[sortField,sortDirection])
	const ongetScriptCodeIdWiseColumnName=async()=>{
		const data1 = await getAccountWiseModemTableViewCoumnName({ tableName: `${props?.GSMdata?.cat0_id}_modem_table_view`, modem_id: props?.GSMdata?.modem_type })
		if(data1.success==true){
			if(data1.data.data.length>0){
				setColumnName(JSON.parse(data1.data.data[0].column_json||'{}'))
			}else{
		const data=await geModemIdWiseColumnName(props?.GSMdata?.modem_type)
        if(data.success===true){
            if(data?.data?.data?.length>0){
				var modem_table_json=data?.data?.data[0]?.COLUMN_NAME
				var column_name={}
            Object.keys(JSON.parse(modem_table_json||'{}')).map((key)=>{
                    column_name[key]= [...(JSON.parse(modem_table_json||'{}')[key]||[]).map((item)=>({...item,u_name:item?.Header}))]
            })
            setColumnName(column_name)
            setUI_LOOKUP(JSON.parse(data?.data?.data[0]?.UI_LOOKUP||'{}'))
            }
        }else{
console.log('something want wrong');
        }
	}
	}else{
		console.log('something want wrong');
				}
    }
    useEffect(()=>{
        ongetHeardbeatData()
		ongetScriptCodeIdWiseColumnName()
    },[])
	useEffect(() => {
		ongetHeardbeatData()
}, [currentPage, PageSize])

const [ReportLoader,setReportLoader]=useState(false)
const [ExpireDate,setExpireDate]=useState({})
	 const [ismodel, setModel] = useState(false)
	 const [reportType, setReportType] = useState('')
	 const [report, setReport] = useState({ value: '', name: '',start_date:'',end_date:'' })
	 const ReportType=[{label:'View Table Info',value:'View_Table_Info'},{label:'All Table Info',value:'All_Table_Info'},{label:'Full Table',value:'Full_Table'}]
	 const ongetStartAndEndDateByTable = async (tableName, col) => {
		 console.log("Wanted to check if this is calling or not")
		 document.body.style.cursor = 'want'
		 const data = await getStartAndEndDateByTable({ tableName: props?.GSMdata?.unique_table_name?.timesync_alert, col: 'scr_ts_entry' })
		 if (data.success === true) {
 
			 console.log("Expiry Dates == ", data.data)
			 setExpireDate(data.data)
			 setModel(true)
			 document.body.style.cursor = 'default'
		 } else {
			 console.log("something want wrong")
			 document.body.style.cursor = 'default'
		 }
	 }
	 const getreportCreacteInTableName=async(Iscsv)=>{
		 if(report?.value!=''&&reportType!=''){
			if(report?.value!='Full_Table'){
				if(report?.start_date&&report?.end_date){
					const x = new Date(moment(report.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
		const y = new Date(moment(report.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'));
		console.log(x>y,x,y);
					if(x>y){
					return	toast.warn('"Start Date" Must be Less than or Equal to "End Date"')
					}
				}else{
					return	toast.warn('Please Select "Start Date" And "End Date"')
				}
			}
			setReportLoader(true)
			 const data=await reportCreacteInTableName({tableName:props?.GSMdata?.unique_table_name?.timesync_alert,start_date:moment(report?.start_date,'DD-MM-YYYY').format('YYYY-MM-DD'),end_date:moment(report?.end_date,'DD-MM-YYYY').format('YYYY-MM-DD'),col:'scr_ts_entry',IsCondition:report?.value=='Full_Table'?false:true})
			 if(data.success==true){
				 var arrayofData=[]
				 var headers = {}
				 if(data.data.data.length==0){
					setReportLoader(false)
					toast.warn('Data Not Found')
						}
			if(report?.value=='view_table_info'){
			data.data.data.map((item,id)=>{
					arrayofData[id]={}
					 ColumnName?.timesync_alert?.length>0&&ColumnName?.timesync_alert
						?.map((col)=>{
						if(col?.Display==true){
							headers[col.Column]=col.u_name
							if(col?.DataType=='DATETIME')
							{
								arrayofData[id][col.u_name]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
							}else{
								if(col?.Float==true){
									if(col?.Division==true){
										if(col?.MF==true&&props?.GSMdata?.MF)	{
											arrayofData[id][col.u_name]=(item[col.Column]*props?.GSMdata?.MF/col?.DivisionNumber).toFixed(col?.FloatPoint)
										}else{
											arrayofData[id][col.u_name]=(item[col.Column]/col?.DivisionNumber).toFixed(col?.FloatPoint)
										}
									}else{
										if(col?.MF==true&&props?.GSMdata?.MF){
											arrayofData[id][col.u_name]=(item[col.Column]*props?.GSMdata?.MF).toFixed(col?.FloatPoint)
										}else{
											arrayofData[id][col.u_name]=(item[col.Column]).toFixed(col?.FloatPoint)
										}
									}
								
								}else{
									if(col?.MF==true&&props?.GSMdata?.MF){
										arrayofData[id][col.u_name]=item[col.Column]*props?.GSMdata?.MF
									}else{
										if(col?.Division==true){
											if(col?.MF==true&&props?.GSMdata?.MF){
												arrayofData[id][col.u_name]=(item[col.Column]*props?.GSMdata?.MF)/col?.DivisionNumber
											}else{
												arrayofData[id][col.u_name]=item[col.Column]/col?.DivisionNumber
											}
										}else{
											arrayofData[id][col.u_name]=item[col.Column]
										}
									}
								}
							}
						}
						
					})
					if(id==(data.data.data.length-1)){
					console.log(id);

						if(reportType==='csv'){
							exportCSVFile(headers,arrayofData,`ONLINE_OFFLINEt_${props?.GSMdata?.UNIQUE_ID}`)
					setReportLoader(false)

						//  setCsvData(data.data.data)
						//  csvLink.current.link.click();
						 }else{
							exportExcelDownload(arrayofData,`ONLINE_OFFLINE_${props?.GSMdata?.UNIQUE_ID}`)
					setReportLoader(false)

						 }
					}
				})
			}else if(report?.value=='All_table_info'||report?.value=='Full_Table'){
				data.data.data.map((item,id)=>{
					arrayofData[id]={}
					 ColumnName?.timesync_alert?.length>0&&ColumnName?.timesync_alert
						?.map((col)=>{
							headers[col.Column]=col.u_name
								headers[col.Column]=col.u_name
								if(col?.DataType=='DATETIME')
								{
									arrayofData[id][col.u_name]=item[col.Column]?moment.utc(item[col.Column]).format("DD-MM-YYYY hh:mm:ss A"):''
								}else{
									if(col?.Float==true){
										if(col?.Division==true){
											if(col?.MF==true&&props?.GSMdata?.MF)	{
												arrayofData[id][col.u_name]=(item[col.Column]*props?.GSMdata?.MF/col?.DivisionNumber).toFixed(col?.FloatPoint)
											}else{
												arrayofData[id][col.u_name]=(item[col.Column]/col?.DivisionNumber).toFixed(col?.FloatPoint)
											}
										}else{
											if(col?.MF==true&&props?.GSMdata?.MF){
												arrayofData[id][col.u_name]=(item[col.Column]*props?.GSMdata?.MF).toFixed(col?.FloatPoint)
											}else{
												arrayofData[id][col.u_name]=(item[col.Column]).toFixed(col?.FloatPoint)
											}
										}
									
									}else{
										if(col?.MF==true&&props?.GSMdata?.MF){
											arrayofData[id][col.u_name]=item[col.Column]*props?.GSMdata?.MF
										}else{
											if(col?.Division==true){
												if(col?.MF==true&&props?.GSMdata?.MF){
													arrayofData[id][col.u_name]=(item[col.Column]*props?.GSMdata?.MF)/col?.DivisionNumber
												}else{
													arrayofData[id][col.u_name]=item[col.Column]/col?.DivisionNumber
												}
											}else{
												arrayofData[id][col.u_name]=item[col.Column]
											}
										}
									}
								}
							
					})

					if(id==(data.data.data.length-1)){
						if(reportType==='csv'){
							exportCSVFile(headers,arrayofData,`TIMESYNC_ALERT_${props?.GSMdata?.UNIQUE_ID}`)
					setReportLoader(false)

						//  setCsvData(data.data.data)
						//  csvLink.current.link.click();
						 }else{
							exportExcelDownload(arrayofData,`TIMESYNC_ALERT_${props?.GSMdata?.UNIQUE_ID}`)
					setReportLoader(false)

						 }
					}
				})
			}
			
			
		}else{
			console.log('Something Went Wrong')
			setReportLoader(false)
		}
	   }else{
		   return	toast.warn('Please Enter Mandatory Fields')
	   }
	 }

	 const handleCancelReport = () => {
		setModel(false);
	};

	const onHandalReport = (e,name) => {
		setReport({ value: e.target.value, name: name })
	}

	const onSort = (field) => {
        if (sortField === field) {
            setsortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setsortField(field);
            setsortDirection('asc');
        }
    };

	const [isModalVisible, setIsModalVisible] = useState(false);
	const [AllData, setAllData] = useState({})
	const [rowData, setRowData] = useState({})
    const [ErrorData, setErrorData] = useState({})
    const [DupData, setDupData] = useState({})
    const [isModalError, setIsModalError] = useState(false)
    const [isModalDup, setIsModalDup] = useState(false)
    const [isModalrow, setIsModalRow] = useState(false)
	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const onAllShowData = (item) => {
		setIsModalVisible(true)
		setAllData(item)
	}
	const onRowDate = (row) => {
        setRowData(row)
        setIsModalRow(true)
    }
    const onDuplicate = (dupli) => {
        setDupData(dupli)
        setIsModalDup(true)
    }
    const onError = (err) => {
        setErrorData(err)
        setIsModalError(true)
    }
  return (
    <>
<Modal title="Error Data" visible={isModalError} footer='' onCancel={() => (setIsModalError(false))} width={1000}>
                {ErrorData}
            </Modal>
            <Modal title="Duplicate Data" visible={isModalDup} footer='' onCancel={() => (setIsModalDup(false))} width={1000}>
                {DupData}
            </Modal>
            <Modal title="Row Data" visible={isModalrow} footer='' onCancel={() => (setIsModalRow(false))} width={1000}>
                {rowData}
            </Modal>
<Modal title="All Data" visible={isModalVisible} onOk={() => (setIsModalVisible(false))} footer='' onCancel={handleCancel} width={1000}>
				<div class="table-responsive">
					<table class="table table-striped" id="tabledata" style={{ width: "100%" }}>
						<thead>
							<tr>
								<th style={{ width: "50%" }}>Data</th>
								<th style={{ width: "50%" }}>Value</th>
							</tr>
						</thead>
						<tbody>
						{ ColumnName?.timesync_alert?.map((col)=>(col?.DataType=='DATETIME'?<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={{ width: "50%" }}>{AllData[col?.Column]?moment.utc(AllData[col?.Column]).format("DD-MM-YYYY hh:mm:ss A"):''}</td>
							</tr>:<tr>
								<td style={{ width: "50%" }}>{col?.Column}</td>
								<td style={col?.Column=='UNITS'||col?.Column=='ERRORBIN'||col?.Column=='RAWDATA'?{ maxWidth: '500px'}:{ width: "50%" }}>{AllData[col?.Column]}</td>
							</tr>))}
						</tbody>
					</table>
				</div>

			</Modal>
	<Modal title="Generate Reports" visible={ismodel} footer='' onCancel={handleCancelReport} width={1500}>
				<section className="section">
					<div className="section-body">
						<div className="row">
							<div className="col-12">
									<div className="form-row" style={{ marginLeft: "10px" }}>
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4 ">Report Type :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reporttype" required="required" onChange={onHandalReport}>
												<option value="">Report Type</option>
												{ReportType?.map((item) => (<option value={item?.value}>{item?.label}</option>))}
											</select>
										</div>
										{report?.value!=''&&report?.value!='Full_Table'?<><div className="form-group col-md-3" id="strdate">
											<label for="inputPassword4">Start Date :<span className='text-danger'>*</span> </label><br></br>
											<DatePicker onChange={(e, dateString) => setReport({ ...report, start_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
												disabledDate={(current) => {
													return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
												}} />
										</div>
											<div className="form-group col-md-3" id="enddate" >
												<label for="inputPassword4">End Date :<span className='text-danger'>*</span> </label><br></br>
												<DatePicker onChange={(e, dateString) => setReport({ ...report, end_date: dateString })} format='DD-MM-YYYY' name='start' style={{ width: '100%', height: '40px' }}
													disabledDate={(current) => {
														return (moment.utc(ExpireDate.start_date).add(-moment().utcOffset(), "m").add(-1, 'days') >= current) || (moment(ExpireDate.end_date).add(-moment().utcOffset(), "m").add(1, 'days') <= current);
													}} />
											</div></>:''}
										<div className="form-group col-md-3" id="grpeui">
											<label for="inputPassword4">Extension :<span className='text-danger'>*</span> </label>
											<select className="form-control form-select" name="reportextension" onChange={(e) => (setReportType(e.target.value))} value={reportType}>
												<option value="">Select Extension</option>
												<option value="xls">XLS</option>
												<option value="csv">CSV</option>
											</select>
										</div>
									</div>
									<p style={{ marginLeft: "25px" }} className="text-danger">Note : * Indicates Mandatory Fields</p>
									<div className="text-left">
										<input type="submit" name="submit" className={`btn btn-danger mr-2 ${ReportLoader==true?'disabled btn-progress':''}`} value="Generate" id="submitvn"  onClick={()=>getreportCreacteInTableName()}/>
										<a className="btn btn-light text-dark" onClick={handleCancelReport}>Cancel</a>
									</div>
							</div>
						</div>
					</div>
				</section>
			</Modal>
        <section className="card-diff-section">
				<div className="section-body">
					<div className="row">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h4><BootstrapTooltip title={`Table Name is ${props?.GSMdata?.unique_table_name?.timesync_alert}`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
									<div className="card-header-action">
										<div id="filtergrm">
											
											<a class="btn btn-info text-white mr-1" data-toggle="tooltip" data-placement="bottom" title="Refresh"  onClick={ongetHeardbeatData}>
												<i class="fa fa-refresh" aria-hidden="true"></i>
											</a>
											<button class="btn btn-info" onClick={()=>ongetStartAndEndDateByTable(true)}>
                      <i class="fa fa-download" aria-hidden="true"></i>
                      </button>
										</div>
									</div>
								</div>
								<div className="card-body">
									{loader && (
										<div className="example">
											<Spin size="large" />
										</div>
									)}
									{heartData.length === 0 && !loader ? StatusCode==503?<h4 className="text-center">Table Not Found</h4> :<h4 className="text-center">No Data Found</h4>:''}
									{heartData.length > 0 &&
										<div className="table-responsive tw-h-screen tw-max-h-screen">
											<div id="table-1_wrapper" class="dataTables_wrapper no-footer">
												
											<table id="table_id" className="table text-center table-borderless table-striped font-10">
                                                    <thead className='tw-sticky tw-z-50 tw-top-0 tw-bg-gray-100'>
														<tr className="tw-border-b">
														{ ColumnName?.timesync_alert?.length>0&&ColumnName?.timesync_alert?.map((col)=>(col?.Display==true?<BootstrapTooltip title={<>Table Column:{col?.Column}<br/>Fectory Default Name:{col?.Header}</>}><th onClick={()=>onSort(col?.Column)}><div className='tw-flex tw-items-center tw-justify-center'>
                                        <div >{col?.u_name}</div>
                                        <div>{sortField === col?.Column && (sortDirection === 'asc' ? ' ▲' : ' ▼')}</div>
                                    </div></th></BootstrapTooltip>:''))}
									<th>ACTION</th>

														</tr>
													</thead>
													<tbody >
														{heartData.length > 0 && heartData.map((item, id) => (
															
															<tr className="trheigth  ">
																{ ColumnName?.timesync_alert?.length>0&&ColumnName?.timesync_alert
															?.map((col)=>(col?.Display==true?col?.DataType=='DATETIME'?<td style={{ minWidth: "100px" }}>{item[col.Column]?moment.utc(item[col.Column]).format("DD/MM/YYYY hh:mm:ss A"):''}</td>:col.Column=='MMD_STATUS_ONLINE_OFFLINE'?<td >
															<div style={{fontSize:'10px'}} className={`badge ${item[col.Column] === 'online' ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item[col.Column] === 'online' ? 'Online' : 'Offline'}</div>
														</td>:col?.UI_LOOKUP&&UI_LOOKUP[col?.UI_LOOKUP]&&UI_LOOKUP[col?.UI_LOOKUP][item[col?.Column]]?.Name?<><BootstrapTooltip title={item[col?.Column]}><td>{UI_LOOKUP[col?.UI_LOOKUP]&&UI_LOOKUP[col?.UI_LOOKUP][item[col?.Column]]?.Name||item[col?.Column]}</td></BootstrapTooltip></>:<td style={col.Column=='UNITS'?{minWidth: '400px'}:col.Column=='ERRORBIN'?{ maxWidth: '400px'}:{}}>{item[col.Column]}</td>:''))}
                                                            
															{/* <td><div style={{fontSize:'10px'}} className={`badge ${item?.DEVICE_STATUS === 'online' ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{heartData[id+1]?.ENTRY_TS?moment.duration(moment(heartData[id+1]?.ENTRY_TS).diff(moment(heartData?.ENTRY_TS),'minutes')).minutes()+' minutes ago':''}</div></td> */}
															<td className="font-13"><a class="action-icon text-success" onClick={() => onAllShowData(item)}><span data-toggle="tooltip" data-placement="bottom" title="AllData Data"><i class="fa fa-database fa-sm" aria-hidden="true"></i></span></a>&nbsp;
															<a class="action-icon text-info" onClick={() => onRowDate(item?.SCR_INFO_RAWDATA)}><span data-toggle="tooltip" data-placement="bottom" title="Raw Data"><i class="fa fa-info-circle fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
															 <a class="action-icon text-danger" onClick={() => (onDuplicate(item?.SCR_INFO_DUPLICATEBIN))}><span data-toggle="tooltip" data-placement="bottom" title="Duplicate Data"><i class="fa fa-clone fa-lg font-13" aria-hidden="true"></i></span></a>&nbsp;
                                                                    <a class="action-icon text-dark" onClick={() => (onError(item?.SCR_INFO_ERRORBIN))}><span data-toggle="tooltip" data-placement="bottom" title="Error Data"><i class="fa fa-exclamation-triangle fa-lg font-13" aria-hidden="true"></i></span></a></td>
															</tr>
														))}
													</tbody>
												</table>
												<>
													<div className="d-flex justify-content-between">
														<div>Total Number Of Row {numberofPage}</div>
														<div class="dataTables_length" id="table_id_length">
													<label>
														Show{" "}
														<select
															name="table_id_length"
															aria-controls="table_id"
															class=""
															onChange={(e) => setPageSize(e.target.value)}
															value={PageSize}
														>
															<option value={10}>10</option>
															<option value={25}>25</option>
															<option value={50}>50</option>
															<option value={100}>100</option>
														</select>{" "}
														entries
													</label>
												</div>
														<Pagination
															className="pagination-bar"
															currentPage={currentPage}
															totalCount={numberofPage || 1}
															pageSize={PageSize}
															onPageChange={(page) => setCurrentPage(page)}
														/>
													</div>
												</>
											</div>
										</div>}

								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
    </>
  )
}

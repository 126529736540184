import chroma from 'chroma-js'
import React, { useState } from 'react'
import { Users } from 'react-feather'
import {Link} from 'react-router-dom'
import Reports from './Reports'
import SlaveScheduleReport from './SlaveScheduleReport'

export default function SlaveReportsIndex() {
	const btnColor = ['#4f5ece', '#f59e0b', '#fc544b', '#3abaf4', '#54ca68', '#191d21', '#7c5295', '#4f5ece']
    const [activeTab,setActiveTab]=useState('Dashboard')

  return (
    <>
   <div className="main-content">
<nav aria-label="breadcrumb">
					<ol className="breadcrumb ">
						<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link to='/All-GSM'>
								<Users style={{ height: "16px" }} /> Slave
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								Slave Report History
							</Link>
						</li>

					</ol>
				</nav>
    <section class="section">
					<div class="section-body">
						<div class="row">

							<div class="col-12 col-sm-12 col-lg-12 tw-p-0">
								<div class="card ">
									<div class="card-header d-flex tw-border-b-0 " style={{ justifyContent: 'space-between' }}>
										<ul class="nav nav-pills tw-gap-2" role="tablist" style={{ lineHeight: '20px', alignItems: 'center' }}>
											<li>
												<button style={{
													backgroundColor: activeTab === "Dashboard" ? chroma(btnColor[0]).alpha(0.4).css() : '',
													color: activeTab === "Dashboard" ? chroma(btnColor[0]).darken(1.5).css() : btnColor[0],
													borderBottom: `1px solid ${btnColor[0]}`,
													boxShadow: activeTab === "Dashboard" ? `0 2px 8px -3px ${btnColor[0]}` : '',
												}}
													className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Dashboard" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true" onClick={() => (setActiveTab('Dashboard'))}>Dashboard*</button>
											</li>
											<li>
												<button style={{
													backgroundColor: activeTab === "Report History List" ? chroma(btnColor[2]).alpha(0.4).css() : '',
													color: activeTab === "Report History List" ? chroma(btnColor[2]).darken(1.5).css() : btnColor[2],
													borderBottom: `1px solid ${btnColor[2]}`,
													boxShadow: activeTab === "Report History List" ? `0 2px 8px -3px ${btnColor[2]}` : '',
												}}
													className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Report History List" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => (setActiveTab('Report History List'))}>Generated Reports</button>
											</li>
											<li>
												<button style={{
													backgroundColor: activeTab === "Schedule Report" ? chroma(btnColor[3]).alpha(0.4).css() : '',
													color: activeTab === "Schedule Report" ? chroma(btnColor[3]).darken(1.5).css() : btnColor[3],
													borderBottom: `1px solid ${btnColor[3]}`,
													boxShadow: activeTab === "Schedule Report" ? `0 2px 8px -3px ${btnColor[3]}` : '',
												}}
													className={`tw-font-semibold tw-transition-all tw-bg-transparent tw-border-t-0 tw-border-r-0 tw-border-l-0 ml-2 tw-px-2 tw-mx-1 tw-my-1 tw-py-1 md:tw-px-3 md:tw-m-0 md:tw-py-2 focus:tw-outline-none ${activeTab === "Schedule Report" ? 'tw-rounded' : 'hover:tw-bg-gray-100 hover:tw-rounded'}`} id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false" onClick={() => (setActiveTab('Schedule Report'))}>Schedule Report</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
						
						{activeTab=='Report History List'&&<Reports/>}
						{activeTab=='Schedule Report'&&<SlaveScheduleReport/>}
							
						</div>
					</div>
				</section>
                </div>
    </>
  )
}


import React, { useEffect, useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import { Lock, Users } from 'react-feather'
import { Link, useNavigate } from 'react-router-dom'
import { BootstrapTooltip } from '../../Common/common'
import * as $ from "jquery";
import * as XLSX from 'xlsx';
import dt from "datatables.net-dt";
import jsPDF from "jspdf";
import { getmeter_category } from '../../Common/Utils'
import { Modal } from 'antd'
import moment from 'moment'
$.fn.DataTable = dt;
export default function Category() {
	const [ModemData, setModemData] = useState([])
	const [filterrolesData, setfilterRolesData] = useState([])
	const [loader, setLoader] = useState(false)
	const [roleFilter, setRoleFilter] = useState({ name: '', status: '' })
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [csvData, setCsvData] = useState([])

	const history = useNavigate()
	const ongetRolesUserData = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getmeter_category()
		if (data.success === true) {
			setModemData(data.data.data)
			setfilterRolesData(data.data.data)
		} else {
			console.log('something went wrong')
			setLoader(false)
		}
	}
	const csvLink = useRef();

	const downloadReport = async (isCsv) => {
		if (ModemData.length > 0) {
			const finalCsvData = await ModemData.map((item) => ({ 'SRNO': item.SRNO, 'Unique ID': item.UNIQUE_ID, 'Created Time': moment(item.CREATED_AT).format('DD-MM-YYYY'), 'Created By': item.CREATED_BY, 'Name': item.METER_CATEGORY_NAME, 'Status': (item.STATUS == 1 ? 'Active' : 'InActive') }))
			setCsvData(finalCsvData)
			if (isCsv === true) {
				if (finalCsvData.length > 0) {
					csvLink.current.link.click();
				}
			}
			else {
				if (finalCsvData.length > 0) {
					const worksheet = XLSX.utils.json_to_sheet(finalCsvData);
					const workbook = XLSX.utils.book_new();
					XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
					XLSX.writeFile(workbook, `Slave-category-data.xlsx`);
				}
			}
		}
	}

	useEffect(() => {
		ongetRolesUserData().then(() =>
			setTimeout(function () {
				$("#table-1").DataTable({ "pageLength": 50, order: [[0, 'desc']] });
				setLoader(false);

			}, 700)
		);
	}, []);


	const UpdateRolesUser = (item) => {
		history(`/add-metercategory`, { state: item })
	}

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const onhandleOk = () => {
		setIsModalVisible(false)
		if (roleFilter.name === '' && roleFilter.status === '') {
			return setModemData(filterrolesData)
		}
		const filteData = filterrolesData && filterrolesData.filter((item) => (roleFilter.name !== '' ? item?.METER_CATEGORY_NAME?.includes(roleFilter.name) : []) && (roleFilter.status !== '' ? item?.STATUS == roleFilter.status : []))
		setModemData(filteData)
	}
	return (
		<>
			<Modal title="Filter Slave Category" visible={isModalVisible} footer="" onCancel={handleCancel}>
				<div class="form-row">
					<div class="form-group col-md-6">
						<label for="inputState">Slave Category Name:</label>
						<input type="text" class="form-control" id="" onChange={(e) => (setRoleFilter({ ...roleFilter, name: e.target.value }))} value={roleFilter.name} name="FirstName" placeholder="Enter Slave Category Name" />
					</div>
					<div class="form-group col-md-6">
						<label for="inputPassword4">Slave Category Status:</label>
						<select
							id="inputState"
							class="form-select form-control"
							name="modem_name"
							onChange={(e) => (setRoleFilter({ ...roleFilter, status: e.target.value }))}
							value={roleFilter.status}
						>
							<option value="">Select Status</option>
							<option value={1}>Active</option>
							<option value={0}>InActive</option>
						</select>
					</div>
				</div>
				<div class="text-left mt-2">
					<input type="submit" class="btn btn-danger" value="Filter" name="filter_customer_form" onClick={onhandleOk} />
					<Link class="btn btn-light text-dark ml-2" to={`/metercategory`}>
						Reset
					</Link>
				</div>
			</Modal>
			<div className="">
				{loader ? (
					<div className="main-content">
						<div class="loader"></div>
					</div>
				) : (
					""
				)}
				
				<div className="main-content">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					<li className="breadcrumb-item">
							<Link to="/all-dashboard">
								<i className="fas fa-tachometer-alt"></i> Home
							</Link>
						</li>
						<li className="breadcrumb-item">
							<Link >
								<Lock style={{ height: "16px" }} />Admin
							</Link>
						</li>
						<li className="breadcrumb-item">
							Driver
						</li>
						<li className="breadcrumb-item">
							<Link to='/all-category'>
							Slave Category
							</Link>
						</li>
					</ol>
				</nav>
					<section className="section">
						<div className="section-body">
							<div className="row">
								<div className="col-12">
									<div className="card">
										<div className="card-header">
											<h4><span className='mr-2'>Slave Category</span><BootstrapTooltip title={`Table Name is gsm_slave_category`} placement='top'><span><i className='fas fa-info-circle info_circle'></i></span></BootstrapTooltip></h4>
											<div className="card-header-action">
												<Link to="/add-metercategory" className="btn btn-info text-white mr-2">
													<BootstrapTooltip title="Add">
														<span>
															<i
																className="fas fa-plus text-white"
																style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
															></i>
														</span>
													</BootstrapTooltip>
												</Link>
												<button type="button" className="btn btn-info mr-2" onClick={() => (setIsModalVisible(true))}>
													<BootstrapTooltip title="Filter">
														<span>
															<i className="fa fa-filter text-white"></i>
														</span>
													</BootstrapTooltip>
												</button>
												<div className="dropdown d-inline mr-2">
													<button
														className="btn btn-info dropdown-toggle mr-2"
														type="button"
														id="dropdownMenuButton3"
														data-toggle="dropdown"
														aria-haspopup="true"
														aria-expanded="false"
														data-placement="bottom"
														title="Download"
													>
														<i className="fa fa-download mr-1"></i>
													</button>
													<div className="dropdown-menu">
														<a className="dropdown-item" >
															PDF
														</a>
														<a onClick={() => downloadReport(false)} className="dropdown-item" >
															Excel
														</a>
														<a onClick={() => downloadReport(true)} className="dropdown-item">
															CSV
														</a>
														<CSVLink data={csvData} filename={"Slave-category-data.csv"} ref={csvLink} />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="row">
								{ModemData && ModemData.map((item) => (
									<div className="col-4">
										<div className="card">
											<div className="card-header" style={{ display: 'flex', justifyContent: 'space-between' }}>
												<div className='d-flex'>
													<h4>{item?.SRNO}.{item?.METER_CATEGORY_NAME}</h4>
													<div className={`badge ${item?.STATUS == 1 ? 'badge-success' : 'badge-danger'} badge-shadow text-white`}>{item?.STATUS == 1 ? 'Active' : 'Inactive'}</div>
												</div>
												<div className="card-header-action">
													<div class="btn btn-info text-white btnedit mr-2" onClick={() => UpdateRolesUser(item)}><BootstrapTooltip title='edit'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-edit  text-white"></i></span></BootstrapTooltip></div>
													<div class="btn btn-primary text-white btnedit " onClick={() => (history(`/all-slaves/${item?.UNIQUE_ID}`))}><BootstrapTooltip title='Slave List'><span data-toggle="tooltip" data-placement="bottom" title="" data-original-title="Edit" ><i class="fas fa-external-link-alt  text-white"></i></span></BootstrapTooltip></div>
												</div>
												

											</div>
										</div>
										
									</div>
								))}
							</div>

						</div>
					</section>
				</div>
			</div>
		</>
	)
}

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BootstrapTooltip } from "../../Common/common";
import { InsertSlaveGroup, UpdateSlaveGroup, getRolesById, getSlaveGroups, getTags } from "../../Common/Utils";
import * as $ from "jquery";
import dt from "datatables.net-dt";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
$.fn.DataTable = dt;

export default function SlaveGroup() {

	const [tagsData, setTagsData] = useState([])
	const [btnloader, setbtnloader] = useState([])
	const [loader, setLoader] = useState(false)
	const [permision, setpermision] = useState({})
	const userInfo = JSON.parse(window.localStorage.getItem("user_info"));
    const [showModal, setShowModal] = useState(false)
    const [details, setDetails] = useState({})
    const [modalData, setModalData] = useState({})
    const [isdisable, setIsDisable] = useState(false)

	const onHandaleRoles = async () => {
		const data = await getRolesById(userInfo?.role);
		if (data.success === true) {
			const obj = data.data.data[0];
			setpermision({ ...obj, ROLES_OTHER_PERMISSIONS: JSON.parse(obj?.roles_other_permissions||'{}') });
		} else {
			console.log('something want wrong')
		}
	}

	const ongetTagsData = async () => {
		setLoader(true);
		$(".loader").fadeOut("slow");
		const data = await getSlaveGroups()
		if (data.success === true) {
			setTagsData(data.data.data)
			setLoader(false)
		} else {
			console.log('somthing want wrong')
			setLoader(false)
		}
	}

	useEffect(() => {
		onHandaleRoles()
		ongetTagsData().then(() =>
			setTimeout(function () {
				// $("#table-1").DataTable({ "pageLength": 50, order: [[0, 'desc']] });
				setLoader(false);
			}, 1000)
		);
	}, []);
	

    const onSubmitCredential = async () => {
        if (details?.name == '') {
            return toast.error('Enter name')
        }
        setbtnloader(true)
        if (Object.keys(modalData || '{}').length == 0) {
            const data = await InsertSlaveGroup({ ...details,add_by:userInfo?.unique_id })
            if (data.success == true) {
                toast.success('Insert Success')
                ongetTagsData()
                handleCloseModal()
                setbtnloader(false)
            } else {
                toast.error('Server Issue')
                setbtnloader(false)
            }
        } else {
            const data = await UpdateSlaveGroup({ ...details,add_by:userInfo?.unique_id }, modalData?.unique_id)
            if (data.success == true) {
                toast.success('Update Success')
                ongetTagsData()
                setbtnloader(false)
                handleCloseModal()
            } else {
                toast.error('Server Issue')
                setbtnloader(false)
            }
        }
    }

    const handleCloseModal = () => {
        setShowModal(false)
        setDetails({})
        setModalData({})

    }

    const handleRowClick=(item)=>{
        setModalData(item)
        setDetails({name:item?.group_name,status:item?.status})
        setShowModal(true)
    }

	return (
		<>
         <Modal show={showModal} onHide={handleCloseModal} size="lg" style={{ fontFamily: "Nunito, Segoe UI, arial" }}>
                <Modal.Header closeButton>
                    <Modal.Title>{Object.keys(modalData || '{}').length == 0 ? 'Add slave Group' : 'Update slave Group'}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="form-row rounded p-2 d-flex" style={{ marginLeft: '10px', borderColor: '#e3e6fc !important', position: 'relative', flexWrap: 'wrap' }}>
                        <div className="form-group col-md-5 tw-mr-3">
                            <label for="inputPassword4">Name:<span className='text-danger'>*</span></label>
                            <input className="form-control" id="profile_name" name="profile_name" type="text" disabled={isdisable} value={details?.name} placeholder="" onChange={(e) => (setDetails({ ...details, name: e.target.value }))} />
                        </div>

                        {Object.keys(modalData || '{}').length != 0 && <div className="form-group col-md-3" id="grpeui">
                            <label for="inputPassword4 ">Status :</label>
                            <select className="form-control form-select" name="reporttype" required="required" onChange={(e) => { setDetails({ ...details, status: e.target.value }) }} value={details?.status} disabled={isdisable}>
                                <option value="1">Active</option>
                                <option value="0">InActive</option>
                            </select>
                        </div>}
                    </div>
                    <div class='d-flex mb-4 !tw-mt-4'>
                        <div className='btn btn-danger tw-mr-2' onClick={onSubmitCredential}>Submit</div>
                        <div className='btn btn-light ' onClick={() => {
                            handleCloseModal()
                        }}>Cancel</div>
                    </div>
                </Modal.Body>

            </Modal>
			{loader ? (
				<div className="main-content">
					<div class="loader"></div>
				</div>
			) : (
				""
			)}
			{/* <div class="main-content"> */}
				{/* <div class="col-12 d-flex justify-content-between align-items-center pb-3 px-0">
					<nav aria-label="breadcrumb">
						<ol class="breadcrumb p-0 mb-0">
							<li class="breadcrumb-item">
								<a href="/">
									<i class="fa fa-dashboard fa-lg"></i> Home
								</a>
							</li>
							<li class="breadcrumb-item active" aria-current="page">
								<a href="/" class="open_rightsidebar">
									<i class="fas fa-list"></i> Tags{" "}
								</a>
							</li>
						</ol>
					</nav>
				</div> */}
				<section class="section">
					<div class="section-body">
						<div class="row">
							<div class="col-12">
								<div class="card">
									<div class="card-header">
										<h4>Group</h4>
										<div class="card-header-action">
											 <Link class="btn btn-info text-white" onClick={()=>(setShowModal(true))}>
												<BootstrapTooltip title="Add">
													<span>
														<i
															className="fas fa-plus text-white"
															style={{ fontSize: "16px", paddingLeft: "5px", paddingRight: "5px" }}
														></i>
													</span>
												</BootstrapTooltip>
											</Link>
										</div>
									</div>
									<div class="card-body">
										<div className="table-responsive">
											<table className="table table-striped text-center" id="table-1">
												<thead>
													<tr>
														<th className="border" style={{ maxWidth: "50px" }}>Unique No</th>
														<th style={{ maxWidth: "300px" }} className="border">Group Name</th>
														{/* <th className="border">Tags Description</th> */}
														<th style={{ maxWidth: "100px" }} className="border">Status</th>

													</tr>
												</thead>
												<tbody>
													{tagsData &&
														tagsData.map((item, id) => (
															<tr style={{ height: "4rem" }} className="trheigth" key={id} >
                                                                <td class="tw-px-2 tw-py-3 tw-text-center tw-text-blue-600 tw-cursor-pointer tw-group" onClick={() => handleRowClick(item)}>
                                                            <div className='tw-flex tw-items-center  tw-gap-2 tw-mx-2'>
                                                                <span>{item?.u_sr_no}</span>
                                                                <div className="tw-opacity-0 tw-transition-all group-hover:tw-opacity-100" >
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" id="right-arrow"><g data-name="Layer 2"><g data-name="arrowhead-right"><path fill='currentColor' d="m18.78 11.37-4.78-6a1 1 0 0 0-1.41-.15 1 1 0 0 0-.15 1.41L16.71 12l-4.48 5.37a1 1 0 0 0 .13 1.41A1 1 0 0 0 13 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 .01-1.27z"></path><path fill='currentColor' d="M7 5.37a1 1 0 0 0-1.61 1.26L9.71 12l-4.48 5.36a1 1 0 0 0 .13 1.41A1 1 0 0 0 6 19a1 1 0 0 0 .77-.36l5-6a1 1 0 0 0 0-1.27z"></path></g></g></svg>
                                                                </div>
                                                            </div>
                                                        </td>
																
																<td className="border">{item.group_name}</td>
																{/* <td className="border">{item.DESCRIPTION}</td> */}
																{item.status === 1 ? <td className="text-center border">
																	<div className="badge badge-success badge-shadow text-white">Active</div>
																</td> : <td className="text-center border">
																	<div className="badge badge-danger badge-shadow text-white">Inactive</div>
																</td>}
															</tr>
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			{/* </div> */}
		</>
	);
}
